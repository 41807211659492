import React, { FC } from 'react';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import { FilterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { NiladicVoid } from 'core/globalTypes';

import { Badge, Button } from 'components/basic';

interface Props {
  onClick: NiladicVoid;
  showDot: boolean;
}

const FilterButton: FC<Props> = ({ onClick, showDot }) => {
  const { t } = useTranslation('common');

  return (
    <Button
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Button,
        prefix: 'filters'
      })}
      icon={<FilterOutlined />}
      onClick={onClick}
    >
      <Badge dot={showDot}>
        <span>{t('common:filters')}</span>
      </Badge>
    </Button>
  );
};

export default FilterButton;
