import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenBreakpoints } from 'hooks';
import { NiladicVoid } from 'core/globalTypes';
import { BulkActionEntity } from 'generatedHooks/commerce/generated';
import { getEntityTranslationKey } from 'services/bulkActions/helpers';
import {
  Can,
  usePermissionAndLimitationContext
} from 'providers/PermissionAndLimitationProvider';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { ITableBulkAction } from 'components/shared/Table/types';
import { Button, Divider, Typography } from 'components/basic';
import styles from './styles.module.less';

const { UcBodyText } = Typography;

interface Props {
  actions?: ITableBulkAction[];
  onCancel?: NiladicVoid;
  totalSelected: number;
  totalDataCount: number;
  entity: BulkActionEntity;
}

const BulkPanel: FC<Props> = ({
  actions,
  onCancel,
  totalSelected,
  totalDataCount,
  entity
}) => {
  const {
    state: {
      permissions: {
        actions: { Do }
      }
    }
  } = usePermissionAndLimitationContext();

  const { t } = useTranslation(['common', 'bulkActions']);
  const { xl } = useScreenBreakpoints();

  return (
    <div
      className={styles.bulk_panel}
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Component,
        prefix: 'bulk-table'
      })}
    >
      <div className={styles.grid_row_container}>
        <div>
          <div
            className={`${styles.bulk_panel_info} ${
              !xl ? styles.bulk_panel_info_lg : ''
            }`}
          >
            <UcBodyText
              type="secondary"
              size="small"
              ellipsis
              data-test={createDataTestAttribute({
                dataTestAttribute: DataTestAttributes.Text,
                prefix: 'bulk-table'
              })}
            >
              {t('bulkActions:entitiesSelectedCount', {
                selectedCount: totalSelected,
                entity: t(getEntityTranslationKey(entity, totalSelected)),
                totalCount: totalDataCount
              })}
            </UcBodyText>
            <Button
              size="small"
              type="text"
              className={styles.cancel}
              onClick={onCancel}
              data-test={createDataTestAttribute({
                dataTestAttribute: DataTestAttributes.Button,
                prefix: 'cancel'
              })}
            >
              {t('cancel')}
            </Button>
            <Divider type="vertical" className={styles.bulk_panel_divider} />
          </div>
        </div>
        <div>
          <div
            data-test="bulk-panel-actions"
            className={styles.bulk_panel_actions}
          >
            <div className={styles.action_bar_container}>
              {actions?.map((action: ITableBulkAction, idx: number) => {
                return (
                  <Can I={Do.BulkActions} key={`${action.slug}${idx}`}>
                    <Button
                      className={styles.action}
                      onClick={() => action.onClick(totalSelected)}
                      danger={Boolean(action.danger)}
                      type={action.danger ? 'primary' : 'default'}
                      data-test={createDataTestAttribute({
                        dataTestAttribute: DataTestAttributes.Button,
                        prefix: action.label
                      })}
                      loading={Boolean(action.loading)}
                    >
                      {action.label}
                    </Button>
                  </Can>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkPanel;
