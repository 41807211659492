import React, { ChangeEvent, cloneElement, FC, useState } from 'react';
import { Popover } from 'antd';
import usePermissionAndLimitationContext from 'providers/PermissionAndLimitationProvider/hooks/usePermissionAndLimitationContext';
import { ChildrenWithProps } from 'providers/PermissionAndLimitationProvider/core/helpers';
import { LimitedAccessAlert } from 'providers/PermissionAndLimitationProvider/components/LimitedAccessAlert';
import { ADD_NEW_NOTE } from 'providers/PermissionAndLimitationProvider/context/actionTypes';
import { PermissionsService } from 'providers/PermissionAndLimitationProvider/services';
import { useTranslation } from 'react-i18next';
import { LockOutlined } from '@ant-design/icons';
import { antPopoverConfigs } from 'providers/PermissionAndLimitationProvider/core/constants';
import useQuicklyCheckPermissionAndLimitation from 'providers/PermissionAndLimitationProvider/hooks/useQuicklyCheckPermissionAndLimitation';
import SystemMessage from 'helpers/utils/SystemMessage';

import { CanProps } from './types';
import IntermediateWrapper from '../IntermediateWrapper';

const Can: FC<CanProps> = ({
  I = '',
  Or = '',
  RequestAccess = '',
  isView = false,
  customAlertComponentForPermission,
  customComponentForAction,
  children,
  viewWrapperStyles,
  ...rest
}) => {
  const {
    state: {
      isOwner,
      project,
      redirectHandler,
      environment: { SSO_URL },
      permissions: {
        availablePermissions,
        notes = {},
        actions: { Update }
      }
    },
    dispatch
  } = usePermissionAndLimitationContext();

  const _children = children ? cloneElement(children, rest) : children;

  const { checkPermission } = useQuicklyCheckPermissionAndLimitation();

  const checkCanUpdatePlan = checkPermission(Update.Plan);

  const [note, setNote] = useState('');
  const [loadingSentNote, setLoadingSentNote] = useState(false);
  const isExistNoteForThisPermission = I in notes;
  const { t } = useTranslation('permissions-and-limitations');

  if (!I || availablePermissions?.includes(I)) {
    return <>{_children}</>;
  }

  if (Or && availablePermissions?.includes(Or)) {
    return <>{_children}</>;
  }

  if (RequestAccess && !availablePermissions?.includes(RequestAccess)) {
    return null;
  }

  const onChangeNote = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const requestForPermissionHandler = async () => {
    try {
      setLoadingSentNote(true);

      if (project?.id && SSO_URL) {
        const optionalMessage = notes[I] ? notes[I].note : note;

        const responsePermission =
          await PermissionsService.requestForPermission(
            SSO_URL,
            I,
            project.id,
            optionalMessage
          );

        if (responsePermission?.requestPermission) {
          const requestedNote = responsePermission.requestPermission;
          dispatch({
            type: ADD_NEW_NOTE,
            payload: { [I]: { ...requestedNote } }
          });

          return SystemMessage.info(
            t('requestSent', 'Your request was successfully sent.')
          );
        }

        SystemMessage.info(
          t('requestNotSent', 'Your request is not sent. Please try again.')
        );
      }
    } catch (e) {
      SystemMessage.info(
        t('requestNotSent', 'Your request is not sent. Please try again.')
      );
    } finally {
      setLoadingSentNote(false);
    }
  };

  const AlertComponent = customAlertComponentForPermission || (
    <LimitedAccessAlert
      checkCanUpdatePlan={checkCanUpdatePlan}
      isOwner={isOwner}
      onChangeNote={onChangeNote}
      requestForPermissionHandler={requestForPermissionHandler}
      isExistNoteForThisPermission={isExistNoteForThisPermission}
      loadingSentNote={loadingSentNote}
      redirectHandler={redirectHandler}
      isView={isView}
    />
  );

  if (isView) {
    return (
      <div
        style={
          viewWrapperStyles || {
            height: '100%',
            background: '#ffffff',
            borderRadius: 12
          }
        }
      >
        {AlertComponent}
      </div>
    );
  }

  const ActionComponent = customComponentForAction || (
    <Popover {...antPopoverConfigs} content={AlertComponent}>
      <IntermediateWrapper disabled>
        {ChildrenWithProps(_children, {
          disabled: true,
          hasCanPermission: true,
          icon: <LockOutlined />
        })}
      </IntermediateWrapper>{' '}
    </Popover>
  );

  return <>{ActionComponent}</>;
};

export default Can;
