import { currentUser } from 'apollo/cache';
import { useEffect } from 'react';
import axios from 'axios';
import { customHeaders, KeyValuePair, NiladicVoid } from 'core/globalTypes';
import { DEFAULT_LANGUAGE } from 'core/constants/common';
import i18n from 'i18next';
import isMarketplaceSellerMode from 'helpers/commonHelpers/isMarketplaceSellerMode';

const { VITE_APP_SSO_URL, VITE_APP_COMMERCE_URL, VITE_APP_SELLER_LOGIN_URL } =
  import.meta.env;

const callbackUrl = `${VITE_APP_SSO_URL}/callback?target=dashboard`;

const getUser = async ({
  fetchUser,
  onError
}: {
  fetchUser: () => Promise<KeyValuePair>;
  onError?: NiladicVoid;
}) => {
  try {
    const user = await fetchUser();

    // TODO-MARAT: Test this for sso user
    currentUser(user);
    const userLanguage = user.language || DEFAULT_LANGUAGE;

    await i18n.changeLanguage(userLanguage);
    document.documentElement.setAttribute('lang', userLanguage);
  } catch (error) {
    onError?.();
  }
};

const useProtectedRoute = () => {
  useEffect(() => {
    const onLoad = () => {
      if (isMarketplaceSellerMode()) {
        getUser({
          fetchUser: () =>
            axios
              .get(`${VITE_APP_COMMERCE_URL}/seller-api/me`, {
                withCredentials: true
              })
              .then(user => user.data),
          onError: () => {
            window.location.replace(VITE_APP_SELLER_LOGIN_URL);
          }
        });
      } else {
        getUser({
          fetchUser: () =>
            axios
              .get(`${VITE_APP_SSO_URL}/api/user`, {
                headers: {
                  ...customHeaders,
                  Authorization: `Bearer ${localStorage.token}`
                }
              })
              .then(user => user.data.data),
          onError: () =>
            (location.href = `${callbackUrl}&returnUrl=${location.pathname}`)
        });

        if (!localStorage.token) {
          location.href = `${callbackUrl}&returnUrl=${location.pathname}`;
        }
      }
    };

    if (!currentUser()) {
      onLoad();
    }
  }, []);
};

export default useProtectedRoute;
