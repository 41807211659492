import { TFunction } from 'react-i18next';
import {
  ConditionOperator,
  QueryPayoutsConditionColumn,
  QueryRequestedPayoutsConditionColumn,
  QueryRequestedPayoutsOrderColumn,
  SortOrder
} from 'generatedHooks/commerce/generated';
import { SortTypes } from 'core/globalTypes';
import i18n from 'i18next';
import { getMarkets } from 'customGQLrequests/markets';

import { FilterElement } from 'components/shared/Filter/types';
import { PaymentTabsEnum } from './types';

export const tabKey = 'tab';

export const defaultResetQueryData = ['page', 'count', 'sort'];

export const PayoutsFilterEnum = {
  ...QueryRequestedPayoutsConditionColumn,
  FromDate: 'from',
  ToDate: 'to',
  CommissionFrom: 'commissionFrom',
  CommissionTo: 'commissionTo',
  AmountFrom: 'amountFrom',
  AmountTo: 'amountTo',
  RequestedDateFrom: 'requestedFrom',
  RequestedDateTo: 'requestedTo'
};

export const REQUESTED_FILTER_INITIAL_STATE = {
  [PayoutsFilterEnum.FromDate]: {
    column: QueryRequestedPayoutsConditionColumn.CreatedAt,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [PayoutsFilterEnum.ToDate]: {
    column: QueryRequestedPayoutsConditionColumn.CreatedAt,
    operator: ConditionOperator.Lte,
    value: ''
  },
  [PayoutsFilterEnum.CommissionFrom]: {
    column: QueryRequestedPayoutsConditionColumn.Commission,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [PayoutsFilterEnum.CommissionTo]: {
    column: QueryRequestedPayoutsConditionColumn.Commission,
    operator: ConditionOperator.Lte,
    value: ''
  },
  [PayoutsFilterEnum.MarketId]: {
    column: QueryRequestedPayoutsConditionColumn.MarketId,
    operator: ConditionOperator.Eq,
    value: ''
  }
};

export const PAYOUTS_FILTER_INITIAL_STATE = {
  [PayoutsFilterEnum.FromDate]: {
    column: QueryPayoutsConditionColumn.CreatedAt,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [PayoutsFilterEnum.ToDate]: {
    column: QueryPayoutsConditionColumn.CreatedAt,
    operator: ConditionOperator.Lte,
    value: ''
  },
  [PayoutsFilterEnum.RequestedDateFrom]: {
    column: QueryPayoutsConditionColumn.RequestedAt,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [PayoutsFilterEnum.RequestedDateTo]: {
    column: QueryPayoutsConditionColumn.RequestedAt,
    operator: ConditionOperator.Lte,
    value: ''
  },
  [PayoutsFilterEnum.FromDate]: {
    column: QueryPayoutsConditionColumn.CreatedAt,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [PayoutsFilterEnum.ToDate]: {
    column: QueryPayoutsConditionColumn.CreatedAt,
    operator: ConditionOperator.Lte,
    value: ''
  },
  [PayoutsFilterEnum.AmountFrom]: {
    column: QueryPayoutsConditionColumn.Amount,
    operator: ConditionOperator.Gte,
    value: ''
  },
  [PayoutsFilterEnum.AmountTo]: {
    column: QueryPayoutsConditionColumn.Amount,
    operator: ConditionOperator.Lte,
    value: ''
  },
  [PayoutsFilterEnum.MarketId]: {
    column: QueryPayoutsConditionColumn.MarketId,
    operator: ConditionOperator.Eq,
    value: ''
  }
};

export const SORT_DATA = {
  [SortTypes.Newest]: {
    column: QueryRequestedPayoutsOrderColumn.CreatedAt,
    order: SortOrder.Desc
  },
  [SortTypes.Oldest]: {
    column: QueryRequestedPayoutsOrderColumn.CreatedAt,
    order: SortOrder.Asc
  }
};

export const filters = ({
  t,
  activeTab
}: {
  t: TFunction;
  activeTab: PaymentTabsEnum;
}): FilterElement[] => {
  const filtersByTab: Record<PaymentTabsEnum, FilterElement[]> = {
    [PaymentTabsEnum.Requests]: [
      {
        type: 'dateRange',
        filterProps: {
          title: t('paymentRequests:filters.dateOfCreation'),
          showTopBorder: true
        },
        pushToUrl: true,
        fromKey: PayoutsFilterEnum.FromDate,
        toKey: PayoutsFilterEnum.ToDate,
        emptyValue: undefined,
        name: 'creationDate',
        onChange: undefined,
        placeholder: [
          t('paymentRequests:filters.startDate'),
          t('paymentRequests:filters.endDate')
        ]
      },
      {
        type: 'priceRange',
        filterProps: {
          title: t('paymentRequests:filters.commission'),
          showTopBorder: true
        },
        toKey: PayoutsFilterEnum.CommissionTo,
        fromKey: PayoutsFilterEnum.CommissionFrom,
        prefix: '$',
        onChange: undefined,
        name: 'commission',
        emptyValue: ''
      },
      {
        type: 'select',
        filterProps: {
          title: t('paymentRequests:filters.seller'),
          showTopBorder: true
        },
        name: PayoutsFilterEnum.MarketId,
        placeholder: t('paymentRequests:filters.seller'),
        emptyValue: null,
        loader: getMarkets
      }
    ],
    [PaymentTabsEnum.Fulfilled]: [
      {
        type: 'dateRange',
        filterProps: {
          title: t('paymentRequests:filters.dateOfCreation'),
          showTopBorder: true
        },
        pushToUrl: true,
        fromKey: PayoutsFilterEnum.FromDate,
        toKey: PayoutsFilterEnum.ToDate,
        emptyValue: undefined,
        name: 'creationDate',
        onChange: undefined,
        placeholder: [
          t('paymentRequests:filters.startDate'),
          t('paymentRequests:filters.endDate')
        ]
      },
      {
        type: 'dateRange',
        filterProps: {
          title: t('paymentRequests:filters.requestedDate'),
          showTopBorder: true
        },
        pushToUrl: true,
        fromKey: PayoutsFilterEnum.RequestedDateFrom,
        toKey: PayoutsFilterEnum.RequestedDateTo,
        emptyValue: undefined,
        name: 'requestedDate',
        onChange: undefined,
        placeholder: [
          t('paymentRequests:filters.requestedStartDate'),
          t('paymentRequests:filters.requestedEndDate')
        ]
      },
      {
        type: 'priceRange',
        filterProps: {
          title: t('paymentRequests:filters.amount'),
          showTopBorder: true
        },
        toKey: PayoutsFilterEnum.AmountTo,
        fromKey: PayoutsFilterEnum.AmountFrom,
        prefix: '$',
        onChange: undefined,
        name: 'amount',
        emptyValue: ''
      },
      {
        type: 'select',
        filterProps: {
          title: t('paymentRequests:filters.seller'),
          showTopBorder: true
        },
        name: PayoutsFilterEnum.MarketId,
        placeholder: t('paymentRequests:filters.seller'),
        emptyValue: null,
        loader: getMarkets
      }
    ]
  };

  return filtersByTab[activeTab];
};

export const FilterKeysToRest = [
  PayoutsFilterEnum.FromDate,
  PayoutsFilterEnum.RequestedDateFrom,
  PayoutsFilterEnum.RequestedDateTo,
  PayoutsFilterEnum.AmountTo,
  PayoutsFilterEnum.AmountFrom,
  PayoutsFilterEnum.MarketId,
  PayoutsFilterEnum.CommissionFrom,
  PayoutsFilterEnum.CommissionTo,
  PayoutsFilterEnum.ToDate
];

export const DEFAULT_SORT_DATA = [
  {
    value: SortTypes.Newest,
    name: i18n.t('common:sortNew', 'Newest')
  },
  {
    value: SortTypes.Oldest,
    name: i18n.t('common:sortOld', 'Oldest')
  }
];
