import {
  OrderFulfillStatus,
  OrderPaymentStatus
} from 'generatedHooks/commerce/generated';

import { TagType } from 'components/basic/Tag/types';

export const TAGS_BY_STATUS: Record<
  OrderFulfillStatus | OrderPaymentStatus,
  TagType
> = {
  [OrderFulfillStatus.Fulfilled]: 'success',
  [OrderPaymentStatus.Paid]: 'success',
  [OrderFulfillStatus.Delivered]: 'success',
  [OrderPaymentStatus.Refunded]: 'success',
  [OrderFulfillStatus.Dispatched]: 'success',
  [OrderFulfillStatus.Canceled]: 'danger',
  [OrderFulfillStatus.Returned]: 'danger',
  [OrderFulfillStatus.Unfulfilled]: 'danger',
  [OrderPaymentStatus.Unpaid]: 'danger',
  [OrderFulfillStatus.PartiallyFulfilled]: 'warning',
  [OrderFulfillStatus.PartiallyReturned]: 'warning',
  [OrderFulfillStatus.PickedUp]: 'warning',
  [OrderPaymentStatus.PartiallyRefunded]: 'warning',
  [OrderPaymentStatus.InProgress]: 'warning',
  [OrderPaymentStatus.PartiallyPaid]: 'warning',
  [OrderPaymentStatus.AwaitingPayment]: 'warning',
  //TODO: We need to handle Mixed status in all places
  [OrderFulfillStatus.Mixed]: 'warning'
};
