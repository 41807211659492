import React, { ComponentType } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider
} from 'react-router-dom';
import { RouteAliases } from 'routes/types';
import {
  AbandonedCheckout,
  ActivityLog,
  AddAppointmentProduct,
  AddBooking,
  AddBookingCategories,
  AddCategories,
  AddCollection,
  AddCollectionCategories,
  AddCollectionItem,
  AddDigitalProduct,
  AddEvent,
  AddEventCategories,
  AddGiftCardProduct,
  AddProduct,
  AddRentalProduct,
  AddRentalType,
  AddSeller,
  AddSubscriptionProduct,
  AddTableProduct,
  AnalyticsOverview,
  Apps,
  Attendees,
  Attributes,
  Billing,
  BookingProductFamilyCreation,
  BookingProductFamilyEdit,
  Bookings,
  BookingTypes,
  Categories,
  Checkout,
  CollectionItems,
  Collections,
  CreateShippingProfile,
  CreateUpdateDiscounts,
  CreateUpdateSelfPickup,
  CustomerPreview,
  CustomerReviews,
  CustomersGroups,
  CustomersOverview,
  Dashboard,
  DiscountsList,
  Domains,
  EditAppointmentProduct,
  EditBooking,
  EditBookingCategories,
  EditCategories,
  EditCollection,
  EditCollectionCategories,
  EditCollectionItem,
  EditDigitalProduct,
  EditEvent,
  EditEventCategories,
  EditGiftCardProduct,
  EditProduct,
  EditRentalProduct,
  EditRentalType,
  EditSeller,
  EditSubscriptionProduct,
  EditTableProduct,
  EventProductFamilyCreation,
  EventProductFamilyEdit,
  Events,
  EventTypes,
  FormFields,
  FormSubmission,
  FormSubmissions,
  InviteMember,
  Languages,
  LegalDoc,
  LegalDocs,
  Locations,
  MarketplaceConfigs,
  Notifications,
  OrderDetails,
  Orders,
  OtherPayments,
  Payments,
  PreviewPage,
  ProductFamily,
  ProductFamilyCreation,
  ProductFamilyEdit,
  Products,
  Projects,
  ProjectSetup,
  ProjectWrapper,
  RentalOrders,
  Rentals,
  RentalTypes,
  Reservations,
  SalesChannels,
  SellerDetails,
  SellerPayments,
  Sellers,
  Shipments,
  ShippingDelivery,
  SinglePayments,
  Taxes,
  Team,
  TimeSlots,
  PaymentRequests,
  MarketplaceReviews
} from 'routes/lazyImports';
import AuthProvider from 'providers/AuthProvider';
import { SmartEntityType } from 'core/globalTypes';
import {
  CategoryType,
  ProductTypeGroup
} from 'generatedHooks/commerce/generated';
import marketplaceSellerModeResolver from 'helpers/commonHelpers/marketplaceSellerModeResolver';

import { protectedRoute } from './utils';
import Settings from 'pages/settings';
import CodeInjection from 'pages/settings/pages/CodeInjection';
import Forbidden from 'pages/forbidden';
import ProjectNavigator from './components/ProjectNavigator';
import 'styles.css';
import { OrderTypes } from 'pages/orderDetails/constants';
import NotFound from 'pages/notFound';
import { ErrorBoundary } from 'components/wrapper';
import { ProductFruits } from 'components/global';

const { VITE_APP_SELLER_DASHBOARD_PREFIX } = import.meta.env;

const ProjectsProtected = protectedRoute(Projects, RouteAliases.PROJECTS_PAGE);

const CurrentProject = protectedRoute(ProjectWrapper as ComponentType);
const DashboardProtected = protectedRoute(
  Dashboard,
  RouteAliases.DASHBOARD_PAGE
);

const OrdersProtected = protectedRoute(Orders, RouteAliases.ORDERS_PAGE);

const AbandonedCheckoutProtected = protectedRoute(
  () => <AbandonedCheckout type={ProductTypeGroup.Physical} />,
  RouteAliases.ABANDONED_CHECKOUT_PAGE
);

const ShipmentsProtected = protectedRoute(Shipments, RouteAliases.SHIPMENTS);

const MarketplaceShipmentsProtected = protectedRoute(
  Shipments,
  RouteAliases.MARKETPLACE_SHIPMENTS
);

const EventsAbandonedCheckoutProtected = protectedRoute(
  () => <AbandonedCheckout type={ProductTypeGroup.BookingEvent} />,
  RouteAliases.EVENTS_ABANDONED_CHECKOUT_PAGE
);

const BookingsAbandonedCheckoutProtected = protectedRoute(
  () => <AbandonedCheckout type={ProductTypeGroup.BookingReservation} />,
  RouteAliases.BOOKINGS_ABANDONED_CHECKOUT_PAGE
);

const RentalsAbandonedCheckoutProtected = protectedRoute(
  () => <AbandonedCheckout type={ProductTypeGroup.BookingRental} />,
  RouteAliases.RENTALS_ABANDONED_CHECKOUT_PAGE
);

const OrderDetailsProtected = protectedRoute(
  () => <OrderDetails orderType={OrderTypes.Order} />,
  RouteAliases.ORDER_DETAILS_PAGE
);

const MarketplaceOrderDetailsProtected = protectedRoute(
  () => <OrderDetails orderType={OrderTypes.Order} />,
  RouteAliases.MARKETPLACE_ORDER_DETAILS_PAGE
);

const AttendeesDetailsProtected = protectedRoute(
  () => <OrderDetails orderType={OrderTypes.Attendee} />,
  RouteAliases.SINGLE_ATENDEES_PAGE
);

const ReservationDetailsProtected = protectedRoute(
  () => <OrderDetails orderType={OrderTypes.Reservation} />,
  RouteAliases.RESERVATION_DETAILS_PAGE
);

const RentalOrderDetailsProtected = protectedRoute(
  () => <OrderDetails orderType={OrderTypes.Order} />,
  RouteAliases.RENTAL_ORDER_DETAILS_PAGE
);

const AttributesProtected = protectedRoute(
  Attributes,
  RouteAliases.ATTRIBUTES_PAGE
);

const CategoriesProtected = protectedRoute(
  () => <Categories type={CategoryType.Product} />,
  RouteAliases.CATEGORIES_PAGE
);

const AddAutomaticCategoriesProtected = protectedRoute(
  () => (
    <AddCategories
      type={SmartEntityType.Automatic}
      categoryType={CategoryType.Product}
    />
  ),
  RouteAliases.ADD_PRODUCT_AUTOMATIC_CATEGORIES_PAGE
);

const AddManualCategoriesProtected = protectedRoute(
  () => (
    <AddCategories
      type={SmartEntityType.Manual}
      categoryType={CategoryType.Product}
    />
  ),
  RouteAliases.ADD_PRODUCT_MANUAL_CATEGORIES_PAGE
);

const EditCategoriesProtected = protectedRoute(
  () => <EditCategories categoryType={CategoryType.Product} />,
  RouteAliases.EDIT_PRODUCT_CATEGORIES_PAGE
);

const CustomerPreviewProtected = protectedRoute(
  CustomerPreview,
  RouteAliases.CUSTOMER_PREVIEW_PAGE
);

const CustomersOverviewProtected = protectedRoute(
  CustomersOverview,
  RouteAliases.CUSTOMER_OVERVIEW_PAGE
);

const CustomersGroupsProtected = protectedRoute(
  CustomersGroups,
  RouteAliases.CUSTOMER_GROUPS_PAGE
);

const FormSubmissionsProtected = protectedRoute(
  FormSubmissions,
  RouteAliases.FORM_SUBMISSIONS
);

const FormSubmissionProtected = protectedRoute(
  FormSubmission,
  RouteAliases.FORM_SUBMISSION
);

const CustomerReviewsProtected = protectedRoute(
  CustomerReviews,
  RouteAliases.CUSTOMER_REVIEWS_PAGE
);

const DiscountsListProtected = protectedRoute(
  DiscountsList,
  RouteAliases.DISCOUNT_LIST_PAGE
);

const CreateAutomaticDiscountProtected = protectedRoute(
  () => <CreateUpdateDiscounts type={SmartEntityType.Automatic} />,
  RouteAliases.CREATE_AUTOMATIC_DISCOUNT
);

const UpdateAutomaticDiscountProtected = protectedRoute(
  () => <CreateUpdateDiscounts type={SmartEntityType.Automatic} />,
  RouteAliases.UPDATE_AUTOMATIC_DISCOUNT
);

const CreateManualDiscountProtected = protectedRoute(
  () => <CreateUpdateDiscounts type={SmartEntityType.Manual} />,
  RouteAliases.CREATE_MANUAL_DISCOUNT
);

const UpdateManualDiscountProtected = protectedRoute(
  () => <CreateUpdateDiscounts type={SmartEntityType.Manual} />,
  RouteAliases.UPDATE_MANUAL_DISCOUNT
);

const AnalyticsOverviewProtected = protectedRoute(
  AnalyticsOverview,
  RouteAliases.ANALYTICS_OVERVIEW_PAGE
);

const ShippingDeliveryProtected = protectedRoute(
  ShippingDelivery,
  RouteAliases.SHIPPING_DELIVERY_PAGE
);

// Todo
// const ShippingProtected = protectedRoute(Shipping, RouteAliases.SHIPPING);
// const SelfPickupsProtected = protectedRoute(
//   SelfPickups,
//   RouteAliases.SELF_PICKUPS
// );
//
// const PackagesProtected = protectedRoute(Packages, RouteAliases.PACKAGES);

const CreateShippingProfileProtected = protectedRoute(
  CreateShippingProfile,
  RouteAliases.CREATE_SHIPPING_PROFILE
);

const CreateSelfPickupProtected = protectedRoute(
  CreateUpdateSelfPickup,
  RouteAliases.CREATE_SELF_PICKUP
);

const UpdateSelfPickupProtected = protectedRoute(
  CreateUpdateSelfPickup,
  RouteAliases.CREATE_SELF_PICKUP
);

const TaxesProtected = protectedRoute(Taxes, RouteAliases.TAXES_PAGE);

const CheckoutProtected = protectedRoute(Checkout, RouteAliases.CHECKOUT_PAGE);

const LegalDocsProtected = protectedRoute(
  LegalDocs,
  RouteAliases.LEGAL_DOCS_PAGE
);

const LegalDocProtected = protectedRoute(LegalDoc, RouteAliases.LEGAL_DOC_PAGE);

// const MailboxesProtected = protectedRoute(Mailboxes);

const LanguagesProtected = protectedRoute(
  Languages,
  RouteAliases.LANGUAGES_PAGE
);

const DomainsProtected = protectedRoute(Domains, RouteAliases.DOMAINS_PAGE);

const BillingProtected = protectedRoute(Billing, RouteAliases.BILLING_PAGE);

const ProductsProtected = protectedRoute(Products, RouteAliases.PRODUCTS_PAGE);

const MarketplaceProductsProtected = protectedRoute(
  () => <Products isMarketplace />,
  RouteAliases.MARKETPLACE_PRODUCTS_PAGE
);

const MarketPlaceOrdersProtected = protectedRoute(
  () => <Orders isMarketplace />,
  RouteAliases.MARKETPLACE_ORDERS_PAGE
);

const AddProductProtected = protectedRoute(
  AddProduct,
  RouteAliases.ADD_PHYSICAL_PRODUCT_PAGE
);

const AddMarketplaceProductProtected = protectedRoute(
  () => <AddProduct isMarketplace />,
  RouteAliases.ADD_MARKETPLACE_PHYSICAL_PRODUCT_PAGE
);

const EditProductProtected = protectedRoute(
  EditProduct,
  RouteAliases.EDIT_PHYSICAL_PRODUCT_PAGE
);

const EditMarketplaceProductProtected = protectedRoute(
  () => <EditProduct isMarketplace />,
  RouteAliases.EDIT_MARKETPLACE_PHYSICAL_PRODUCT_PAGE
);

const AddSubscriptionProductProtected = protectedRoute(
  AddSubscriptionProduct,
  RouteAliases.ADD_SUBSCRIPTION_PRODUCT_PAGE
);

const AddMarketplaceSubscriptionProductProtected = protectedRoute(
  () => <AddSubscriptionProduct isMarketplace />,
  RouteAliases.ADD_MARKETPLACE_SUBSCRIPTION_PRODUCT_PAGE
);

const EditSubscriptionProductProtected = protectedRoute(
  EditSubscriptionProduct,
  RouteAliases.EDIT_SUBSCRIPTION_PRODUCT_PAGE
);

const EditMarketplaceSubscriptionProductProtected = protectedRoute(
  () => <EditSubscriptionProduct isMarketplace />,
  RouteAliases.EDIT_MARKETPLACE_SUBSCRIPTION_PRODUCT_PAGE
);

const AddDigitalProductProtected = protectedRoute(
  AddDigitalProduct,
  RouteAliases.ADD_DIGITAL_PRODUCT_PAGE
);

const AddMarketplaceDigitalProductProtected = protectedRoute(
  () => <AddDigitalProduct isMarketplace />,
  RouteAliases.ADD_MARKETPLACE_DIGITAL_PRODUCT_PAGE
);

const EditDigitalProductProtected = protectedRoute(
  EditDigitalProduct,
  RouteAliases.EDIT_DIGITAL_PRODUCT_PAGE
);

const EditMarketplaceDigitalProductProtected = protectedRoute(
  () => <EditDigitalProduct isMarketplace />,
  RouteAliases.EDIT_MARKETPLACE_DIGITAL_PRODUCT_PAGE
);

//appointment product
const AddAppointmentProductProtected = protectedRoute(
  AddAppointmentProduct,
  RouteAliases.ADD_APPOINTMENT_PRODUCT_PAGE
);

const AddMarketplaceAppointmentProductProtected = protectedRoute(
  () => <AddAppointmentProduct isMarketplace />,
  RouteAliases.ADD_MARKETPLACE_APPOINTMENT_PRODUCT_PAGE
);

const EditAppointmentProductProtected = protectedRoute(
  EditAppointmentProduct,
  RouteAliases.EDIT_APPOINTMENT_PRODUCT_PAGE
);

const EditMarketplaceAppointmentProductProtected = protectedRoute(
  () => <EditAppointmentProduct isMarketplace />,
  RouteAliases.EDIT_MARKETPLACE_APPOINTMENT_PRODUCT_PAGE
);

//appointment product
const AddBookingProtected = protectedRoute(
  AddBooking,
  RouteAliases.CREATE_BOOKING_PAGE
);

const EditBookingProtected = protectedRoute(
  EditBooking,
  RouteAliases.EDIT_BOOKING_PAGE
);

const AddRentalProductProtected = protectedRoute(
  AddRentalProduct,
  RouteAliases.ADD_RENTAL_PRODUCT_PAGE
);

const EditRentalProductProtected = protectedRoute(
  EditRentalProduct,
  RouteAliases.EDIT_RENTAL_PRODUCT_PAGE
);

const RentalTypesProtected = protectedRoute(
  RentalTypes,
  RouteAliases.RENTAL_TYPES_PAGE
);

const AddRentalTypeProtected = protectedRoute(
  AddRentalType,
  RouteAliases.ADD_RENTAL_TYPE
);

const EditRentalTypeProtected = protectedRoute(
  EditRentalType,
  RouteAliases.EDIT_RENTAL_TYPE
);

const AddTableProductProtected = protectedRoute(
  AddTableProduct,
  RouteAliases.ADD_TABLE_PRODUCT_PAGE
);

const AddMarketplaceTableProductProtected = protectedRoute(
  () => <AddTableProduct isMarketplace />,
  RouteAliases.ADD_MARKETPLACE_TABLE_PRODUCT_PAGE
);

const EditTableProductProtected = protectedRoute(
  EditTableProduct,
  RouteAliases.EDIT_TABLE_PRODUCT_PAGE
);

const EditMarketplaceTableProductProtected = protectedRoute(
  () => <EditTableProduct isMarketplace />,
  RouteAliases.EDIT_MARKETPLACE_TABLE_PRODUCT_PAGE
);

const AddGiftCardProductProtected = protectedRoute(
  AddGiftCardProduct,
  RouteAliases.ADD_GIFT_CARD_PRODUCT_PAGE
);

const AddMarketplaceGiftCardProductProtected = protectedRoute(
  () => <AddGiftCardProduct isMarketplace />,
  RouteAliases.ADD_MARKETPLACE_GIFT_CARD_PRODUCT_PAGE
);

const EditGiftCardProductProtected = protectedRoute(
  EditGiftCardProduct,
  RouteAliases.EDIT_GIFT_CARD_PRODUCT_PAGE
);

const EditMarketplaceGiftCardProductProtected = protectedRoute(
  () => <EditGiftCardProduct isMarketplace />,
  RouteAliases.EDIT_MARKETPLACE_GIFT_CARD_PRODUCT_PAGE
);

const BookingsProtected = protectedRoute(Bookings, RouteAliases.BOOKINGS_PAGE);

const RentalsProtected = protectedRoute(Rentals, RouteAliases.RENTALS_PAGE);

const RentalCategoriesProtected = protectedRoute(
  () => <Categories type={CategoryType.Rental} />,
  RouteAliases.RENTAL_CATEGORIES
);

const AddAutomaticRentalCategoriesProtected = protectedRoute(
  () => (
    <AddBookingCategories
      type={SmartEntityType.Automatic}
      categoryType={CategoryType.Rental}
    />
  ),
  RouteAliases.CREATE_RENTAL_AUTOMATIC_CATEGORY
);

const AddManualRentalCategoriesProtected = protectedRoute(
  () => (
    <AddBookingCategories
      type={SmartEntityType.Manual}
      categoryType={CategoryType.Rental}
    />
  ),
  RouteAliases.CREATE_RENTAL_MANUAL_CATEGORY
);

const EditRentalCategoriesProtected = protectedRoute(
  () => <EditBookingCategories categoryType={CategoryType.Rental} />,
  RouteAliases.EDIT_RENTAL_CATEGORY
);

const BookingCategoriesProtected = protectedRoute(
  () => <Categories type={CategoryType.Reservation} />,
  RouteAliases.BOOKING_CATEGORIES
);

const AddAutomaticBookingCategoriesProtected = protectedRoute(
  () => (
    <AddBookingCategories
      type={SmartEntityType.Automatic}
      categoryType={CategoryType.Reservation}
    />
  ),
  RouteAliases.CREATE_BOOKING_AUTOMATIC_CATEGORY
);

const AddManualBookingCategoriesProtected = protectedRoute(
  () => (
    <AddBookingCategories
      type={SmartEntityType.Manual}
      categoryType={CategoryType.Reservation}
    />
  ),
  RouteAliases.CREATE_BOOKING_MANUAL_CATEGORY
);

const EditBookingCategoriesProtected = protectedRoute(
  () => <EditBookingCategories categoryType={CategoryType.Reservation} />,
  RouteAliases.EDIT_BOOKING_CATEGORY
);

const EventCategoriesProtected = protectedRoute(
  () => <Categories type={CategoryType.Event} />,
  RouteAliases.EVENT_CATEGORIES
);

const AddAutomaticEventCategoriesProtected = protectedRoute(
  () => (
    <AddEventCategories
      type={SmartEntityType.Automatic}
      categoryType={CategoryType.Event}
    />
  ),
  RouteAliases.CREATE_EVENT_AUTOMATIC_CATEGORY
);

const AddManualEventCategoriesProtected = protectedRoute(
  () => (
    <AddEventCategories
      type={SmartEntityType.Manual}
      categoryType={CategoryType.Event}
    />
  ),
  RouteAliases.CREATE_EVENT_MANUAL_CATEGORY
);

const EditEventCategoriesProtected = protectedRoute(
  () => <EditEventCategories categoryType={CategoryType.Event} />,
  RouteAliases.EDIT_EVENT_CATEGORY
);

const EventTypesProtected = protectedRoute(
  EventTypes,
  RouteAliases.EVENT_TYPES_PAGE
);

const EventTypeCreationProtected = protectedRoute(
  EventProductFamilyCreation,
  RouteAliases.EVENT_TYPE_CREATION_PAGE
);

const EventProductFamilyEditProtected = protectedRoute(
  EventProductFamilyEdit,
  RouteAliases.EVENT_TYPE_EDIT_PAGE
);

const ReservationsProtected = protectedRoute(
  Reservations,
  RouteAliases.RESERVATIONS_PAGE
);

const RentalOrdersProtected = protectedRoute(
  RentalOrders,
  RouteAliases.RENTAL_ORDERS_PAGE
);

const ProductFamilyProtected = protectedRoute(
  ProductFamily,
  RouteAliases.PRODUCT_FAMILY_PAGE
);

const ProductFamilyCreationProtected = protectedRoute(
  ProductFamilyCreation,
  RouteAliases.PRODUCT_FAMILY_CREATION_PAGE
);

const ProductFamilyEditProtected = protectedRoute(
  ProductFamilyEdit,
  RouteAliases.PRODUCT_FAMILY_EDIT_PAGE
);

const SettingsProtected = protectedRoute(Settings, RouteAliases.SETTINGS_PAGE);
const BookingTypesProtected = protectedRoute(
  BookingTypes,
  RouteAliases.BOOKING_TYPES_PAGE
);

const BookingTypeCreationProtected = protectedRoute(
  BookingProductFamilyCreation,
  RouteAliases.BOOKING_TYPE_CREATION_PAGE
);

const BookingTypeEditProtected = protectedRoute(
  BookingProductFamilyEdit,
  RouteAliases.BOOKING_TYPE_EDIT_PAGE
);

const ProjectSetupProtected = protectedRoute(
  ProjectSetup,
  RouteAliases.PROJECT_SETUP_PAGE
);

const PaymentsProtected = protectedRoute(Payments, RouteAliases.PAYMENTS_PAGE);

const OtherPaymentsProtected = protectedRoute(
  OtherPayments,
  RouteAliases.OTHER_PAYMENTS_PAGE
);

const SinglePaymentsProtected = protectedRoute(
  SinglePayments,
  RouteAliases.SINGLE_PAYMENTS_PAGE
);

const NotificationsProtected = protectedRoute(
  Notifications,
  RouteAliases.NOTIFICATIONS_PAGE
);

const PreviewPageProtected = protectedRoute(
  PreviewPage,
  RouteAliases.PREVIEW_PAGE
);

const TeamProtected = protectedRoute(Team, RouteAliases.TEAM_PAGE);

const InviteMemberProtected = protectedRoute(
  InviteMember,
  RouteAliases.INVITE_MEMBER_PAGE
);

const LocationsProtected = protectedRoute(
  Locations,
  RouteAliases.LOCATIONS_PAGE
);

const AppsProtected = protectedRoute(Apps, RouteAliases.APPS_PAGE);
const ActivityLogProtected = protectedRoute(
  ActivityLog,
  RouteAliases.ACTIVITY_LOG_PAGE
);

const TimeSlotsProtected = protectedRoute(TimeSlots, RouteAliases.TIME_SLOTS);

const CodeInjectionProtected = protectedRoute(
  CodeInjection,
  RouteAliases.CODE_INJECTION
);

const CollectionsProtected = protectedRoute(
  Collections,
  RouteAliases.COLLECTIONS
);

const AddCollectionProtected = protectedRoute(
  AddCollection,
  RouteAliases.ADD_COLLECTION
);

const EditCollectionProtected = protectedRoute(
  EditCollection,
  RouteAliases.EDIT_COLLECTION
);

const CollectionItemsProtected = protectedRoute(
  CollectionItems,
  RouteAliases.COLLECTION_ITEMS
);

const AddCollectionItemProtected = protectedRoute(
  AddCollectionItem,
  RouteAliases.ADD_COLLECTION_ITEM
);

const EditCollectionItemProtected = protectedRoute(
  EditCollectionItem,
  RouteAliases.EDIT_COLLECTION_ITEM
);

const CollectionCategoriesProtected = protectedRoute(
  () => <Categories type={CategoryType.Collection} />,
  RouteAliases.COLLECTION_CATEGORIES
);

const AddAutomaticCollectionCategoriesProtected = protectedRoute(
  () => (
    <AddCollectionCategories
      categoryType={CategoryType.Collection}
      type={SmartEntityType.Automatic}
    />
  ),
  RouteAliases.CREATE_COLLECTION_AUTOMATIC_CATEGORY
);

const AddManualCollectionCategoriesProtected = protectedRoute(
  () => (
    <AddCollectionCategories
      categoryType={CategoryType.Collection}
      type={SmartEntityType.Manual}
    />
  ),
  RouteAliases.CREATE_COLLECTION_MANUAL_CATEGORY
);

const EditCollectionCategoriesProtected = protectedRoute(
  () => <EditCollectionCategories categoryType={CategoryType.Collection} />,
  RouteAliases.EDIT_COLLECTION_CATEGORY
);

const FormFieldsProtected = protectedRoute(
  FormFields,
  RouteAliases.FORM_FIELDS
);

const EventsProtected = protectedRoute(Events, RouteAliases.EVENTS);

const AttendeesProtected = protectedRoute(
  Attendees,
  RouteAliases.ATTENDEES_PAGE
);

const AddEventProtected = protectedRoute(AddEvent, RouteAliases.ADD_EVENT);

const EditEventProtected = protectedRoute(EditEvent, RouteAliases.EDIT_EVENT);

const SalesChannelsProtected = protectedRoute(
  SalesChannels,
  RouteAliases.SALES_CHANNELS_PAGE
);

const SellerDetailsProtected = protectedRoute(
  SellerDetails,
  RouteAliases.SELLER_DETAILS
);

const SellerPaymentsProtected = protectedRoute(
  SellerPayments,
  RouteAliases.SELLER_PAYMENTS
);

const SellersProtected = protectedRoute(Sellers, RouteAliases.SELLERS_PAGE);

const CreateSeller = protectedRoute(AddSeller, RouteAliases.CREATE_SELLER);

const UpdateSeller = protectedRoute(EditSeller, RouteAliases.UPDATE_SELLER);

const PaymentRequestsProtected = protectedRoute(
  PaymentRequests,
  RouteAliases.PAYMENT_REQUESTS
);

const MarketplaceConfigsProtected = protectedRoute(
  MarketplaceConfigs,
  RouteAliases.MARKETPLACE_CONFIGS
);

export const sellerModeRoutes = createBrowserRouter(
  [
    {
      path: '',
      element: (
        <ErrorBoundary>
          <AuthProvider />
        </ErrorBoundary>
      ),
      children: [
        {
          path: '',
          element: (
            <>
              <ProductFruits />
              <CurrentProject />
            </>
          ),
          children: [
            {
              path: '',
              element: <DashboardProtected />
            },
            {
              path: 'commerce',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <Navigate to={'orders'} replace />
                },
                {
                  path: 'orders',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <OrdersProtected />
                    },
                    {
                      path: 'details/:id',
                      element: <OrderDetailsProtected />
                    }
                  ]
                },
                {
                  path: 'products',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <ProductsProtected />
                    },
                    {
                      path: ':familyId/physical-product/add',
                      element: <AddProductProtected />
                    },
                    {
                      path: ':familyId/physical-product/:id/edit',
                      element: <EditProductProtected />
                    },
                    {
                      path: ':familyId/digital-product/add',
                      element: <AddDigitalProductProtected />
                    },
                    {
                      path: ':familyId/digital-product/:id/edit',
                      element: <EditDigitalProductProtected />
                    },
                    {
                      path: ':familyId/subscription-product/add',
                      element: <AddSubscriptionProductProtected />
                    },
                    {
                      path: ':familyId/subscription-product/:id/edit',
                      element: <EditSubscriptionProductProtected />
                    },
                    {
                      path: ':familyId/booking-appointment-product/add',
                      element: <AddAppointmentProductProtected />
                    },
                    {
                      path: ':familyId/booking-appointment-product/:id/edit',
                      element: <EditAppointmentProductProtected />
                    },
                    {
                      path: ':familyId/booking-table-product/add',
                      element: <AddTableProductProtected />
                    },
                    {
                      path: ':familyId/booking-table-product/:id/edit',
                      element: <EditTableProductProtected />
                    },
                    {
                      path: ':familyId/gift-card-product/add',
                      element: <AddGiftCardProductProtected />
                    },
                    {
                      path: ':familyId/gift-card-product/:id/edit',
                      element: <EditGiftCardProductProtected />
                    }
                  ]
                },
                {
                  path: 'abandoned-checkouts',
                  element: <AbandonedCheckoutProtected />
                },
                {
                  path: 'shipments',
                  element: <ShipmentsProtected />
                }
              ]
            },
            {
              path: 'events',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <Navigate to={'events'} replace />
                },
                {
                  path: 'events',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <EventsProtected />
                    },
                    {
                      path: ':familyId/event/add',
                      element: <AddEventProtected />
                    },
                    {
                      path: ':familyId/event/:id/edit',
                      element: <EditEventProtected />
                    }
                  ]
                },
                {
                  path: 'attendees',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <AttendeesProtected />
                    },
                    {
                      path: 'details/:id',
                      element: <AttendeesDetailsProtected />
                    }
                  ]
                },
                {
                  path: 'events-abandoned-checkouts',
                  element: <EventsAbandonedCheckoutProtected />
                }
              ]
            },
            {
              path: 'bookings',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <Navigate to={'bookings'} replace />
                },
                {
                  path: 'bookings',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <BookingsProtected />
                    },
                    {
                      path: ':familyId/booking/add',
                      element: <AddBookingProtected />
                    },
                    {
                      path: ':familyId/booking/:id/edit',
                      element: <EditBookingProtected />
                    }
                  ]
                },
                {
                  path: 'reservations',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <ReservationsProtected />
                    },
                    {
                      path: 'details/:id',
                      element: <ReservationDetailsProtected />
                    }
                  ]
                },
                {
                  path: 'bookings-abandoned-checkouts',
                  element: <BookingsAbandonedCheckoutProtected />
                }
              ]
            },
            {
              path: 'rentals',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <Navigate to={'rentals'} replace />
                },
                {
                  path: 'rentals',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <RentalsProtected />
                    },
                    {
                      path: ':familyId/rental/add',
                      element: <AddRentalProductProtected />
                    },
                    {
                      path: ':familyId/rental/:id/edit',
                      element: <EditRentalProductProtected />
                    }
                  ]
                },
                {
                  path: 'rental-orders',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <RentalOrdersProtected />
                    },
                    {
                      path: 'details/:id',
                      element: <RentalOrderDetailsProtected />
                    }
                  ]
                },
                {
                  path: 'rentals-abandoned-checkouts',
                  element: <RentalsAbandonedCheckoutProtected />
                }
              ]
            },
            {
              path: 'customers',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <CustomersOverviewProtected />
                },
                {
                  path: ':id',
                  element: <CustomerPreviewProtected />
                },
                {
                  path: 'customer/:id',
                  element: <CustomerPreviewProtected />
                },
                {
                  path: 'reviews',
                  element: <CustomerReviewsProtected />
                },
                {
                  path: 'seller-reviews',
                  element: <MarketplaceReviews />
                }
              ]
            },
            {
              path: 'analytics',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <Navigate to={'overview'} replace />
                },
                {
                  path: 'overview',
                  element: <AnalyticsOverviewProtected />
                }
              ]
            },
            {
              path: 'settings',
              element: <SettingsProtected />,
              children: [
                {
                  path: '',
                  element: <Navigate to="seller-details" replace />
                },
                {
                  path: 'seller-details',
                  element: <SellerDetailsProtected />
                },
                {
                  path: 'shipping-delivery',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <ShippingDeliveryProtected />
                    },
                    {
                      path: 'new-shipping-profile',
                      element: <CreateShippingProfileProtected />
                    },
                    {
                      path: 'update-shipping-profile/:id',
                      element: <CreateShippingProfileProtected />
                    },
                    {
                      path: 'new-self-pickup',
                      element: <CreateSelfPickupProtected />
                    },
                    {
                      path: 'update-self-pickup/:id',
                      element: <UpdateSelfPickupProtected />
                    }
                    // {
                    //   path: 'shipping',
                    //   element: <ShippingProtected />
                    // },
                    // {
                    //   path: 'self-pickups',
                    //   element: <SelfPickupsProtected />
                    // },
                    // {
                    //   path: 'packages',
                    //   element: <PackagesProtected />
                    // }
                  ]
                },
                {
                  path: 'locations',
                  element: <LocationsProtected />
                },
                {
                  path: 'payments',
                  element: <SellerPaymentsProtected />
                },
                {
                  path: 'business-hours',
                  element: <TimeSlotsProtected />
                }
              ]
            }
          ]
        },
        {
          path: '/forbidden',
          element: <Forbidden />
        },
        {
          path: '/~',
          element: <NotFound />
        },
        {
          path: '*',
          element: <NotFound />
        }
      ]
    }
  ],
  { basename: VITE_APP_SELLER_DASHBOARD_PREFIX }
);

export const routes = createBrowserRouter([
  {
    path: '',
    element: (
      <ErrorBoundary>
        <AuthProvider />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '',
        element: <ProjectsProtected />
      },
      {
        path: ':projectName',
        element: (
          <>
            <ProductFruits />
            <CurrentProject />
          </>
        ),
        children: [
          {
            path: '',
            element: <ProjectNavigator />
          },
          {
            path: 'dashboard',
            element: <DashboardProtected />
          },
          {
            path: 'marketplace',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate to={'sellers'} replace />
              },
              {
                path: 'sellers',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <SellersProtected />
                  },
                  {
                    path: 'new',
                    element: <CreateSeller />
                  },
                  {
                    path: 'edit/:id',
                    element: <UpdateSeller />
                  }
                ]
              },
              {
                path: 'marketplace-orders',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <MarketPlaceOrdersProtected />
                  },
                  {
                    path: 'details/:id',
                    element: <MarketplaceOrderDetailsProtected />
                  }
                ]
              },
              {
                path: 'marketplace-products',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <MarketplaceProductsProtected />
                  },
                  {
                    path: ':familyId/physical-product/add',
                    element: <AddMarketplaceProductProtected />
                  },
                  {
                    path: ':familyId/physical-product/:id/edit',
                    element: <EditMarketplaceProductProtected />
                  },
                  {
                    path: ':familyId/digital-product/add',
                    element: <AddMarketplaceDigitalProductProtected />
                  },
                  {
                    path: ':familyId/digital-product/:id/edit',
                    element: <EditMarketplaceDigitalProductProtected />
                  },
                  {
                    path: ':familyId/subscription-product/add',
                    element: <AddMarketplaceSubscriptionProductProtected />
                  },
                  {
                    path: ':familyId/subscription-product/:id/edit',
                    element: <EditMarketplaceSubscriptionProductProtected />
                  },
                  {
                    path: ':familyId/booking-appointment-product/add',
                    element: <AddMarketplaceAppointmentProductProtected />
                  },
                  {
                    path: ':familyId/booking-appointment-product/:id/edit',
                    element: <EditMarketplaceAppointmentProductProtected />
                  },
                  {
                    path: ':familyId/booking-table-product/add',
                    element: <AddMarketplaceTableProductProtected />
                  },
                  {
                    path: ':familyId/booking-table-product/:id/edit',
                    element: <EditMarketplaceTableProductProtected />
                  },
                  {
                    path: ':familyId/gift-card-product/add',
                    element: <AddMarketplaceGiftCardProductProtected />
                  },
                  {
                    path: ':familyId/gift-card-product/:id/edit',
                    element: <EditMarketplaceGiftCardProductProtected />
                  }
                ]
              },
              {
                path: 'payment-requests',
                element: <PaymentRequestsProtected />
              },
              {
                path: 'configs',
                element: <MarketplaceConfigsProtected />
              },
              {
                path: 'marketplace-reviews',
                element: <MarketplaceReviews />
              },
              {
                path: 'marketplace-shipments',
                element: <MarketplaceShipmentsProtected />
              }
            ]
          },
          {
            path: 'commerce',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate to={'orders'} replace />
              },
              {
                path: 'categories',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <CategoriesProtected />
                  },
                  {
                    path: ':id/edit',
                    element: <EditCategoriesProtected />
                  },
                  {
                    path: 'add-automatic',
                    element: <AddAutomaticCategoriesProtected />
                  },
                  {
                    path: 'add-manual',
                    element: <AddManualCategoriesProtected />
                  }
                ]
              },
              {
                path: 'orders',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <OrdersProtected />
                  },
                  {
                    path: 'details/:id',
                    element: <OrderDetailsProtected />
                  }
                ]
              },
              {
                path: 'products',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <ProductsProtected />
                  },
                  {
                    path: ':familyId/physical-product/add',
                    element: <AddProductProtected />
                  },
                  {
                    path: ':familyId/physical-product/:id/edit',
                    element: <EditProductProtected />
                  },
                  {
                    path: ':familyId/digital-product/add',
                    element: <AddDigitalProductProtected />
                  },
                  {
                    path: ':familyId/digital-product/:id/edit',
                    element: <EditDigitalProductProtected />
                  },
                  {
                    path: ':familyId/subscription-product/add',
                    element: <AddSubscriptionProductProtected />
                  },
                  {
                    path: ':familyId/subscription-product/:id/edit',
                    element: <EditSubscriptionProductProtected />
                  },
                  {
                    path: ':familyId/booking-appointment-product/add',
                    element: <AddAppointmentProductProtected />
                  },
                  {
                    path: ':familyId/booking-appointment-product/:id/edit',
                    element: <EditAppointmentProductProtected />
                  },
                  {
                    path: ':familyId/booking-table-product/add',
                    element: <AddTableProductProtected />
                  },
                  {
                    path: ':familyId/booking-table-product/:id/edit',
                    element: <EditTableProductProtected />
                  },
                  {
                    path: ':familyId/gift-card-product/add',
                    element: <AddGiftCardProductProtected />
                  },
                  {
                    path: ':familyId/gift-card-product/:id/edit',
                    element: <EditGiftCardProductProtected />
                  }
                ]
              },

              {
                path: 'fields',
                element: <AttributesProtected />
              },
              {
                path: 'product-type',
                element: <ProductFamilyProtected />
              },
              {
                path: 'product-type/:type/create',
                element: <ProductFamilyCreationProtected />
              },
              {
                path: 'product-type/:type/:code/edit',
                element: <ProductFamilyEditProtected />
              },
              {
                path: 'abandoned-checkouts',
                element: <AbandonedCheckoutProtected />
              },
              {
                path: 'shipments',
                element: <ShipmentsProtected />
              }
            ]
          },
          {
            path: 'events',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate to={'events'} replace />
              },
              {
                path: 'event-categories',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <EventCategoriesProtected />
                  },
                  {
                    path: ':id/edit',
                    element: <EditEventCategoriesProtected />
                  },
                  {
                    path: 'add-automatic',
                    element: <AddAutomaticEventCategoriesProtected />
                  },
                  {
                    path: 'add-manual',
                    element: <AddManualEventCategoriesProtected />
                  }
                ]
              },
              {
                path: 'events',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <EventsProtected />
                  },
                  {
                    path: ':familyId/event/add',
                    element: <AddEventProtected />
                  },
                  {
                    path: ':familyId/event/:id/edit',
                    element: <EditEventProtected />
                  }
                ]
              },
              {
                path: 'attendees',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <AttendeesProtected />
                  },
                  {
                    path: 'details/:id',
                    element: <AttendeesDetailsProtected />
                  }
                ]
              },
              {
                path: 'event-types',
                element: <EventTypesProtected />
              },
              {
                path: 'event-types/:type/create',
                element: <EventTypeCreationProtected />
              },
              {
                path: 'event-types/:type/:code/edit',
                element: <EventProductFamilyEditProtected />
              },
              {
                path: 'events-abandoned-checkouts',
                element: <EventsAbandonedCheckoutProtected />
              }
            ]
          },
          {
            path: 'bookings',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate to={'bookings'} replace />
              },
              {
                path: 'booking-categories',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <BookingCategoriesProtected />
                  },
                  {
                    path: ':id/edit',
                    element: <EditBookingCategoriesProtected />
                  },
                  {
                    path: 'add-automatic',
                    element: <AddAutomaticBookingCategoriesProtected />
                  },
                  {
                    path: 'add-manual',
                    element: <AddManualBookingCategoriesProtected />
                  }
                ]
              },
              {
                path: 'bookings',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <BookingsProtected />
                  },
                  {
                    path: ':familyId/booking/add',
                    element: <AddBookingProtected />
                  },
                  {
                    path: ':familyId/booking/:id/edit',
                    element: <EditBookingProtected />
                  }
                ]
              },
              {
                path: 'reservations',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <ReservationsProtected />
                  },
                  {
                    path: 'details/:id',
                    element: <ReservationDetailsProtected />
                  }
                ]
              },
              {
                path: 'booking-types',
                element: <BookingTypesProtected />
              },
              {
                path: 'booking-types/:type/create',
                element: <BookingTypeCreationProtected />
              },
              {
                path: 'booking-types/:type/:code/edit',
                element: <BookingTypeEditProtected />
              },
              {
                path: 'bookings-abandoned-checkouts',
                element: <BookingsAbandonedCheckoutProtected />
              }
            ]
          },
          {
            path: 'rentals',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate to={'rentals'} replace />
              },
              {
                path: 'rental-categories',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <RentalCategoriesProtected />
                  },
                  {
                    path: ':id/edit',
                    element: <EditRentalCategoriesProtected />
                  },
                  {
                    path: 'add-automatic',
                    element: <AddAutomaticRentalCategoriesProtected />
                  },
                  {
                    path: 'add-manual',
                    element: <AddManualRentalCategoriesProtected />
                  }
                ]
              },
              {
                path: 'rentals',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <RentalsProtected />
                  },
                  {
                    path: ':familyId/rental/add',
                    element: <AddRentalProductProtected />
                  },
                  {
                    path: ':familyId/rental/:id/edit',
                    element: <EditRentalProductProtected />
                  }
                ]
              },
              {
                path: 'rental-orders',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <RentalOrdersProtected />
                  },
                  {
                    path: 'details/:id',
                    element: <RentalOrderDetailsProtected />
                  }
                ]
              },
              {
                path: 'rental-types',
                element: <RentalTypesProtected />
              },
              {
                path: 'rental-types/:type/create',
                element: <AddRentalTypeProtected />
              },
              {
                path: 'rental-types/:type/:code/edit',
                element: <EditRentalTypeProtected />
              },
              {
                path: 'rentals-abandoned-checkouts',
                element: <RentalsAbandonedCheckoutProtected />
              }
            ]
          },
          {
            path: 'item-management',
            element: <Outlet />,
            children: [
              {
                path: 'collections',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <CollectionsProtected />
                  },
                  {
                    path: 'add',
                    element: <AddCollectionProtected />
                  },
                  {
                    path: ':collectionId',
                    element: <EditCollectionProtected />
                  }
                ]
              },
              {
                path: 'collection-categories',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <CollectionCategoriesProtected />
                  },
                  {
                    path: 'add-automatic',
                    element: <AddAutomaticCollectionCategoriesProtected />
                  },
                  {
                    path: 'add-manual',
                    element: <AddManualCollectionCategoriesProtected />
                  },
                  {
                    path: ':id/edit',
                    element: <EditCollectionCategoriesProtected />
                  }
                ]
              },
              {
                path: 'items',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <CollectionItemsProtected />
                  },
                  {
                    path: ':collectionId/add',
                    element: <AddCollectionItemProtected />
                  },
                  {
                    path: ':collectionId/edit/:id',
                    element: <EditCollectionItemProtected />
                  }
                ]
              },
              {
                path: 'form-fields',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <FormFieldsProtected />
                  }
                ]
              }
            ]
          },
          {
            path: 'customers',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <CustomersOverviewProtected />
              },
              {
                path: ':id',
                element: <CustomerPreviewProtected />
              },
              {
                path: 'groups/',
                element: <CustomersGroupsProtected />
              },
              {
                path: 'customer/:id',
                element: <CustomerPreviewProtected />
              },
              {
                path: 'form-submissions',
                element: <FormSubmissionsProtected />
              },
              {
                path: 'form-submissions/:id',
                element: <FormSubmissionProtected />
              },
              {
                path: 'reviews',
                element: <CustomerReviewsProtected />
              }
            ]
          },
          {
            path: 'analytics',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate to={'overview'} replace />
              },
              {
                path: 'overview',
                element: <AnalyticsOverviewProtected />
              }
            ]
          },
          {
            path: 'discounts',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <DiscountsListProtected />
              },
              {
                path: 'create-automatic',
                element: <CreateAutomaticDiscountProtected />
              },
              {
                path: 'update-automatic',
                element: <UpdateAutomaticDiscountProtected />
              },
              {
                path: 'create-manual',
                element: <CreateManualDiscountProtected />
              },
              {
                path: 'update-manual',
                element: <UpdateManualDiscountProtected />
              }
            ]
          },
          {
            path: 'settings',
            element: <SettingsProtected />,
            children: [
              {
                path: '',
                element: <Navigate to="project-setup" replace />
              },
              {
                path: 'project-setup',
                element: <ProjectSetupProtected />
              },
              {
                path: 'notifications',
                element: <NotificationsProtected />,
                children: [
                  {
                    path: ':type',
                    element: <PreviewPageProtected />
                  }
                ]
              },
              {
                path: 'team',
                element: <TeamProtected />
              },
              {
                path: 'team/invite-member',
                element: <InviteMemberProtected />
              },
              {
                path: 'team/invite-member/:id',
                element: <InviteMemberProtected />
              },
              {
                path: 'taxes',
                element: <TaxesProtected />
              },
              {
                path: 'checkouts',
                element: <CheckoutProtected />
              },
              {
                path: 'shipping-delivery',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <ShippingDeliveryProtected />
                  },
                  {
                    path: 'new-shipping-profile',
                    element: <CreateShippingProfileProtected />
                  },
                  {
                    path: 'update-shipping-profile/:id',
                    element: <CreateShippingProfileProtected />
                  },
                  {
                    path: 'new-self-pickup',
                    element: <CreateSelfPickupProtected />
                  },
                  {
                    path: 'update-self-pickup/:id',
                    element: <UpdateSelfPickupProtected />
                  }
                  // {
                  //   path: 'shipping',
                  //   element: <ShippingProtected />
                  // },
                  // {
                  //   path: 'self-pickups',
                  //   element: <SelfPickupsProtected />
                  // },
                  // {
                  //   path: 'packages',
                  //   element: <PackagesProtected />
                  // }
                ]
              },
              {
                path: 'languages',
                element: <LanguagesProtected />
              },
              {
                path: 'locations',
                element: <LocationsProtected />
              },
              {
                path: 'payments',
                element: <PaymentsProtected />,
                children: [
                  {
                    path: 'other-payment-methods',
                    element: <OtherPaymentsProtected />
                  },
                  {
                    path: 'other-payment-methods/:name',
                    element: <SinglePaymentsProtected />
                  },
                  {
                    path: ':name',
                    element: <SinglePaymentsProtected />
                  }
                ]
              },
              {
                path: 'domains',
                element: <DomainsProtected />
              },
              {
                path: 'billing',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <BillingProtected />
                  },
                  {
                    path: 'change-plan',
                    element: <BillingProtected />
                  },
                  {
                    path: 'payment-methods',
                    element: <BillingProtected />
                  }
                ]
              },
              {
                path: 'business-hours',
                element: <TimeSlotsProtected />
              },
              {
                path: 'code-injection',
                element: <CodeInjectionProtected />
              },
              {
                path: 'legal-docs',
                element: <LegalDocsProtected />
              },
              {
                path: 'legal-docs/:id',
                element: <LegalDocProtected />
              }
            ]
          },
          {
            path: 'applications',
            element: <AppsProtected />
          },
          {
            path: 'activity-log',
            element: <ActivityLogProtected />
          },
          {
            path: 'channels',
            element: <SalesChannelsProtected />
          }
        ]
      },
      {
        path: '/forbidden',
        element: <Forbidden />
      },
      {
        path: '/~',
        element: <NotFound />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]);

export const Router = () => (
  <RouterProvider
    router={marketplaceSellerModeResolver(sellerModeRoutes, routes)}
  />
);
