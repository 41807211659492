import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  KeyboardShortcutKeys,
  KeyValuePair,
  NiladicVoid,
  OperatingSystems
} from 'core/globalTypes';
import { detectOS, isConfirmModalOpen } from 'helpers';
import isMarketplaceSellerMode from 'helpers/commonHelpers/isMarketplaceSellerMode';

/**
 * An object of options, that contains ```ignoreHandlers``` boolean field. The handlers will not execute while this field is true.
 */

interface IOptions {
  ignoreHandlers: boolean;
}

interface IKeyboardShortcut {
  key: KeyboardShortcutKeys;
  handler: NiladicVoid;
  withCtrl: boolean;
}

/**
 * Detects if only the Command (Control) key is pressed
 * @param e
 * @param os
 */

const isCtrlOrCmdPressed = (
  e: KeyboardEvent,
  os: OperatingSystems
): boolean => {
  const altAndShiftNotPressed = !e.shiftKey && !e.altKey;

  if (os === OperatingSystems.MAC) {
    return e.metaKey && altAndShiftNotPressed;
  }

  return e.ctrlKey && altAndShiftNotPressed;
};

/**
 * Checks if the passed key is the relevant Control or Meta key depending on the OS.
 * @param key
 * @param os
 */

const isControlOrCommandKey = (key: string, os: OperatingSystems) => {
  if (os === OperatingSystems.MAC) {
    return key === KeyboardShortcutKeys.Meta;
  }

  return key === KeyboardShortcutKeys.Control;
};

/**
 * Detects if the user is using the keyboard for typing inside Input/Textarea/Editable element.
 * @param e
 */

const isTypingMode = (e: KeyboardEvent) => {
  const { localName, isContentEditable } = e.target as KeyValuePair;

  return localName === 'input' || localName === 'textarea' || isContentEditable;
};

/**
 * `useKeyboardShortcuts` accepts an array of shortcuts, and registers for the current page.
 * If the provided shortcut is used by the browser by default, it will prevent the default behaviour and override with the new shortcut.
 *
 * ###Example
 * ```
 * useKeyboardShortcuts([
 *    {
 *       key: KeyboardShortcutKeys.O,
 *       handler: () => navigate('orders'),
 *       withCtrl: false
 *     }
 * ])
 * ```
 * Set `withCtrl` to `true` for shortcut combinations with Ctrl or Cmd.
 * @param keyMap A keymap to register for current page
 * @param options An object of options, that contains `ignoreHandlers` boolean field. The handlers will not execute while this field is `true`.
 * @param deps An array of dependencies to pass to the `useEffect` hook
 */
const useKeyboardShortcuts = (
  keyMap: IKeyboardShortcut[],
  options?: IOptions,
  deps?: any[]
) => {
  if (isMarketplaceSellerMode()) {
    return;
  }

  const isShortcutInProcess = useRef(false);

  const os = useMemo(() => {
    return detectOS();
  }, []);

  /**
   * Prevents the default behaviour of the provided keys.
   * @param e
   */
  const preventDefaultHandler = useCallback(
    (e: KeyboardEvent) => {
      /**
       * If the current shortcut exists in the provided keymap the default behaviour will be prevented
       */
      if (
        keyMap.some(
          shortcut =>
            shortcut.key === e.code &&
            shortcut.withCtrl === isCtrlOrCmdPressed(e, os)
        )
      ) {
        e.preventDefault();
      }
    },
    [keyMap]
  );

  const shortcutHandler = useCallback(
    (e: KeyboardEvent) => {
      if (options?.ignoreHandlers || isConfirmModalOpen()) {
        return;
      }

      /**
       * Checking if the current shortcut exists in provided keymap.
       */
      const shortcut = keyMap.find(shortcut => shortcut.key === e.code);

      if (!shortcut) {
        return;
      }

      if (shortcut.withCtrl === isCtrlOrCmdPressed(e, os)) {
        shortcut.handler();
      }
    },
    [keyMap, options?.ignoreHandlers]
  );

  const onKeyDown = (e: KeyboardEvent) => {
    if (isControlOrCommandKey(e.key, os)) {
      return;
    }

    /**
     * Checking if the user is currently typing inside any input element and uses a shortcut without Control/Command.
     */
    if (isTypingMode(e) && !isCtrlOrCmdPressed(e, os)) {
      return;
    }

    /**
     * Preventing the default behaviour of the registered keymap
     */
    preventDefaultHandler(e);

    /**
     * Checking if the key is currently pressed, to prevent multiple executions until the key is down.
     */
    if (isShortcutInProcess.current) {
      return;
    }

    /**
     * Settings `isShortcutInProcess` to `true` to determine that the is already pressed and the multiple executions while key is pressed are prevented.
     */
    if (!isControlOrCommandKey(e.key, os)) {
      isShortcutInProcess.current = true;
    }

    /**
     * Calling the main handler when all checks are passed
     */
    shortcutHandler(e);
  };

  const onKeyUp = () => {
    /**
     * Setting `isShortcutInProcess` to `false` to determine that the key is not pressed anymore.
     */
    isShortcutInProcess.current = false;
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyDown, onKeyUp, options?.ignoreHandlers, ...(deps || [])]);
};

export default useKeyboardShortcuts;
