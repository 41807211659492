import {
  projectPermissionsQuery,
  projectQuery,
  requestPermissionMutation,
  requestPermissionsStoryMutation,
  sellerProjectPermissionsQuery
} from 'providers/PermissionAndLimitationProvider/gql';
import isMarketplaceSellerMode from 'helpers/commonHelpers/isMarketplaceSellerMode';
import { IEnvironment } from 'providers/PermissionAndLimitationProvider/core/globalTypes';
import marketplaceSellerModeResolver from 'helpers/commonHelpers/marketplaceSellerModeResolver';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';

import { FetchingGraphqlService } from './fetchingGraphql';
import { NON_MARKETPLACE_PERMISSIONS } from './constants';
import { PERMISSION_CONSTANTS } from '../core/constants';

export class PermissionsService {
  private static get token() {
    return `Bearer ${localStorage.getItem('token')}`;
  }

  private static async projectQuery(url: string, projectId: string) {
    const Authorization = PermissionsService.token;

    try {
      const project = await FetchingGraphqlService.fetchGraphql(
        url,
        {
          Authorization
        },
        projectQuery,
        {
          id: projectId
        }
      );

      if (!('errors' in project)) {
        return project;
      }

      throw Error(`Something went wrong, Can't get project`);
    } catch (e) {
      throw Error(`Something went wrong, Can't get project`);
    }
  }

  private static async permissionsStoryQuery(
    url: string,
    projectId: string,
    permissionsStoryHeaders: any
  ) {
    try {
      const permissionsStory = await FetchingGraphqlService.fetchGraphql(
        url,
        permissionsStoryHeaders,
        requestPermissionsStoryMutation,
        {
          input: {
            projectId
          }
        }
      );

      if (!('errors' in permissionsStory)) {
        const notes: { [key: string]: object } = {};
        const story = permissionsStory.data.requestedPermissions;

        for (let i = 0; i < story.length; i++) {
          notes[story[i].id] = story[i].permissionRequest;
        }

        return notes;
      }

      throw new Error(`Something went wrong, Can't get notes permission`);
    } catch (e) {
      throw new Error(`Something went wrong, Can't get notes permission`);
    }
  }

  private static async projectPermissionQuery(
    url: string,
    projectId: string = '',
    projectPermissionsHeader: any = {}
  ) {
    try {
      const permissionsQuery = marketplaceSellerModeResolver(
        sellerProjectPermissionsQuery,
        projectPermissionsQuery
      );

      const variables = projectId ? { projectId } : null;

      const permissionsResponse = await FetchingGraphqlService.fetchGraphql(
        url,
        projectPermissionsHeader,
        permissionsQuery,
        variables
      );

      const permissions = [
        ...(permissionsResponse?.data?.projectPermissions?.user || []),
        ...(permissionsResponse?.data?.projectPermissions?.project || [])
      ];

      if (!('errors' in permissionsResponse)) {
        const hasMarketplace = permissions.find(permission =>
          isEqual(permission.alias, PERMISSION_CONSTANTS['Has'].Marketplace)
        );

        return permissions.reduce((accumulator, currentValue) => {
          if (hasMarketplace) {
            if (!includes(NON_MARKETPLACE_PERMISSIONS, currentValue.alias)) {
              accumulator.push(currentValue.alias);
            }
          } else {
            accumulator.push(currentValue.alias);
          }

          return accumulator;
        }, []);
      }

      throw new Error(`Something went wrong,Can't get Project permissions`);
    } catch (e) {
      throw new Error(`Something went wrong,Can't get Project permissions`);
    }
  }

  static async requestForPermission(
    url: string,
    I: string,
    projectId: string,
    note: string
  ) {
    try {
      const Authorization = PermissionsService.token;
      const resPermission = await FetchingGraphqlService.fetchGraphql(
        url,
        {
          Authorization
        },
        requestPermissionMutation,
        {
          input: {
            permissionAlias: I,
            projectId,
            note
          }
        }
      );

      if (!('errors' in resPermission)) {
        return resPermission.data;
      }

      throw new Error(`Cant request for permission`);
    } catch (e) {
      throw new Error(`Cant request for permission`);
    }
  }

  static async permissionsInitialQuery({
    environment,
    projectId
  }: {
    environment: IEnvironment;
    projectId: string;
  }) {
    try {
      if (!isMarketplaceSellerMode()) {
        return await this.permissionsQueryForProject(
          environment.SSO_URL,
          environment.REMOTE_HOST,
          projectId
        );
      }

      return await this.permissionsQueryForSellerProject(
        environment.COMMERCE_URL,
        environment.REMOTE_HOST
      );
    } catch (error: any) {
      console.error(error);
    }
  }

  private static async permissionsQueryForProject(
    url: string,
    remoteHost: string,
    projectId: string
  ) {
    const project = await PermissionsService.projectQuery(url, projectId);
    const AuthorizationHeaders = {
      Authorization: PermissionsService.token
    };

    const notes = await PermissionsService.permissionsStoryQuery(
      url,
      projectId,
      AuthorizationHeaders
    );

    const permissions = await PermissionsService.projectPermissionQuery(
      url,
      projectId,
      AuthorizationHeaders
    );

    return {
      project: project.data.project,
      notes,
      permissions
    };
  }

  private static async permissionsQueryForSellerProject(
    url: string,
    remoteHost: string
  ) {
    const permissions = await PermissionsService.projectPermissionQuery(
      url,
      '',
      { 'Remote-Host': remoteHost }
    );

    return {
      permissions
    };
  }
}
