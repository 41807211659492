import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import React, { FC } from 'react';
import { NiladicVoid } from 'core/globalTypes';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/basic';

interface Props {
  onClick: NiladicVoid;
}

const ResetButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('common');

  return (
    <Button
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Button,
        prefix: 'clear-filters'
      })}
      type="text"
      onClick={onClick}
      danger
    >
      {t('common:filterableTable.clearFilters')}
    </Button>
  );
};

export default ResetButton;
