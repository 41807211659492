import { DASHBOARD_SIDEBAR_ROUTES } from 'core/constants/common';
import marketplaceSellerModeGuard from 'helpers/commonHelpers/marketplaceSellerModeGuard';

import {
  SettingsIcon,
  UserIcon,
  OrdersIcon,
  DiscountsIcon,
  ActivityLogIcon,
  ItemManagementIcon,
  AnalyticLogIcon,
  DashboardIcon,
  EventsIcon,
  ApplicationLogIcon,
  BookingsIcon,
  SalesChannelsLinkIcon,
  Rentals,
  MarketplaceIcon
} from 'components/shared/SVG';
import { SiderMenuItemType } from 'components/global/Sider/types';

const {
  DASHBOARD,
  ORDERS,
  ABANDONED_CHECKOUTS,
  PRODUCTS,
  PRODUCT_CATEGORIES,
  EVENTS,
  ATTENDEES,
  APPS,
  ATTRIBUTES,
  ACTIVITY_LOG,
  ANALYTICS_OVERVIEW,
  COLLECTIONS,
  COLLECTION_CATEGORIES,
  COLLECTION_ITEMS,
  CUSTOMERS,
  CUSTOMER_GROUPS,
  CUSTOMER_REVIEWS,
  DISCOUNTS,
  FORM_FIELDS,
  FORM_SUBMISSIONS,
  PRODUCT_FAMILIES,
  SETTINGS,
  EVENTS_CATEGORIES,
  EVENT_TYPES,
  BOOKINGS,
  BOOKING_CATEGORIES,
  BOOKING_TYPES,
  RESERVATIONS,
  RENTAL_ORDERS,
  SALES_CHANNELS,
  RENTAL_CATEGORIES,
  RENTALS,
  EVENTS_ABANDONED_CHECKOUTS,
  BOOKINGS_ABANDONED_CHECKOUTS,
  RENTALS_ABANDONED_CHECKOUTS,
  SELLERS,
  MARKETPLACE_PRODUCTS,
  RENTAL_TYPES,
  SHIPMENTS,
  MARKETPLACE_CONFIGS,
  PAYMENT_REQUESTS,
  SELLER_REVIEWS,
  MARKETPLACE_REVIEWS,
  MARKETPLACE_ORDERS,
  MARKETPLACE_SHIPMENTS
} = DASHBOARD_SIDEBAR_ROUTES;

export const SIDER_MENU_PATHS = [
  marketplaceSellerModeGuard('dashboard', ''),
  'orders',
  'shipments',
  'abandoned-checkouts',
  'events-abandoned-checkouts',
  'bookings-abandoned-checkouts',
  'rentals-abandoned-checkouts',
  'products',
  'bookings',
  'events',
  'event-types',
  'attendees',
  'reservations',
  'categories',
  'event-categories',
  'booking-categories',
  'fields',
  'product-type',
  'booking-types',
  'collections',
  'collection-categories',
  'items',
  'form-fields',
  'customers',
  'groups',
  'reviews',
  'form-submissions',
  'overview',
  'discounts',
  'applications',
  'activity-log',
  'settings',
  'channels',
  'rentals',
  'rental-categories',
  'rental-orders',
  'commerce',
  'rental-types',
  'marketplace',
  'sellers',
  'seller-reviews',
  'marketplace-products',
  'marketplace-orders',
  'configs',
  'payment-requests',
  'marketplace-reviews',
  'marketplace-shipments'
];

export const MOBILE_MENU_ITEMS = [
  {
    key: 'channels',
    icon: <SalesChannelsLinkIcon />,
    i18nKey: 'menu:channels',
    defaultLabel: 'Channels',
    dataTestKeyPrefix: 'channels',
    url: SALES_CHANNELS.pathname,
    type: SiderMenuItemType.SingleItem,
    permission: SALES_CHANNELS.permission
  }
];

export const MENU_ITEMS_DATA = [
  {
    key: marketplaceSellerModeGuard('dashboard', ''),
    icon: <DashboardIcon />,
    i18nKey: 'menu:dashboard',
    defaultLabel: 'Dashboard',
    dataTestKeyPrefix: 'dashboard',
    url: DASHBOARD.pathname,
    type: SiderMenuItemType.SingleItem,
    permission: DASHBOARD.permission
  },
  {
    key: 'marketplace',
    icon: <MarketplaceIcon />,
    i18nKey: 'menu:marketplace.marketplace',
    defaultLabel: 'Marketplace',
    dataTestKeyPrefix: 'marketplace',
    url: '',
    type: SiderMenuItemType.Submenu,
    children: [
      {
        key: 'marketplace-orders',
        dataTestKeyPrefix: 'marketplace-orders',
        i18nKey: 'menu:orders.orders',
        defaultLabel: 'Orders',
        url: MARKETPLACE_ORDERS.pathname,
        permission: MARKETPLACE_ORDERS.permission
      },
      {
        key: 'marketplace-products',
        dataTestKeyPrefix: 'marketplace-products',
        i18nKey: 'menu:products.products',
        defaultLabel: 'Products',
        url: MARKETPLACE_PRODUCTS.pathname,
        permission: MARKETPLACE_PRODUCTS.permission
      },
      {
        key: 'sellers',
        dataTestKeyPrefix: 'sellers',
        i18nKey: 'menu:marketplace.sellers',
        defaultLabel: 'Sellers',
        url: SELLERS.pathname,
        permission: SELLERS.permission
      },
      {
        key: 'marketplace-reviews',
        dataTestKeyPrefix: 'marketplace-reviews',
        i18nKey: 'customers.sellerReviews',
        defaultLabel: 'Seller Reviews',
        url: MARKETPLACE_REVIEWS.pathname,
        permission: MARKETPLACE_REVIEWS.permission
      },
      {
        key: 'marketplace-shipments',
        dataTestKeyPrefix: 'marketplace-shipments',
        i18nKey: 'menu:shipments',
        defaultLabel: 'Shipments',
        url: MARKETPLACE_SHIPMENTS.pathname,
        permission: MARKETPLACE_SHIPMENTS.permission
      },
      {
        key: 'payment-requests',
        dataTestKeyPrefix: 'payment-requests',
        i18nKey: 'menu:marketplace.paymentRequests',
        defaultLabel: 'Payment Requests',
        url: PAYMENT_REQUESTS.pathname,
        permission: PAYMENT_REQUESTS.permission
      },
      {
        key: 'configs',
        dataTestKeyPrefix: 'configs',
        i18nKey: 'menu:settings',
        defaultLabel: 'Settings',
        url: MARKETPLACE_CONFIGS.pathname,
        permission: MARKETPLACE_CONFIGS.permission
      }
    ]
  },
  {
    key: 'commerce',
    icon: <OrdersIcon />,
    i18nKey: 'menu:products.eCommerce',
    defaultLabel: 'Commerce',
    dataTestKeyPrefix: 'commerce',
    url: '',
    type: SiderMenuItemType.Submenu,
    children: [
      {
        key: 'orders',
        dataTestKeyPrefix: 'commerce-orders',
        i18nKey: 'menu:orders.orders',
        defaultLabel: 'Orders',
        url: ORDERS.pathname,
        permission: ORDERS.permission
      },
      {
        key: 'products',
        dataTestKeyPrefix: 'commerce-products',
        i18nKey: 'menu:products.products',
        defaultLabel: 'Products',
        url: PRODUCTS.pathname,
        permission: PRODUCTS.permission
      },
      {
        key: 'product-type',
        dataTestKeyPrefix: 'commerce-products-type',
        i18nKey: 'menu:products.productFamily',
        defaultLabel: 'Product Types',
        url: PRODUCT_FAMILIES.pathname,
        permission: PRODUCT_FAMILIES.permission
      },
      {
        key: 'categories',
        dataTestKeyPrefix: 'commerce-categories',
        i18nKey: 'menu:products.categories',
        defaultLabel: 'Categories',
        url: PRODUCT_CATEGORIES.pathname,
        permission: PRODUCT_CATEGORIES.permission
      },
      {
        key: 'fields',
        dataTestKeyPrefix: 'commerce-fields',
        i18nKey: 'menu:products.fields',
        defaultLabel: 'Fields',
        url: ATTRIBUTES.pathname,
        permission: ATTRIBUTES.permission
      },
      {
        key: 'abandoned-checkouts',
        dataTestKeyPrefix: 'commerce-abandoned-checkouts',
        i18nKey: 'menu:orders.abandonedCheckouts',
        defaultLabel: 'Abandoned Checkouts',
        url: ABANDONED_CHECKOUTS.pathname,
        permission: ABANDONED_CHECKOUTS.permission
      },
      {
        key: 'shipments',
        dataTestKeyPrefix: 'shipments',
        i18nKey: 'menu:shipments',
        defaultLabel: 'Shipments',
        url: SHIPMENTS.pathname,
        permission: SHIPMENTS.permission
      }
    ]
  },
  {
    key: 'eventsPage',
    icon: <EventsIcon />,
    i18nKey: 'menu:events.events',
    dataTestKeyPrefix: 'events',
    defaultLabel: 'Events',
    url: '',
    type: SiderMenuItemType.Submenu,
    children: [
      {
        key: 'attendees',
        dataTestKeyPrefix: 'events-attendees',
        i18nKey: 'menu:events.attendees',
        defaultLabel: 'Attendees',
        url: ATTENDEES.pathname,
        permission: ATTENDEES.permission
      },
      {
        key: 'events',
        dataTestKeyPrefix: 'event-list',
        i18nKey: 'menu:events.eventList',
        defaultLabel: 'Event List',
        url: EVENTS.pathname,
        permission: EVENTS.permission
      },
      {
        key: 'event-categories',
        dataTestKeyPrefix: 'event-categories',
        i18nKey: 'menu:events.categories',
        defaultLabel: 'Categories',
        url: EVENTS_CATEGORIES.pathname,
        permission: EVENTS_CATEGORIES.permission
      },
      {
        key: 'event-types',
        dataTestKeyPrefix: 'event-types',
        i18nKey: 'menu:events.eventTypes',
        defaultLabel: 'Event Type',
        url: EVENT_TYPES.pathname,
        permission: EVENT_TYPES.permission
      },
      {
        key: 'events-abandoned-checkouts',
        dataTestKeyPrefix: 'event-abandoned-checkouts',
        i18nKey: 'menu:orders.abandonedCheckouts',
        defaultLabel: 'Abandoned Checkouts',
        url: EVENTS_ABANDONED_CHECKOUTS.pathname,
        permission: EVENTS_ABANDONED_CHECKOUTS.permission
      }
    ]
  },
  {
    key: 'bookingsPage',
    icon: <BookingsIcon />,
    i18nKey: 'menu:bookings.bookings',
    dataTestKeyPrefix: 'bookings',
    defaultLabel: 'Bookings',
    url: '',
    type: SiderMenuItemType.Submenu,
    betaMode: true,
    children: [
      {
        key: 'reservations',
        dataTestKeyPrefix: 'bookings-reservations',
        i18nKey: 'menu:bookings.reservations',
        defaultLabel: 'Reservations',
        url: RESERVATIONS.pathname,
        permission: RESERVATIONS.permission
      },
      {
        key: 'bookings',
        dataTestKeyPrefix: 'booking-products',
        i18nKey: 'menu:bookings.bookingProducts',
        defaultLabel: 'Bookings',
        url: BOOKINGS.pathname,
        permission: BOOKINGS.permission
      },
      {
        key: 'booking-categories',
        dataTestKeyPrefix: 'bookings-categories',
        i18nKey: 'menu:bookings.categories',
        defaultLabel: 'Categories',
        url: BOOKING_CATEGORIES.pathname,
        permission: BOOKING_CATEGORIES.permission
      },
      {
        key: 'booking-types',
        dataTestKeyPrefix: 'booking-types',
        i18nKey: 'menu:bookings.bookingTypes',
        defaultLabel: 'Booking Types',
        url: BOOKING_TYPES.pathname,
        permission: BOOKING_TYPES.permission
      },
      {
        key: 'bookings-abandoned-checkouts',
        dataTestKeyPrefix: 'bookings-abandoned-checkouts',
        i18nKey: 'menu:orders.abandonedCheckouts',
        defaultLabel: 'Abandoned Checkouts',
        url: BOOKINGS_ABANDONED_CHECKOUTS.pathname,
        permission: BOOKINGS_ABANDONED_CHECKOUTS.permission
      }
    ]
  },
  {
    key: 'rentals',
    icon: <Rentals />,
    i18nKey: 'menu:rentals.rentalProducts',
    dataTestKeyPrefix: 'rentals',
    defaultLabel: 'Rental Products',
    url: '',
    type: SiderMenuItemType.Submenu,
    betaMode: true,
    children: [
      {
        key: 'rental-orders',
        dataTestKeyPrefix: 'rental-orders',
        i18nKey: 'menu:rentals.rentalOrders',
        defaultLabel: 'Rental Orders',
        url: RENTAL_ORDERS.pathname,
        permission: RENTAL_ORDERS.permission
      },
      {
        key: 'rentals',
        dataTestKeyPrefix: 'rental-products',
        i18nKey: 'menu:rentals.rentalProducts',
        defaultLabel: 'Rental Products',
        url: RENTALS.pathname,
        permission: RENTALS.permission
      },
      {
        key: 'rental-categories',
        dataTestKeyPrefix: 'rental-categories',
        i18nKey: 'menu:bookings.categories',
        defaultLabel: 'Categories',
        url: RENTAL_CATEGORIES.pathname,
        permission: RENTAL_CATEGORIES.permission
      },
      {
        key: 'rental-types',
        dataTestKeyPrefix: 'rental-types',
        i18nKey: 'menu:rentals.rentalTypes',
        defaultLabel: 'Rental Types',
        url: RENTAL_TYPES.pathname,
        permission: RENTAL_TYPES.permission
      },
      {
        key: 'rentals-abandoned-checkouts',
        dataTestKeyPrefix: 'rental-abandoned-checkouts',
        i18nKey: 'menu:orders.abandonedCheckouts',
        defaultLabel: 'Abandoned Checkouts',
        url: RENTALS_ABANDONED_CHECKOUTS.pathname,
        permission: RENTALS_ABANDONED_CHECKOUTS.permission
      }
    ]
  },
  {
    key: 'itemManagement',
    icon: <ItemManagementIcon />,
    i18nKey: 'menu:itemManagement.itemManagement',
    defaultLabel: 'Item Management',
    dataTestKeyPrefix: 'articleManagement',
    url: '',
    type: SiderMenuItemType.Submenu,
    children: [
      {
        key: 'collections',
        dataTestKeyPrefix: 'itemManagement-collections',
        i18nKey: 'menu:itemManagement.collections',
        defaultLabel: 'Collections',
        url: COLLECTIONS.pathname,
        permission: COLLECTIONS.permission
      },
      {
        key: 'collection-categories',
        dataTestKeyPrefix: 'itemManagement-categories',
        i18nKey: 'menu:itemManagement.categories',
        defaultLabel: 'Categories',
        url: COLLECTION_CATEGORIES.pathname,
        permission: COLLECTION_CATEGORIES.permission
      },
      {
        key: 'items',
        dataTestKeyPrefix: 'articleManagement-articles',
        i18nKey: 'menu:itemManagement.items',
        defaultLabel: 'Items',
        url: COLLECTION_ITEMS.pathname,
        permission: COLLECTION_ITEMS.permission
      },
      {
        key: 'form-fields',
        dataTestKeyPrefix: 'itemManagement-fields',
        i18nKey: 'menu:itemManagement.formFields',
        defaultLabel: 'Fields',
        url: FORM_FIELDS.pathname,
        permission: FORM_FIELDS.permission
      }
    ]
  },
  {
    key: 'customersPage',
    dataTestKeyPrefix: 'customers',
    icon: <UserIcon />,
    i18nKey: 'menu:customers.crm',
    defaultLabel: 'CRM',
    url: '',
    type: SiderMenuItemType.Submenu,
    children: [
      {
        key: 'customers',
        dataTestKeyPrefix: 'customers-customers',
        i18nKey: 'menu:customers.customers',
        defaultLabel: 'Customers',
        url: CUSTOMERS.pathname,
        permission: CUSTOMERS.permission
      },
      {
        key: 'groups',
        dataTestKeyPrefix: 'customers-group',
        i18nKey: 'menu:customers.groups',
        defaultLabel: 'Groups',
        url: CUSTOMER_GROUPS.pathname,
        permission: CUSTOMER_GROUPS.permission
      },
      {
        key: 'reviews',
        dataTestKeyPrefix: 'customers-reviews',
        i18nKey: 'menu:customers.reviews',
        defaultLabel: 'Reviews',
        url: CUSTOMER_REVIEWS.pathname,
        permission: CUSTOMER_REVIEWS.permission
      },
      {
        key: 'seller-reviews',
        dataTestKeyPrefix: 'seller-reviews',
        i18nKey: 'menu:customers.sellerReviews',
        defaultLabel: 'Seller Reviews',
        url: SELLER_REVIEWS.pathname,
        permission: SELLER_REVIEWS.permission
      },
      {
        key: 'form-submissions',
        dataTestKeyPrefix: 'customers-form-submissions',
        i18nKey: 'menu:customers.formSubmissions',
        defaultLabel: 'Form Submissions',
        url: FORM_SUBMISSIONS.pathname,
        permission: FORM_SUBMISSIONS.permission
      }
    ]
  },
  {
    key: 'analytics',
    icon: <AnalyticLogIcon />,
    i18nKey: 'menu:analytics.analytics',
    dataTestKeyPrefix: 'analytics',
    defaultLabel: 'Analytics',
    url: '',
    type: SiderMenuItemType.Submenu,
    children: [
      {
        key: 'overview',
        dataTestKeyPrefix: 'analytics-overview',
        i18nKey: 'menu:analytics.overview',
        defaultLabel: 'Overview',
        url: ANALYTICS_OVERVIEW.pathname,
        permission: ANALYTICS_OVERVIEW.permission
      }
    ]
  },
  {
    key: 'discounts',
    icon: <DiscountsIcon />,
    i18nKey: 'menu:discounts',
    dataTestKeyPrefix: 'discounts',
    defaultLabel: 'Discounts',
    url: DISCOUNTS.pathname,
    type: SiderMenuItemType.SingleItem,
    permission: DISCOUNTS.permission
  },
  {
    key: 'applications',
    icon: <ApplicationLogIcon />,
    i18nKey: 'menu:apps',
    dataTestKeyPrefix: 'applications',
    defaultLabel: 'Apps',
    url: 'applications',
    type: SiderMenuItemType.SingleItem,
    permission: APPS.permission
  },
  {
    key: 'activity-log',
    icon: <ActivityLogIcon />,
    i18nKey: 'menu:activityLog',
    dataTestKeyPrefix: 'activity-log',
    defaultLabel: 'Activity Log',
    url: ACTIVITY_LOG.pathname,
    type: SiderMenuItemType.SingleItem,
    permission: ACTIVITY_LOG.permission
  },
  {
    key: 'settings',
    icon: <SettingsIcon />,
    i18nKey: 'menu:settings',
    dataTestKeyPrefix: 'settings',
    defaultLabel: 'Settings',
    url: SETTINGS.pathname,
    type: SiderMenuItemType.SingleItem,
    permission: SETTINGS.permission
  }
];
