import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import {
  DatabaseOutlined,
  LinkOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { linkTypes } from 'apollo/link';

import { IDataItem } from 'components/mobile/MobileDropdown';
import useInfiniteScroll from '../UIComponents/useInfiniteScroll';
import FileInput from '../UIComponents/FileInput';
import Preview from '../Preview';
import {
  mediaWithFolders,
  mediaWithFolders_mediaWithFolders_data
} from '../__generated__/types';
import {
  getMediaQuery,
  getSingleMediaQuery,
  getMediaByIdsQuery,
  mediaFileFragment
} from '../queries/mediaQuery';
import useMediaUpload from './useUploadMedia';
import { MediaModal, MediaWithoutFolder, PageType } from '../types';
import useLoadRemoteMedia from '../useLoadRemoteMedia';
import {
  mediaTypeAcceptFormats,
  mediaTypeMapper,
  orderingMenus
} from '../constants';
import Web from './Web';
import Mobile from 'components/mobile/media/UploadFormMediaMobile';

type Props = {
  visible: boolean;
  multiSelect?: boolean;
  initialIds?: string[];
  hiddenIds?: string[];
  types: Array<PageType>;
  onCancel: () => void;
  onInsert: (ids: string[]) => void;
  closePopup?: () => void;
  mediaManagerData?: IDataItem[];
  forMobile?: boolean;
  setParentIdMob?: (id: string | null) => void;
  setDefaultMediaId?: (arg: number | null) => void;
};

const [defaultOrdering] = orderingMenus;

/**
 * Renders modal for upload media.
 * @param visible - Detect if modal visible or not.
 * @param onCancel - Triggers after modal close.
 * @param types - Array of types for rendering media tabs.
 * @param onInsert - Callback to Save selected medias.
 * @param multiSelect - If multiSelect is true renders medias with checkbox for multiselect
 * else renders radio Button for select single media.
 * @param initialIds - Array of added media ids.
 * @param hiddenIds - Array of these identifiers that does not need to be displayed on the List.
 * @param closePopup
 * @param mediaManagerData
 * @param forMobile
 * @param setParentIdMob
 * @param setDefaultMediaId
 * @returns ReactNode.
 */
function UploadFromMedia({
  visible,
  onCancel,
  types,
  onInsert,
  multiSelect = false,
  initialIds = [],
  hiddenIds = [],
  closePopup = () => {},
  mediaManagerData = [],
  forMobile,
  setParentIdMob,
  setDefaultMediaId
}: Props) {
  const { t } = useTranslation(['media', 'common']);
  const client = useApolloClient();

  const [selectedMedias, setSelectedMedias] = useState<string[]>(initialIds);
  const [type, setType] = useState<PageType>(types[0]);
  const [parentId, setParentId] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [orderBy, setOrderBy] = useState(defaultOrdering);

  // Opens preview screen on the same modal if previewMedia is true.
  const [previewMedia, setPreviewMedia] = useState<MediaWithoutFolder | null>(
    null
  );

  const { order, column } = orderBy;
  useQuery(getMediaByIdsQuery, {
    context: { urlType: linkTypes.builder },
    variables: {
      ids: initialIds
    },
    skip: !visible || !selectedMedias.length
  });

  const [getImages, { data, loading }] = useLazyQuery<mediaWithFolders>(
    getMediaQuery,
    {
      context: { urlType: linkTypes.builder },
      variables: {
        orderBy: [{ order, column }],
        first: 50,
        parentId: null,
        page: 1,
        type: mediaTypeMapper[type],
        search
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const [visibleModal, setVisibleModal] = useState<MediaModal | null>(null);
  const { onUploadMedia, setUploadList, uploadList } = useMediaUpload({
    type,
    parentId
  });

  const { onLoadRemoteMedia, loadRemoteMediaLoading } = useLoadRemoteMedia({
    type,
    parentId: parentId as string
  });

  async function handleImportFromUrlConfirm(url: string) {
    try {
      await onLoadRemoteMedia(url);
      setVisibleModal(null);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Sets the initial ids to select it in the List.
   */
  useEffect(() => {
    visible && initialIds && setSelectedMedias(initialIds);
  }, [visible]);

  useEffect(() => {
    if (visible) {
      resetPage();
      getImages({
        context: { urlType: linkTypes.builder },
        variables: { page: 1, search }
      });
    } else {
      setPreviewMedia(null);
      setSearch(null);
    }
  }, [visible, search]);

  /**
   * Renders preview component.
   * @returns ReactNode.
   */
  function renderPreview() {
    const previewsProps = {
      type,
      data: previewMedia as mediaWithFolders_mediaWithFolders_data,
      closePreview: () => {
        setPreviewMedia(null);
        setVisibleModal(null);
      },
      visible: visibleModal === MediaModal.preview
    };

    return <Preview {...previewsProps} />;
  }

  /**
   * Gets media from apollo cache.
   * @param id - Media id.
   * @returns media data.
   */
  function getMediaById(id: string) {
    return client.readFragment({
      id: client.cache.identify({ id, __typename: 'Media' }),
      fragment: mediaFileFragment
    });
  }

  /**
   * Generates addAndEdit new media options.
   * @returns array of options.
   */
  function getAddNewMenuItems() {
    const addLink = {
      text: t('addLink'),
      icon: <LinkOutlined />,
      dataTest: 'link-url',
      onClick: () => setVisibleModal(MediaModal.link)
    };

    const addFreeStock = {
      text: t('addFreeStock'),
      icon: <DatabaseOutlined />,
      dataTest: 'free-stock',
      onClick: () => setVisibleModal(MediaModal.stock)
    };

    const partialMenuItems = {
      images: [addFreeStock, addLink],
      docs: [],
      videos: [addLink],
      audios: [addLink],
      vectors: []
    };

    const uploadProps = {
      multiple: true,
      accept: mediaTypeAcceptFormats[type],
      onChange: onUploadMedia
    };

    return [
      {
        title: t('addTitle'),
        items: [
          {
            render: (
              <FileInput {...uploadProps}>
                <UploadOutlined
                  style={{
                    marginLeft: '-8px',
                    marginRight: '8px'
                  }}
                />
                {t('addComputer')}
              </FileInput>
            )
          },
          ...partialMenuItems[type]
        ]
      }
    ];
  }

  const orderMenuItems = [
    {
      title: t('common:sortBy'),
      items: orderingMenus.map(({ title, order, column, dataTest }) => ({
        text: t(title),
        dataTest,
        onClick: () => {
          setOrderBy({ order, column, title });
        }
      }))
    }
  ];

  const media = data?.mediaWithFolders?.data || [];
  const total = data?.mediaWithFolders?.paginatorInfo?.total;
  const hasMorePages = data?.mediaWithFolders?.paginatorInfo?.hasMorePages;
  const singleMedia = useQuery(getSingleMediaQuery, {
    context: { urlType: linkTypes.builder },
    variables: {
      id: parentId,
      type: mediaTypeMapper[type]
    },
    skip: !parentId
  });

  const parentsData = singleMedia?.data?.media || {};

  function handleScroll() {
    getImages({
      context: { urlType: linkTypes.builder },
      variables: {
        page: getCurrentPage(),
        first: 50,
        fetchMore: true,
        search,
        parentId
      }
    });
  }

  /**
   * @param trigger - triggers when need to get new data.
   * @param loading - Returns boolean value for loading state.
   * @param hasMore - Returns a boolean value to know if there are more pages or not.
   * @returns onScroll callback to get the position of the DOM element,
   * getCurrentPage callback to get current page and
   * resetPage callback to resetPage to default value.
   */
  const { onScroll, resetPage, getCurrentPage } = useInfiniteScroll({
    trigger: handleScroll,
    loading,
    hasMore: hasMorePages,
    global: false
  });

  const items = parentsData?.parents
    ? [
        { id: parentsData.id, name: parentsData.name },
        ...parentsData.parents
      ].reverse()
    : [];

  const onBreadcrumbClick = (parentId: string | null) => {
    setParentId(parentId);
    getImages({
      context: { urlType: linkTypes.builder },
      variables: {
        orderBy: [{ order, column }],
        first: 50,
        parentId,
        page: 1,
        type: mediaTypeMapper[type]
      }
    });
    resetPage();
  };

  /**
   * Toggles the value of the media select property.
   * @param id - Media id.
   */
  const onOverlayClick = (id: string) => {
    multiSelect
      ? setSelectedMedias(
          selectedMedias.includes(id)
            ? selectedMedias.filter(v => v !== id)
            : [...selectedMedias, id]
        )
      : setSelectedMedias([id]);
  };

  const onTabChange = (tab: string) => {
    resetPage();
    setType(tab as PageType);
    setParentId(null);
    getImages();
  };

  const Component = forMobile ? Mobile : Web;

  return (
    <Component
      type={type}
      total={total}
      items={items}
      media={media}
      types={types}
      order={order}
      column={column}
      orderBy={orderBy}
      loading={loading}
      visible={visible}
      onScroll={onScroll}
      parentId={parentId}
      onInsert={onInsert}
      onCancel={onCancel}
      setSearch={setSearch}
      hiddenIds={hiddenIds}
      getImages={getImages}
      closePopup={closePopup}
      initialIds={initialIds}
      uploadList={uploadList}
      setParentId={setParentId}
      multiSelect={multiSelect}
      getMediaById={getMediaById}
      visibleModal={visibleModal}
      renderPreview={renderPreview}
      setUploadList={setUploadList}
      orderMenuItems={orderMenuItems}
      setParentIdMob={setParentIdMob}
      onOverlayClick={onOverlayClick}
      selectedMedias={selectedMedias}
      setVisibleModal={setVisibleModal}
      setPreviewMedia={setPreviewMedia}
      mediaManagerData={mediaManagerData}
      onLoadRemoteMedia={onLoadRemoteMedia}
      setDefaultMediaId={setDefaultMediaId}
      setSelectedMedias={setSelectedMedias}
      onBreadcrumbClick={onBreadcrumbClick}
      getAddNewMenuItems={getAddNewMenuItems}
      loadRemoteMediaLoading={loadRemoteMediaLoading}
      handleImportFromUrlConfirm={handleImportFromUrlConfirm}
      onTabChange={onTabChange}
    />
  );
}

export default UploadFromMedia;
