import { KeyValuePair, PureNullable } from 'core/globalTypes';

const doesNotExists = (value: any) => value === null || value === undefined;

export const buttonGroupChangeHandler = (multiple: boolean) => {
  return function <T = string | number>(value: PureNullable<T> | T[]) {
    const shouldRemove =
      multiple && value ? !(value as T[]).length : doesNotExists(value);

    if (shouldRemove) {
      return null;
    }

    return value;
  };
};

export const multiselectChangeHandler = ({ ids }: KeyValuePair) => {
  if (!ids || !ids.length) {
    return null;
  }

  return ids;
};

export const selectChangeHandler = ({ value }: KeyValuePair) => {
  if (doesNotExists(value)) {
    return null;
  }

  return value;
};

export const numberInputChangeHandler = ({ value }: KeyValuePair) => {
  if (doesNotExists(value)) {
    return null;
  }

  return value;
};

export const dateChangeHandler = (date: PureNullable<string>) => {
  if (doesNotExists(date)) {
    return null;
  }

  return date;
};
