import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { Spin } from 'antd';
import { Project } from 'generatedHooks/accounts/generated';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import SystemMessage from 'helpers/utils/SystemMessage';
import { showJoinMessageQueryParam } from 'core/constants/common';
import { useSocket } from 'services/socketService';
import useProjectDataInit from 'providers/ProjectProvider/hooks/useProjectDataInit';

export const ProjectContext = createContext<Project>({} as Project);

export const useProjectContext = () => useContext(ProjectContext);

const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const { t } = useTranslation('common');
  const [searchParams, setSearchParams] = useSearchParams();

  const { projectData, hasError } = useProjectDataInit();

  useSocket(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has(showJoinMessageQueryParam) && projectData?.clientName) {
      SystemMessage.success(
        <div style={{ whiteSpace: 'pre-line' }}>
          <Trans
            shouldUnescape
            t={t}
            i18nKey="joinCongratulations"
            values={{ projectName: projectData?.clientName }}
          />
        </div>,
        {
          icon: <></>
        }
      );
      searchParams.delete(showJoinMessageQueryParam);
      setSearchParams(searchParams);
    }
  }, [projectData?.clientName]);

  if (hasError) {
    return <Navigate to="~" replace />;
  }

  return (
    <ProjectContext.Provider value={(projectData || {}) as Project}>
      {projectData ? children : <Spin />}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
