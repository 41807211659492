import { useProjectByAliasQuery } from 'generatedHooks/accounts/generated';
import { useLazyQuery } from '@apollo/client';
import { getMediaByIdsQuery } from 'packages/Media/queries/mediaQuery';
import { linkTypes } from 'apollo/link';
import { useSellerProjectQuery } from 'generatedHooks/commerce/generated';
import isMarketplaceSellerMode from 'helpers/commonHelpers/isMarketplaceSellerMode';
import marketplaceSellerModeResolver from 'helpers/commonHelpers/marketplaceSellerModeResolver';

const useProjectDataInit = () => {
  const projectName = location.pathname.split('/')[1];

  const [getMediaByIds] = useLazyQuery(getMediaByIdsQuery, {
    context: { urlType: linkTypes.builder }
  });

  const isSeller = isMarketplaceSellerMode();

  const { data: project, loading: projectByAliasLoading } =
    useProjectByAliasQuery({
      skip: isSeller,
      variables: {
        name: projectName
      },
      context: {
        urlType: linkTypes.accounts
      },
      async onCompleted(data) {
        const logoId = data.projectByAlias?.logo;

        if (logoId) {
          await getMediaByIds({
            variables: {
              ids: [logoId]
            }
          });
        }
      }
    });

  const { data: sellerProject, loading: sellerProjectLoading } =
    useSellerProjectQuery({
      skip: !isSeller
    });

  const sellerProjectData = sellerProject
    ? { ...sellerProject.project, previewUrl: window.origin }
    : null;

  const projectData = marketplaceSellerModeResolver(
    sellerProjectData,
    project?.projectByAlias
  );

  const queryResult = marketplaceSellerModeResolver(sellerProject, project);

  return {
    projectData,
    hasError: queryResult && !projectData,
    loading: projectByAliasLoading || sellerProjectLoading
  };
};

export default useProjectDataInit;
