import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Array: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DateTimeUnix: { input: any; output: any; }
  Email: { input: any; output: any; }
  Json: { input: any; output: any; }
  Mixed: { input: any; output: any; }
  URL: { input: any; output: any; }
  Upload: { input: any; output: any; }
  WorkspaceTeamSize: { input: any; output: any; }
  WorkspaceTeamType: { input: any; output: any; }
};

export type AiBlock = BlockInterface & {
  __typename?: 'AIBlock';
  categories: Array<BlockCategory>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  industries: Array<Industry>;
  scope: BlockScope;
  thumbnail?: Maybe<Scalars['URL']['output']>;
  title: Scalars['String']['output'];
};

export type AccountDeletionReason = {
  __typename?: 'AccountDeletionReason';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type ActivateSubscriptionInput = {
  subscriptionId: Scalars['ID']['input'];
};

export type ActivateWorkspaceClientsInput = {
  ids: Array<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type ActivityRecord = {
  __typename?: 'ActivityRecord';
  action?: Maybe<Scalars['String']['output']>;
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  geographicInfo?: Maybe<GeographicInfo>;
  id?: Maybe<Scalars['String']['output']>;
  user?: Maybe<LightweightUser>;
  userAgent?: Maybe<UserAgent>;
};

export type ActivityRecordFilter = {
  __typename?: 'ActivityRecordFilter';
  filter: Scalars['String']['output'];
  options?: Maybe<Scalars['Array']['output']>;
};

export type ActivityRecordFilterClauseInput = {
  column: ActivityRecordFilterColumn;
  operator: ConditionOperatorEnum;
  value: Scalars['Mixed']['input'];
};

/** List of available columns to filter Activity records. */
export enum ActivityRecordFilterColumn {
  /** Filter activity records by action field. */
  Action = 'ACTION',
  /** Filter activity records by browser field. */
  Browser = 'BROWSER',
  /** Filter activity records by country field. */
  Country = 'COUNTRY',
  /** Filter activity records by dateTime field. */
  DateTime = 'DATE_TIME',
  /** Filter activity records by operatingSystem field. */
  OperatingSystem = 'OPERATING_SYSTEM',
  /** Filter activity records by UserId field. */
  User = 'USER'
}

export type ActivityRecordInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type ActivityRecordOrderByClauseInput = {
  column: ActivityRecordOrderByColumn;
  order: SortOrder;
};

/** List of available columns to order Activity records. */
export enum ActivityRecordOrderByColumn {
  /** Order activity records by dateTime field. */
  DateTime = 'DATE_TIME',
  /** Order activity records by id field. */
  Id = 'ID'
}

export type ActivityRecordPaginator = {
  __typename?: 'ActivityRecordPaginator';
  data: Array<ActivityRecord>;
  paginatorInfo: PaginatorInfo;
};

export type ActivityRecordsAvailableFilterInput = {
  projectId: Scalars['String']['input'];
};

export type AdminCreateAiBlockInput = {
  categories: Array<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  industries?: InputMaybe<Array<Scalars['ID']['input']>>;
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
  title: Scalars['String']['input'];
};

export type AdminCreateGlobalBlockInput = {
  categories: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  industries: Array<Scalars['ID']['input']>;
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
  title: Scalars['String']['input'];
};

export type AdminDeleteDomainInput = {
  id: Scalars['ID']['input'];
};

export type AdminDeleteDomainsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type AdminGetBlocksInput = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>;
  projects?: InputMaybe<Array<Scalars['ID']['input']>>;
  scope?: InputMaybe<BlockScope>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type AdminProjectPaginator = {
  __typename?: 'AdminProjectPaginator';
  data: Array<Project>;
  paginatorInfo: PaginatorInfo;
};

export type AdminTemplateInput = {
  orderBy?: InputMaybe<Array<QueryTemplatesOrderOrderByClause>>;
  search?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AdminUpdateAiBlockInput = {
  categories: Array<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  industries?: InputMaybe<Array<Scalars['ID']['input']>>;
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateDomainInput = {
  id: Scalars['ID']['input'];
  isSubdomain?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<DomainStatus>;
};

export type AdminUpdateGlobalBlockInput = {
  categories: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  industries?: InputMaybe<Array<Scalars['ID']['input']>>;
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateLocalBlockInput = {
  categories: Array<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateTemplateInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  demoPath?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  designer?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  industries?: InputMaybe<Array<Scalars['ID']['input']>>;
  isBlank?: InputMaybe<Scalars['Boolean']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  visible: Scalars['Boolean']['input'];
};

export type AdminUpdateTemplateTagsInput = {
  id: Scalars['ID']['input'];
  tags: Array<Scalars['ID']['input']>;
};

export type AllPackagesInput = {
  packageType?: InputMaybe<PackageType>;
};

export type AllProjectsInput = {
  filters?: InputMaybe<Array<ProjectFilters>>;
  packageId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type AllWorkspacesInput = {
  packageId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationPackage = {
  __typename?: 'ApplicationPackage';
  agreement?: Maybe<Scalars['String']['output']>;
  alias: Scalars['String']['output'];
  applicationId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  features: FeaturePaginator;
  featuresText?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  limitations?: Maybe<Array<Limitation>>;
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  packageGroups?: Maybe<Array<PackageGroup>>;
  params?: Maybe<Scalars['Array']['output']>;
  permissions: Array<Permission>;
  projectRequired: Scalars['Boolean']['output'];
  public?: Maybe<Scalars['Boolean']['output']>;
};


export type ApplicationPackageFeaturesArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** A paginated list of ApplicationPackage items. */
export type ApplicationPackagePaginator = {
  __typename?: 'ApplicationPackagePaginator';
  /** A list of ApplicationPackage items. */
  data: Array<ApplicationPackage>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ApplicationPackageReference = {
  __typename?: 'ApplicationPackageReference';
  applicationPackage: Package;
  applicationPackageId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  packageable?: Maybe<Package>;
  packageableId: Scalars['ID']['output'];
  packageableType: Scalars['String']['output'];
};

export type ApplicationPackageTermsInput = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationPackageTranslationInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationRole = {
  applicationId: Scalars['ID']['input'];
  roles: Array<SingleRoleObject>;
};

export type ApplicationRoleType = {
  __typename?: 'ApplicationRoleType';
  applicationId: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AttachAddonInput = {
  couponId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
};

export type AttachCouponInput = {
  couponId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
};

export type AttachFeatureInput = {
  featureId: Scalars['ID']['input'];
  limit: Scalars['String']['input'];
  packageId: Scalars['ID']['input'];
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  phoneCode: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type BillingInfo = {
  __typename?: 'BillingInfo';
  intent: Scalars['Array']['output'];
  planId: Scalars['ID']['output'];
};

export type Block = AiBlock | GlobalBlock | LocalBlock;

export type BlockCategory = {
  __typename?: 'BlockCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BlockInterface = {
  categories: Array<BlockCategory>;
  id: Scalars['ID']['output'];
  scope: BlockScope;
  thumbnail?: Maybe<Scalars['URL']['output']>;
  title: Scalars['String']['output'];
};

/** A paginated list of Block items. */
export type BlockPaginator = {
  __typename?: 'BlockPaginator';
  /** A list of Block items. */
  data: Array<Block>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** List of available scopes for blocks. */
export enum BlockScope {
  /** AI scope. */
  Ai = 'AI',
  /** Global scope. */
  Global = 'GLOBAL',
  /** Local scope. */
  Local = 'LOCAL'
}

/** List of columns by which blocks can be ordered. */
export enum BlocksOrderByColumn {
  /** Order blocks by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order blocks by id. */
  Id = 'ID',
  /** Order blocks by title. */
  Title = 'TITLE'
}

/** List of available bulk action entities. */
export enum BulkActionEntity {
  /** Team member. */
  TeamMember = 'TEAM_MEMBER'
}

/** List of available bulk action operations. */
export enum BulkActionOperation {
  /** Create. */
  Create = 'CREATE',
  /** Delete. */
  Delete = 'DELETE',
  /** Make invisible. */
  MakeInvisible = 'MAKE_INVISIBLE',
  /** Make visible. */
  MakeVisible = 'MAKE_VISIBLE',
  /** Publish. */
  Publish = 'PUBLISH',
  /** Un publish. */
  UnPublish = 'UN_PUBLISH',
  /** Update. */
  Update = 'UPDATE'
}

export type BulkActionResponse = {
  __typename?: 'BulkActionResponse';
  entity: BulkActionEntity;
  uuid: Scalars['ID']['output'];
};

export type BulkActionTeamInput = {
  /** Each id prefixed with "t-" (team member) or "i-" (invitation), e.g. '["t-20", "i-51"]'. */
  excludedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filters?: InputMaybe<MembersInput>;
  /** Each id prefixed with "t-" (team member) or "i-" (invitation), e.g. '["t-20", "i-51"]'. */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type CancelSubscriptionInput = {
  subscriptionId: Scalars['ID']['input'];
};

export type CheckDomainInput = {
  domain: Scalars['String']['input'];
};

export type CheckProjectCreationStatus = {
  __typename?: 'CheckProjectCreationStatus';
  creationStatus: ProjectCreationStatus;
  id?: Maybe<Scalars['ID']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type CheckProjectEmailSenderDomainSettingsInput = {
  domainId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type CheckoutDomainInput = {
  buyerInfo: DomainBuyerInput;
  domains: Array<DomainsInput>;
  nonce: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

/** List of available conditions. */
export enum ConditionOperatorEnum {
  /** Column equals to. */
  Eq = 'EQ',
  /** Column is greater than. */
  Gt = 'GT',
  /** Column is greater than or equal. */
  Gte = 'GTE',
  /** Column is in. */
  In = 'IN',
  /** Column is less than. */
  Lt = 'LT',
  /** Column is less then or equal. */
  Lte = 'LTE',
  /** Column is not equal. */
  Neq = 'NEQ',
  /** Column is not in. */
  NotIn = 'NOT_IN'
}

export type Configuration = {
  __typename?: 'Configuration';
  project: ProjectConfiguration;
};

export type ConnectDomainInput = {
  domain: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type ConnectedDomain = {
  __typename?: 'ConnectedDomain';
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isBoughtFromUs: Scalars['Boolean']['output'];
};

export type Coupon = {
  __typename?: 'Coupon';
  amount?: Maybe<Scalars['Float']['output']>;
  code: Scalars['String']['output'];
  connectedToReferralSystem?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberOfCoupons?: Maybe<Scalars['Int']['output']>;
  params?: Maybe<Scalars['Array']['output']>;
  percent: Scalars['Boolean']['output'];
  plans?: Maybe<Array<Plan>>;
  prorate: Scalars['Boolean']['output'];
  redeem: CouponRedeemType;
  usedCouponsCount?: Maybe<Scalars['Int']['output']>;
};

/** A paginated list of Coupon items. */
export type CouponPaginator = {
  __typename?: 'CouponPaginator';
  /** A list of Coupon items. */
  data: Array<Coupon>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** List of available redeem types for Coupons. */
export enum CouponRedeemType {
  /** Auto redeem type of coupon. */
  AutoRedeem = 'AUTO_REDEEM',
  /** Internal redeem type of coupon. */
  Internal = 'INTERNAL',
  /** Manual redeem type of coupon. */
  Manual = 'MANUAL'
}

export type CouponTermsInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** List of available columns to order Coupons. */
export enum CouponsOrderByColumn {
  /** Order coupons by code field. */
  Code = 'CODE',
  /** Order coupons by created_at field. */
  CreatedAt = 'CREATED_AT',
  /** Order coupons by id field. */
  Id = 'ID'
}

export type CreateApplicationPackageInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<FeatureInput>>;
  featuresText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['Upload']['input']>;
  limitations?: InputMaybe<Array<LimitationInput>>;
  name: Scalars['String']['input'];
  ordering?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<Scalars['Array']['input']>;
  permissions?: InputMaybe<Array<Scalars['ID']['input']>>;
  public: Scalars['Boolean']['input'];
  translations?: InputMaybe<Array<ApplicationPackageTranslationInput>>;
};

export type CreateApplicationPackageReferenceInput = {
  applicationPackageId: Scalars['ID']['input'];
  packageableId: Scalars['ID']['input'];
  packageableType: Scalars['String']['input'];
};

export type CreateBillingDetailsInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  phoneCode: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  recaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type CreateCouponInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  code: Scalars['String']['input'];
  connectedToReferralSystem?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  numberOfCoupons?: InputMaybe<Scalars['Int']['input']>;
  percent: Scalars['Boolean']['input'];
  plans?: InputMaybe<Array<Scalars['ID']['input']>>;
  prorate: Scalars['Boolean']['input'];
  redeem: CouponRedeemType;
};

export type CreateDomainPackageInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<FeatureInput>>;
  featuresText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
  ordering?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<Scalars['Array']['input']>;
  public: Scalars['Boolean']['input'];
  translations?: InputMaybe<Array<DomainPackageTranslationInput>>;
};

export type CreateFeatureGroupInput = {
  alias: Scalars['String']['input'];
  title: Scalars['String']['input'];
  translations?: InputMaybe<Array<FeatureGroupTranslationInput>>;
};

export type CreateFeatureInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  ordering?: InputMaybe<Scalars['Int']['input']>;
  packageType: PackageType;
  params?: InputMaybe<Scalars['Array']['input']>;
  title: Scalars['String']['input'];
  translations?: InputMaybe<Array<FeatureTranslationInput>>;
};

export type CreateMailTemplateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alias: Scalars['String']['input'];
  body?: InputMaybe<Scalars['String']['input']>;
  bodyObject?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  packageId?: InputMaybe<Scalars['ID']['input']>;
  packageType?: InputMaybe<PackageType>;
  subject?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<CreateMailTemplateTranslationInput>>;
};

export type CreateMailTemplateTranslationInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  bodyObject?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePaymentMethodInput = {
  nonce: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type CreatePermissionGroupInput = {
  alias: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreatePermissionInput = {
  alias: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CreatePlanInput = {
  activeFeatures?: InputMaybe<Array<PlanFeatureLimit>>;
  addons?: InputMaybe<Array<Scalars['ID']['input']>>;
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias: Scalars['String']['input'];
  billingFrequency: Scalars['Int']['input'];
  cardRequired?: InputMaybe<Scalars['Boolean']['input']>;
  comingSoon?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<Scalars['String']['input']>>;
  moneyback?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  ordering?: InputMaybe<Scalars['Int']['input']>;
  packageId: Scalars['ID']['input'];
  packageType: PackageType;
  plans?: InputMaybe<Array<Scalars['ID']['input']>>;
  popular?: InputMaybe<Scalars['Boolean']['input']>;
  price: Scalars['Float']['input'];
  recommended?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<Array<PlanTranslationInput>>;
  trialDays?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<PlanVisibility>;
};

export type CreateProjectInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  billingUserId?: InputMaybe<Scalars['ID']['input']>;
  businessLegalName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  coupon?: InputMaybe<Scalars['String']['input']>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  isEcommerce?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  measurement: Measurement;
  name: Scalars['String']['input'];
  nonce?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  planAlias: Scalars['String']['input'];
  recaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  socials?: InputMaybe<Array<SocialItemInput>>;
  state?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
  timezone: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['ID']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProjectPackageInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias: Scalars['String']['input'];
  allowedTemplateTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<FeatureInput>>;
  featuresText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['Upload']['input']>;
  isUpgradable?: InputMaybe<Scalars['Boolean']['input']>;
  limitations?: InputMaybe<Array<LimitationInput>>;
  name: Scalars['String']['input'];
  ordering?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<Scalars['Array']['input']>;
  permissions: Array<Scalars['ID']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<Array<ProjectPackageTranslationInput>>;
};

export type CreateRoleInput = {
  alias: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type CreateTagInput = {
  name: Scalars['String']['input'];
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateTagsInput = {
  names: Array<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateTemplateTypeInput = {
  name: Scalars['String']['input'];
  packageId: Scalars['ID']['input'];
  planAlias?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<TemplateTypeTranslationInput>>;
};

export type CreateWorkspaceClientInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['Int']['input'];
};

export type CreateWorkspaceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  billingUserId?: InputMaybe<Scalars['ID']['input']>;
  businessDescription?: InputMaybe<Scalars['String']['input']>;
  businessLegalName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  legal?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nonce?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  planAlias: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  teamSize?: InputMaybe<Scalars['WorkspaceTeamSize']['input']>;
  teamType?: InputMaybe<Scalars['WorkspaceTeamType']['input']>;
  webPage?: InputMaybe<Scalars['URL']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkspacePackageInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias: Scalars['String']['input'];
  allowedTemplateTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<FeatureInput>>;
  featuresText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['Upload']['input']>;
  limitations?: InputMaybe<Array<WorkspaceLimitationInput>>;
  name: Scalars['String']['input'];
  ordering?: InputMaybe<Scalars['Int']['input']>;
  partnerDiscount?: InputMaybe<Scalars['Float']['input']>;
  permissions: Array<Scalars['ID']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateWorkspacePaymentMethodInput = {
  nonce: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateWorkspacePermissionGroupInput = {
  alias: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateWorkspaceRoleInput = {
  alias: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

/** List of available DNS record types. */
export enum DnsRecordType {
  /** CNAME Record Type. */
  Cname = 'CNAME',
  /** TXT Record Type. */
  Txt = 'TXT'
}

export type DeleteAccountInput = {
  code: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  substitutes?: InputMaybe<Array<SubstitutesInput>>;
};

export type DeleteApplicationPackageInput = {
  id: Scalars['ID']['input'];
};

export type DeleteApplicationPackageReferenceInput = {
  id: Scalars['ID']['input'];
};

export type DeleteApplicationPackagesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteCouponsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteDomainInput = {
  id: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type DeleteDomainPackageInput = {
  id: Scalars['ID']['input'];
};

export type DeleteDomainPackagesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteFeatureGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteFeatureGroupsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteFeatureInput = {
  id: Scalars['ID']['input'];
};

export type DeleteFeaturesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteInvitationInput = {
  id: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type DeleteMemberInput = {
  projectId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type DeletePermissionGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeletePermissionInput = {
  id: Scalars['ID']['input'];
};

export type DeletePermissionsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeletePlanInput = {
  id: Scalars['ID']['input'];
};

export type DeletePlansInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteProjectInput = {
  id: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};

export type DeleteProjectPackageInput = {
  id: Scalars['ID']['input'];
};

export type DeleteProjectPackagesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteProjectWithoutPasswordInput = {
  id: Scalars['ID']['input'];
};

export type DeleteProjectsWithoutPasswordInput = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type DeleteRoleInput = {
  projectId: Scalars['ID']['input'];
  replaceId?: InputMaybe<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
};

export type DeleteTemplateTypeInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUsersInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteWorkspaceClientsInput = {
  ids: Array<Scalars['ID']['input']>;
  password: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type DeleteWorkspaceInvitationsInput = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  workspaceId: Scalars['ID']['input'];
};

export type DeleteWorkspaceMemberInput = {
  userIds: Array<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type DeleteWorkspacePackageInput = {
  id: Scalars['ID']['input'];
};

export type DeleteWorkspacePackagesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteWorkspacePermissionGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteWorkspaceRoleInput = {
  id: Scalars['ID']['input'];
  replaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteWorkspaceWithoutPasswordInput = {
  id: Scalars['ID']['input'];
};

export type DeleteWorkspacesWithoutPasswordInput = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type DetachAddonInput = {
  couponId: Array<Scalars['ID']['input']>;
  planId: Scalars['ID']['input'];
};

export type DetachCouponInput = {
  couponId: Array<Scalars['ID']['input']>;
  planId: Scalars['ID']['input'];
};

export type DetachFeatureInput = {
  featureId: Array<Scalars['ID']['input']>;
  packageId: Scalars['ID']['input'];
};

export type DisconnectDomainInput = {
  domainId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type DnsRecord = {
  __typename?: 'DnsRecord';
  connected: Scalars['Boolean']['output'];
  host: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Domain = {
  __typename?: 'Domain';
  creator: User;
  default: Scalars['Boolean']['output'];
  dnsRecords?: Maybe<Array<DnsRecord>>;
  dnsVerified: Scalars['Boolean']['output'];
  domain: Scalars['String']['output'];
  expiryDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isChecked: Scalars['Boolean']['output'];
  isSubDomain: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  mailbox?: Maybe<Scalars['String']['output']>;
  params?: Maybe<Array<DomainParamsData>>;
  projectNativeHost?: Maybe<Scalars['String']['output']>;
  purchase?: Maybe<Purchase>;
  redirectToWww: Scalars['Boolean']['output'];
  registrantData?: Maybe<Array<DomainRegistrantData>>;
  ssl: Scalars['Boolean']['output'];
  status: DomainStatus;
  type: DomainType;
  wrongRecords?: Maybe<Array<Maybe<WrongRecord>>>;
};

export type DomainBuyerInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  code: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type DomainCheckoutResponse = {
  __typename?: 'DomainCheckoutResponse';
  invoices: Array<DomainInvoice>;
  paymentMethod: PaymentMethod;
};

export type DomainFrequency = {
  __typename?: 'DomainFrequency';
  frequency: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
};

export type DomainInvoice = {
  __typename?: 'DomainInvoice';
  discount?: Maybe<Scalars['Float']['output']>;
  domain: Scalars['String']['output'];
  frequency: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  old?: Maybe<Scalars['Boolean']['output']>;
  price: Scalars['Float']['output'];
  summary: Scalars['Float']['output'];
  transactionId: Scalars['ID']['output'];
};

export type DomainPackage = {
  __typename?: 'DomainPackage';
  agreement?: Maybe<Scalars['String']['output']>;
  alias: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  features: FeaturePaginator;
  featuresText?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  packageGroups?: Maybe<Array<PackageGroup>>;
  params?: Maybe<Scalars['Array']['output']>;
  projectRequired: Scalars['Boolean']['output'];
  public?: Maybe<Scalars['Boolean']['output']>;
};


export type DomainPackageFeaturesArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** A paginated list of DomainPackage items. */
export type DomainPackagePaginator = {
  __typename?: 'DomainPackagePaginator';
  /** A list of DomainPackage items. */
  data: Array<DomainPackage>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type DomainPackageTermsInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type DomainPackageTranslationInput = {
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A paginated list of Domain items. */
export type DomainPaginator = {
  __typename?: 'DomainPaginator';
  /** A list of Domain items. */
  data: Array<Domain>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type DomainParamsData = {
  __typename?: 'DomainParamsData';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DomainRegistrantData = {
  __typename?: 'DomainRegistrantData';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DomainSearchResult = {
  __typename?: 'DomainSearchResult';
  available: Scalars['Boolean']['output'];
  billingFrequencies: Array<DomainFrequency>;
  currency: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  domainName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

/** List of available statuses for domain. */
export enum DomainStatus {
  /** Domain is connected. */
  Connected = 'CONNECTED',
  /** Domain is disconnected. */
  Disconnected = 'DISCONNECTED',
  /** Domain is pending. */
  Pending = 'PENDING'
}

export type DomainTermsInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** List of available types for domain. */
export enum DomainType {
  /** Domain is root domain. */
  Domain = 'DOMAIN',
  /** Domain is href lang. */
  HrefLang = 'HREF_LANG',
  /** Domain is sub domain. */
  SubDomain = 'SUB_DOMAIN'
}

export type DomainsInput = {
  domain: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
};

/** The DomainsOrderByColumn enum specifies the columns by which domains can be ordered. */
export enum DomainsOrderByColumn {
  /** Order domains by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order domains by domain name. */
  Domain = 'DOMAIN'
}

export type DuplicateRoleInput = {
  projectId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

export type DuplicateWorkspaceRoleInput = {
  id: Scalars['ID']['input'];
};

export type EntityLimitation = {
  __typename?: 'EntityLimitation';
  entity: LimitationType;
  limit: Scalars['Int']['output'];
};

export type ExtendSubscriptionInput = {
  subscriptionId: Scalars['ID']['input'];
};

export type Feature = {
  __typename?: 'Feature';
  description: Scalars['String']['output'];
  group?: Maybe<FeatureGroup>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['String']['output']>;
  ordering?: Maybe<Scalars['Int']['output']>;
  packageType: PackageType;
  selected?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
};

export type FeatureGroup = {
  __typename?: 'FeatureGroup';
  alias: Scalars['String']['output'];
  features: Array<Feature>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};


export type FeatureGroupFeaturesArgs = {
  input?: InputMaybe<FeatureTermsInput>;
};

/** A paginated list of FeatureGroup items. */
export type FeatureGroupPaginator = {
  __typename?: 'FeatureGroupPaginator';
  /** A list of FeatureGroup items. */
  data: Array<FeatureGroup>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FeatureGroupTermsInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FeatureGroupTranslationInput = {
  locale: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

/** List of available ordering columns for feature groups. */
export enum FeatureGroupsOrderByColumn {
  /** Column to order by created_at. */
  CreatedAt = 'CREATED_AT',
  /** Column to order by title. */
  Title = 'TITLE'
}

export type FeatureInput = {
  id: Scalars['ID']['input'];
  limit: Scalars['String']['input'];
};

/** A paginated list of Feature items. */
export type FeaturePaginator = {
  __typename?: 'FeaturePaginator';
  /** A list of Feature items. */
  data: Array<Feature>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FeatureTermsInput = {
  packageType: PackageType;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FeatureTranslationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

/** List of available ordering columns for features. */
export enum FeaturesOrderByColumn {
  /** Column to order by created_at. */
  CreatedAt = 'CREATED_AT',
  /** Column to order by title. */
  Title = 'TITLE'
}

export type FilterTemplateTagInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GeographicInfo = {
  __typename?: 'GeographicInfo';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  ip: Scalars['String']['output'];
};

export type GetWorkspaceClientInvoicesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WorkspaceClientInvoiceStatus>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type GetWorkspaceClientSubscriptionsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WorkspaceClientSubscriptionStatus>;
};

export type GetWorkspaceClientsInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorkspaceClientsOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type GetWorkspaceClientsInvoicesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WorkspaceClientInvoiceStatus>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type GetWorkspaceClientsSubscriptionsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WorkspaceClientSubscriptionStatus>;
  workspaceId: Scalars['ID']['input'];
};

export type GetWorkspacePermissionGroupsInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorkspacePermissionGroupsOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type GetWorkspaceProjectsInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorkspaceProjectOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GetWorkspaceRolesInput = {
  orderBy?: InputMaybe<Array<WorkspaceRolesOrderByClause>>;
  workspaceId: Scalars['ID']['input'];
};

export type GetWorkspaceTemplatesInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorkspaceTemplateOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GetWorkspaceUsersInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GetWorkspacesInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WorkspacesOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GlobalBlock = BlockInterface & {
  __typename?: 'GlobalBlock';
  categories: Array<BlockCategory>;
  id: Scalars['ID']['output'];
  industries: Array<Industry>;
  scope: BlockScope;
  thumbnail?: Maybe<Scalars['URL']['output']>;
  title: Scalars['String']['output'];
};

export type Host = Project | User | Workspace;

export type Industry = {
  __typename?: 'Industry';
  alias: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Invitation = Membership & {
  __typename?: 'Invitation';
  email: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
  role: Role;
  status: MembershipStatus;
};


export type InvitationRoleArgs = {
  projectId: Scalars['ID']['input'];
};

export type InviteMembersInput = {
  applicationRoles?: InputMaybe<Array<ApplicationRole>>;
  emails: Array<Scalars['Email']['input']>;
  projectId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

export type InviteMembersWithNewRoleInput = {
  alias: Scalars['String']['input'];
  applicationRoles?: InputMaybe<Array<ApplicationRole>>;
  description: Scalars['String']['input'];
  emails: Array<Scalars['Email']['input']>;
  name: Scalars['String']['input'];
  permissionIds: Array<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};

export type InviteWorkspaceMembersInput = {
  emails: Array<Scalars['Email']['input']>;
  roleId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type InviteWorkspaceMembersWithNewRoleInput = {
  alias: Scalars['String']['input'];
  description: Scalars['String']['input'];
  emails: Array<Scalars['Email']['input']>;
  name: Scalars['String']['input'];
  permissionIds: Array<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

/** List of available language detection modes. */
export enum LanguageDetectionMode {
  /** Browser. */
  Browser = 'BROWSER',
  /** Default. */
  Default = 'DEFAULT',
  /** IP. */
  Ip = 'IP',
  /** Ip browser. */
  IpBrowser = 'IP_BROWSER'
}

export type LeaveProjectInput = {
  projectId: Scalars['ID']['input'];
};

export type LightweightUser = {
  __typename?: 'LightweightUser';
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['Email']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type Limitation = {
  __typename?: 'Limitation';
  count: Scalars['Int']['output'];
  entity: LimitationType;
  limit: Scalars['Int']['output'];
  limitReached: Scalars['Boolean']['output'];
};

export type LimitationInput = {
  entity: LimitationType;
  limit: Scalars['Int']['input'];
};

/** List of available limitation types. */
export enum LimitationType {
  /** Appointment product type. */
  AppointmentProduct = 'APPOINTMENT_PRODUCT',
  /** Collection item type. */
  CollectionItem = 'COLLECTION_ITEM',
  /** Currency type. */
  Currency = 'CURRENCY',
  /** Digital product type. */
  DigitalProduct = 'DIGITAL_PRODUCT',
  /** Event product type. */
  EventProduct = 'EVENT_PRODUCT',
  /** Gift card product type. */
  GiftCardProduct = 'GIFT_CARD_PRODUCT',
  /** Inventory source type. */
  InventorySource = 'INVENTORY_SOURCE',
  /** Language type. */
  Language = 'LANGUAGE',
  /** Page type. */
  Page = 'PAGE',
  /** Physical product type. */
  PhysicalProduct = 'PHYSICAL_PRODUCT',
  /** Product type. */
  Product = 'PRODUCT',
  /** Rental product type. */
  RentalProduct = 'RENTAL_PRODUCT',
  /** Reservation product type. */
  ReservationProduct = 'RESERVATION_PRODUCT',
  /** Table product type. */
  TableProduct = 'TABLE_PRODUCT',
  /** Tax rate type. */
  TaxRate = 'TAX_RATE',
  /** Team member type. */
  TeamMember = 'TEAM_MEMBER'
}

export type LocalBlock = BlockInterface & {
  __typename?: 'LocalBlock';
  categories: Array<BlockCategory>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  scope: BlockScope;
  thumbnail?: Maybe<Scalars['URL']['output']>;
  title: Scalars['String']['output'];
};

export type LocalesInfo = {
  __typename?: 'LocalesInfo';
  availableLocales: Scalars['Array']['output'];
  availableLocalesWithCountries: Scalars['Array']['output'];
  defaultLocale: Scalars['String']['output'];
  fallbackLocale: Scalars['String']['output'];
};

export type MailTemplate = {
  __typename?: 'MailTemplate';
  active: Scalars['Boolean']['output'];
  alias: Scalars['String']['output'];
  body?: Maybe<Scalars['String']['output']>;
  bodyObject?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isTemplate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['ID']['output']>;
  packageType?: Maybe<PackageType>;
  subject?: Maybe<Scalars['String']['output']>;
  variables?: Maybe<Scalars['Array']['output']>;
};

export type MakeDomainDefaultInput = {
  domainId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type ManipulatePaymentMethodInput = {
  projectId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type ManipulateWorkspacePaymentMethodInput = {
  token: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

/** List of available types for measurement. */
export enum Measurement {
  /** Imperial measurement type. */
  Imperial = 'IMPERIAL',
  /** Metric measurement type. */
  Metric = 'METRIC'
}

export type Member = Invitation | TeamMember;

export type MembersInput = {
  orderBy?: InputMaybe<MembersOrderByClause>;
  projectId: Scalars['ID']['input'];
  roleId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MembershipStatus>;
};

export type MembersOrderByClause = {
  column: MembersOrderColumn;
  order: SortOrder;
};

/** List of available order columns. */
export enum MembersOrderColumn {
  /** Order by created date. */
  CreatedAt = 'CREATED_AT',
  /** Order by name. */
  Email = 'EMAIL'
}

export type Membership = {
  email: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
  status: MembershipStatus;
};

/** List of available types of membership status. */
export enum MembershipStatus {
  /** ACTIVE status. */
  Active = 'ACTIVE',
  /** PENDING status. */
  Pending = 'PENDING'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateSubscription: Scalars['Boolean']['output'];
  activateWorkspaceClients: Scalars['Boolean']['output'];
  activateWorkspaceSubscription: Scalars['Boolean']['output'];
  adminCreateAIBlock: AiBlock;
  adminCreateGlobalBlock: GlobalBlock;
  adminDeleteDomain: Scalars['Boolean']['output'];
  adminDeleteDomains: Scalars['Int']['output'];
  adminRemoveBlock: Scalars['Boolean']['output'];
  adminUpdateAIBlock: AiBlock;
  adminUpdateDomain: Domain;
  adminUpdateGlobalBlock: GlobalBlock;
  adminUpdateLocalBlock: LocalBlock;
  adminUpdateProject?: Maybe<Project>;
  assignPermissionToUser: Scalars['Boolean']['output'];
  assignRoleToUser: Scalars['Boolean']['output'];
  attachAddon: Plan;
  attachCoupon: Plan;
  attachFeatureToDomainPackage: Scalars['Boolean']['output'];
  attachFeatureToProjectPackage: Scalars['Boolean']['output'];
  attachFeatureToWorkspacePackage: Scalars['Boolean']['output'];
  bulkDeleteMember: BulkActionResponse;
  cancelSubscription: Scalars['Boolean']['output'];
  cancelWorkspaceSubscription: Scalars['Boolean']['output'];
  checkoutDomain: DomainCheckoutResponse;
  connectDomain: Domain;
  createApplicationPackage: ApplicationPackage;
  createApplicationPackageReference: ApplicationPackageReference;
  createBillingDetails: User;
  createCoupon: Coupon;
  createDomainPackage: DomainPackage;
  createFeature: Feature;
  createFeatureGroup: FeatureGroup;
  createMailTemplate?: Maybe<MailTemplate>;
  createPaymentMethod: PaymentMethod;
  createPermissionGroup: PermissionGroup;
  createPlan: Plan;
  createProject?: Maybe<Project>;
  createProjectPackage: ProjectPackage;
  createRole: Role;
  createTag: TemplateTag;
  createTags: Scalars['Boolean']['output'];
  createTemplateType: TemplateType;
  createWorkspace: Workspace;
  createWorkspaceClient: WorkspaceClient;
  createWorkspacePackage: WorkspacePackage;
  createWorkspacePaymentMethod: PaymentMethod;
  createWorkspaceProject?: Maybe<Project>;
  createWorkspaceRole: WorkspaceRole;
  deleteAccount: Scalars['Boolean']['output'];
  deleteApplicationPackage: Scalars['Boolean']['output'];
  deleteApplicationPackageReference: Scalars['Boolean']['output'];
  deleteApplicationPackages: Scalars['Int']['output'];
  deleteCoupon?: Maybe<Scalars['Boolean']['output']>;
  deleteCoupons: Scalars['Int']['output'];
  deleteDomain: Scalars['Boolean']['output'];
  deleteDomainPackage: Scalars['Boolean']['output'];
  deleteDomainPackages: Scalars['Int']['output'];
  deleteFeature: Scalars['Boolean']['output'];
  deleteFeatureGroup: Scalars['Boolean']['output'];
  deleteFeatureGroups: Scalars['Int']['output'];
  deleteFeatures: Scalars['Int']['output'];
  deleteInvitation: Scalars['Boolean']['output'];
  deleteMember: Scalars['Boolean']['output'];
  deletePaymentMethod: Scalars['Boolean']['output'];
  deletePermissionGroup: Scalars['Boolean']['output'];
  deletePlan: Scalars['Boolean']['output'];
  deletePlans?: Maybe<Scalars['Int']['output']>;
  deleteProject: Scalars['Boolean']['output'];
  deleteProjectPackage: Scalars['Boolean']['output'];
  deleteProjectPackages: Scalars['Int']['output'];
  deleteProjectWithoutPassword: Scalars['Boolean']['output'];
  deleteProjectsWithoutPassword: Scalars['Int']['output'];
  deleteRole: Scalars['Boolean']['output'];
  deleteTag: TemplateTag;
  deleteTemplateType: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  deleteUsers: Scalars['Int']['output'];
  deleteWorkspaceClients: Scalars['Boolean']['output'];
  deleteWorkspaceInvitations: Scalars['Boolean']['output'];
  deleteWorkspaceMember: Scalars['Boolean']['output'];
  deleteWorkspacePackage: Scalars['Boolean']['output'];
  deleteWorkspacePackages: Scalars['Int']['output'];
  deleteWorkspacePaymentMethod: Scalars['Boolean']['output'];
  deleteWorkspaceProject: Scalars['Boolean']['output'];
  deleteWorkspaceRole: Scalars['Boolean']['output'];
  deleteWorkspaceTemplate: Scalars['Boolean']['output'];
  detachAddon: Plan;
  detachCoupon: Plan;
  detachFeatureFromDomainPackage: Scalars['Boolean']['output'];
  detachFeatureFromProjectPackage: Scalars['Boolean']['output'];
  detachFeatureFromWorkspacePackage: Scalars['Boolean']['output'];
  disconnectDomain: Domain;
  duplicateRole: Role;
  duplicateWorkspaceRole: WorkspaceRole;
  extendSubscription?: Maybe<SubscriptionService>;
  extendWorkspaceSubscription?: Maybe<SubscriptionService>;
  forceDeleteUser: Scalars['Boolean']['output'];
  forceDeleteUsers: Scalars['Int']['output'];
  generateProjectName: Scalars['String']['output'];
  generateWorkspaceName: Scalars['String']['output'];
  inviteMembers: Scalars['Boolean']['output'];
  inviteMembersWithNewRole?: Maybe<Scalars['Boolean']['output']>;
  inviteWorkspaceMembers: Scalars['Boolean']['output'];
  inviteWorkspaceMembersWithNewRole?: Maybe<Scalars['Boolean']['output']>;
  leaveProject?: Maybe<Scalars['Boolean']['output']>;
  makeDomainDefault: Domain;
  removeAvatar: Scalars['Boolean']['output'];
  removeOwnTemplate: Scalars['Boolean']['output'];
  removePermissionFromUser: Scalars['Boolean']['output'];
  removeRoleFromUser: Scalars['Boolean']['output'];
  removeTemplate: Scalars['Boolean']['output'];
  removeTemplateThumbnail: Scalars['Boolean']['output'];
  requestPermission: PermissionRequest;
  resaveTemplate: Template;
  saveAsTemplate: Template;
  saveWorkspaceProjectAsTemplate: Template;
  setDefaultPaymentMethod: Scalars['Boolean']['output'];
  setPassword?: Maybe<Project>;
  setWorkspaceDefaultPaymentMethod: Scalars['Boolean']['output'];
  setupInitialPayment: Scalars['Boolean']['output'];
  transferProject: Scalars['Boolean']['output'];
  transferProjectOwnership: Scalars['Boolean']['output'];
  unsetPassword?: Maybe<Project>;
  updateApplicationPackage: ApplicationPackage;
  updateApplicationPackageReference: ApplicationPackageReference;
  updateBillingDetails: User;
  updateCoupon: Coupon;
  updateCurrentPlan: Plan;
  updateCurrentWorkspacePlan: Plan;
  updateDomain: Domain;
  updateDomainPackage: DomainPackage;
  updateFeature: Feature;
  updateFeatureGroup: FeatureGroup;
  updateMailTemplate?: Maybe<MailTemplate>;
  updateMailTemplateContent?: Maybe<MailTemplate>;
  updateMembersRole: Scalars['Boolean']['output'];
  updateMembersWithNewRole: Scalars['Boolean']['output'];
  updateOwnTemplate: Template;
  updateOwnTemplateTags: Template;
  updatePassword: Scalars['Boolean']['output'];
  updatePermission: Permission;
  updatePermissionGroup: PermissionGroup;
  updatePlan: Plan;
  updateProfile: User;
  updateProject?: Maybe<Project>;
  updateProjectEmailSenderConfig: ProjectEmailSenderConfig;
  updateProjectPackage: ProjectPackage;
  updateRole: Role;
  updateSubscription: Scalars['Boolean']['output'];
  updateTag: TemplateTag;
  updateTemplate: Template;
  updateTemplateTags: Template;
  updateTemplateType: TemplateType;
  updateUser: User;
  updateUserProjectNotificationSettings: UserProjectNotificationSetting;
  updateWorkspace: Workspace;
  updateWorkspaceClient: WorkspaceClient;
  updateWorkspaceMembersRole: Scalars['Boolean']['output'];
  updateWorkspaceMembersWithNewRole: Scalars['Boolean']['output'];
  updateWorkspacePackage: WorkspacePackage;
  updateWorkspaceRole: WorkspaceRole;
  updateWorkspaceSubscription: Scalars['Boolean']['output'];
  updateWorkspaceTemplate: Template;
  verifyAccountDeletion: Scalars['Boolean']['output'];
};


export type MutationActivateSubscriptionArgs = {
  input: ActivateSubscriptionInput;
};


export type MutationActivateWorkspaceClientsArgs = {
  input: ActivateWorkspaceClientsInput;
};


export type MutationActivateWorkspaceSubscriptionArgs = {
  input: ActivateSubscriptionInput;
};


export type MutationAdminCreateAiBlockArgs = {
  input: AdminCreateAiBlockInput;
};


export type MutationAdminCreateGlobalBlockArgs = {
  input: AdminCreateGlobalBlockInput;
};


export type MutationAdminDeleteDomainArgs = {
  input: AdminDeleteDomainInput;
};


export type MutationAdminDeleteDomainsArgs = {
  input: AdminDeleteDomainsInput;
};


export type MutationAdminRemoveBlockArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAdminUpdateAiBlockArgs = {
  input: AdminUpdateAiBlockInput;
};


export type MutationAdminUpdateDomainArgs = {
  input: AdminUpdateDomainInput;
};


export type MutationAdminUpdateGlobalBlockArgs = {
  input: AdminUpdateGlobalBlockInput;
};


export type MutationAdminUpdateLocalBlockArgs = {
  input: AdminUpdateLocalBlockInput;
};


export type MutationAdminUpdateProjectArgs = {
  input: UpdateProjectInput;
};


export type MutationAssignPermissionToUserArgs = {
  input: PermissionAssignmentInput;
};


export type MutationAssignRoleToUserArgs = {
  input: RoleAssignmentInput;
};


export type MutationAttachAddonArgs = {
  input: AttachAddonInput;
};


export type MutationAttachCouponArgs = {
  input: AttachCouponInput;
};


export type MutationAttachFeatureToDomainPackageArgs = {
  input: AttachFeatureInput;
};


export type MutationAttachFeatureToProjectPackageArgs = {
  input: AttachFeatureInput;
};


export type MutationAttachFeatureToWorkspacePackageArgs = {
  input: AttachFeatureInput;
};


export type MutationBulkDeleteMemberArgs = {
  input: BulkActionTeamInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCancelWorkspaceSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCheckoutDomainArgs = {
  input: CheckoutDomainInput;
};


export type MutationConnectDomainArgs = {
  input: ConnectDomainInput;
};


export type MutationCreateApplicationPackageArgs = {
  input: CreateApplicationPackageInput;
};


export type MutationCreateApplicationPackageReferenceArgs = {
  input: CreateApplicationPackageReferenceInput;
};


export type MutationCreateBillingDetailsArgs = {
  input: CreateBillingDetailsInput;
};


export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationCreateDomainPackageArgs = {
  input: CreateDomainPackageInput;
};


export type MutationCreateFeatureArgs = {
  input: CreateFeatureInput;
};


export type MutationCreateFeatureGroupArgs = {
  input: CreateFeatureGroupInput;
};


export type MutationCreateMailTemplateArgs = {
  input?: InputMaybe<CreateMailTemplateInput>;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePermissionGroupArgs = {
  input: CreatePermissionGroupInput;
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreateProjectPackageArgs = {
  input: CreateProjectPackageInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTagsArgs = {
  input: CreateTagsInput;
};


export type MutationCreateTemplateTypeArgs = {
  input: CreateTemplateTypeInput;
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationCreateWorkspaceClientArgs = {
  input: CreateWorkspaceClientInput;
};


export type MutationCreateWorkspacePackageArgs = {
  input: CreateWorkspacePackageInput;
};


export type MutationCreateWorkspacePaymentMethodArgs = {
  input: CreateWorkspacePaymentMethodInput;
};


export type MutationCreateWorkspaceProjectArgs = {
  input: CreateProjectInput;
  workspaceName: Scalars['String']['input'];
};


export type MutationCreateWorkspaceRoleArgs = {
  input: CreateWorkspaceRoleInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteApplicationPackageArgs = {
  input: DeleteApplicationPackageInput;
};


export type MutationDeleteApplicationPackageReferenceArgs = {
  input: DeleteApplicationPackageReferenceInput;
};


export type MutationDeleteApplicationPackagesArgs = {
  input: DeleteApplicationPackagesInput;
};


export type MutationDeleteCouponArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCouponsArgs = {
  input: DeleteCouponsInput;
};


export type MutationDeleteDomainArgs = {
  input: DeleteDomainInput;
};


export type MutationDeleteDomainPackageArgs = {
  input: DeleteDomainPackageInput;
};


export type MutationDeleteDomainPackagesArgs = {
  input: DeleteDomainPackagesInput;
};


export type MutationDeleteFeatureArgs = {
  input: DeleteFeatureInput;
};


export type MutationDeleteFeatureGroupArgs = {
  input: DeleteFeatureGroupInput;
};


export type MutationDeleteFeatureGroupsArgs = {
  input: DeleteFeatureGroupsInput;
};


export type MutationDeleteFeaturesArgs = {
  input: DeleteFeaturesInput;
};


export type MutationDeleteInvitationArgs = {
  input: DeleteInvitationInput;
};


export type MutationDeleteMemberArgs = {
  input: DeleteMemberInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: ManipulatePaymentMethodInput;
};


export type MutationDeletePermissionGroupArgs = {
  input: DeletePermissionGroupInput;
};


export type MutationDeletePlanArgs = {
  input: DeletePlanInput;
};


export type MutationDeletePlansArgs = {
  input: DeletePlansInput;
};


export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};


export type MutationDeleteProjectPackageArgs = {
  input: DeleteProjectPackageInput;
};


export type MutationDeleteProjectPackagesArgs = {
  input: DeleteProjectPackagesInput;
};


export type MutationDeleteProjectWithoutPasswordArgs = {
  input: DeleteProjectWithoutPasswordInput;
};


export type MutationDeleteProjectsWithoutPasswordArgs = {
  input: DeleteProjectsWithoutPasswordInput;
};


export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTemplateTypeArgs = {
  input: DeleteTemplateTypeInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDeleteUsersArgs = {
  input: DeleteUsersInput;
};


export type MutationDeleteWorkspaceClientsArgs = {
  input: DeleteWorkspaceClientsInput;
};


export type MutationDeleteWorkspaceInvitationsArgs = {
  input: DeleteWorkspaceInvitationsInput;
};


export type MutationDeleteWorkspaceMemberArgs = {
  input: DeleteWorkspaceMemberInput;
};


export type MutationDeleteWorkspacePackageArgs = {
  input: DeleteWorkspacePackageInput;
};


export type MutationDeleteWorkspacePackagesArgs = {
  input: DeleteWorkspacePackagesInput;
};


export type MutationDeleteWorkspacePaymentMethodArgs = {
  input: ManipulateWorkspacePaymentMethodInput;
};


export type MutationDeleteWorkspaceProjectArgs = {
  input: DeleteProjectInput;
};


export type MutationDeleteWorkspaceRoleArgs = {
  input: DeleteWorkspaceRoleInput;
};


export type MutationDeleteWorkspaceTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDetachAddonArgs = {
  input: DetachAddonInput;
};


export type MutationDetachCouponArgs = {
  input: DetachCouponInput;
};


export type MutationDetachFeatureFromDomainPackageArgs = {
  input: DetachFeatureInput;
};


export type MutationDetachFeatureFromProjectPackageArgs = {
  input: DetachFeatureInput;
};


export type MutationDetachFeatureFromWorkspacePackageArgs = {
  input: DetachFeatureInput;
};


export type MutationDisconnectDomainArgs = {
  input: DisconnectDomainInput;
};


export type MutationDuplicateRoleArgs = {
  input: DuplicateRoleInput;
};


export type MutationDuplicateWorkspaceRoleArgs = {
  input: DuplicateWorkspaceRoleInput;
};


export type MutationExtendSubscriptionArgs = {
  input: ExtendSubscriptionInput;
};


export type MutationExtendWorkspaceSubscriptionArgs = {
  input: ExtendSubscriptionInput;
};


export type MutationForceDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationForceDeleteUsersArgs = {
  input: DeleteUsersInput;
};


export type MutationGenerateProjectNameArgs = {
  name: Scalars['String']['input'];
};


export type MutationGenerateWorkspaceNameArgs = {
  name: Scalars['String']['input'];
};


export type MutationInviteMembersArgs = {
  input: InviteMembersInput;
};


export type MutationInviteMembersWithNewRoleArgs = {
  input: InviteMembersWithNewRoleInput;
};


export type MutationInviteWorkspaceMembersArgs = {
  input: InviteWorkspaceMembersInput;
};


export type MutationInviteWorkspaceMembersWithNewRoleArgs = {
  input: InviteWorkspaceMembersWithNewRoleInput;
};


export type MutationLeaveProjectArgs = {
  input: LeaveProjectInput;
};


export type MutationMakeDomainDefaultArgs = {
  input: MakeDomainDefaultInput;
};


export type MutationRemoveOwnTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemovePermissionFromUserArgs = {
  input: PermissionRemoveInput;
};


export type MutationRemoveRoleFromUserArgs = {
  input: RoleAssignmentInput;
};


export type MutationRemoveTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveTemplateThumbnailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRequestPermissionArgs = {
  input: RequestPermissionInput;
};


export type MutationResaveTemplateArgs = {
  input: ResaveTemplateInput;
};


export type MutationSaveAsTemplateArgs = {
  input: SaveAsTemplateInput;
};


export type MutationSaveWorkspaceProjectAsTemplateArgs = {
  input: SaveAsTemplateInput;
};


export type MutationSetDefaultPaymentMethodArgs = {
  input: ManipulatePaymentMethodInput;
};


export type MutationSetPasswordArgs = {
  input: SetPasswordInput;
};


export type MutationSetWorkspaceDefaultPaymentMethodArgs = {
  input: ManipulateWorkspacePaymentMethodInput;
};


export type MutationSetupInitialPaymentArgs = {
  input: SetupInitialPaymentInput;
};


export type MutationTransferProjectArgs = {
  input: TransferProjectInput;
};


export type MutationTransferProjectOwnershipArgs = {
  input: TransferProjectOwnershipInput;
};


export type MutationUnsetPasswordArgs = {
  input: UnsetPasswordInput;
};


export type MutationUpdateApplicationPackageArgs = {
  input: UpdateApplicationPackageInput;
};


export type MutationUpdateApplicationPackageReferenceArgs = {
  input: UpdateApplicationPackageReferenceInput;
};


export type MutationUpdateBillingDetailsArgs = {
  input: UpdateBillingDetailsInput;
};


export type MutationUpdateCouponArgs = {
  input: UpdateCouponInput;
};


export type MutationUpdateCurrentPlanArgs = {
  input: UpdateCurrentPlanInput;
};


export type MutationUpdateCurrentWorkspacePlanArgs = {
  input: UpdateCurrentWorkspacePlanInput;
};


export type MutationUpdateDomainArgs = {
  input: UpdateDomainInput;
};


export type MutationUpdateDomainPackageArgs = {
  input: UpdateDomainPackageInput;
};


export type MutationUpdateFeatureArgs = {
  input: UpdateFeatureInput;
};


export type MutationUpdateFeatureGroupArgs = {
  input: UpdateFeatureGroupInput;
};


export type MutationUpdateMailTemplateArgs = {
  input?: InputMaybe<UpdateMailTemplateInput>;
};


export type MutationUpdateMailTemplateContentArgs = {
  input?: InputMaybe<UpdateMailTemplateContentInput>;
};


export type MutationUpdateMembersRoleArgs = {
  input: UpdateMembersRoleInput;
};


export type MutationUpdateMembersWithNewRoleArgs = {
  input: UpdateMembersWithNewRoleInput;
};


export type MutationUpdateOwnTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationUpdateOwnTemplateTagsArgs = {
  input: UpdateTemplateTagsInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};


export type MutationUpdatePermissionGroupArgs = {
  input: UpdatePermissionGroupInput;
};


export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};


export type MutationUpdateProjectEmailSenderConfigArgs = {
  input: UpdateProjectEmailSenderConfigInput;
};


export type MutationUpdateProjectPackageArgs = {
  input: UpdateProjectPackageInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateTemplateArgs = {
  input: AdminUpdateTemplateInput;
};


export type MutationUpdateTemplateTagsArgs = {
  input: AdminUpdateTemplateTagsInput;
};


export type MutationUpdateTemplateTypeArgs = {
  input: UpdateTemplateTypeInput;
};


export type MutationUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};


export type MutationUpdateUserProjectNotificationSettingsArgs = {
  input: UpdateUserProjectNotificationSettingsInput;
};


export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};


export type MutationUpdateWorkspaceClientArgs = {
  input: UpdateWorkspaceClientInput;
};


export type MutationUpdateWorkspaceMembersRoleArgs = {
  input: UpdateWorkspaceMembersRoleInput;
};


export type MutationUpdateWorkspaceMembersWithNewRoleArgs = {
  input: UpdateWorkspaceMembersWithNewRoleInput;
};


export type MutationUpdateWorkspacePackageArgs = {
  input: UpdateWorkspacePackageInput;
};


export type MutationUpdateWorkspaceRoleArgs = {
  input: UpdateWorkspaceRoleInput;
};


export type MutationUpdateWorkspaceSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationUpdateWorkspaceTemplateArgs = {
  input: UpdateTemplateInput;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type Package = {
  __typename?: 'Package';
  agreement?: Maybe<Scalars['String']['output']>;
  alias: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Scalars['String']['output']>>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  limitations: Array<EntityLimitation>;
  name: Scalars['String']['output'];
  packageGroups?: Maybe<Array<PackageGroup>>;
  params?: Maybe<Scalars['Array']['output']>;
  projectRequired: Scalars['Boolean']['output'];
  public: Scalars['Boolean']['output'];
  type: PackageType;
};

export type PackageGroup = {
  __typename?: 'PackageGroup';
  active: Scalars['Boolean']['output'];
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ordering: Scalars['Int']['output'];
};

/** List of available package limited domains. */
export enum PackageLimitedDomain {
  /** Disconnect. */
  Disconnect = 'DISCONNECT'
}

export type PackageLimitedEntities = {
  __typename?: 'PackageLimitedEntities';
  domain?: Maybe<Array<PackageLimitedDomain>>;
  limitations?: Maybe<Array<PackageLimitedEntityLimitation>>;
  payment?: Maybe<Array<PackageLimitedPayment>>;
  product?: Maybe<Array<PackageLimitedProduct>>;
  shipping?: Maybe<Array<PackageLimitedShipping>>;
};

export type PackageLimitedEntityLimitation = {
  __typename?: 'PackageLimitedEntityLimitation';
  count: Scalars['Int']['output'];
  entity: LimitationType;
  limit: Scalars['Int']['output'];
};

/** List of available package limited payments. */
export enum PackageLimitedPayment {
  /** Acba. */
  Acba = 'ACBA',
  /** Ameria. */
  Ameria = 'AMERIA',
  /** Ardshin. */
  Ardshin = 'ARDSHIN',
  /** Braintree. */
  Braintree = 'BRAINTREE',
  /** Cashu. */
  Cashu = 'CASHU',
  /** Cash on delivery. */
  CashOnDelivery = 'CASH_ON_DELIVERY',
  /** Evoca. */
  Evoca = 'EVOCA',
  /** Fastshift. */
  Fastshift = 'FASTSHIFT',
  /** Idram. */
  Idram = 'IDRAM',
  /** Mollie. */
  Mollie = 'MOLLIE',
  /** Money transfer. */
  MoneyTransfer = 'MONEY_TRANSFER',
  /** Paypal. */
  PaypalRest = 'PAYPAL_REST',
  /** Pay tabs. */
  PayTabs = 'PAY_TABS',
  /** Pos on delivery. */
  PosOnDelivery = 'POS_ON_DELIVERY',
  /** Revolut. */
  Revolut = 'REVOLUT',
  /** Square. */
  Square = 'SQUARE',
  /** Stripe. */
  Stripe = 'STRIPE',
  /** Telr. */
  Telr = 'TELR',
  /** Ucraft pay. */
  UcraftPay = 'UCRAFT_PAY'
}

/** List of available package limited products. */
export enum PackageLimitedProduct {
  /** Configurable. */
  Configurable = 'CONFIGURABLE'
}

/** List of available package limited shipping types. */
export enum PackageLimitedShipping {
  /** International. */
  International = 'INTERNATIONAL',
  /** Local. */
  Local = 'LOCAL'
}

/** List of columns by which packages can be ordered. */
export enum PackageOrderByColumn {
  /** Order packages by created_at. */
  CreatedAt = 'CREATED_AT',
  /** Order packages by name. */
  Name = 'NAME'
}

/** List of available types for packages. */
export enum PackageType {
  /** Application package. */
  Application = 'APPLICATION',
  /** Domain package. */
  Domain = 'DOMAIN',
  /** Logo package. */
  Logo = 'LOGO',
  /** Project package. */
  Project = 'PROJECT',
  /** Workspace package. */
  Workspace = 'WORKSPACE'
}

export type PaginateActivityRecordInput = {
  filters?: InputMaybe<Array<ActivityRecordFilterClauseInput>>;
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<ActivityRecordOrderByClauseInput>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']['output']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']['output'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']['output']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** Number of items per page. */
  perPage: Scalars['Int']['output'];
  /** Number of total available items. */
  total: Scalars['Int']['output'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  additional?: Maybe<Scalars['Array']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  default: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  gateway: Scalars['String']['output'];
  holder?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  last4: Scalars['String']['output'];
  token: Scalars['String']['output'];
  type: PaymentMethodType;
  zip?: Maybe<Scalars['String']['output']>;
};

/** List of available cart types. */
export enum PaymentMethodType {
  /** When cart type is ameria. */
  Ameria = 'AMERIA',
  /** When cart type is amex. */
  Amex = 'AMEX',
  /** When cart type is arca */
  Arca = 'ARCA',
  /** When cart type is credit card. */
  CreditCard = 'CREDIT_CARD',
  /** When cart type is diners club. */
  DinersClub = 'DINERS_CLUB',
  /** When cart type is discover. */
  Discover = 'DISCOVER',
  /** When cart type is master card. */
  MasterCard = 'MASTER_CARD',
  /** Define paypal account type. */
  PaypalAccount = 'PAYPAL_ACCOUNT',
  /** When cart type is visa. */
  Visa = 'VISA'
}

export type PaymentToken = {
  __typename?: 'PaymentToken';
  api_key?: Maybe<Scalars['String']['output']>;
  application?: Maybe<Scalars['String']['output']>;
  cancellation_reason?: Maybe<Scalars['String']['output']>;
  client_secret: Scalars['String']['output'];
  created?: Maybe<Scalars['Int']['output']>;
  customer: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  last_setup_error?: Maybe<Scalars['String']['output']>;
  latest_attempt?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  mandate?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['Array']['output']>;
  next_action?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  on_behalf_of?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_method_options?: Maybe<Scalars['Array']['output']>;
  payment_method_types: Scalars['Array']['output'];
  single_use_mandate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  usage?: Maybe<Scalars['String']['output']>;
};

export type PaymentTokenResponse = {
  __typename?: 'PaymentTokenResponse';
  paymentToken?: Maybe<PaymentToken>;
  userId: Scalars['ID']['output'];
};

export type Permission = {
  __typename?: 'Permission';
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  group: PermissionGroup;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissionRequest?: Maybe<PermissionRequest>;
  type: PermissionType;
};

export type PermissionAssignmentInput = {
  permissionId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
};


export type PermissionGroupPermissionsArgs = {
  types?: InputMaybe<Array<PermissionType>>;
};

/** A paginated list of PermissionGroup items. */
export type PermissionGroupPaginator = {
  __typename?: 'PermissionGroupPaginator';
  /** A list of PermissionGroup items. */
  data: Array<PermissionGroup>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Permission items. */
export type PermissionPaginator = {
  __typename?: 'PermissionPaginator';
  /** A list of Permission items. */
  data: Array<Permission>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type PermissionRemoveInput = {
  permissionId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PermissionRequest = {
  __typename?: 'PermissionRequest';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  permission: Permission;
};

export type PermissionTranslationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** List of available permission types. */
export enum PermissionType {
  /** General type of permission. Can be attached to role and package. */
  General = 'GENERAL',
  /** Package type of permission. Can be attached only to the packages. */
  Package = 'PACKAGE',
  /** Role type of permission. Can be attached only to role. */
  Role = 'ROLE'
}

/** List of available ordering columns for permissions. */
export enum PermissionsOrderByColumn {
  /** Column to order by created_at. */
  CreatedAt = 'CREATED_AT',
  /** Column to order by id. */
  Id = 'ID',
  /** Column to order by name. */
  Name = 'NAME'
}

export type Plan = {
  __typename?: 'Plan';
  activeFeatures?: Maybe<Array<PlanFeature>>;
  addons: CouponPaginator;
  agreement?: Maybe<Scalars['String']['output']>;
  alias: Scalars['String']['output'];
  billingFrequency: Scalars['Int']['output'];
  cardRequired: Scalars['Boolean']['output'];
  comingSoon: Scalars['Boolean']['output'];
  coupons: CouponPaginator;
  currencySymbol: Scalars['String']['output'];
  current?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Scalars['String']['output']>>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  moneyback: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  ordering: Scalars['Int']['output'];
  package?: Maybe<Package>;
  packageType: PackageType;
  partnerDiscountedPrice: Scalars['Float']['output'];
  popular: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
  recommended: Scalars['Boolean']['output'];
  summary: Scalars['Float']['output'];
  trialDays: Scalars['Int']['output'];
  upgradable?: Maybe<Scalars['Boolean']['output']>;
  visibility: PlanVisibility;
};


export type PlanAddonsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PlanAddonsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type PlanCouponsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PlanCouponsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Order by clause for Plan.addons.orderBy. */
export type PlanAddonsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: CouponsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Plan.coupons.orderBy. */
export type PlanCouponsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: CouponsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type PlanFeature = {
  __typename?: 'PlanFeature';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type PlanFeatureLimit = {
  featureId: Scalars['ID']['input'];
  limit: Scalars['String']['input'];
};

/** A paginated list of Plan items. */
export type PlanPaginator = {
  __typename?: 'PlanPaginator';
  /** A list of Plan items. */
  data: Array<Plan>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type PlanPurchase = {
  __typename?: 'PlanPurchase';
  name: Scalars['String']['output'];
};

export type PlanPurchaseInfo = PlanPurchase | PlanSubscription;

export type PlanQueryInput = {
  billingFrequency?: InputMaybe<Scalars['Int']['input']>;
  coupon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  packageType?: InputMaybe<PackageType>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type PlanSubscription = {
  __typename?: 'PlanSubscription';
  currency: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  frequencyLabel: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>;
  period: Scalars['String']['output'];
  price: Scalars['String']['output'];
  startBillingDate?: Maybe<Scalars['DateTime']['output']>;
  status: SubscriptionStatus;
  summary: Scalars['String']['output'];
};

export type PlanTermsInput = {
  packageType?: InputMaybe<PackageType>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type PlanTranslationInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<Scalars['String']['input']>>;
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** List of available visibility types for plan. */
export enum PlanVisibility {
  /** Disabled plans are not available in the pricing list, and can not be purchased. */
  Disabled = 'DISABLED',
  /** Hidden plans are not available in the pricing list, but can be purchased via direct link. */
  Hidden = 'HIDDEN',
  /** Visible plans are available in the pricing list, and can be purchased. */
  Visible = 'VISIBLE'
}

/** List of available columns to order Plans. */
export enum PlansOrderByColumn {
  /** Order plans by created_at field. */
  CreatedAt = 'CREATED_AT',
  /** Order plans by id field. */
  Id = 'ID',
  /** Order plans by name field. */
  Name = 'NAME'
}

export type PlansQueryInput = {
  billingFrequency?: InputMaybe<Scalars['Int']['input']>;
  coupon?: InputMaybe<Scalars['String']['input']>;
  onlyAliases?: InputMaybe<Array<Scalars['String']['input']>>;
  packageType?: InputMaybe<PackageType>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type Project = {
  __typename?: 'Project';
  address?: Maybe<Scalars['String']['output']>;
  businessLegalName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  clientName: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creationStatus: ProjectCreationStatus;
  creator?: Maybe<RelatedUser>;
  currentPackage?: Maybe<ProjectPackage>;
  darkModeLogo?: Maybe<Scalars['ID']['output']>;
  defaultDomain?: Maybe<ProjectDomain>;
  domains: ProjectDomainPaginator;
  emailSenderConfig?: Maybe<ProjectEmailSenderConfig>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  favicon?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Scalars['String']['output']>>;
  isEcommerce: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  isOffline: Scalars['Boolean']['output'];
  languageDetectionMode: LanguageDetectionMode;
  logo?: Maybe<Scalars['ID']['output']>;
  mobileDarkModeLogo?: Maybe<Scalars['ID']['output']>;
  mobileLogo?: Maybe<Scalars['ID']['output']>;
  mobileTouchIcon?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  owners: RelatedUserPaginator;
  passwordProtection: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  previewUrl: Scalars['String']['output'];
  purePassword?: Maybe<Scalars['String']['output']>;
  roles: Array<ProjectRole>;
  screenshotUrl: Scalars['String']['output'];
  shortDescription?: Maybe<Scalars['String']['output']>;
  socials?: Maybe<Array<SocialItem>>;
  sqlServer: SqlServer;
  state?: Maybe<Scalars['String']['output']>;
  status: ProjectStatus;
  storeId: Scalars['ID']['output'];
  subscriptionDetails?: Maybe<SubscriptionDetails>;
  subscriptions: SubscriptionServicePaginator;
  template?: Maybe<ProjectTemplate>;
  testMode?: Maybe<Scalars['Boolean']['output']>;
  transferOrder?: Maybe<WorkspaceOrder>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
  userRoles?: Maybe<Array<ProjectRole>>;
  users: RelatedUserPaginator;
  uuid: Scalars['ID']['output'];
  workspace: ProjectWorkspace;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type ProjectDomainsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<ProjectDomainsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type ProjectOwnersArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectSubscriptionsArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectUserRolesArgs = {
  userId: Scalars['ID']['input'];
};


export type ProjectUsersArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectAccessRolesPivot = {
  __typename?: 'ProjectAccessRolesPivot';
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
};

/** List of available access types for project. */
export enum ProjectAccessType {
  /** Accept permission for project. */
  Accept = 'ACCEPT',
  /** Deny permission for project. */
  Deny = 'DENY'
}

export type ProjectConfiguration = {
  __typename?: 'ProjectConfiguration';
  favicon?: Maybe<Scalars['ID']['output']>;
  logo?: Maybe<Scalars['ID']['output']>;
};

/** List of available statuses for project creation. */
export enum ProjectCreationStatus {
  /** Project completely created. */
  Completed = 'COMPLETED',
  /** Project creation failed. */
  Failed = 'FAILED',
  /** Project creation is running. */
  Running = 'RUNNING'
}

export type ProjectDomain = {
  __typename?: 'ProjectDomain';
  default: Scalars['Boolean']['output'];
  dnsVerified: Scalars['Boolean']['output'];
  domain: Scalars['String']['output'];
  expiryDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isChecked: Scalars['Boolean']['output'];
  isSubDomain: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  mailbox?: Maybe<Scalars['String']['output']>;
  ssl: Scalars['Boolean']['output'];
  status: DomainStatus;
  type: DomainType;
};

/** A paginated list of ProjectDomain items. */
export type ProjectDomainPaginator = {
  __typename?: 'ProjectDomainPaginator';
  /** A list of ProjectDomain items. */
  data: Array<ProjectDomain>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Order by clause for Project.domains.orderBy. */
export type ProjectDomainsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: DomainsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type ProjectEmailSenderConfig = {
  __typename?: 'ProjectEmailSenderConfig';
  contactEmail?: Maybe<Scalars['String']['output']>;
  domainId?: Maybe<Scalars['ID']['output']>;
  emailPrefix: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
};

export type ProjectEmailSenderDomainRecord = {
  __typename?: 'ProjectEmailSenderDomainRecord';
  dnsRecordType: DnsRecordType;
  isVerified: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: ProjectEmailSenderDomainRecordType;
  value: Scalars['String']['output'];
  valueEscaped?: Maybe<Scalars['String']['output']>;
};

/** List of available record types for project email sender domain. */
export enum ProjectEmailSenderDomainRecordType {
  /** DKIM1 Type. */
  Dkim1 = 'DKIM1',
  /** DKIM2 Type. */
  Dkim2 = 'DKIM2',
  /** DMARC Type. */
  Dmarc = 'DMARC',
  /** Verified Domain Type. */
  VerifiedDomain = 'VERIFIED_DOMAIN'
}

export type ProjectEmailSenderDomainSettings = {
  __typename?: 'ProjectEmailSenderDomainSettings';
  isAllVerified: Scalars['Boolean']['output'];
  records: Array<ProjectEmailSenderDomainRecord>;
};

/** List of available columns to filter Projects. */
export enum ProjectFilterColumn {
  /** Filter projects by created_at field. */
  CreatedAt = 'CREATED_AT',
  /** Filter projects by package_id field. */
  ProjectPackage = 'PROJECT_PACKAGE'
}

export type ProjectFilters = {
  column: ProjectFilterColumn;
  operator: ConditionOperatorEnum;
  value: Scalars['Mixed']['input'];
};

export type ProjectPackage = {
  __typename?: 'ProjectPackage';
  agreement?: Maybe<Scalars['String']['output']>;
  alias: Scalars['String']['output'];
  allowedTemplateTypes?: Maybe<Array<Scalars['ID']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  features: ProjectPackageFeaturePaginator;
  featuresText?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isUpgradable: Scalars['Boolean']['output'];
  limitations?: Maybe<Array<Limitation>>;
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  packageGroups?: Maybe<Array<PackageGroup>>;
  params?: Maybe<Scalars['Array']['output']>;
  permissions: Array<Permission>;
  projectRequired: Scalars['Boolean']['output'];
  public: Scalars['Boolean']['output'];
};


export type ProjectPackageFeaturesArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectPackageFeature = {
  __typename?: 'ProjectPackageFeature';
  description: Scalars['String']['output'];
  group?: Maybe<FeatureGroup>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['String']['output']>;
  ordering?: Maybe<Scalars['Int']['output']>;
  packageType: PackageType;
  selected?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
};

/** A paginated list of ProjectPackageFeature items. */
export type ProjectPackageFeaturePaginator = {
  __typename?: 'ProjectPackageFeaturePaginator';
  /** A list of ProjectPackageFeature items. */
  data: Array<ProjectPackageFeature>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of ProjectPackage items. */
export type ProjectPackagePaginator = {
  __typename?: 'ProjectPackagePaginator';
  /** A list of ProjectPackage items. */
  data: Array<ProjectPackage>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ProjectPackageTermsInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectPackageTranslationInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A paginated list of Project items. */
export type ProjectPaginator = {
  __typename?: 'ProjectPaginator';
  /** A list of Project items. */
  data: Array<Project>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ProjectPermissionGroup = {
  __typename?: 'ProjectPermissionGroup';
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Permission>>;
};

export type ProjectRole = {
  __typename?: 'ProjectRole';
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
};

/** List of available statuses for project. */
export enum ProjectStatus {
  /** Project is active. */
  Active = 'ACTIVE',
  /** Project is in demo. */
  Demo = 'DEMO',
  /** Project is in pending. */
  Pending = 'PENDING',
  /** Project is in trial. */
  Trial = 'TRIAL'
}

export type ProjectTemplate = {
  __typename?: 'ProjectTemplate';
  alias?: Maybe<Scalars['String']['output']>;
  demoPath?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  designer?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  industry?: Maybe<Industry>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['URL']['output']>;
  type: TemplateType;
  usage: Scalars['Int']['output'];
  version: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type ProjectWorkspace = {
  __typename?: 'ProjectWorkspace';
  address?: Maybe<Scalars['String']['output']>;
  businessDescription?: Maybe<Scalars['String']['output']>;
  businessLegalName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  clientName: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  creator: RelatedUser;
  id: Scalars['ID']['output'];
  legal?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['URL']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  teamSize?: Maybe<Scalars['WorkspaceTeamSize']['output']>;
  teamType?: Maybe<Scalars['WorkspaceTeamType']['output']>;
  webPage?: Maybe<Scalars['URL']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** List of columns by which projects can be ordered. */
export enum ProjectsOrderByColumn {
  /** Order projects by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order projects by project name. */
  Name = 'NAME',
  /** Order projects by modification date. */
  UpdatedAt = 'UPDATED_AT'
}

export type Purchase = {
  __typename?: 'Purchase';
  active: Scalars['Boolean']['output'];
  currentSubscription?: Maybe<SubscriptionService>;
  host?: Maybe<Host>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  package?: Maybe<Package>;
  subscriptions: SubscriptionServicePaginator;
  transactions: TransactionPaginator;
};


export type PurchaseSubscriptionsArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<SubscriptionTermsInput>;
  orderBy?: InputMaybe<Array<PurchaseSubscriptionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type PurchaseTransactionsArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** A paginated list of Purchase items. */
export type PurchasePaginator = {
  __typename?: 'PurchasePaginator';
  /** A list of Purchase items. */
  data: Array<Purchase>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Order by clause for Purchase.subscriptions.orderBy. */
export type PurchaseSubscriptionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SubscriptionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type PurchaseTermsInput = {
  packageType?: InputMaybe<PackageType>;
};

/** List of available ordering columns for purchases. */
export enum PurchasesOrderByColumn {
  /** Column to order by created date. */
  CreatedAt = 'CREATED_AT',
  /** Column to order by name. */
  Name = 'NAME'
}

export type Query = {
  __typename?: 'Query';
  accountDeletionReasons: Array<AccountDeletionReason>;
  activityRecord?: Maybe<ActivityRecord>;
  activityRecords: ActivityRecordPaginator;
  activityRecordsAvailableFilters?: Maybe<Array<Maybe<ActivityRecordFilter>>>;
  adminBlockCategories: Array<BlockCategory>;
  adminBlocks: BlockPaginator;
  adminIndustries: Array<Industry>;
  adminTemplateTags?: Maybe<TemplateTagPaginator>;
  adminTemplates?: Maybe<TemplatePaginator>;
  allDomains: DomainPaginator;
  allFeatureGroups: FeatureGroupPaginator;
  allPackages?: Maybe<Array<Package>>;
  allPlans: PlanPaginator;
  allProjects?: Maybe<AdminProjectPaginator>;
  allSubscriptions: SubscriptionServicePaginator;
  allTemplateTags: Array<TemplateTag>;
  allWorkspaces?: Maybe<WorkspacePaginator>;
  applicationPackage?: Maybe<ApplicationPackage>;
  applicationPackages: ApplicationPackagePaginator;
  authUser: User;
  checkDomain: Domain;
  checkProjectCreationStatus: CheckProjectCreationStatus;
  checkProjectEmailSenderDomainSettings: ProjectEmailSenderDomainSettings;
  checkSubDomain: Scalars['Boolean']['output'];
  configuration: Configuration;
  connectedAccountBalance: Scalars['Float']['output'];
  connectedAccountLoginUrl?: Maybe<Scalars['String']['output']>;
  connectedAccountOnboardingUrl?: Maybe<Scalars['String']['output']>;
  connectedAccountRevenue: Scalars['Float']['output'];
  connectedAccountStatus?: Maybe<WorkspaceConnectedAccountStatus>;
  connectedDomains: Array<ConnectedDomain>;
  coupon?: Maybe<Coupon>;
  coupons: CouponPaginator;
  domain?: Maybe<Domain>;
  domainPackage?: Maybe<DomainPackage>;
  domainPackages: DomainPackagePaginator;
  domainStatuses: Scalars['Array']['output'];
  domains: DomainPaginator;
  entitiesToDisable?: Maybe<PackageLimitedEntities>;
  entityCountLimitation?: Maybe<Array<Limitation>>;
  feature?: Maybe<Feature>;
  featureGroup?: Maybe<FeatureGroup>;
  featureGroups?: Maybe<Array<FeatureGroup>>;
  features: FeaturePaginator;
  getCurrentPaymentMethod?: Maybe<PaymentMethod>;
  getCurrentPurchase?: Maybe<Purchase>;
  getCurrentSubscription?: Maybe<SubscriptionService>;
  getDomain?: Maybe<Domain>;
  getPlanPurchaseInfo?: Maybe<PlanPurchaseInfo>;
  getTransactions?: Maybe<Array<Transaction>>;
  getUserProjectNotificationSettings?: Maybe<UserProjectNotificationSetting>;
  industries?: Maybe<Array<Industry>>;
  limitationAwareEntities?: Maybe<Scalars['Array']['output']>;
  limitationAwareWorkspaceEntities?: Maybe<Scalars['Array']['output']>;
  locales: LocalesInfo;
  mailTemplate: MailTemplate;
  mailTemplateAliases?: Maybe<Array<Scalars['String']['output']>>;
  mailTemplates?: Maybe<Array<MailTemplate>>;
  member?: Maybe<TeamMember>;
  members: Array<Member>;
  packageTypes: Scalars['Array']['output'];
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  paymentToken: PaymentTokenResponse;
  permission: Permission;
  permissionGroup: PermissionGroup;
  permissionGroups: PermissionGroupPaginator;
  permissions: PermissionPaginator;
  plan: Plan;
  planVisibilities: Scalars['Array']['output'];
  plans: Array<Plan>;
  project?: Maybe<Project>;
  projectByAlias?: Maybe<Project>;
  projectByUuid?: Maybe<Project>;
  projectGroupedPermissions?: Maybe<Array<ProjectPermissionGroup>>;
  projectPackage?: Maybe<ProjectPackage>;
  projectPackages: ProjectPackagePaginator;
  projectPermissions?: Maybe<Array<Permission>>;
  projectStatuses: Scalars['Array']['output'];
  projects: ProjectPaginator;
  purchase: Purchase;
  purchases: PurchasePaginator;
  requestedPermissions?: Maybe<Array<Permission>>;
  retrievePlan?: Maybe<Plan>;
  role: Role;
  roles: Array<Role>;
  searchDomain?: Maybe<Array<Maybe<DomainSearchResult>>>;
  searchDomainAvailability?: Maybe<Array<Maybe<DomainSearchResult>>>;
  sendDownloadLink: Scalars['Boolean']['output'];
  sqlServers?: Maybe<Array<SqlServer>>;
  subscription?: Maybe<SubscriptionService>;
  subscriptions?: Maybe<Array<SubscriptionService>>;
  subscriptionsPaginated: SubscriptionServicePaginated;
  template?: Maybe<Template>;
  templateTags: Array<TemplateTag>;
  templateType?: Maybe<TemplateType>;
  templateTypes: TemplateTypePaginator;
  templates?: Maybe<TemplatePaginator>;
  transactions: TransactionPaginator;
  transactionsPaginated: TransactionPaginated;
  user?: Maybe<User>;
  users?: Maybe<UsersPaginator>;
  workspace?: Maybe<Workspace>;
  workspaceByName?: Maybe<Workspace>;
  workspaceClient?: Maybe<WorkspaceClient>;
  workspaceClientTotalSpent: Scalars['Float']['output'];
  workspaceClients?: Maybe<WorkspaceClientPaginator>;
  workspaceClientsInvoices?: Maybe<WorkspaceClientInvoicePaginator>;
  workspaceClientsSubscriptions?: Maybe<WorkspaceClientSubscriptionPaginator>;
  workspaceCurrentPaymentMethod?: Maybe<PaymentMethod>;
  workspaceCurrentSubscription?: Maybe<SubscriptionService>;
  workspaceEntityCountLimitation?: Maybe<Array<WorkspaceLimitation>>;
  workspaceFeatureGroups?: Maybe<Array<FeatureGroup>>;
  workspaceMember?: Maybe<WorkspaceTeamMember>;
  workspaceMembers: Array<WorkspaceMember>;
  workspacePackage?: Maybe<WorkspacePackage>;
  workspacePackages: WorkspacePackagePaginator;
  workspacePaymentMethods?: Maybe<Array<PaymentMethod>>;
  workspacePaymentToken: PaymentTokenResponse;
  workspacePermissionGroup: WorkspacePermissionGroup;
  workspacePermissionGroups?: Maybe<WorkspacePermissionGroupPaginator>;
  workspacePlans: Array<Plan>;
  workspaceProjectPlans: Array<Plan>;
  workspaceRole: WorkspaceRole;
  workspaceRoles: Array<WorkspaceRole>;
  workspaceStatuses: Scalars['Array']['output'];
  workspaceSubscription?: Maybe<SubscriptionService>;
  workspaceSubscriptions?: Maybe<Array<SubscriptionService>>;
  workspaceTransactions?: Maybe<Array<Transaction>>;
  workspaces?: Maybe<WorkspacePaginator>;
};


export type QueryActivityRecordArgs = {
  input: ActivityRecordInput;
};


export type QueryActivityRecordsArgs = {
  input: PaginateActivityRecordInput;
};


export type QueryActivityRecordsAvailableFiltersArgs = {
  input?: InputMaybe<ActivityRecordsAvailableFilterInput>;
};


export type QueryAdminBlocksArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<AdminGetBlocksInput>;
  orderBy?: InputMaybe<Array<QueryAdminBlocksOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdminTemplateTagsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<TemplateTagInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdminTemplatesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<AdminTemplateInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllDomainsArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<DomainTermsInput>;
  orderBy?: InputMaybe<Array<QueryAllDomainsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllFeatureGroupsArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<FeatureGroupTermsInput>;
  orderBy?: InputMaybe<Array<QueryAllFeatureGroupsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllPackagesArgs = {
  input?: InputMaybe<AllPackagesInput>;
};


export type QueryAllPlansArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<PlanTermsInput>;
  orderBy?: InputMaybe<Array<QueryAllPlansOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllProjectsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<AllProjectsInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllSubscriptionsArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<SubscriptionTermsInput>;
  orderBy?: InputMaybe<Array<QueryAllSubscriptionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllWorkspacesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<AllWorkspacesInput>;
  orderBy?: InputMaybe<Array<QueryAllWorkspacesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryApplicationPackageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryApplicationPackagesArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<ApplicationPackageTermsInput>;
  orderBy?: InputMaybe<Array<QueryApplicationPackagesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCheckDomainArgs = {
  input: CheckDomainInput;
};


export type QueryCheckProjectCreationStatusArgs = {
  uuid: Scalars['ID']['input'];
};


export type QueryCheckProjectEmailSenderDomainSettingsArgs = {
  input: CheckProjectEmailSenderDomainSettingsInput;
};


export type QueryCheckSubDomainArgs = {
  subDomain: Scalars['String']['input'];
};


export type QueryConfigurationArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryConnectedAccountBalanceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConnectedAccountLoginUrlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConnectedAccountOnboardingUrlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConnectedAccountRevenueArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConnectedAccountStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConnectedDomainsArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryCouponArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCouponsArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<CouponTermsInput>;
  orderBy?: InputMaybe<Array<QueryCouponsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDomainArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDomainPackageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDomainPackagesArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<DomainPackageTermsInput>;
  orderBy?: InputMaybe<Array<QueryDomainPackagesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDomainsArgs = {
  first: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
};


export type QueryEntitiesToDisableArgs = {
  planAlias: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryEntityCountLimitationArgs = {
  entities: Array<LimitationType>;
  projectId: Scalars['ID']['input'];
};


export type QueryFeatureArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFeatureGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFeatureGroupsArgs = {
  planAlias: Scalars['String']['input'];
};


export type QueryFeaturesArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<FeatureTermsInput>;
  orderBy?: InputMaybe<Array<QueryFeaturesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCurrentPaymentMethodArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryGetCurrentPurchaseArgs = {
  alias: Scalars['String']['input'];
  packageType: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryGetCurrentSubscriptionArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryGetDomainArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPlanPurchaseInfoArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryGetTransactionsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['ID']['input'];
};


export type QueryGetUserProjectNotificationSettingsArgs = {
  input: UserProjectNotificationSettingsInput;
};


export type QueryIndustriesArgs = {
  showTemplateAssignedOnes?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMailTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMemberArgs = {
  projectId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryMembersArgs = {
  input: MembersInput;
};


export type QueryPaymentMethodsArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryPaymentTokenArgs = {
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPermissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPermissionGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPermissionGroupsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<QueryPermissionGroupsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPermissionsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<QueryPermissionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<PermissionType>>;
};


export type QueryPlanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlansArgs = {
  input?: InputMaybe<PlansQueryInput>;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectByAliasArgs = {
  name: Scalars['String']['input'];
};


export type QueryProjectByUuidArgs = {
  uuid: Scalars['ID']['input'];
};


export type QueryProjectGroupedPermissionsArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryProjectPackageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectPackagesArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<ProjectPackageTermsInput>;
  orderBy?: InputMaybe<Array<QueryProjectPackagesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProjectPermissionsArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryProjectsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<QueryProjectsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPurchaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPurchasesArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<PurchaseTermsInput>;
  orderBy?: InputMaybe<Array<QueryPurchasesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRequestedPermissionsArgs = {
  input: RequestedPermissionsInput;
};


export type QueryRetrievePlanArgs = {
  input?: InputMaybe<PlanQueryInput>;
};


export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRolesArgs = {
  orderBy?: InputMaybe<Array<RolesOrderByClause>>;
  projectId: Scalars['ID']['input'];
};


export type QuerySearchDomainArgs = {
  input: CheckDomainInput;
};


export type QuerySearchDomainAvailabilityArgs = {
  input: CheckDomainInput;
};


export type QuerySubscriptionArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type QuerySubscriptionsArgs = {
  projectId: Scalars['ID']['input'];
};


export type QuerySubscriptionsPaginatedArgs = {
  alias: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['DateTimeUnix']['input']>;
  packageType: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
  toDate?: InputMaybe<Scalars['DateTimeUnix']['input']>;
};


export type QueryTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTemplateTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTemplateTypesArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplatesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<TemplateInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTransactionsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<QueryTransactionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTransactionsPaginatedArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<UserTermsInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspaceByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryWorkspaceClientArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspaceClientTotalSpentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspaceClientsArgs = {
  input: GetWorkspaceClientsInput;
};


export type QueryWorkspaceClientsInvoicesArgs = {
  input: GetWorkspaceClientsInvoicesInput;
};


export type QueryWorkspaceClientsSubscriptionsArgs = {
  input: GetWorkspaceClientsSubscriptionsInput;
};


export type QueryWorkspaceCurrentPaymentMethodArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceCurrentSubscriptionArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceEntityCountLimitationArgs = {
  input: WorkspaceEntityCountLimitation;
};


export type QueryWorkspaceFeatureGroupsArgs = {
  planAlias: Scalars['String']['input'];
};


export type QueryWorkspaceMemberArgs = {
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceMembersArgs = {
  input: WorkspaceMembersInput;
};


export type QueryWorkspacePackageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspacePackagesArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<WorkspacePackageTermsInput>;
  orderBy?: InputMaybe<Array<QueryWorkspacePackagesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWorkspacePaymentMethodsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspacePaymentTokenArgs = {
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryWorkspacePermissionGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspacePermissionGroupsArgs = {
  input?: InputMaybe<GetWorkspacePermissionGroupsInput>;
};


export type QueryWorkspacePlansArgs = {
  input?: InputMaybe<WorkspacePlansQueryInput>;
};


export type QueryWorkspaceProjectPlansArgs = {
  input?: InputMaybe<PlansQueryInput>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspaceRolesArgs = {
  input: GetWorkspaceRolesInput;
};


export type QueryWorkspaceSubscriptionArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type QueryWorkspaceSubscriptionsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceTransactionsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['ID']['input'];
};


export type QueryWorkspacesArgs = {
  input?: InputMaybe<GetWorkspacesInput>;
};

/** Order by clause for Query.adminBlocks.orderBy. */
export type QueryAdminBlocksOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: BlocksOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.allDomains.orderBy. */
export type QueryAllDomainsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: DomainsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.allFeatureGroups.orderBy. */
export type QueryAllFeatureGroupsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: FeatureGroupsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.allPlans.orderBy. */
export type QueryAllPlansOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PlansOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.allSubscriptions.orderBy. */
export type QueryAllSubscriptionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SubscriptionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.allWorkspaces.orderBy. */
export type QueryAllWorkspacesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: WorkspacesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.applicationPackages.orderBy. */
export type QueryApplicationPackagesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackageOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.coupons.orderBy. */
export type QueryCouponsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: CouponsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.domainPackages.orderBy. */
export type QueryDomainPackagesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackageOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.features.orderBy. */
export type QueryFeaturesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: FeaturesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.permissionGroups.orderBy. */
export enum QueryPermissionGroupsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

/** Order by clause for Query.permissionGroups.orderBy. */
export type QueryPermissionGroupsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryPermissionGroupsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.permissions.orderBy. */
export type QueryPermissionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PermissionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.projectPackages.orderBy. */
export type QueryProjectPackagesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackageOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.projects.orderBy. */
export type QueryProjectsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ProjectsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.purchases.orderBy. */
export type QueryPurchasesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PurchasesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type QueryTemplateTagOrderOrderByClause = {
  column: TemplateTagOrderByColumn;
  order: SortOrder;
};

export type QueryTemplatesOrderOrderByClause = {
  column: TemplateOrderByColumn;
  order: SortOrder;
};

/** Order by clause for Query.transactions.orderBy. */
export type QueryTransactionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: TransactionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type QueryUsersOrderOrderByClause = {
  column: UsersOrderByColumn;
  order: SortOrder;
};

/** Order by clause for Query.workspacePackages.orderBy. */
export type QueryWorkspacePackagesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackageOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type RelatedUser = {
  __typename?: 'RelatedUser';
  active: Scalars['Boolean']['output'];
  avatarUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['Email']['output'];
  emailVerifiedAt?: Maybe<Scalars['Date']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

/** A paginated list of RelatedUser items. */
export type RelatedUserPaginator = {
  __typename?: 'RelatedUserPaginator';
  /** A list of RelatedUser items. */
  data: Array<RelatedUser>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type RequestPermissionInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  permissionAlias: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type RequestedPermissionsInput = {
  projectId: Scalars['ID']['input'];
};

export type ResaveTemplateInput = {
  id: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type Role = {
  __typename?: 'Role';
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
};

export type RoleAssignmentInput = {
  projectId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RolesOrderByClause = {
  column: RolesOrderColumn;
  order: SortOrder;
};

/** List of available order columns. */
export enum RolesOrderColumn {
  /** Order by created date. */
  CreatedAt = 'CREATED_AT',
  /** Order by name. */
  Name = 'NAME'
}

export type SaveAsTemplateInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  demoPath?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  designer?: InputMaybe<Scalars['String']['input']>;
  industries?: InputMaybe<Array<Scalars['ID']['input']>>;
  isBlank?: InputMaybe<Scalars['Boolean']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
  typeId: Scalars['ID']['input'];
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SendInstructionInput = {
  emails: Array<Scalars['Email']['input']>;
};

export type SetPasswordInput = {
  password: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type SetupInitialPaymentInput = {
  planId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type SingleRoleObject = {
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SocialItem = {
  __typename?: 'SocialItem';
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type SocialItemInput = {
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  url: Scalars['String']['input'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type SqlServer = {
  __typename?: 'SqlServer';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SubscriptionDetails = {
  __typename?: 'SubscriptionDetails';
  isConnectedCard?: Maybe<Scalars['Boolean']['output']>;
  isUpgradable?: Maybe<Scalars['Boolean']['output']>;
  onTrial?: Maybe<Scalars['Boolean']['output']>;
  remainingDays?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionService = {
  __typename?: 'SubscriptionService';
  autoRenew: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  daysLeft: Scalars['Int']['output'];
  frequencyLabel: Scalars['String']['output'];
  host: Host;
  id: Scalars['ID']['output'];
  isExtendable: Scalars['Boolean']['output'];
  isExtended: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>;
  package: Package;
  period: Scalars['String']['output'];
  plan: Plan;
  price: Scalars['String']['output'];
  purchase: Purchase;
  startBillingDate?: Maybe<Scalars['DateTime']['output']>;
  status: SubscriptionStatus;
  summary: Scalars['String']['output'];
  user: User;
  workspace?: Maybe<Workspace>;
};

export type SubscriptionServicePaginated = {
  __typename?: 'SubscriptionServicePaginated';
  data: Array<SubscriptionService>;
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of SubscriptionService items. */
export type SubscriptionServicePaginator = {
  __typename?: 'SubscriptionServicePaginator';
  /** A list of SubscriptionService items. */
  data: Array<SubscriptionService>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** List of available statuses. */
export enum SubscriptionStatus {
  /** When subscription is active. */
  Active = 'ACTIVE',
  /** When subscription is cancelled. */
  Cancelled = 'CANCELLED',
  /** When subscription is expired. */
  Expired = 'EXPIRED',
  /** When there is no status. */
  None = 'NONE',
  /** When subscription is pending. */
  Pending = 'PENDING',
  /** When trial is active. */
  TrialActive = 'TRIAL_ACTIVE',
  /** When trial is expired. */
  TrialExpired = 'TRIAL_EXPIRED'
}

export type SubscriptionTermsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billingFrequency?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** List of available ordering columns for subscriptions. */
export enum SubscriptionsOrderByColumn {
  /** Column to order by created date. */
  CreatedAt = 'CREATED_AT',
  /** Column to order by name. */
  Name = 'NAME'
}

export type SubstitutesInput = {
  projectId: Scalars['ID']['input'];
  substituteId: Scalars['ID']['input'];
};

export type TeamMember = Membership & {
  __typename?: 'TeamMember';
  applicationRoles?: Maybe<Array<ApplicationRoleType>>;
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['Email']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  params?: Maybe<Scalars['Array']['output']>;
  pivot?: Maybe<ProjectAccessRolesPivot>;
  role: Role;
  status: MembershipStatus;
};


export type TeamMemberRoleArgs = {
  projectId: Scalars['ID']['input'];
};

export type Template = {
  __typename?: 'Template';
  alias?: Maybe<Scalars['String']['output']>;
  demoPath?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  designer?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Industry>>;
  isBlank?: Maybe<Scalars['Boolean']['output']>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner?: Maybe<User>;
  tags: Array<TemplateTag>;
  thumbnail?: Maybe<Scalars['URL']['output']>;
  type: TemplateType;
  usage: Scalars['Int']['output'];
  version: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type TemplateInput = {
  industries?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<Array<QueryTemplatesOrderOrderByClause>>;
  search?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Specifies the columns by which templates can be ordered. */
export enum TemplateOrderByColumn {
  /** Order templates by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order templates by usage. */
  Popular = 'POPULAR'
}

export type TemplatePaginator = {
  __typename?: 'TemplatePaginator';
  data: Array<Template>;
  paginatorInfo: PaginatorInfo;
};

export type TemplateTag = {
  __typename?: 'TemplateTag';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ordering: Scalars['Int']['output'];
  templates: Array<Template>;
  visible: Scalars['Boolean']['output'];
};

export type TemplateTagInput = {
  orderBy?: InputMaybe<Array<QueryTemplateTagOrderOrderByClause>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** List of available ordering columns for template tags. */
export enum TemplateTagOrderByColumn {
  /** Column to order by created_at. */
  CreatedAt = 'CREATED_AT',
  /** Column to order by updated_at. */
  UpdatedAt = 'UPDATED_AT'
}

export type TemplateTagPaginator = {
  __typename?: 'TemplateTagPaginator';
  data: Array<TemplateTag>;
  paginatorInfo: PaginatorInfo;
};

export type TemplateType = {
  __typename?: 'TemplateType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  package: ProjectPackage;
  planAlias?: Maybe<Scalars['String']['output']>;
};

/** A paginated list of TemplateType items. */
export type TemplateTypePaginator = {
  __typename?: 'TemplateTypePaginator';
  /** A list of TemplateType items. */
  data: Array<TemplateType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type TemplateTypeTranslationInput = {
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  discount?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  paymentMethod?: Maybe<PaymentMethod>;
  price?: Maybe<Scalars['String']['output']>;
  purchase?: Maybe<Purchase>;
  status: TransactionStatus;
  summary?: Maybe<Scalars['String']['output']>;
};

export type TransactionPaginated = {
  __typename?: 'TransactionPaginated';
  data: Array<Transaction>;
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Transaction items. */
export type TransactionPaginator = {
  __typename?: 'TransactionPaginator';
  /** A list of Transaction items. */
  data: Array<Transaction>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** List of available statuses. */
export enum TransactionStatus {
  /** When transaction status is failed. */
  Failed = 'FAILED',
  /** When transaction status is pending. */
  Pending = 'PENDING',
  /** When transaction status is refunded. */
  Refunded = 'REFUNDED',
  /** When transaction status is success. */
  Success = 'SUCCESS',
  /** When transaction status is voided. */
  Voided = 'VOIDED'
}

/** List of available columns to order transactions. */
export enum TransactionsOrderByColumn {
  /** Order transactions by created date. */
  CreatedAt = 'CREATED_AT',
  /** Order transactions by name field. */
  Name = 'NAME',
  /** Order transactions by price field. */
  Price = 'PRICE'
}

export type TransferProjectInput = {
  projectId: Scalars['ID']['input'];
  targetWorkspaceId: Scalars['ID']['input'];
};

export type TransferProjectOwnershipInput = {
  clientId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  feePaidBy: WorkspaceUserType;
  footer?: InputMaybe<Scalars['String']['input']>;
  oneTimeFee?: InputMaybe<Scalars['Float']['input']>;
  planAlias?: InputMaybe<Scalars['String']['input']>;
  projectHostingFee?: InputMaybe<Scalars['Float']['input']>;
  projectId: Scalars['ID']['input'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UnsetPasswordInput = {
  projectId: Scalars['ID']['input'];
};

export type UpdateApplicationPackageInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<FeatureInput>>;
  featuresText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['ID']['input'];
  limitations?: InputMaybe<Array<LimitationInput>>;
  name: Scalars['String']['input'];
  ordering?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<Scalars['Array']['input']>;
  permissions?: InputMaybe<Array<Scalars['ID']['input']>>;
  public: Scalars['Boolean']['input'];
  translations?: InputMaybe<Array<ApplicationPackageTranslationInput>>;
};

export type UpdateApplicationPackageReferenceInput = {
  applicationPackageId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  packageableId?: InputMaybe<Scalars['ID']['input']>;
  packageableType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBillingDetailsInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  phoneCode?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  recaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCouponInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  code: Scalars['String']['input'];
  connectedToReferralSystem?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  numberOfCoupons?: InputMaybe<Scalars['Int']['input']>;
  percent: Scalars['Boolean']['input'];
  plans?: InputMaybe<Array<Scalars['ID']['input']>>;
  prorate: Scalars['Boolean']['input'];
  redeem: CouponRedeemType;
};

export type UpdateCurrentPlanInput = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  planAlias: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type UpdateCurrentWorkspacePlanInput = {
  planAlias: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateDomainInput = {
  id: Scalars['ID']['input'];
  redirectToWww?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDomainPackageInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<FeatureInput>>;
  featuresText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<Scalars['Array']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<Array<DomainPackageTranslationInput>>;
};

export type UpdateFeatureGroupInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  translations?: InputMaybe<Array<FeatureGroupTranslationInput>>;
};

export type UpdateFeatureInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  ordering?: InputMaybe<Scalars['Int']['input']>;
  packageType?: InputMaybe<PackageType>;
  params?: InputMaybe<Scalars['Array']['input']>;
  title: Scalars['String']['input'];
  translations?: InputMaybe<Array<FeatureTranslationInput>>;
};

export type UpdateMailTemplateContentInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  bodyObject?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  translations?: InputMaybe<Array<UpdateMailTemplateContentTranslationInput>>;
};

export type UpdateMailTemplateContentTranslationInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  bodyObject?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
};

export type UpdateMailTemplateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alias: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  packageId?: InputMaybe<Scalars['ID']['input']>;
  packageType?: InputMaybe<PackageType>;
  subject?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<UpdateMailTemplateTranslationInput>>;
};

export type UpdateMailTemplateTranslationInput = {
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMembersRoleInput = {
  applicationRoles?: InputMaybe<Array<ApplicationRole>>;
  projectId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export type UpdateMembersWithNewRoleInput = {
  alias: Scalars['String']['input'];
  applicationRoles?: InputMaybe<Array<ApplicationRole>>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  permissionIds: Array<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export type UpdatePasswordInput = {
  oldPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  recaptchaResponse?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePermissionGroupInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePermissionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<PermissionTranslationInput>>;
  type?: InputMaybe<PermissionType>;
};

export type UpdatePlanInput = {
  activeFeatures?: InputMaybe<Array<PlanFeatureLimit>>;
  addons?: InputMaybe<Array<Scalars['ID']['input']>>;
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  billingFrequency: Scalars['Int']['input'];
  cardRequired?: InputMaybe<Scalars['Boolean']['input']>;
  comingSoon?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  moneyback?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  packageId?: InputMaybe<Scalars['ID']['input']>;
  packageType?: InputMaybe<PackageType>;
  plans?: InputMaybe<Array<Scalars['ID']['input']>>;
  popular?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  recommended?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<Array<PlanTranslationInput>>;
  trialDays?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<PlanVisibility>;
};

export type UpdateProfileInput = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  recaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  receiveNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateProjectEmailSenderConfigInput = {
  contactEmail: Scalars['String']['input'];
  domainId: Scalars['ID']['input'];
  emailPrefix: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type UpdateProjectInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  businessLegalName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  darkModeLogo?: InputMaybe<Scalars['ID']['input']>;
  favicon?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  languageDetectionMode?: InputMaybe<LanguageDetectionMode>;
  logo?: InputMaybe<Scalars['ID']['input']>;
  mobileDarkModeLogo?: InputMaybe<Scalars['ID']['input']>;
  mobileLogo?: InputMaybe<Scalars['ID']['input']>;
  mobileTouchIcon?: InputMaybe<Scalars['ID']['input']>;
  package?: InputMaybe<Scalars['ID']['input']>;
  passwordProtection?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  socials?: InputMaybe<Array<SocialItemInput>>;
  sqlServer?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  testMode?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectPackageInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  allowedTemplateTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<FeatureInput>>;
  featuresText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['ID']['input'];
  isUpgradable: Scalars['Boolean']['input'];
  limitations?: InputMaybe<Array<LimitationInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<Scalars['Array']['input']>;
  permissions?: InputMaybe<Array<Scalars['ID']['input']>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<Array<ProjectPackageTranslationInput>>;
};

export type UpdateRoleInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissionIds: Array<InputMaybe<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

export type UpdateSubscriptionInput = {
  autoRenew: Scalars['Boolean']['input'];
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type UpdateTagInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type UpdateTemplateInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  demoPath?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  designer?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  industries?: InputMaybe<Array<Scalars['ID']['input']>>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
  visible: Scalars['Boolean']['input'];
};

export type UpdateTemplateTagsInput = {
  id: Scalars['ID']['input'];
  tags: Array<Scalars['ID']['input']>;
};

export type UpdateTemplateTypeInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  packageId?: InputMaybe<Scalars['ID']['input']>;
  planAlias?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<TemplateTypeTranslationInput>>;
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<UserGroup>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserProjectNotificationSettingsInput = {
  projectId: Scalars['ID']['input'];
  receiveMailNotification: Scalars['Boolean']['input'];
  receivePushNotification: Scalars['Boolean']['input'];
};

export type UpdateWorkspaceClientInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  businessDescription?: InputMaybe<Scalars['String']['input']>;
  businessLegalName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  favicon?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['ID']['input'];
  legal?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  mobileTouchIcon?: InputMaybe<Scalars['Upload']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  teamSize?: InputMaybe<Scalars['WorkspaceTeamSize']['input']>;
  teamType?: InputMaybe<Scalars['WorkspaceTeamType']['input']>;
  webPage?: InputMaybe<Scalars['URL']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceMembersRoleInput = {
  roleId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateWorkspaceMembersWithNewRoleInput = {
  alias: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  permissionIds: Array<Scalars['ID']['input']>;
  userIds: Array<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateWorkspacePackageInput = {
  agreement?: InputMaybe<Scalars['String']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  allowedTemplateTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<FeatureInput>>;
  featuresText?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['ID']['input'];
  limitations?: InputMaybe<Array<WorkspaceLimitationInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  partnerDiscount?: InputMaybe<Scalars['Float']['input']>;
  permissions?: InputMaybe<Array<Scalars['ID']['input']>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateWorkspacePermissionGroupInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceRoleInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  permissionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  avatarUrl?: Maybe<Scalars['String']['output']>;
  balance: Scalars['Float']['output'];
  billingDetails?: Maybe<BillingDetails>;
  createdAt: Scalars['Date']['output'];
  email: Scalars['Email']['output'];
  emailVerifiedAt?: Maybe<Scalars['Date']['output']>;
  firstName: Scalars['String']['output'];
  group: UserGroup;
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  paymentGateway?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Permission>>;
  projects: ProjectPaginator;
  projectsCount: Scalars['Int']['output'];
  receiveNewsletter: Scalars['Boolean']['output'];
  role?: Maybe<Role>;
  subscriptions: SubscriptionServicePaginator;
  transactions: TransactionPaginator;
  workspaceRole?: Maybe<WorkspaceRole>;
};


export type UserPermissionsArgs = {
  projectId: Scalars['ID']['input'];
};


export type UserProjectsArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<AllProjectsInput>;
  orderBy?: InputMaybe<Array<UserProjectsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type UserRoleArgs = {
  projectId: Scalars['ID']['input'];
};


export type UserSubscriptionsArgs = {
  first: Scalars['Int']['input'];
  input?: InputMaybe<SubscriptionTermsInput>;
  orderBy?: InputMaybe<Array<UserSubscriptionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTransactionsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<UserTransactionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type UserWorkspaceRoleArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type UserAgent = {
  __typename?: 'UserAgent';
  browser: Scalars['String']['output'];
  operatingSystem: Scalars['String']['output'];
};

/** List of available user groups. */
export enum UserGroup {
  /** Admin group. */
  Admin = 'ADMIN',
  /** Basic (default) group. */
  Basic = 'BASIC',
  /** Staff group. */
  Staff = 'STAFF',
  /** Super admin group. */
  SuperAdmin = 'SUPER_ADMIN',
  /** Tester group. */
  Tester = 'TESTER'
}

export type UserProjectNotificationSetting = {
  __typename?: 'UserProjectNotificationSetting';
  receiveMailNotification: Scalars['Boolean']['output'];
  receivePushNotification: Scalars['Boolean']['output'];
};

export type UserProjectNotificationSettingsInput = {
  projectId: Scalars['ID']['input'];
};

/** Order by clause for User.projects.orderBy. */
export type UserProjectsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ProjectsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for User.subscriptions.orderBy. */
export type UserSubscriptionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SubscriptionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type UserTermsInput = {
  orderBy?: InputMaybe<Array<QueryUsersOrderOrderByClause>>;
  search?: InputMaybe<Scalars['String']['input']>;
  trashed?: InputMaybe<UserTermsTrashedStatus>;
};

/** List of available status that users can have(trashed, without trashed and only trashed). */
export enum UserTermsTrashedStatus {
  /** Only trashed user status. */
  OnlyTrashed = 'ONLY_TRASHED',
  /** User without trashed status. */
  WithoutTrashed = 'WITHOUT_TRASHED',
  /** User with trashed status. */
  WithTrashed = 'WITH_TRASHED'
}

/** Order by clause for User.transactions.orderBy. */
export type UserTransactionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: TransactionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** List of available columns to order users. */
export enum UsersOrderByColumn {
  /** Order users by created_at field. */
  CreatedAt = 'CREATED_AT',
  /** Order users by email field. */
  Email = 'EMAIL',
  /** Order users by first_name field. */
  FirstName = 'FIRST_NAME',
  /** Order users by last_name field. */
  LastName = 'LAST_NAME'
}

export type UsersPaginator = {
  __typename?: 'UsersPaginator';
  data: Array<User>;
  paginatorInfo: PaginatorInfo;
};

export type Workspace = {
  __typename?: 'Workspace';
  address?: Maybe<Scalars['String']['output']>;
  businessDescription?: Maybe<Scalars['String']['output']>;
  businessLegalName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  clientName: Scalars['String']['output'];
  connectedAccountStatus: WorkspaceConnectedAccountStatus;
  country?: Maybe<Scalars['String']['output']>;
  creator: User;
  currentPackage?: Maybe<WorkspacePackage>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  favicon?: Maybe<Scalars['String']['output']>;
  faviconUrl?: Maybe<Scalars['URL']['output']>;
  id: Scalars['ID']['output'];
  isExpired: Scalars['Boolean']['output'];
  legal?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['URL']['output']>;
  mobileTouchIcon?: Maybe<Scalars['String']['output']>;
  mobileTouchIconUrl?: Maybe<Scalars['URL']['output']>;
  name: Scalars['String']['output'];
  nativeDomainHost?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  projects?: Maybe<WorkspaceProjectsPaginator>;
  projectsCount: Scalars['Int']['output'];
  state?: Maybe<Scalars['String']['output']>;
  status: WorkspaceStatus;
  subscriptions: SubscriptionServicePaginator;
  teamSize?: Maybe<Scalars['WorkspaceTeamSize']['output']>;
  teamType?: Maybe<Scalars['WorkspaceTeamType']['output']>;
  templates?: Maybe<WorkspaceTemplatesPaginator>;
  url?: Maybe<Scalars['String']['output']>;
  userRole?: Maybe<WorkspaceRole>;
  users?: Maybe<WorkspaceUsersPaginator>;
  usersCount: Scalars['Int']['output'];
  webPage?: Maybe<Scalars['URL']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type WorkspaceProjectsArgs = {
  input?: InputMaybe<GetWorkspaceProjectsInput>;
};


export type WorkspaceSubscriptionsArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type WorkspaceTemplatesArgs = {
  input?: InputMaybe<GetWorkspaceTemplatesInput>;
};


export type WorkspaceUsersArgs = {
  input?: InputMaybe<GetWorkspaceUsersInput>;
};

export type WorkspaceAccessRolesPivot = {
  __typename?: 'WorkspaceAccessRolesPivot';
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WorkspaceClient = {
  __typename?: 'WorkspaceClient';
  company?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<WorkspaceClientCustomer>;
  email: Scalars['Email']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoices?: Maybe<WorkspaceClientInvoicePaginator>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  projects?: Maybe<WorkspaceProjectsPaginator>;
  projectsCount: Scalars['Int']['output'];
  status: WorkspaceClientStatus;
  subscriptions?: Maybe<WorkspaceClientSubscriptionPaginator>;
  workspace: Workspace;
};


export type WorkspaceClientInvoicesArgs = {
  input: GetWorkspaceClientInvoicesInput;
};


export type WorkspaceClientProjectsArgs = {
  input?: InputMaybe<GetWorkspaceProjectsInput>;
};


export type WorkspaceClientSubscriptionsArgs = {
  input: GetWorkspaceClientSubscriptionsInput;
};

export type WorkspaceClientCustomer = {
  __typename?: 'WorkspaceClientCustomer';
  balance: Scalars['Float']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type WorkspaceClientInvoice = {
  __typename?: 'WorkspaceClientInvoice';
  applicationFeePercent?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  hostedInvoiceUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoicePdf?: Maybe<Scalars['String']['output']>;
  items?: Maybe<WorkspaceClientInvoiceItemCollection>;
  status: Scalars['String']['output'];
  total: Scalars['Float']['output'];
};

export type WorkspaceClientInvoiceItem = {
  __typename?: 'WorkspaceClientInvoiceItem';
  amount?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interval?: Maybe<Scalars['String']['output']>;
  intervalCount?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Float']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type WorkspaceClientInvoiceItemCollection = {
  __typename?: 'WorkspaceClientInvoiceItemCollection';
  data?: Maybe<Array<WorkspaceClientInvoiceItem>>;
  hasMore?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkspaceClientInvoiceItemPeriod = {
  __typename?: 'WorkspaceClientInvoiceItemPeriod';
  end?: Maybe<Scalars['DateTime']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type WorkspaceClientInvoicePaginator = {
  __typename?: 'WorkspaceClientInvoicePaginator';
  data?: Maybe<Array<WorkspaceClientInvoice>>;
  hasMore?: Maybe<Scalars['Boolean']['output']>;
};

/** List of status of workspace client invoice. */
export enum WorkspaceClientInvoiceStatus {
  /** Workspace client invoice status is draft. */
  Draft = 'DRAFT',
  /** Workspace client invoice status is open. */
  Open = 'OPEN',
  /** Workspace client invoice status is paid. */
  Paid = 'PAID',
  /** Workspace client invoice status is uncollectible. */
  Uncollectible = 'UNCOLLECTIBLE',
  /** Workspace client invoice status is void. */
  Void = 'VOID'
}

export type WorkspaceClientPaginator = {
  __typename?: 'WorkspaceClientPaginator';
  data?: Maybe<Array<WorkspaceClient>>;
  paginatorInfo: PaginatorInfo;
};

/** List of status of workspace client checkout session payment. */
export enum WorkspaceClientPaymentStatus {
  /** The payment is delayed to a future date, or the Checkout Session is in setup mode and doesn’t require a payment at this time. */
  NotRequired = 'NOT_REQUIRED',
  /** The payment funds are available in your account. */
  Paid = 'PAID',
  /** The payment funds are not yet available in your account. */
  Unpaid = 'UNPAID'
}

/** List of available statuses for project. */
export enum WorkspaceClientStatus {
  /** Client is active. */
  Active = 'ACTIVE',
  /** Client is disabled. */
  Disabled = 'DISABLED',
  /** Client is pending. */
  Pending = 'PENDING'
}

export type WorkspaceClientSubscription = {
  __typename?: 'WorkspaceClientSubscription';
  applicationFeePercent?: Maybe<Scalars['Float']['output']>;
  cancelAt?: Maybe<Scalars['DateTime']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  collectionMethod?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['String']['output'];
  currentPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  currentPeriodStart?: Maybe<Scalars['DateTime']['output']>;
  daysUntilDue?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  items?: Maybe<WorkspaceClientSubscriptionItemCollection>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  nextPendingInvoiceItemInvoice?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
};

export type WorkspaceClientSubscriptionItem = {
  __typename?: 'WorkspaceClientSubscriptionItem';
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  interval?: Maybe<Scalars['String']['output']>;
  intervalCount?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Float']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type WorkspaceClientSubscriptionItemCollection = {
  __typename?: 'WorkspaceClientSubscriptionItemCollection';
  data?: Maybe<Array<WorkspaceClientSubscriptionItem>>;
  hasMore?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkspaceClientSubscriptionPaginator = {
  __typename?: 'WorkspaceClientSubscriptionPaginator';
  data?: Maybe<Array<WorkspaceClientSubscription>>;
  hasMore?: Maybe<Scalars['Boolean']['output']>;
};

/** List of status of workspace client subscription. */
export enum WorkspaceClientSubscriptionStatus {
  /** Workspace client subscription status is active. */
  Active = 'ACTIVE',
  /** Workspace client subscription status is all. */
  All = 'ALL',
  /** Workspace client subscription status is canceled. */
  Canceled = 'CANCELED',
  /** Workspace client subscription status is ended. */
  Ended = 'ENDED',
  /** Workspace client subscription status is incomplete. */
  Incomplete = 'INCOMPLETE',
  /** Workspace client subscription status is incomplete_expired. */
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  /** Workspace client subscription status is past_due. */
  PastDue = 'PAST_DUE',
  /** Workspace client subscription status is paused. */
  Paused = 'PAUSED',
  /** Workspace client subscription status is trialing. */
  Trialing = 'TRIALING',
  /** Workspace client subscription status is unpaid. */
  Unpaid = 'UNPAID'
}

export type WorkspaceClientsOrderByClause = {
  column: WorkspaceClientsOrderByColumn;
  order: SortOrder;
};

/** List of columns by which workspace clients can be ordered. */
export enum WorkspaceClientsOrderByColumn {
  /** Order workspace clients by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order workspace clients by email. */
  Email = 'EMAIL'
}

/** List of status which workspace connected account can have. */
export enum WorkspaceConnectedAccountStatus {
  /** Workspace connected account status is active. */
  Active = 'ACTIVE',
  /** Workspace connected account status is pending. */
  Pending = 'PENDING',
  /** Workspace connected account status is details submitted. */
  Submitted = 'SUBMITTED'
}

/** List of types of workspace connected account. */
export enum WorkspaceConnectedAccountType {
  /** Express type of connected account. */
  Express = 'EXPRESS'
}

export type WorkspaceEntityCountLimitation = {
  entities: Array<WorkspaceLimitationType>;
  workspaceId: Scalars['ID']['input'];
};

export type WorkspaceInvitation = Membership & {
  __typename?: 'WorkspaceInvitation';
  email: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
  role: WorkspaceRole;
  status: MembershipStatus;
};


export type WorkspaceInvitationRoleArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type WorkspaceLimitation = {
  __typename?: 'WorkspaceLimitation';
  count: Scalars['Int']['output'];
  entity: WorkspaceLimitationType;
  limit: Scalars['Int']['output'];
  limitReached: Scalars['Boolean']['output'];
};

export type WorkspaceLimitationInput = {
  entity: WorkspaceLimitationType;
  limit: Scalars['Int']['input'];
};

/** List of available workspace limitation types. */
export enum WorkspaceLimitationType {
  /** Workspace Project type. */
  WorkspaceProject = 'WORKSPACE_PROJECT'
}

export type WorkspaceMember = WorkspaceInvitation | WorkspaceTeamMember;

export type WorkspaceMembersInput = {
  orderBy?: InputMaybe<WorkspaceMembersOrderByClause>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MembershipStatus>;
  workspaceId: Scalars['ID']['input'];
};

export type WorkspaceMembersOrderByClause = {
  column: WorkspaceMembersOrderByColumn;
  order: SortOrder;
};

/** List of columns by which workspace members can be ordered. */
export enum WorkspaceMembersOrderByColumn {
  /** Order workspace members by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order workspace members by email. */
  Email = 'EMAIL'
}

export type WorkspaceOrder = {
  __typename?: 'WorkspaceOrder';
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<WorkspaceOrderStatus>;
};

/** List of available status for worksace order. */
export enum WorkspaceOrderStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Draft */
  Draft = 'DRAFT',
  /** Expired */
  Expired = 'EXPIRED'
}

export type WorkspacePackage = {
  __typename?: 'WorkspacePackage';
  agreement?: Maybe<Scalars['String']['output']>;
  alias: Scalars['String']['output'];
  allowedTemplateTypes?: Maybe<Array<Scalars['ID']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  features: WorkspacePackageFeaturePaginator;
  featuresText?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  limitations?: Maybe<Array<WorkspaceLimitation>>;
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  packageGroups?: Maybe<Array<PackageGroup>>;
  partnerDiscount?: Maybe<Scalars['Float']['output']>;
  permissions: PermissionPaginator;
  projectRequired: Scalars['Boolean']['output'];
  public: Scalars['Boolean']['output'];
};


export type WorkspacePackageFeaturesArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type WorkspacePackagePermissionsArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkspacePackageFeature = {
  __typename?: 'WorkspacePackageFeature';
  description: Scalars['String']['output'];
  group?: Maybe<FeatureGroup>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['String']['output']>;
  ordering?: Maybe<Scalars['Int']['output']>;
  packageType: PackageType;
  selected?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
};

/** A paginated list of WorkspacePackageFeature items. */
export type WorkspacePackageFeaturePaginator = {
  __typename?: 'WorkspacePackageFeaturePaginator';
  /** A list of WorkspacePackageFeature items. */
  data: Array<WorkspacePackageFeature>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of WorkspacePackage items. */
export type WorkspacePackagePaginator = {
  __typename?: 'WorkspacePackagePaginator';
  /** A list of WorkspacePackage items. */
  data: Array<WorkspacePackage>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type WorkspacePackageTermsInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type WorkspacePaginator = {
  __typename?: 'WorkspacePaginator';
  data?: Maybe<Array<Workspace>>;
  paginatorInfo: PaginatorInfo;
};

export type WorkspacePermission = {
  __typename?: 'WorkspacePermission';
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  group: WorkspacePermissionGroup;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type WorkspacePermissionGroup = {
  __typename?: 'WorkspacePermissionGroup';
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<WorkspacePermission>;
};

export type WorkspacePermissionGroupPaginator = {
  __typename?: 'WorkspacePermissionGroupPaginator';
  data?: Maybe<Array<WorkspacePermissionGroup>>;
  paginatorInfo: PaginatorInfo;
};

export type WorkspacePermissionGroupsOrderByClause = {
  column: WorkspacePermissionGroupsOrderByColumn;
  order: SortOrder;
};

/** List of columns by which workspace permission groups can be ordered. */
export enum WorkspacePermissionGroupsOrderByColumn {
  /** Order workspace permission groups by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order workspace permission groups by name. */
  Name = 'NAME'
}

export type WorkspacePlansQueryInput = {
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type WorkspaceProjectOrderByClause = {
  column: ProjectsOrderByColumn;
  order: SortOrder;
};

export type WorkspaceProjectsPaginator = {
  __typename?: 'WorkspaceProjectsPaginator';
  data?: Maybe<Array<Project>>;
  paginatorInfo: PaginatorInfo;
};

export type WorkspaceRole = {
  __typename?: 'WorkspaceRole';
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAdminType: Scalars['Boolean']['output'];
  isOwnerType: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  permissions: Array<WorkspacePermission>;
  workspace?: Maybe<Workspace>;
};

export type WorkspaceRolesOrderByClause = {
  column: WorkspaceRolesOrderByColumn;
  order: SortOrder;
};

/** List of columns by which workspace roles can be ordered. */
export enum WorkspaceRolesOrderByColumn {
  /** Order workspace roles by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order workspace roles by role name. */
  Name = 'NAME'
}

/** List of available statuses for workspace. */
export enum WorkspaceStatus {
  /** Workspace is inactive. */
  Active = 'ACTIVE',
  /** Workspace is inactive. */
  Trial = 'TRIAL'
}

export type WorkspaceTeamMember = Membership & {
  __typename?: 'WorkspaceTeamMember';
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['Email']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  params?: Maybe<Scalars['Array']['output']>;
  pivot?: Maybe<WorkspaceAccessRolesPivot>;
  role: WorkspaceRole;
  status: MembershipStatus;
};


export type WorkspaceTeamMemberRoleArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type WorkspaceTemplateOrderByClause = {
  column: WorkspaceTemplatesOrderByColumn;
  order: SortOrder;
};

/** List of columns by which workspace templates can be ordered. */
export enum WorkspaceTemplatesOrderByColumn {
  /** Order workspace templates by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order workspace templates by template name. */
  Name = 'NAME'
}

export type WorkspaceTemplatesPaginator = {
  __typename?: 'WorkspaceTemplatesPaginator';
  data?: Maybe<Array<Template>>;
  paginatorInfo: PaginatorInfo;
};

/** List of available type of user who can pay workspace order. */
export enum WorkspaceUserType {
  /** Workspace order payble by Client. */
  Client = 'CLIENT',
  /** Workspace order payble by Partner. */
  Partner = 'PARTNER'
}

export type WorkspaceUsersPaginator = {
  __typename?: 'WorkspaceUsersPaginator';
  data?: Maybe<Array<RelatedUser>>;
  paginatorInfo: PaginatorInfo;
};

export type WorkspacesOrderByClause = {
  column: WorkspacesOrderByColumn;
  order: SortOrder;
};

/** List of columns by which workspaces can be ordered. */
export enum WorkspacesOrderByColumn {
  /** Order workspaces by name. */
  ClientName = 'CLIENT_NAME',
  /** Order workspaces by creation date. */
  CreatedAt = 'CREATED_AT'
}

export type WrongRecord = {
  __typename?: 'WrongRecord';
  host: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AIBlock: ResolverTypeWrapper<any>;
  AccountDeletionReason: ResolverTypeWrapper<any>;
  ActivateSubscriptionInput: ResolverTypeWrapper<any>;
  ActivateWorkspaceClientsInput: ResolverTypeWrapper<any>;
  ActivityRecord: ResolverTypeWrapper<any>;
  ActivityRecordFilter: ResolverTypeWrapper<any>;
  ActivityRecordFilterClauseInput: ResolverTypeWrapper<any>;
  ActivityRecordFilterColumn: ResolverTypeWrapper<any>;
  ActivityRecordInput: ResolverTypeWrapper<any>;
  ActivityRecordOrderByClauseInput: ResolverTypeWrapper<any>;
  ActivityRecordOrderByColumn: ResolverTypeWrapper<any>;
  ActivityRecordPaginator: ResolverTypeWrapper<any>;
  ActivityRecordsAvailableFilterInput: ResolverTypeWrapper<any>;
  AdminCreateAIBlockInput: ResolverTypeWrapper<any>;
  AdminCreateGlobalBlockInput: ResolverTypeWrapper<any>;
  AdminDeleteDomainInput: ResolverTypeWrapper<any>;
  AdminDeleteDomainsInput: ResolverTypeWrapper<any>;
  AdminGetBlocksInput: ResolverTypeWrapper<any>;
  AdminProjectPaginator: ResolverTypeWrapper<any>;
  AdminTemplateInput: ResolverTypeWrapper<any>;
  AdminUpdateAIBlockInput: ResolverTypeWrapper<any>;
  AdminUpdateDomainInput: ResolverTypeWrapper<any>;
  AdminUpdateGlobalBlockInput: ResolverTypeWrapper<any>;
  AdminUpdateLocalBlockInput: ResolverTypeWrapper<any>;
  AdminUpdateTemplateInput: ResolverTypeWrapper<any>;
  AdminUpdateTemplateTagsInput: ResolverTypeWrapper<any>;
  AllPackagesInput: ResolverTypeWrapper<any>;
  AllProjectsInput: ResolverTypeWrapper<any>;
  AllWorkspacesInput: ResolverTypeWrapper<any>;
  ApplicationPackage: ResolverTypeWrapper<any>;
  ApplicationPackagePaginator: ResolverTypeWrapper<any>;
  ApplicationPackageReference: ResolverTypeWrapper<any>;
  ApplicationPackageTermsInput: ResolverTypeWrapper<any>;
  ApplicationPackageTranslationInput: ResolverTypeWrapper<any>;
  ApplicationRole: ResolverTypeWrapper<any>;
  ApplicationRoleType: ResolverTypeWrapper<any>;
  Array: ResolverTypeWrapper<any>;
  AttachAddonInput: ResolverTypeWrapper<any>;
  AttachCouponInput: ResolverTypeWrapper<any>;
  AttachFeatureInput: ResolverTypeWrapper<any>;
  BillingDetails: ResolverTypeWrapper<any>;
  BillingInfo: ResolverTypeWrapper<any>;
  Block: ResolverTypeWrapper<any>;
  BlockCategory: ResolverTypeWrapper<any>;
  BlockInterface: ResolverTypeWrapper<any>;
  BlockPaginator: ResolverTypeWrapper<any>;
  BlockScope: ResolverTypeWrapper<any>;
  BlocksOrderByColumn: ResolverTypeWrapper<any>;
  Boolean: ResolverTypeWrapper<any>;
  BulkActionEntity: ResolverTypeWrapper<any>;
  BulkActionOperation: ResolverTypeWrapper<any>;
  BulkActionResponse: ResolverTypeWrapper<any>;
  BulkActionTeamInput: ResolverTypeWrapper<any>;
  CancelSubscriptionInput: ResolverTypeWrapper<any>;
  CheckDomainInput: ResolverTypeWrapper<any>;
  CheckProjectCreationStatus: ResolverTypeWrapper<any>;
  CheckProjectEmailSenderDomainSettingsInput: ResolverTypeWrapper<any>;
  CheckoutDomainInput: ResolverTypeWrapper<any>;
  ConditionOperatorEnum: ResolverTypeWrapper<any>;
  Configuration: ResolverTypeWrapper<any>;
  ConnectDomainInput: ResolverTypeWrapper<any>;
  ConnectedDomain: ResolverTypeWrapper<any>;
  Coupon: ResolverTypeWrapper<any>;
  CouponPaginator: ResolverTypeWrapper<any>;
  CouponRedeemType: ResolverTypeWrapper<any>;
  CouponTermsInput: ResolverTypeWrapper<any>;
  CouponsOrderByColumn: ResolverTypeWrapper<any>;
  CreateApplicationPackageInput: ResolverTypeWrapper<any>;
  CreateApplicationPackageReferenceInput: ResolverTypeWrapper<any>;
  CreateBillingDetailsInput: ResolverTypeWrapper<any>;
  CreateCouponInput: ResolverTypeWrapper<any>;
  CreateDomainPackageInput: ResolverTypeWrapper<any>;
  CreateFeatureGroupInput: ResolverTypeWrapper<any>;
  CreateFeatureInput: ResolverTypeWrapper<any>;
  CreateMailTemplateInput: ResolverTypeWrapper<any>;
  CreateMailTemplateTranslationInput: ResolverTypeWrapper<any>;
  CreatePaymentMethodInput: ResolverTypeWrapper<any>;
  CreatePermissionGroupInput: ResolverTypeWrapper<any>;
  CreatePermissionInput: ResolverTypeWrapper<any>;
  CreatePlanInput: ResolverTypeWrapper<any>;
  CreateProjectInput: ResolverTypeWrapper<any>;
  CreateProjectPackageInput: ResolverTypeWrapper<any>;
  CreateRoleInput: ResolverTypeWrapper<any>;
  CreateTagInput: ResolverTypeWrapper<any>;
  CreateTagsInput: ResolverTypeWrapper<any>;
  CreateTemplateTypeInput: ResolverTypeWrapper<any>;
  CreateWorkspaceClientInput: ResolverTypeWrapper<any>;
  CreateWorkspaceInput: ResolverTypeWrapper<any>;
  CreateWorkspacePackageInput: ResolverTypeWrapper<any>;
  CreateWorkspacePaymentMethodInput: ResolverTypeWrapper<any>;
  CreateWorkspacePermissionGroupInput: ResolverTypeWrapper<any>;
  CreateWorkspaceRoleInput: ResolverTypeWrapper<any>;
  DNSRecordType: ResolverTypeWrapper<any>;
  Date: ResolverTypeWrapper<any>;
  DateTime: ResolverTypeWrapper<any>;
  DateTimeUnix: ResolverTypeWrapper<any>;
  DeleteAccountInput: ResolverTypeWrapper<any>;
  DeleteApplicationPackageInput: ResolverTypeWrapper<any>;
  DeleteApplicationPackageReferenceInput: ResolverTypeWrapper<any>;
  DeleteApplicationPackagesInput: ResolverTypeWrapper<any>;
  DeleteCouponsInput: ResolverTypeWrapper<any>;
  DeleteDomainInput: ResolverTypeWrapper<any>;
  DeleteDomainPackageInput: ResolverTypeWrapper<any>;
  DeleteDomainPackagesInput: ResolverTypeWrapper<any>;
  DeleteFeatureGroupInput: ResolverTypeWrapper<any>;
  DeleteFeatureGroupsInput: ResolverTypeWrapper<any>;
  DeleteFeatureInput: ResolverTypeWrapper<any>;
  DeleteFeaturesInput: ResolverTypeWrapper<any>;
  DeleteInvitationInput: ResolverTypeWrapper<any>;
  DeleteMemberInput: ResolverTypeWrapper<any>;
  DeletePermissionGroupInput: ResolverTypeWrapper<any>;
  DeletePermissionInput: ResolverTypeWrapper<any>;
  DeletePermissionsInput: ResolverTypeWrapper<any>;
  DeletePlanInput: ResolverTypeWrapper<any>;
  DeletePlansInput: ResolverTypeWrapper<any>;
  DeleteProjectInput: ResolverTypeWrapper<any>;
  DeleteProjectPackageInput: ResolverTypeWrapper<any>;
  DeleteProjectPackagesInput: ResolverTypeWrapper<any>;
  DeleteProjectWithoutPasswordInput: ResolverTypeWrapper<any>;
  DeleteProjectsWithoutPasswordInput: ResolverTypeWrapper<any>;
  DeleteRoleInput: ResolverTypeWrapper<any>;
  DeleteTemplateTypeInput: ResolverTypeWrapper<any>;
  DeleteUserInput: ResolverTypeWrapper<any>;
  DeleteUsersInput: ResolverTypeWrapper<any>;
  DeleteWorkspaceClientsInput: ResolverTypeWrapper<any>;
  DeleteWorkspaceInvitationsInput: ResolverTypeWrapper<any>;
  DeleteWorkspaceMemberInput: ResolverTypeWrapper<any>;
  DeleteWorkspacePackageInput: ResolverTypeWrapper<any>;
  DeleteWorkspacePackagesInput: ResolverTypeWrapper<any>;
  DeleteWorkspacePermissionGroupInput: ResolverTypeWrapper<any>;
  DeleteWorkspaceRoleInput: ResolverTypeWrapper<any>;
  DeleteWorkspaceWithoutPasswordInput: ResolverTypeWrapper<any>;
  DeleteWorkspacesWithoutPasswordInput: ResolverTypeWrapper<any>;
  DetachAddonInput: ResolverTypeWrapper<any>;
  DetachCouponInput: ResolverTypeWrapper<any>;
  DetachFeatureInput: ResolverTypeWrapper<any>;
  DisconnectDomainInput: ResolverTypeWrapper<any>;
  DnsRecord: ResolverTypeWrapper<any>;
  Domain: ResolverTypeWrapper<any>;
  DomainBuyerInput: ResolverTypeWrapper<any>;
  DomainCheckoutResponse: ResolverTypeWrapper<any>;
  DomainFrequency: ResolverTypeWrapper<any>;
  DomainInvoice: ResolverTypeWrapper<any>;
  DomainPackage: ResolverTypeWrapper<any>;
  DomainPackagePaginator: ResolverTypeWrapper<any>;
  DomainPackageTermsInput: ResolverTypeWrapper<any>;
  DomainPackageTranslationInput: ResolverTypeWrapper<any>;
  DomainPaginator: ResolverTypeWrapper<any>;
  DomainParamsData: ResolverTypeWrapper<any>;
  DomainRegistrantData: ResolverTypeWrapper<any>;
  DomainSearchResult: ResolverTypeWrapper<any>;
  DomainStatus: ResolverTypeWrapper<any>;
  DomainTermsInput: ResolverTypeWrapper<any>;
  DomainType: ResolverTypeWrapper<any>;
  DomainsInput: ResolverTypeWrapper<any>;
  DomainsOrderByColumn: ResolverTypeWrapper<any>;
  DuplicateRoleInput: ResolverTypeWrapper<any>;
  DuplicateWorkspaceRoleInput: ResolverTypeWrapper<any>;
  Email: ResolverTypeWrapper<any>;
  EntityLimitation: ResolverTypeWrapper<any>;
  ExtendSubscriptionInput: ResolverTypeWrapper<any>;
  Feature: ResolverTypeWrapper<any>;
  FeatureGroup: ResolverTypeWrapper<any>;
  FeatureGroupPaginator: ResolverTypeWrapper<any>;
  FeatureGroupTermsInput: ResolverTypeWrapper<any>;
  FeatureGroupTranslationInput: ResolverTypeWrapper<any>;
  FeatureGroupsOrderByColumn: ResolverTypeWrapper<any>;
  FeatureInput: ResolverTypeWrapper<any>;
  FeaturePaginator: ResolverTypeWrapper<any>;
  FeatureTermsInput: ResolverTypeWrapper<any>;
  FeatureTranslationInput: ResolverTypeWrapper<any>;
  FeaturesOrderByColumn: ResolverTypeWrapper<any>;
  FilterTemplateTagInput: ResolverTypeWrapper<any>;
  Float: ResolverTypeWrapper<any>;
  GeographicInfo: ResolverTypeWrapper<any>;
  GetWorkspaceClientInvoicesInput: ResolverTypeWrapper<any>;
  GetWorkspaceClientSubscriptionsInput: ResolverTypeWrapper<any>;
  GetWorkspaceClientsInput: ResolverTypeWrapper<any>;
  GetWorkspaceClientsInvoicesInput: ResolverTypeWrapper<any>;
  GetWorkspaceClientsSubscriptionsInput: ResolverTypeWrapper<any>;
  GetWorkspacePermissionGroupsInput: ResolverTypeWrapper<any>;
  GetWorkspaceProjectsInput: ResolverTypeWrapper<any>;
  GetWorkspaceRolesInput: ResolverTypeWrapper<any>;
  GetWorkspaceTemplatesInput: ResolverTypeWrapper<any>;
  GetWorkspaceUsersInput: ResolverTypeWrapper<any>;
  GetWorkspacesInput: ResolverTypeWrapper<any>;
  GlobalBlock: ResolverTypeWrapper<any>;
  Host: ResolverTypeWrapper<any>;
  ID: ResolverTypeWrapper<any>;
  Industry: ResolverTypeWrapper<any>;
  Int: ResolverTypeWrapper<any>;
  Invitation: ResolverTypeWrapper<any>;
  InviteMembersInput: ResolverTypeWrapper<any>;
  InviteMembersWithNewRoleInput: ResolverTypeWrapper<any>;
  InviteWorkspaceMembersInput: ResolverTypeWrapper<any>;
  InviteWorkspaceMembersWithNewRoleInput: ResolverTypeWrapper<any>;
  Json: ResolverTypeWrapper<any>;
  LanguageDetectionMode: ResolverTypeWrapper<any>;
  LeaveProjectInput: ResolverTypeWrapper<any>;
  LightweightUser: ResolverTypeWrapper<any>;
  Limitation: ResolverTypeWrapper<any>;
  LimitationInput: ResolverTypeWrapper<any>;
  LimitationType: ResolverTypeWrapper<any>;
  LocalBlock: ResolverTypeWrapper<any>;
  LocalesInfo: ResolverTypeWrapper<any>;
  MailTemplate: ResolverTypeWrapper<any>;
  MakeDomainDefaultInput: ResolverTypeWrapper<any>;
  ManipulatePaymentMethodInput: ResolverTypeWrapper<any>;
  ManipulateWorkspacePaymentMethodInput: ResolverTypeWrapper<any>;
  Measurement: ResolverTypeWrapper<any>;
  Member: ResolverTypeWrapper<any>;
  MembersInput: ResolverTypeWrapper<any>;
  MembersOrderByClause: ResolverTypeWrapper<any>;
  MembersOrderColumn: ResolverTypeWrapper<any>;
  Membership: ResolverTypeWrapper<any>;
  MembershipStatus: ResolverTypeWrapper<any>;
  Mixed: ResolverTypeWrapper<any>;
  Mutation: ResolverTypeWrapper<{}>;
  OrderByClause: ResolverTypeWrapper<any>;
  OrderByRelationAggregateFunction: ResolverTypeWrapper<any>;
  OrderByRelationWithColumnAggregateFunction: ResolverTypeWrapper<any>;
  Package: ResolverTypeWrapper<any>;
  PackageGroup: ResolverTypeWrapper<any>;
  PackageLimitedDomain: ResolverTypeWrapper<any>;
  PackageLimitedEntities: ResolverTypeWrapper<any>;
  PackageLimitedEntityLimitation: ResolverTypeWrapper<any>;
  PackageLimitedPayment: ResolverTypeWrapper<any>;
  PackageLimitedProduct: ResolverTypeWrapper<any>;
  PackageLimitedShipping: ResolverTypeWrapper<any>;
  PackageOrderByColumn: ResolverTypeWrapper<any>;
  PackageType: ResolverTypeWrapper<any>;
  PaginateActivityRecordInput: ResolverTypeWrapper<any>;
  PaginatorInfo: ResolverTypeWrapper<any>;
  PaymentMethod: ResolverTypeWrapper<any>;
  PaymentMethodType: ResolverTypeWrapper<any>;
  PaymentToken: ResolverTypeWrapper<any>;
  PaymentTokenResponse: ResolverTypeWrapper<any>;
  Permission: ResolverTypeWrapper<any>;
  PermissionAssignmentInput: ResolverTypeWrapper<any>;
  PermissionGroup: ResolverTypeWrapper<any>;
  PermissionGroupPaginator: ResolverTypeWrapper<any>;
  PermissionPaginator: ResolverTypeWrapper<any>;
  PermissionRemoveInput: ResolverTypeWrapper<any>;
  PermissionRequest: ResolverTypeWrapper<any>;
  PermissionTranslationInput: ResolverTypeWrapper<any>;
  PermissionType: ResolverTypeWrapper<any>;
  PermissionsOrderByColumn: ResolverTypeWrapper<any>;
  Plan: ResolverTypeWrapper<any>;
  PlanAddonsOrderByOrderByClause: ResolverTypeWrapper<any>;
  PlanCouponsOrderByOrderByClause: ResolverTypeWrapper<any>;
  PlanFeature: ResolverTypeWrapper<any>;
  PlanFeatureLimit: ResolverTypeWrapper<any>;
  PlanPaginator: ResolverTypeWrapper<any>;
  PlanPurchase: ResolverTypeWrapper<any>;
  PlanPurchaseInfo: ResolverTypeWrapper<any>;
  PlanQueryInput: ResolverTypeWrapper<any>;
  PlanSubscription: ResolverTypeWrapper<any>;
  PlanTermsInput: ResolverTypeWrapper<any>;
  PlanTranslationInput: ResolverTypeWrapper<any>;
  PlanVisibility: ResolverTypeWrapper<any>;
  PlansOrderByColumn: ResolverTypeWrapper<any>;
  PlansQueryInput: ResolverTypeWrapper<any>;
  Project: ResolverTypeWrapper<any>;
  ProjectAccessRolesPivot: ResolverTypeWrapper<any>;
  ProjectAccessType: ResolverTypeWrapper<any>;
  ProjectConfiguration: ResolverTypeWrapper<any>;
  ProjectCreationStatus: ResolverTypeWrapper<any>;
  ProjectDomain: ResolverTypeWrapper<any>;
  ProjectDomainPaginator: ResolverTypeWrapper<any>;
  ProjectDomainsOrderByOrderByClause: ResolverTypeWrapper<any>;
  ProjectEmailSenderConfig: ResolverTypeWrapper<any>;
  ProjectEmailSenderDomainRecord: ResolverTypeWrapper<any>;
  ProjectEmailSenderDomainRecordType: ResolverTypeWrapper<any>;
  ProjectEmailSenderDomainSettings: ResolverTypeWrapper<any>;
  ProjectFilterColumn: ResolverTypeWrapper<any>;
  ProjectFilters: ResolverTypeWrapper<any>;
  ProjectPackage: ResolverTypeWrapper<any>;
  ProjectPackageFeature: ResolverTypeWrapper<any>;
  ProjectPackageFeaturePaginator: ResolverTypeWrapper<any>;
  ProjectPackagePaginator: ResolverTypeWrapper<any>;
  ProjectPackageTermsInput: ResolverTypeWrapper<any>;
  ProjectPackageTranslationInput: ResolverTypeWrapper<any>;
  ProjectPaginator: ResolverTypeWrapper<any>;
  ProjectPermissionGroup: ResolverTypeWrapper<any>;
  ProjectRole: ResolverTypeWrapper<any>;
  ProjectStatus: ResolverTypeWrapper<any>;
  ProjectTemplate: ResolverTypeWrapper<any>;
  ProjectWorkspace: ResolverTypeWrapper<any>;
  ProjectsOrderByColumn: ResolverTypeWrapper<any>;
  Purchase: ResolverTypeWrapper<any>;
  PurchasePaginator: ResolverTypeWrapper<any>;
  PurchaseSubscriptionsOrderByOrderByClause: ResolverTypeWrapper<any>;
  PurchaseTermsInput: ResolverTypeWrapper<any>;
  PurchasesOrderByColumn: ResolverTypeWrapper<any>;
  Query: ResolverTypeWrapper<{}>;
  QueryAdminBlocksOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryAllDomainsOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryAllFeatureGroupsOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryAllPlansOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryAllSubscriptionsOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryAllWorkspacesOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryApplicationPackagesOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryCouponsOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryDomainPackagesOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryFeaturesOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryPermissionGroupsOrderByColumn: ResolverTypeWrapper<any>;
  QueryPermissionGroupsOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryPermissionsOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryProjectPackagesOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryProjectsOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryPurchasesOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryTemplateTagOrderOrderByClause: ResolverTypeWrapper<any>;
  QueryTemplatesOrderOrderByClause: ResolverTypeWrapper<any>;
  QueryTransactionsOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryUsersOrderOrderByClause: ResolverTypeWrapper<any>;
  QueryWorkspacePackagesOrderByOrderByClause: ResolverTypeWrapper<any>;
  RelatedUser: ResolverTypeWrapper<any>;
  RelatedUserPaginator: ResolverTypeWrapper<any>;
  RequestPermissionInput: ResolverTypeWrapper<any>;
  RequestedPermissionsInput: ResolverTypeWrapper<any>;
  ResaveTemplateInput: ResolverTypeWrapper<any>;
  Role: ResolverTypeWrapper<any>;
  RoleAssignmentInput: ResolverTypeWrapper<any>;
  RolesOrderByClause: ResolverTypeWrapper<any>;
  RolesOrderColumn: ResolverTypeWrapper<any>;
  SaveAsTemplateInput: ResolverTypeWrapper<any>;
  SendInstructionInput: ResolverTypeWrapper<any>;
  SetPasswordInput: ResolverTypeWrapper<any>;
  SetupInitialPaymentInput: ResolverTypeWrapper<any>;
  SingleRoleObject: ResolverTypeWrapper<any>;
  SocialItem: ResolverTypeWrapper<any>;
  SocialItemInput: ResolverTypeWrapper<any>;
  SortOrder: ResolverTypeWrapper<any>;
  SqlServer: ResolverTypeWrapper<any>;
  String: ResolverTypeWrapper<any>;
  SubscriptionDetails: ResolverTypeWrapper<any>;
  SubscriptionService: ResolverTypeWrapper<any>;
  SubscriptionServicePaginated: ResolverTypeWrapper<any>;
  SubscriptionServicePaginator: ResolverTypeWrapper<any>;
  SubscriptionStatus: ResolverTypeWrapper<any>;
  SubscriptionTermsInput: ResolverTypeWrapper<any>;
  SubscriptionsOrderByColumn: ResolverTypeWrapper<any>;
  SubstitutesInput: ResolverTypeWrapper<any>;
  TeamMember: ResolverTypeWrapper<any>;
  Template: ResolverTypeWrapper<any>;
  TemplateInput: ResolverTypeWrapper<any>;
  TemplateOrderByColumn: ResolverTypeWrapper<any>;
  TemplatePaginator: ResolverTypeWrapper<any>;
  TemplateTag: ResolverTypeWrapper<any>;
  TemplateTagInput: ResolverTypeWrapper<any>;
  TemplateTagOrderByColumn: ResolverTypeWrapper<any>;
  TemplateTagPaginator: ResolverTypeWrapper<any>;
  TemplateType: ResolverTypeWrapper<any>;
  TemplateTypePaginator: ResolverTypeWrapper<any>;
  TemplateTypeTranslationInput: ResolverTypeWrapper<any>;
  Transaction: ResolverTypeWrapper<any>;
  TransactionPaginated: ResolverTypeWrapper<any>;
  TransactionPaginator: ResolverTypeWrapper<any>;
  TransactionStatus: ResolverTypeWrapper<any>;
  TransactionsOrderByColumn: ResolverTypeWrapper<any>;
  TransferProjectInput: ResolverTypeWrapper<any>;
  TransferProjectOwnershipInput: ResolverTypeWrapper<any>;
  Trashed: ResolverTypeWrapper<any>;
  URL: ResolverTypeWrapper<any>;
  UnsetPasswordInput: ResolverTypeWrapper<any>;
  UpdateApplicationPackageInput: ResolverTypeWrapper<any>;
  UpdateApplicationPackageReferenceInput: ResolverTypeWrapper<any>;
  UpdateBillingDetailsInput: ResolverTypeWrapper<any>;
  UpdateCouponInput: ResolverTypeWrapper<any>;
  UpdateCurrentPlanInput: ResolverTypeWrapper<any>;
  UpdateCurrentWorkspacePlanInput: ResolverTypeWrapper<any>;
  UpdateDomainInput: ResolverTypeWrapper<any>;
  UpdateDomainPackageInput: ResolverTypeWrapper<any>;
  UpdateFeatureGroupInput: ResolverTypeWrapper<any>;
  UpdateFeatureInput: ResolverTypeWrapper<any>;
  UpdateMailTemplateContentInput: ResolverTypeWrapper<any>;
  UpdateMailTemplateContentTranslationInput: ResolverTypeWrapper<any>;
  UpdateMailTemplateInput: ResolverTypeWrapper<any>;
  UpdateMailTemplateTranslationInput: ResolverTypeWrapper<any>;
  UpdateMembersRoleInput: ResolverTypeWrapper<any>;
  UpdateMembersWithNewRoleInput: ResolverTypeWrapper<any>;
  UpdatePasswordInput: ResolverTypeWrapper<any>;
  UpdatePermissionGroupInput: ResolverTypeWrapper<any>;
  UpdatePermissionInput: ResolverTypeWrapper<any>;
  UpdatePlanInput: ResolverTypeWrapper<any>;
  UpdateProfileInput: ResolverTypeWrapper<any>;
  UpdateProjectEmailSenderConfigInput: ResolverTypeWrapper<any>;
  UpdateProjectInput: ResolverTypeWrapper<any>;
  UpdateProjectPackageInput: ResolverTypeWrapper<any>;
  UpdateRoleInput: ResolverTypeWrapper<any>;
  UpdateSubscriptionInput: ResolverTypeWrapper<any>;
  UpdateTagInput: ResolverTypeWrapper<any>;
  UpdateTemplateInput: ResolverTypeWrapper<any>;
  UpdateTemplateTagsInput: ResolverTypeWrapper<any>;
  UpdateTemplateTypeInput: ResolverTypeWrapper<any>;
  UpdateUserInput: ResolverTypeWrapper<any>;
  UpdateUserProjectNotificationSettingsInput: ResolverTypeWrapper<any>;
  UpdateWorkspaceClientInput: ResolverTypeWrapper<any>;
  UpdateWorkspaceInput: ResolverTypeWrapper<any>;
  UpdateWorkspaceMembersRoleInput: ResolverTypeWrapper<any>;
  UpdateWorkspaceMembersWithNewRoleInput: ResolverTypeWrapper<any>;
  UpdateWorkspacePackageInput: ResolverTypeWrapper<any>;
  UpdateWorkspacePermissionGroupInput: ResolverTypeWrapper<any>;
  UpdateWorkspaceRoleInput: ResolverTypeWrapper<any>;
  Upload: ResolverTypeWrapper<any>;
  User: ResolverTypeWrapper<any>;
  UserAgent: ResolverTypeWrapper<any>;
  UserGroup: ResolverTypeWrapper<any>;
  UserProjectNotificationSetting: ResolverTypeWrapper<any>;
  UserProjectNotificationSettingsInput: ResolverTypeWrapper<any>;
  UserProjectsOrderByOrderByClause: ResolverTypeWrapper<any>;
  UserSubscriptionsOrderByOrderByClause: ResolverTypeWrapper<any>;
  UserTermsInput: ResolverTypeWrapper<any>;
  UserTermsTrashedStatus: ResolverTypeWrapper<any>;
  UserTransactionsOrderByOrderByClause: ResolverTypeWrapper<any>;
  UsersOrderByColumn: ResolverTypeWrapper<any>;
  UsersPaginator: ResolverTypeWrapper<any>;
  Workspace: ResolverTypeWrapper<any>;
  WorkspaceAccessRolesPivot: ResolverTypeWrapper<any>;
  WorkspaceClient: ResolverTypeWrapper<any>;
  WorkspaceClientCustomer: ResolverTypeWrapper<any>;
  WorkspaceClientInvoice: ResolverTypeWrapper<any>;
  WorkspaceClientInvoiceItem: ResolverTypeWrapper<any>;
  WorkspaceClientInvoiceItemCollection: ResolverTypeWrapper<any>;
  WorkspaceClientInvoiceItemPeriod: ResolverTypeWrapper<any>;
  WorkspaceClientInvoicePaginator: ResolverTypeWrapper<any>;
  WorkspaceClientInvoiceStatus: ResolverTypeWrapper<any>;
  WorkspaceClientPaginator: ResolverTypeWrapper<any>;
  WorkspaceClientPaymentStatus: ResolverTypeWrapper<any>;
  WorkspaceClientStatus: ResolverTypeWrapper<any>;
  WorkspaceClientSubscription: ResolverTypeWrapper<any>;
  WorkspaceClientSubscriptionItem: ResolverTypeWrapper<any>;
  WorkspaceClientSubscriptionItemCollection: ResolverTypeWrapper<any>;
  WorkspaceClientSubscriptionPaginator: ResolverTypeWrapper<any>;
  WorkspaceClientSubscriptionStatus: ResolverTypeWrapper<any>;
  WorkspaceClientsOrderByClause: ResolverTypeWrapper<any>;
  WorkspaceClientsOrderByColumn: ResolverTypeWrapper<any>;
  WorkspaceConnectedAccountStatus: ResolverTypeWrapper<any>;
  WorkspaceConnectedAccountType: ResolverTypeWrapper<any>;
  WorkspaceEntityCountLimitation: ResolverTypeWrapper<any>;
  WorkspaceInvitation: ResolverTypeWrapper<any>;
  WorkspaceLimitation: ResolverTypeWrapper<any>;
  WorkspaceLimitationInput: ResolverTypeWrapper<any>;
  WorkspaceLimitationType: ResolverTypeWrapper<any>;
  WorkspaceMember: ResolverTypeWrapper<any>;
  WorkspaceMembersInput: ResolverTypeWrapper<any>;
  WorkspaceMembersOrderByClause: ResolverTypeWrapper<any>;
  WorkspaceMembersOrderByColumn: ResolverTypeWrapper<any>;
  WorkspaceOrder: ResolverTypeWrapper<any>;
  WorkspaceOrderStatus: ResolverTypeWrapper<any>;
  WorkspacePackage: ResolverTypeWrapper<any>;
  WorkspacePackageFeature: ResolverTypeWrapper<any>;
  WorkspacePackageFeaturePaginator: ResolverTypeWrapper<any>;
  WorkspacePackagePaginator: ResolverTypeWrapper<any>;
  WorkspacePackageTermsInput: ResolverTypeWrapper<any>;
  WorkspacePaginator: ResolverTypeWrapper<any>;
  WorkspacePermission: ResolverTypeWrapper<any>;
  WorkspacePermissionGroup: ResolverTypeWrapper<any>;
  WorkspacePermissionGroupPaginator: ResolverTypeWrapper<any>;
  WorkspacePermissionGroupsOrderByClause: ResolverTypeWrapper<any>;
  WorkspacePermissionGroupsOrderByColumn: ResolverTypeWrapper<any>;
  WorkspacePlansQueryInput: ResolverTypeWrapper<any>;
  WorkspaceProjectOrderByClause: ResolverTypeWrapper<any>;
  WorkspaceProjectsPaginator: ResolverTypeWrapper<any>;
  WorkspaceRole: ResolverTypeWrapper<any>;
  WorkspaceRolesOrderByClause: ResolverTypeWrapper<any>;
  WorkspaceRolesOrderByColumn: ResolverTypeWrapper<any>;
  WorkspaceStatus: ResolverTypeWrapper<any>;
  WorkspaceTeamMember: ResolverTypeWrapper<any>;
  WorkspaceTeamSize: ResolverTypeWrapper<any>;
  WorkspaceTeamType: ResolverTypeWrapper<any>;
  WorkspaceTemplateOrderByClause: ResolverTypeWrapper<any>;
  WorkspaceTemplatesOrderByColumn: ResolverTypeWrapper<any>;
  WorkspaceTemplatesPaginator: ResolverTypeWrapper<any>;
  WorkspaceUserType: ResolverTypeWrapper<any>;
  WorkspaceUsersPaginator: ResolverTypeWrapper<any>;
  WorkspacesOrderByClause: ResolverTypeWrapper<any>;
  WorkspacesOrderByColumn: ResolverTypeWrapper<any>;
  WrongRecord: ResolverTypeWrapper<any>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AIBlock: any;
  AccountDeletionReason: any;
  ActivateSubscriptionInput: any;
  ActivateWorkspaceClientsInput: any;
  ActivityRecord: any;
  ActivityRecordFilter: any;
  ActivityRecordFilterClauseInput: any;
  ActivityRecordInput: any;
  ActivityRecordOrderByClauseInput: any;
  ActivityRecordPaginator: any;
  ActivityRecordsAvailableFilterInput: any;
  AdminCreateAIBlockInput: any;
  AdminCreateGlobalBlockInput: any;
  AdminDeleteDomainInput: any;
  AdminDeleteDomainsInput: any;
  AdminGetBlocksInput: any;
  AdminProjectPaginator: any;
  AdminTemplateInput: any;
  AdminUpdateAIBlockInput: any;
  AdminUpdateDomainInput: any;
  AdminUpdateGlobalBlockInput: any;
  AdminUpdateLocalBlockInput: any;
  AdminUpdateTemplateInput: any;
  AdminUpdateTemplateTagsInput: any;
  AllPackagesInput: any;
  AllProjectsInput: any;
  AllWorkspacesInput: any;
  ApplicationPackage: any;
  ApplicationPackagePaginator: any;
  ApplicationPackageReference: any;
  ApplicationPackageTermsInput: any;
  ApplicationPackageTranslationInput: any;
  ApplicationRole: any;
  ApplicationRoleType: any;
  Array: any;
  AttachAddonInput: any;
  AttachCouponInput: any;
  AttachFeatureInput: any;
  BillingDetails: any;
  BillingInfo: any;
  Block: any;
  BlockCategory: any;
  BlockInterface: any;
  BlockPaginator: any;
  Boolean: any;
  BulkActionResponse: any;
  BulkActionTeamInput: any;
  CancelSubscriptionInput: any;
  CheckDomainInput: any;
  CheckProjectCreationStatus: any;
  CheckProjectEmailSenderDomainSettingsInput: any;
  CheckoutDomainInput: any;
  Configuration: any;
  ConnectDomainInput: any;
  ConnectedDomain: any;
  Coupon: any;
  CouponPaginator: any;
  CouponTermsInput: any;
  CreateApplicationPackageInput: any;
  CreateApplicationPackageReferenceInput: any;
  CreateBillingDetailsInput: any;
  CreateCouponInput: any;
  CreateDomainPackageInput: any;
  CreateFeatureGroupInput: any;
  CreateFeatureInput: any;
  CreateMailTemplateInput: any;
  CreateMailTemplateTranslationInput: any;
  CreatePaymentMethodInput: any;
  CreatePermissionGroupInput: any;
  CreatePermissionInput: any;
  CreatePlanInput: any;
  CreateProjectInput: any;
  CreateProjectPackageInput: any;
  CreateRoleInput: any;
  CreateTagInput: any;
  CreateTagsInput: any;
  CreateTemplateTypeInput: any;
  CreateWorkspaceClientInput: any;
  CreateWorkspaceInput: any;
  CreateWorkspacePackageInput: any;
  CreateWorkspacePaymentMethodInput: any;
  CreateWorkspacePermissionGroupInput: any;
  CreateWorkspaceRoleInput: any;
  Date: any;
  DateTime: any;
  DateTimeUnix: any;
  DeleteAccountInput: any;
  DeleteApplicationPackageInput: any;
  DeleteApplicationPackageReferenceInput: any;
  DeleteApplicationPackagesInput: any;
  DeleteCouponsInput: any;
  DeleteDomainInput: any;
  DeleteDomainPackageInput: any;
  DeleteDomainPackagesInput: any;
  DeleteFeatureGroupInput: any;
  DeleteFeatureGroupsInput: any;
  DeleteFeatureInput: any;
  DeleteFeaturesInput: any;
  DeleteInvitationInput: any;
  DeleteMemberInput: any;
  DeletePermissionGroupInput: any;
  DeletePermissionInput: any;
  DeletePermissionsInput: any;
  DeletePlanInput: any;
  DeletePlansInput: any;
  DeleteProjectInput: any;
  DeleteProjectPackageInput: any;
  DeleteProjectPackagesInput: any;
  DeleteProjectWithoutPasswordInput: any;
  DeleteProjectsWithoutPasswordInput: any;
  DeleteRoleInput: any;
  DeleteTemplateTypeInput: any;
  DeleteUserInput: any;
  DeleteUsersInput: any;
  DeleteWorkspaceClientsInput: any;
  DeleteWorkspaceInvitationsInput: any;
  DeleteWorkspaceMemberInput: any;
  DeleteWorkspacePackageInput: any;
  DeleteWorkspacePackagesInput: any;
  DeleteWorkspacePermissionGroupInput: any;
  DeleteWorkspaceRoleInput: any;
  DeleteWorkspaceWithoutPasswordInput: any;
  DeleteWorkspacesWithoutPasswordInput: any;
  DetachAddonInput: any;
  DetachCouponInput: any;
  DetachFeatureInput: any;
  DisconnectDomainInput: any;
  DnsRecord: any;
  Domain: any;
  DomainBuyerInput: any;
  DomainCheckoutResponse: any;
  DomainFrequency: any;
  DomainInvoice: any;
  DomainPackage: any;
  DomainPackagePaginator: any;
  DomainPackageTermsInput: any;
  DomainPackageTranslationInput: any;
  DomainPaginator: any;
  DomainParamsData: any;
  DomainRegistrantData: any;
  DomainSearchResult: any;
  DomainTermsInput: any;
  DomainsInput: any;
  DuplicateRoleInput: any;
  DuplicateWorkspaceRoleInput: any;
  Email: any;
  EntityLimitation: any;
  ExtendSubscriptionInput: any;
  Feature: any;
  FeatureGroup: any;
  FeatureGroupPaginator: any;
  FeatureGroupTermsInput: any;
  FeatureGroupTranslationInput: any;
  FeatureInput: any;
  FeaturePaginator: any;
  FeatureTermsInput: any;
  FeatureTranslationInput: any;
  FilterTemplateTagInput: any;
  Float: any;
  GeographicInfo: any;
  GetWorkspaceClientInvoicesInput: any;
  GetWorkspaceClientSubscriptionsInput: any;
  GetWorkspaceClientsInput: any;
  GetWorkspaceClientsInvoicesInput: any;
  GetWorkspaceClientsSubscriptionsInput: any;
  GetWorkspacePermissionGroupsInput: any;
  GetWorkspaceProjectsInput: any;
  GetWorkspaceRolesInput: any;
  GetWorkspaceTemplatesInput: any;
  GetWorkspaceUsersInput: any;
  GetWorkspacesInput: any;
  GlobalBlock: any;
  Host: any;
  ID: any;
  Industry: any;
  Int: any;
  Invitation: any;
  InviteMembersInput: any;
  InviteMembersWithNewRoleInput: any;
  InviteWorkspaceMembersInput: any;
  InviteWorkspaceMembersWithNewRoleInput: any;
  Json: any;
  LeaveProjectInput: any;
  LightweightUser: any;
  Limitation: any;
  LimitationInput: any;
  LocalBlock: any;
  LocalesInfo: any;
  MailTemplate: any;
  MakeDomainDefaultInput: any;
  ManipulatePaymentMethodInput: any;
  ManipulateWorkspacePaymentMethodInput: any;
  Member: any;
  MembersInput: any;
  MembersOrderByClause: any;
  Membership: any;
  Mixed: any;
  Mutation: {};
  OrderByClause: any;
  Package: any;
  PackageGroup: any;
  PackageLimitedEntities: any;
  PackageLimitedEntityLimitation: any;
  PaginateActivityRecordInput: any;
  PaginatorInfo: any;
  PaymentMethod: any;
  PaymentToken: any;
  PaymentTokenResponse: any;
  Permission: any;
  PermissionAssignmentInput: any;
  PermissionGroup: any;
  PermissionGroupPaginator: any;
  PermissionPaginator: any;
  PermissionRemoveInput: any;
  PermissionRequest: any;
  PermissionTranslationInput: any;
  Plan: any;
  PlanAddonsOrderByOrderByClause: any;
  PlanCouponsOrderByOrderByClause: any;
  PlanFeature: any;
  PlanFeatureLimit: any;
  PlanPaginator: any;
  PlanPurchase: any;
  PlanPurchaseInfo: any;
  PlanQueryInput: any;
  PlanSubscription: any;
  PlanTermsInput: any;
  PlanTranslationInput: any;
  PlansQueryInput: any;
  Project: any;
  ProjectAccessRolesPivot: any;
  ProjectConfiguration: any;
  ProjectDomain: any;
  ProjectDomainPaginator: any;
  ProjectDomainsOrderByOrderByClause: any;
  ProjectEmailSenderConfig: any;
  ProjectEmailSenderDomainRecord: any;
  ProjectEmailSenderDomainSettings: any;
  ProjectFilters: any;
  ProjectPackage: any;
  ProjectPackageFeature: any;
  ProjectPackageFeaturePaginator: any;
  ProjectPackagePaginator: any;
  ProjectPackageTermsInput: any;
  ProjectPackageTranslationInput: any;
  ProjectPaginator: any;
  ProjectPermissionGroup: any;
  ProjectRole: any;
  ProjectTemplate: any;
  ProjectWorkspace: any;
  Purchase: any;
  PurchasePaginator: any;
  PurchaseSubscriptionsOrderByOrderByClause: any;
  PurchaseTermsInput: any;
  Query: {};
  QueryAdminBlocksOrderByOrderByClause: any;
  QueryAllDomainsOrderByOrderByClause: any;
  QueryAllFeatureGroupsOrderByOrderByClause: any;
  QueryAllPlansOrderByOrderByClause: any;
  QueryAllSubscriptionsOrderByOrderByClause: any;
  QueryAllWorkspacesOrderByOrderByClause: any;
  QueryApplicationPackagesOrderByOrderByClause: any;
  QueryCouponsOrderByOrderByClause: any;
  QueryDomainPackagesOrderByOrderByClause: any;
  QueryFeaturesOrderByOrderByClause: any;
  QueryPermissionGroupsOrderByOrderByClause: any;
  QueryPermissionsOrderByOrderByClause: any;
  QueryProjectPackagesOrderByOrderByClause: any;
  QueryProjectsOrderByOrderByClause: any;
  QueryPurchasesOrderByOrderByClause: any;
  QueryTemplateTagOrderOrderByClause: any;
  QueryTemplatesOrderOrderByClause: any;
  QueryTransactionsOrderByOrderByClause: any;
  QueryUsersOrderOrderByClause: any;
  QueryWorkspacePackagesOrderByOrderByClause: any;
  RelatedUser: any;
  RelatedUserPaginator: any;
  RequestPermissionInput: any;
  RequestedPermissionsInput: any;
  ResaveTemplateInput: any;
  Role: any;
  RoleAssignmentInput: any;
  RolesOrderByClause: any;
  SaveAsTemplateInput: any;
  SendInstructionInput: any;
  SetPasswordInput: any;
  SetupInitialPaymentInput: any;
  SingleRoleObject: any;
  SocialItem: any;
  SocialItemInput: any;
  SqlServer: any;
  String: any;
  SubscriptionDetails: any;
  SubscriptionService: any;
  SubscriptionServicePaginated: any;
  SubscriptionServicePaginator: any;
  SubscriptionTermsInput: any;
  SubstitutesInput: any;
  TeamMember: any;
  Template: any;
  TemplateInput: any;
  TemplatePaginator: any;
  TemplateTag: any;
  TemplateTagInput: any;
  TemplateTagPaginator: any;
  TemplateType: any;
  TemplateTypePaginator: any;
  TemplateTypeTranslationInput: any;
  Transaction: any;
  TransactionPaginated: any;
  TransactionPaginator: any;
  TransferProjectInput: any;
  TransferProjectOwnershipInput: any;
  URL: any;
  UnsetPasswordInput: any;
  UpdateApplicationPackageInput: any;
  UpdateApplicationPackageReferenceInput: any;
  UpdateBillingDetailsInput: any;
  UpdateCouponInput: any;
  UpdateCurrentPlanInput: any;
  UpdateCurrentWorkspacePlanInput: any;
  UpdateDomainInput: any;
  UpdateDomainPackageInput: any;
  UpdateFeatureGroupInput: any;
  UpdateFeatureInput: any;
  UpdateMailTemplateContentInput: any;
  UpdateMailTemplateContentTranslationInput: any;
  UpdateMailTemplateInput: any;
  UpdateMailTemplateTranslationInput: any;
  UpdateMembersRoleInput: any;
  UpdateMembersWithNewRoleInput: any;
  UpdatePasswordInput: any;
  UpdatePermissionGroupInput: any;
  UpdatePermissionInput: any;
  UpdatePlanInput: any;
  UpdateProfileInput: any;
  UpdateProjectEmailSenderConfigInput: any;
  UpdateProjectInput: any;
  UpdateProjectPackageInput: any;
  UpdateRoleInput: any;
  UpdateSubscriptionInput: any;
  UpdateTagInput: any;
  UpdateTemplateInput: any;
  UpdateTemplateTagsInput: any;
  UpdateTemplateTypeInput: any;
  UpdateUserInput: any;
  UpdateUserProjectNotificationSettingsInput: any;
  UpdateWorkspaceClientInput: any;
  UpdateWorkspaceInput: any;
  UpdateWorkspaceMembersRoleInput: any;
  UpdateWorkspaceMembersWithNewRoleInput: any;
  UpdateWorkspacePackageInput: any;
  UpdateWorkspacePermissionGroupInput: any;
  UpdateWorkspaceRoleInput: any;
  Upload: any;
  User: any;
  UserAgent: any;
  UserProjectNotificationSetting: any;
  UserProjectNotificationSettingsInput: any;
  UserProjectsOrderByOrderByClause: any;
  UserSubscriptionsOrderByOrderByClause: any;
  UserTermsInput: any;
  UserTransactionsOrderByOrderByClause: any;
  UsersPaginator: any;
  Workspace: any;
  WorkspaceAccessRolesPivot: any;
  WorkspaceClient: any;
  WorkspaceClientCustomer: any;
  WorkspaceClientInvoice: any;
  WorkspaceClientInvoiceItem: any;
  WorkspaceClientInvoiceItemCollection: any;
  WorkspaceClientInvoiceItemPeriod: any;
  WorkspaceClientInvoicePaginator: any;
  WorkspaceClientPaginator: any;
  WorkspaceClientSubscription: any;
  WorkspaceClientSubscriptionItem: any;
  WorkspaceClientSubscriptionItemCollection: any;
  WorkspaceClientSubscriptionPaginator: any;
  WorkspaceClientsOrderByClause: any;
  WorkspaceEntityCountLimitation: any;
  WorkspaceInvitation: any;
  WorkspaceLimitation: any;
  WorkspaceLimitationInput: any;
  WorkspaceMember: any;
  WorkspaceMembersInput: any;
  WorkspaceMembersOrderByClause: any;
  WorkspaceOrder: any;
  WorkspacePackage: any;
  WorkspacePackageFeature: any;
  WorkspacePackageFeaturePaginator: any;
  WorkspacePackagePaginator: any;
  WorkspacePackageTermsInput: any;
  WorkspacePaginator: any;
  WorkspacePermission: any;
  WorkspacePermissionGroup: any;
  WorkspacePermissionGroupPaginator: any;
  WorkspacePermissionGroupsOrderByClause: any;
  WorkspacePlansQueryInput: any;
  WorkspaceProjectOrderByClause: any;
  WorkspaceProjectsPaginator: any;
  WorkspaceRole: any;
  WorkspaceRolesOrderByClause: any;
  WorkspaceTeamMember: any;
  WorkspaceTeamSize: any;
  WorkspaceTeamType: any;
  WorkspaceTemplateOrderByClause: any;
  WorkspaceTemplatesPaginator: any;
  WorkspaceUsersPaginator: any;
  WorkspacesOrderByClause: any;
  WrongRecord: any;
};

export type AiBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['AIBlock'] = ResolversParentTypes['AIBlock']> = {
  categories?: Resolver<Array<ResolversTypes['BlockCategory']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industries?: Resolver<Array<ResolversTypes['Industry']>, ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['BlockScope'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountDeletionReasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountDeletionReason'] = ResolversParentTypes['AccountDeletionReason']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityRecord'] = ResolversParentTypes['ActivityRecord']> = {
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geographicInfo?: Resolver<Maybe<ResolversTypes['GeographicInfo']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['LightweightUser']>, ParentType, ContextType>;
  userAgent?: Resolver<Maybe<ResolversTypes['UserAgent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityRecordFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityRecordFilter'] = ResolversParentTypes['ActivityRecordFilter']> = {
  filter?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityRecordPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityRecordPaginator'] = ResolversParentTypes['ActivityRecordPaginator']> = {
  data?: Resolver<Array<ResolversTypes['ActivityRecord']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminProjectPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminProjectPaginator'] = ResolversParentTypes['AdminProjectPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Project']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationPackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationPackage'] = ResolversParentTypes['ApplicationPackage']> = {
  agreement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  applicationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<ResolversTypes['FeaturePaginator'], ParentType, ContextType, RequireFields<ApplicationPackageFeaturesArgs, 'first'>>;
  featuresText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['Limitation']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  packageGroups?: Resolver<Maybe<Array<ResolversTypes['PackageGroup']>>, ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
  projectRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  public?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationPackagePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationPackagePaginator'] = ResolversParentTypes['ApplicationPackagePaginator']> = {
  data?: Resolver<Array<ResolversTypes['ApplicationPackage']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationPackageReferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationPackageReference'] = ResolversParentTypes['ApplicationPackageReference']> = {
  applicationPackage?: Resolver<ResolversTypes['Package'], ParentType, ContextType>;
  applicationPackageId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  packageable?: Resolver<Maybe<ResolversTypes['Package']>, ParentType, ContextType>;
  packageableId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  packageableType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationRoleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationRoleType'] = ResolversParentTypes['ApplicationRoleType']> = {
  applicationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ArrayScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Array'], any> {
  name: 'Array';
}

export type BillingDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingDetails'] = ResolversParentTypes['BillingDetails']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zipCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingInfo'] = ResolversParentTypes['BillingInfo']> = {
  intent?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['Block'] = ResolversParentTypes['Block']> = {
  __resolveType: TypeResolveFn<'AIBlock' | 'GlobalBlock' | 'LocalBlock', ParentType, ContextType>;
};

export type BlockCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlockCategory'] = ResolversParentTypes['BlockCategory']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlockInterface'] = ResolversParentTypes['BlockInterface']> = {
  __resolveType: TypeResolveFn<'AIBlock' | 'GlobalBlock' | 'LocalBlock', ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['BlockCategory']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['BlockScope'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type BlockPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlockPaginator'] = ResolversParentTypes['BlockPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Block']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkActionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkActionResponse'] = ResolversParentTypes['BulkActionResponse']> = {
  entity?: Resolver<ResolversTypes['BulkActionEntity'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CheckProjectCreationStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckProjectCreationStatus'] = ResolversParentTypes['CheckProjectCreationStatus']> = {
  creationStatus?: Resolver<ResolversTypes['ProjectCreationStatus'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Configuration'] = ResolversParentTypes['Configuration']> = {
  project?: Resolver<ResolversTypes['ProjectConfiguration'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectedDomainResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConnectedDomain'] = ResolversParentTypes['ConnectedDomain']> = {
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBoughtFromUs?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coupon'] = ResolversParentTypes['Coupon']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  connectedToReferralSystem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfCoupons?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  percent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plans?: Resolver<Maybe<Array<ResolversTypes['Plan']>>, ParentType, ContextType>;
  prorate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  redeem?: Resolver<ResolversTypes['CouponRedeemType'], ParentType, ContextType>;
  usedCouponsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponPaginator'] = ResolversParentTypes['CouponPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Coupon']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DateTimeUnixScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTimeUnix'], any> {
  name: 'DateTimeUnix';
}

export type DnsRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['DnsRecord'] = ResolversParentTypes['DnsRecord']> = {
  connected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainResolvers<ContextType = any, ParentType extends ResolversParentTypes['Domain'] = ResolversParentTypes['Domain']> = {
  creator?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dnsRecords?: Resolver<Maybe<Array<ResolversTypes['DnsRecord']>>, ParentType, ContextType>;
  dnsVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isChecked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubDomain?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mailbox?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  params?: Resolver<Maybe<Array<ResolversTypes['DomainParamsData']>>, ParentType, ContextType>;
  projectNativeHost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase?: Resolver<Maybe<ResolversTypes['Purchase']>, ParentType, ContextType>;
  redirectToWww?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  registrantData?: Resolver<Maybe<Array<ResolversTypes['DomainRegistrantData']>>, ParentType, ContextType>;
  ssl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DomainStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DomainType'], ParentType, ContextType>;
  wrongRecords?: Resolver<Maybe<Array<Maybe<ResolversTypes['WrongRecord']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainCheckoutResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainCheckoutResponse'] = ResolversParentTypes['DomainCheckoutResponse']> = {
  invoices?: Resolver<Array<ResolversTypes['DomainInvoice']>, ParentType, ContextType>;
  paymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainFrequencyResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainFrequency'] = ResolversParentTypes['DomainFrequency']> = {
  frequency?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainInvoice'] = ResolversParentTypes['DomainInvoice']> = {
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  old?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainPackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainPackage'] = ResolversParentTypes['DomainPackage']> = {
  agreement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<ResolversTypes['FeaturePaginator'], ParentType, ContextType, RequireFields<DomainPackageFeaturesArgs, 'first'>>;
  featuresText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  packageGroups?: Resolver<Maybe<Array<ResolversTypes['PackageGroup']>>, ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  projectRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  public?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainPackagePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainPackagePaginator'] = ResolversParentTypes['DomainPackagePaginator']> = {
  data?: Resolver<Array<ResolversTypes['DomainPackage']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainPaginator'] = ResolversParentTypes['DomainPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Domain']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainParamsDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainParamsData'] = ResolversParentTypes['DomainParamsData']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainRegistrantDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainRegistrantData'] = ResolversParentTypes['DomainRegistrantData']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainSearchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainSearchResult'] = ResolversParentTypes['DomainSearchResult']> = {
  available?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  billingFrequencies?: Resolver<Array<ResolversTypes['DomainFrequency']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencySymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  domainName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Email'], any> {
  name: 'Email';
}

export type EntityLimitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityLimitation'] = ResolversParentTypes['EntityLimitation']> = {
  entity?: Resolver<ResolversTypes['LimitationType'], ParentType, ContextType>;
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['Feature'] = ResolversParentTypes['Feature']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['FeatureGroup']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  packageType?: Resolver<ResolversTypes['PackageType'], ParentType, ContextType>;
  selected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeatureGroup'] = ResolversParentTypes['FeatureGroup']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  features?: Resolver<Array<ResolversTypes['Feature']>, ParentType, ContextType, Partial<FeatureGroupFeaturesArgs>>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureGroupPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeatureGroupPaginator'] = ResolversParentTypes['FeatureGroupPaginator']> = {
  data?: Resolver<Array<ResolversTypes['FeatureGroup']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeaturePaginator'] = ResolversParentTypes['FeaturePaginator']> = {
  data?: Resolver<Array<ResolversTypes['Feature']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeographicInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeographicInfo'] = ResolversParentTypes['GeographicInfo']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ip?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlobalBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlobalBlock'] = ResolversParentTypes['GlobalBlock']> = {
  categories?: Resolver<Array<ResolversTypes['BlockCategory']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industries?: Resolver<Array<ResolversTypes['Industry']>, ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['BlockScope'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HostResolvers<ContextType = any, ParentType extends ResolversParentTypes['Host'] = ResolversParentTypes['Host']> = {
  __resolveType: TypeResolveFn<'Project' | 'User' | 'Workspace', ParentType, ContextType>;
};

export type IndustryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Industry'] = ResolversParentTypes['Industry']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invitation'] = ResolversParentTypes['Invitation']> = {
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType, RequireFields<InvitationRoleArgs, 'projectId'>>;
  status?: Resolver<ResolversTypes['MembershipStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Json'], any> {
  name: 'Json';
}

export type LightweightUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['LightweightUser'] = ResolversParentTypes['LightweightUser']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LimitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Limitation'] = ResolversParentTypes['Limitation']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entity?: Resolver<ResolversTypes['LimitationType'], ParentType, ContextType>;
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  limitReached?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocalBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocalBlock'] = ResolversParentTypes['LocalBlock']> = {
  categories?: Resolver<Array<ResolversTypes['BlockCategory']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['BlockScope'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocalesInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocalesInfo'] = ResolversParentTypes['LocalesInfo']> = {
  availableLocales?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  availableLocalesWithCountries?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  defaultLocale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fallbackLocale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MailTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MailTemplate'] = ResolversParentTypes['MailTemplate']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bodyObject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  package?: Resolver<Maybe<ResolversTypes['Package']>, ParentType, ContextType>;
  packageId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  packageType?: Resolver<Maybe<ResolversTypes['PackageType']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  variables?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['Member'] = ResolversParentTypes['Member']> = {
  __resolveType: TypeResolveFn<'Invitation' | 'TeamMember', ParentType, ContextType>;
};

export type MembershipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Membership'] = ResolversParentTypes['Membership']> = {
  __resolveType: TypeResolveFn<'Invitation' | 'TeamMember' | 'WorkspaceInvitation' | 'WorkspaceTeamMember', ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MembershipStatus'], ParentType, ContextType>;
};

export interface MixedScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Mixed'], any> {
  name: 'Mixed';
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  activateSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationActivateSubscriptionArgs, 'input'>>;
  activateWorkspaceClients?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationActivateWorkspaceClientsArgs, 'input'>>;
  activateWorkspaceSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationActivateWorkspaceSubscriptionArgs, 'input'>>;
  adminCreateAIBlock?: Resolver<ResolversTypes['AIBlock'], ParentType, ContextType, RequireFields<MutationAdminCreateAiBlockArgs, 'input'>>;
  adminCreateGlobalBlock?: Resolver<ResolversTypes['GlobalBlock'], ParentType, ContextType, RequireFields<MutationAdminCreateGlobalBlockArgs, 'input'>>;
  adminDeleteDomain?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAdminDeleteDomainArgs, 'input'>>;
  adminDeleteDomains?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationAdminDeleteDomainsArgs, 'input'>>;
  adminRemoveBlock?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAdminRemoveBlockArgs, 'id'>>;
  adminUpdateAIBlock?: Resolver<ResolversTypes['AIBlock'], ParentType, ContextType, RequireFields<MutationAdminUpdateAiBlockArgs, 'input'>>;
  adminUpdateDomain?: Resolver<ResolversTypes['Domain'], ParentType, ContextType, RequireFields<MutationAdminUpdateDomainArgs, 'input'>>;
  adminUpdateGlobalBlock?: Resolver<ResolversTypes['GlobalBlock'], ParentType, ContextType, RequireFields<MutationAdminUpdateGlobalBlockArgs, 'input'>>;
  adminUpdateLocalBlock?: Resolver<ResolversTypes['LocalBlock'], ParentType, ContextType, RequireFields<MutationAdminUpdateLocalBlockArgs, 'input'>>;
  adminUpdateProject?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<MutationAdminUpdateProjectArgs, 'input'>>;
  assignPermissionToUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAssignPermissionToUserArgs, 'input'>>;
  assignRoleToUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAssignRoleToUserArgs, 'input'>>;
  attachAddon?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationAttachAddonArgs, 'input'>>;
  attachCoupon?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationAttachCouponArgs, 'input'>>;
  attachFeatureToDomainPackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAttachFeatureToDomainPackageArgs, 'input'>>;
  attachFeatureToProjectPackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAttachFeatureToProjectPackageArgs, 'input'>>;
  attachFeatureToWorkspacePackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAttachFeatureToWorkspacePackageArgs, 'input'>>;
  bulkDeleteMember?: Resolver<ResolversTypes['BulkActionResponse'], ParentType, ContextType, RequireFields<MutationBulkDeleteMemberArgs, 'input'>>;
  cancelSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelSubscriptionArgs, 'input'>>;
  cancelWorkspaceSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelWorkspaceSubscriptionArgs, 'input'>>;
  checkoutDomain?: Resolver<ResolversTypes['DomainCheckoutResponse'], ParentType, ContextType, RequireFields<MutationCheckoutDomainArgs, 'input'>>;
  connectDomain?: Resolver<ResolversTypes['Domain'], ParentType, ContextType, RequireFields<MutationConnectDomainArgs, 'input'>>;
  createApplicationPackage?: Resolver<ResolversTypes['ApplicationPackage'], ParentType, ContextType, RequireFields<MutationCreateApplicationPackageArgs, 'input'>>;
  createApplicationPackageReference?: Resolver<ResolversTypes['ApplicationPackageReference'], ParentType, ContextType, RequireFields<MutationCreateApplicationPackageReferenceArgs, 'input'>>;
  createBillingDetails?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateBillingDetailsArgs, 'input'>>;
  createCoupon?: Resolver<ResolversTypes['Coupon'], ParentType, ContextType, RequireFields<MutationCreateCouponArgs, 'input'>>;
  createDomainPackage?: Resolver<ResolversTypes['DomainPackage'], ParentType, ContextType, RequireFields<MutationCreateDomainPackageArgs, 'input'>>;
  createFeature?: Resolver<ResolversTypes['Feature'], ParentType, ContextType, RequireFields<MutationCreateFeatureArgs, 'input'>>;
  createFeatureGroup?: Resolver<ResolversTypes['FeatureGroup'], ParentType, ContextType, RequireFields<MutationCreateFeatureGroupArgs, 'input'>>;
  createMailTemplate?: Resolver<Maybe<ResolversTypes['MailTemplate']>, ParentType, ContextType, Partial<MutationCreateMailTemplateArgs>>;
  createPaymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType, RequireFields<MutationCreatePaymentMethodArgs, 'input'>>;
  createPermissionGroup?: Resolver<ResolversTypes['PermissionGroup'], ParentType, ContextType, RequireFields<MutationCreatePermissionGroupArgs, 'input'>>;
  createPlan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationCreatePlanArgs, 'input'>>;
  createProject?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<MutationCreateProjectArgs, 'input'>>;
  createProjectPackage?: Resolver<ResolversTypes['ProjectPackage'], ParentType, ContextType, RequireFields<MutationCreateProjectPackageArgs, 'input'>>;
  createRole?: Resolver<ResolversTypes['Role'], ParentType, ContextType, RequireFields<MutationCreateRoleArgs, 'input'>>;
  createTag?: Resolver<ResolversTypes['TemplateTag'], ParentType, ContextType, RequireFields<MutationCreateTagArgs, 'input'>>;
  createTags?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateTagsArgs, 'input'>>;
  createTemplateType?: Resolver<ResolversTypes['TemplateType'], ParentType, ContextType, RequireFields<MutationCreateTemplateTypeArgs, 'input'>>;
  createWorkspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType, RequireFields<MutationCreateWorkspaceArgs, 'input'>>;
  createWorkspaceClient?: Resolver<ResolversTypes['WorkspaceClient'], ParentType, ContextType, RequireFields<MutationCreateWorkspaceClientArgs, 'input'>>;
  createWorkspacePackage?: Resolver<ResolversTypes['WorkspacePackage'], ParentType, ContextType, RequireFields<MutationCreateWorkspacePackageArgs, 'input'>>;
  createWorkspacePaymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType, RequireFields<MutationCreateWorkspacePaymentMethodArgs, 'input'>>;
  createWorkspaceProject?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<MutationCreateWorkspaceProjectArgs, 'input' | 'workspaceName'>>;
  createWorkspaceRole?: Resolver<ResolversTypes['WorkspaceRole'], ParentType, ContextType, RequireFields<MutationCreateWorkspaceRoleArgs, 'input'>>;
  deleteAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteAccountArgs, 'input'>>;
  deleteApplicationPackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteApplicationPackageArgs, 'input'>>;
  deleteApplicationPackageReference?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteApplicationPackageReferenceArgs, 'input'>>;
  deleteApplicationPackages?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDeleteApplicationPackagesArgs, 'input'>>;
  deleteCoupon?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteCouponArgs, 'id'>>;
  deleteCoupons?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDeleteCouponsArgs, 'input'>>;
  deleteDomain?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteDomainArgs, 'input'>>;
  deleteDomainPackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteDomainPackageArgs, 'input'>>;
  deleteDomainPackages?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDeleteDomainPackagesArgs, 'input'>>;
  deleteFeature?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteFeatureArgs, 'input'>>;
  deleteFeatureGroup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteFeatureGroupArgs, 'input'>>;
  deleteFeatureGroups?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDeleteFeatureGroupsArgs, 'input'>>;
  deleteFeatures?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDeleteFeaturesArgs, 'input'>>;
  deleteInvitation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteInvitationArgs, 'input'>>;
  deleteMember?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteMemberArgs, 'input'>>;
  deletePaymentMethod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeletePaymentMethodArgs, 'input'>>;
  deletePermissionGroup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeletePermissionGroupArgs, 'input'>>;
  deletePlan?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeletePlanArgs, 'input'>>;
  deletePlans?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationDeletePlansArgs, 'input'>>;
  deleteProject?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteProjectArgs, 'input'>>;
  deleteProjectPackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteProjectPackageArgs, 'input'>>;
  deleteProjectPackages?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDeleteProjectPackagesArgs, 'input'>>;
  deleteProjectWithoutPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteProjectWithoutPasswordArgs, 'input'>>;
  deleteProjectsWithoutPassword?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDeleteProjectsWithoutPasswordArgs, 'input'>>;
  deleteRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteRoleArgs, 'input'>>;
  deleteTag?: Resolver<ResolversTypes['TemplateTag'], ParentType, ContextType, RequireFields<MutationDeleteTagArgs, 'id'>>;
  deleteTemplateType?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteTemplateTypeArgs, 'input'>>;
  deleteUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'input'>>;
  deleteUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDeleteUsersArgs, 'input'>>;
  deleteWorkspaceClients?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteWorkspaceClientsArgs, 'input'>>;
  deleteWorkspaceInvitations?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteWorkspaceInvitationsArgs, 'input'>>;
  deleteWorkspaceMember?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteWorkspaceMemberArgs, 'input'>>;
  deleteWorkspacePackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteWorkspacePackageArgs, 'input'>>;
  deleteWorkspacePackages?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDeleteWorkspacePackagesArgs, 'input'>>;
  deleteWorkspacePaymentMethod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteWorkspacePaymentMethodArgs, 'input'>>;
  deleteWorkspaceProject?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteWorkspaceProjectArgs, 'input'>>;
  deleteWorkspaceRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteWorkspaceRoleArgs, 'input'>>;
  deleteWorkspaceTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteWorkspaceTemplateArgs, 'id'>>;
  detachAddon?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationDetachAddonArgs, 'input'>>;
  detachCoupon?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationDetachCouponArgs, 'input'>>;
  detachFeatureFromDomainPackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDetachFeatureFromDomainPackageArgs, 'input'>>;
  detachFeatureFromProjectPackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDetachFeatureFromProjectPackageArgs, 'input'>>;
  detachFeatureFromWorkspacePackage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDetachFeatureFromWorkspacePackageArgs, 'input'>>;
  disconnectDomain?: Resolver<ResolversTypes['Domain'], ParentType, ContextType, RequireFields<MutationDisconnectDomainArgs, 'input'>>;
  duplicateRole?: Resolver<ResolversTypes['Role'], ParentType, ContextType, RequireFields<MutationDuplicateRoleArgs, 'input'>>;
  duplicateWorkspaceRole?: Resolver<ResolversTypes['WorkspaceRole'], ParentType, ContextType, RequireFields<MutationDuplicateWorkspaceRoleArgs, 'input'>>;
  extendSubscription?: Resolver<Maybe<ResolversTypes['SubscriptionService']>, ParentType, ContextType, RequireFields<MutationExtendSubscriptionArgs, 'input'>>;
  extendWorkspaceSubscription?: Resolver<Maybe<ResolversTypes['SubscriptionService']>, ParentType, ContextType, RequireFields<MutationExtendWorkspaceSubscriptionArgs, 'input'>>;
  forceDeleteUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationForceDeleteUserArgs, 'input'>>;
  forceDeleteUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationForceDeleteUsersArgs, 'input'>>;
  generateProjectName?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationGenerateProjectNameArgs, 'name'>>;
  generateWorkspaceName?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationGenerateWorkspaceNameArgs, 'name'>>;
  inviteMembers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationInviteMembersArgs, 'input'>>;
  inviteMembersWithNewRole?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationInviteMembersWithNewRoleArgs, 'input'>>;
  inviteWorkspaceMembers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationInviteWorkspaceMembersArgs, 'input'>>;
  inviteWorkspaceMembersWithNewRole?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationInviteWorkspaceMembersWithNewRoleArgs, 'input'>>;
  leaveProject?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationLeaveProjectArgs, 'input'>>;
  makeDomainDefault?: Resolver<ResolversTypes['Domain'], ParentType, ContextType, RequireFields<MutationMakeDomainDefaultArgs, 'input'>>;
  removeAvatar?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  removeOwnTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveOwnTemplateArgs, 'id'>>;
  removePermissionFromUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemovePermissionFromUserArgs, 'input'>>;
  removeRoleFromUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveRoleFromUserArgs, 'input'>>;
  removeTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveTemplateArgs, 'id'>>;
  removeTemplateThumbnail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveTemplateThumbnailArgs, 'id'>>;
  requestPermission?: Resolver<ResolversTypes['PermissionRequest'], ParentType, ContextType, RequireFields<MutationRequestPermissionArgs, 'input'>>;
  resaveTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationResaveTemplateArgs, 'input'>>;
  saveAsTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationSaveAsTemplateArgs, 'input'>>;
  saveWorkspaceProjectAsTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationSaveWorkspaceProjectAsTemplateArgs, 'input'>>;
  setDefaultPaymentMethod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetDefaultPaymentMethodArgs, 'input'>>;
  setPassword?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<MutationSetPasswordArgs, 'input'>>;
  setWorkspaceDefaultPaymentMethod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetWorkspaceDefaultPaymentMethodArgs, 'input'>>;
  setupInitialPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetupInitialPaymentArgs, 'input'>>;
  transferProject?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationTransferProjectArgs, 'input'>>;
  transferProjectOwnership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationTransferProjectOwnershipArgs, 'input'>>;
  unsetPassword?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<MutationUnsetPasswordArgs, 'input'>>;
  updateApplicationPackage?: Resolver<ResolversTypes['ApplicationPackage'], ParentType, ContextType, RequireFields<MutationUpdateApplicationPackageArgs, 'input'>>;
  updateApplicationPackageReference?: Resolver<ResolversTypes['ApplicationPackageReference'], ParentType, ContextType, RequireFields<MutationUpdateApplicationPackageReferenceArgs, 'input'>>;
  updateBillingDetails?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateBillingDetailsArgs, 'input'>>;
  updateCoupon?: Resolver<ResolversTypes['Coupon'], ParentType, ContextType, RequireFields<MutationUpdateCouponArgs, 'input'>>;
  updateCurrentPlan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationUpdateCurrentPlanArgs, 'input'>>;
  updateCurrentWorkspacePlan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationUpdateCurrentWorkspacePlanArgs, 'input'>>;
  updateDomain?: Resolver<ResolversTypes['Domain'], ParentType, ContextType, RequireFields<MutationUpdateDomainArgs, 'input'>>;
  updateDomainPackage?: Resolver<ResolversTypes['DomainPackage'], ParentType, ContextType, RequireFields<MutationUpdateDomainPackageArgs, 'input'>>;
  updateFeature?: Resolver<ResolversTypes['Feature'], ParentType, ContextType, RequireFields<MutationUpdateFeatureArgs, 'input'>>;
  updateFeatureGroup?: Resolver<ResolversTypes['FeatureGroup'], ParentType, ContextType, RequireFields<MutationUpdateFeatureGroupArgs, 'input'>>;
  updateMailTemplate?: Resolver<Maybe<ResolversTypes['MailTemplate']>, ParentType, ContextType, Partial<MutationUpdateMailTemplateArgs>>;
  updateMailTemplateContent?: Resolver<Maybe<ResolversTypes['MailTemplate']>, ParentType, ContextType, Partial<MutationUpdateMailTemplateContentArgs>>;
  updateMembersRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateMembersRoleArgs, 'input'>>;
  updateMembersWithNewRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateMembersWithNewRoleArgs, 'input'>>;
  updateOwnTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateOwnTemplateArgs, 'input'>>;
  updateOwnTemplateTags?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateOwnTemplateTagsArgs, 'input'>>;
  updatePassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'input'>>;
  updatePermission?: Resolver<ResolversTypes['Permission'], ParentType, ContextType, RequireFields<MutationUpdatePermissionArgs, 'input'>>;
  updatePermissionGroup?: Resolver<ResolversTypes['PermissionGroup'], ParentType, ContextType, RequireFields<MutationUpdatePermissionGroupArgs, 'input'>>;
  updatePlan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationUpdatePlanArgs, 'input'>>;
  updateProfile?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateProfileArgs, 'input'>>;
  updateProject?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<MutationUpdateProjectArgs, 'input'>>;
  updateProjectEmailSenderConfig?: Resolver<ResolversTypes['ProjectEmailSenderConfig'], ParentType, ContextType, RequireFields<MutationUpdateProjectEmailSenderConfigArgs, 'input'>>;
  updateProjectPackage?: Resolver<ResolversTypes['ProjectPackage'], ParentType, ContextType, RequireFields<MutationUpdateProjectPackageArgs, 'input'>>;
  updateRole?: Resolver<ResolversTypes['Role'], ParentType, ContextType, RequireFields<MutationUpdateRoleArgs, 'input'>>;
  updateSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateSubscriptionArgs, 'input'>>;
  updateTag?: Resolver<ResolversTypes['TemplateTag'], ParentType, ContextType, RequireFields<MutationUpdateTagArgs, 'input'>>;
  updateTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateTemplateArgs, 'input'>>;
  updateTemplateTags?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateTemplateTagsArgs, 'input'>>;
  updateTemplateType?: Resolver<ResolversTypes['TemplateType'], ParentType, ContextType, RequireFields<MutationUpdateTemplateTypeArgs, 'input'>>;
  updateUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, Partial<MutationUpdateUserArgs>>;
  updateUserProjectNotificationSettings?: Resolver<ResolversTypes['UserProjectNotificationSetting'], ParentType, ContextType, RequireFields<MutationUpdateUserProjectNotificationSettingsArgs, 'input'>>;
  updateWorkspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceArgs, 'input'>>;
  updateWorkspaceClient?: Resolver<ResolversTypes['WorkspaceClient'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceClientArgs, 'input'>>;
  updateWorkspaceMembersRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceMembersRoleArgs, 'input'>>;
  updateWorkspaceMembersWithNewRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceMembersWithNewRoleArgs, 'input'>>;
  updateWorkspacePackage?: Resolver<ResolversTypes['WorkspacePackage'], ParentType, ContextType, RequireFields<MutationUpdateWorkspacePackageArgs, 'input'>>;
  updateWorkspaceRole?: Resolver<ResolversTypes['WorkspaceRole'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceRoleArgs, 'input'>>;
  updateWorkspaceSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceSubscriptionArgs, 'input'>>;
  updateWorkspaceTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceTemplateArgs, 'input'>>;
  verifyAccountDeletion?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
};

export type PackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Package'] = ResolversParentTypes['Package']> = {
  agreement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  limitations?: Resolver<Array<ResolversTypes['EntityLimitation']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packageGroups?: Resolver<Maybe<Array<ResolversTypes['PackageGroup']>>, ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  projectRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  public?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PackageType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackageGroup'] = ResolversParentTypes['PackageGroup']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordering?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageLimitedEntitiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackageLimitedEntities'] = ResolversParentTypes['PackageLimitedEntities']> = {
  domain?: Resolver<Maybe<Array<ResolversTypes['PackageLimitedDomain']>>, ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['PackageLimitedEntityLimitation']>>, ParentType, ContextType>;
  payment?: Resolver<Maybe<Array<ResolversTypes['PackageLimitedPayment']>>, ParentType, ContextType>;
  product?: Resolver<Maybe<Array<ResolversTypes['PackageLimitedProduct']>>, ParentType, ContextType>;
  shipping?: Resolver<Maybe<Array<ResolversTypes['PackageLimitedShipping']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageLimitedEntityLimitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackageLimitedEntityLimitation'] = ResolversParentTypes['PackageLimitedEntityLimitation']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entity?: Resolver<ResolversTypes['LimitationType'], ParentType, ContextType>;
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatorInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatorInfo'] = ResolversParentTypes['PaginatorInfo']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstItem?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasMorePages?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastItem?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  perPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']> = {
  additional?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gateway?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  holder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PaymentMethodType'], ParentType, ContextType>;
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentToken'] = ResolversParentTypes['PaymentToken']> = {
  api_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  application?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cancellation_reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_secret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last_setup_error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_attempt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  livemode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mandate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  next_action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  object?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  on_behalf_of?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payment_method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payment_method_options?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  payment_method_types?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  single_use_mandate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentTokenResponse'] = ResolversParentTypes['PaymentTokenResponse']> = {
  paymentToken?: Resolver<Maybe<ResolversTypes['PaymentToken']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Permission'] = ResolversParentTypes['Permission']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['PermissionGroup'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissionRequest?: Resolver<Maybe<ResolversTypes['PermissionRequest']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PermissionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionGroup'] = ResolversParentTypes['PermissionGroup']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType, Partial<PermissionGroupPermissionsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionGroupPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionGroupPaginator'] = ResolversParentTypes['PermissionGroupPaginator']> = {
  data?: Resolver<Array<ResolversTypes['PermissionGroup']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionPaginator'] = ResolversParentTypes['PermissionPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionRequest'] = ResolversParentTypes['PermissionRequest']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  permission?: Resolver<ResolversTypes['Permission'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan'] = ResolversParentTypes['Plan']> = {
  activeFeatures?: Resolver<Maybe<Array<ResolversTypes['PlanFeature']>>, ParentType, ContextType>;
  addons?: Resolver<ResolversTypes['CouponPaginator'], ParentType, ContextType, RequireFields<PlanAddonsArgs, 'first'>>;
  agreement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingFrequency?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cardRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  comingSoon?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  coupons?: Resolver<ResolversTypes['CouponPaginator'], ParentType, ContextType, RequireFields<PlanCouponsArgs, 'first'>>;
  currencySymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  current?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  moneyback?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordering?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  package?: Resolver<Maybe<ResolversTypes['Package']>, ParentType, ContextType>;
  packageType?: Resolver<ResolversTypes['PackageType'], ParentType, ContextType>;
  partnerDiscountedPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  popular?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  recommended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  trialDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  upgradable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['PlanVisibility'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanFeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanFeature'] = ResolversParentTypes['PlanFeature']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanPaginator'] = ResolversParentTypes['PlanPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanPurchaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanPurchase'] = ResolversParentTypes['PlanPurchase']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanPurchaseInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanPurchaseInfo'] = ResolversParentTypes['PlanPurchaseInfo']> = {
  __resolveType: TypeResolveFn<'PlanPurchase' | 'PlanSubscription', ParentType, ContextType>;
};

export type PlanSubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanSubscription'] = ResolversParentTypes['PlanSubscription']> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencySymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  frequencyLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextBillingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  period?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startBillingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SubscriptionStatus'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Project'] = ResolversParentTypes['Project']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessLegalName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creationStatus?: Resolver<ResolversTypes['ProjectCreationStatus'], ParentType, ContextType>;
  creator?: Resolver<Maybe<ResolversTypes['RelatedUser']>, ParentType, ContextType>;
  currentPackage?: Resolver<Maybe<ResolversTypes['ProjectPackage']>, ParentType, ContextType>;
  darkModeLogo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  defaultDomain?: Resolver<Maybe<ResolversTypes['ProjectDomain']>, ParentType, ContextType>;
  domains?: Resolver<ResolversTypes['ProjectDomainPaginator'], ParentType, ContextType, RequireFields<ProjectDomainsArgs, 'first'>>;
  emailSenderConfig?: Resolver<Maybe<ResolversTypes['ProjectEmailSenderConfig']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  favicon?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industries?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  isEcommerce?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOffline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  languageDetectionMode?: Resolver<ResolversTypes['LanguageDetectionMode'], ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mobileDarkModeLogo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mobileLogo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mobileTouchIcon?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owners?: Resolver<ResolversTypes['RelatedUserPaginator'], ParentType, ContextType, RequireFields<ProjectOwnersArgs, 'first'>>;
  passwordProtection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previewUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purePassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['ProjectRole']>, ParentType, ContextType>;
  screenshotUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socials?: Resolver<Maybe<Array<ResolversTypes['SocialItem']>>, ParentType, ContextType>;
  sqlServer?: Resolver<ResolversTypes['SqlServer'], ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ProjectStatus'], ParentType, ContextType>;
  storeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  subscriptionDetails?: Resolver<Maybe<ResolversTypes['SubscriptionDetails']>, ParentType, ContextType>;
  subscriptions?: Resolver<ResolversTypes['SubscriptionServicePaginator'], ParentType, ContextType, RequireFields<ProjectSubscriptionsArgs, 'first'>>;
  template?: Resolver<Maybe<ResolversTypes['ProjectTemplate']>, ParentType, ContextType>;
  testMode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  transferOrder?: Resolver<Maybe<ResolversTypes['WorkspaceOrder']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userRoles?: Resolver<Maybe<Array<ResolversTypes['ProjectRole']>>, ParentType, ContextType, RequireFields<ProjectUserRolesArgs, 'userId'>>;
  users?: Resolver<ResolversTypes['RelatedUserPaginator'], ParentType, ContextType, RequireFields<ProjectUsersArgs, 'first'>>;
  uuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  workspace?: Resolver<ResolversTypes['ProjectWorkspace'], ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectAccessRolesPivotResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectAccessRolesPivot'] = ResolversParentTypes['ProjectAccessRolesPivot']> = {
  disabledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectConfiguration'] = ResolversParentTypes['ProjectConfiguration']> = {
  favicon?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectDomainResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectDomain'] = ResolversParentTypes['ProjectDomain']> = {
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dnsVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isChecked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubDomain?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mailbox?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ssl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DomainStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DomainType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectDomainPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectDomainPaginator'] = ResolversParentTypes['ProjectDomainPaginator']> = {
  data?: Resolver<Array<ResolversTypes['ProjectDomain']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectEmailSenderConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectEmailSenderConfig'] = ResolversParentTypes['ProjectEmailSenderConfig']> = {
  contactEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  domainId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  emailPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectEmailSenderDomainRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectEmailSenderDomainRecord'] = ResolversParentTypes['ProjectEmailSenderDomainRecord']> = {
  dnsRecordType?: Resolver<ResolversTypes['DNSRecordType'], ParentType, ContextType>;
  isVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ProjectEmailSenderDomainRecordType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  valueEscaped?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectEmailSenderDomainSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectEmailSenderDomainSettings'] = ResolversParentTypes['ProjectEmailSenderDomainSettings']> = {
  isAllVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  records?: Resolver<Array<ResolversTypes['ProjectEmailSenderDomainRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectPackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectPackage'] = ResolversParentTypes['ProjectPackage']> = {
  agreement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  allowedTemplateTypes?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<ResolversTypes['ProjectPackageFeaturePaginator'], ParentType, ContextType, RequireFields<ProjectPackageFeaturesArgs, 'first'>>;
  featuresText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isUpgradable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['Limitation']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  packageGroups?: Resolver<Maybe<Array<ResolversTypes['PackageGroup']>>, ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
  projectRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  public?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectPackageFeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectPackageFeature'] = ResolversParentTypes['ProjectPackageFeature']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['FeatureGroup']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  packageType?: Resolver<ResolversTypes['PackageType'], ParentType, ContextType>;
  selected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectPackageFeaturePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectPackageFeaturePaginator'] = ResolversParentTypes['ProjectPackageFeaturePaginator']> = {
  data?: Resolver<Array<ResolversTypes['ProjectPackageFeature']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectPackagePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectPackagePaginator'] = ResolversParentTypes['ProjectPackagePaginator']> = {
  data?: Resolver<Array<ResolversTypes['ProjectPackage']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectPaginator'] = ResolversParentTypes['ProjectPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Project']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectPermissionGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectPermissionGroup'] = ResolversParentTypes['ProjectPermissionGroup']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Maybe<Array<ResolversTypes['Permission']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectRole'] = ResolversParentTypes['ProjectRole']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectTemplate'] = ResolversParentTypes['ProjectTemplate']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  demoPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  designer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['Industry']>, ParentType, ContextType>;
  metaDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TemplateType'], ParentType, ContextType>;
  usage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectWorkspaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectWorkspace'] = ResolversParentTypes['ProjectWorkspace']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessLegalName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['RelatedUser'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  legal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logoUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamSize?: Resolver<Maybe<ResolversTypes['WorkspaceTeamSize']>, ParentType, ContextType>;
  teamType?: Resolver<Maybe<ResolversTypes['WorkspaceTeamType']>, ParentType, ContextType>;
  webPage?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Purchase'] = ResolversParentTypes['Purchase']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  currentSubscription?: Resolver<Maybe<ResolversTypes['SubscriptionService']>, ParentType, ContextType>;
  host?: Resolver<Maybe<ResolversTypes['Host']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  package?: Resolver<Maybe<ResolversTypes['Package']>, ParentType, ContextType>;
  subscriptions?: Resolver<ResolversTypes['SubscriptionServicePaginator'], ParentType, ContextType, RequireFields<PurchaseSubscriptionsArgs, 'first'>>;
  transactions?: Resolver<ResolversTypes['TransactionPaginator'], ParentType, ContextType, RequireFields<PurchaseTransactionsArgs, 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchasePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchasePaginator'] = ResolversParentTypes['PurchasePaginator']> = {
  data?: Resolver<Array<ResolversTypes['Purchase']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  accountDeletionReasons?: Resolver<Array<ResolversTypes['AccountDeletionReason']>, ParentType, ContextType>;
  activityRecord?: Resolver<Maybe<ResolversTypes['ActivityRecord']>, ParentType, ContextType, RequireFields<QueryActivityRecordArgs, 'input'>>;
  activityRecords?: Resolver<ResolversTypes['ActivityRecordPaginator'], ParentType, ContextType, RequireFields<QueryActivityRecordsArgs, 'input'>>;
  activityRecordsAvailableFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['ActivityRecordFilter']>>>, ParentType, ContextType, Partial<QueryActivityRecordsAvailableFiltersArgs>>;
  adminBlockCategories?: Resolver<Array<ResolversTypes['BlockCategory']>, ParentType, ContextType>;
  adminBlocks?: Resolver<ResolversTypes['BlockPaginator'], ParentType, ContextType, RequireFields<QueryAdminBlocksArgs, 'first'>>;
  adminIndustries?: Resolver<Array<ResolversTypes['Industry']>, ParentType, ContextType>;
  adminTemplateTags?: Resolver<Maybe<ResolversTypes['TemplateTagPaginator']>, ParentType, ContextType, Partial<QueryAdminTemplateTagsArgs>>;
  adminTemplates?: Resolver<Maybe<ResolversTypes['TemplatePaginator']>, ParentType, ContextType, Partial<QueryAdminTemplatesArgs>>;
  allDomains?: Resolver<ResolversTypes['DomainPaginator'], ParentType, ContextType, RequireFields<QueryAllDomainsArgs, 'first'>>;
  allFeatureGroups?: Resolver<ResolversTypes['FeatureGroupPaginator'], ParentType, ContextType, RequireFields<QueryAllFeatureGroupsArgs, 'first'>>;
  allPackages?: Resolver<Maybe<Array<ResolversTypes['Package']>>, ParentType, ContextType, Partial<QueryAllPackagesArgs>>;
  allPlans?: Resolver<ResolversTypes['PlanPaginator'], ParentType, ContextType, RequireFields<QueryAllPlansArgs, 'first'>>;
  allProjects?: Resolver<Maybe<ResolversTypes['AdminProjectPaginator']>, ParentType, ContextType, Partial<QueryAllProjectsArgs>>;
  allSubscriptions?: Resolver<ResolversTypes['SubscriptionServicePaginator'], ParentType, ContextType, RequireFields<QueryAllSubscriptionsArgs, 'first'>>;
  allTemplateTags?: Resolver<Array<ResolversTypes['TemplateTag']>, ParentType, ContextType>;
  allWorkspaces?: Resolver<Maybe<ResolversTypes['WorkspacePaginator']>, ParentType, ContextType, Partial<QueryAllWorkspacesArgs>>;
  applicationPackage?: Resolver<Maybe<ResolversTypes['ApplicationPackage']>, ParentType, ContextType, RequireFields<QueryApplicationPackageArgs, 'id'>>;
  applicationPackages?: Resolver<ResolversTypes['ApplicationPackagePaginator'], ParentType, ContextType, RequireFields<QueryApplicationPackagesArgs, 'first'>>;
  authUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  checkDomain?: Resolver<ResolversTypes['Domain'], ParentType, ContextType, RequireFields<QueryCheckDomainArgs, 'input'>>;
  checkProjectCreationStatus?: Resolver<ResolversTypes['CheckProjectCreationStatus'], ParentType, ContextType, RequireFields<QueryCheckProjectCreationStatusArgs, 'uuid'>>;
  checkProjectEmailSenderDomainSettings?: Resolver<ResolversTypes['ProjectEmailSenderDomainSettings'], ParentType, ContextType, RequireFields<QueryCheckProjectEmailSenderDomainSettingsArgs, 'input'>>;
  checkSubDomain?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCheckSubDomainArgs, 'subDomain'>>;
  configuration?: Resolver<ResolversTypes['Configuration'], ParentType, ContextType, RequireFields<QueryConfigurationArgs, 'projectName'>>;
  connectedAccountBalance?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryConnectedAccountBalanceArgs, 'id'>>;
  connectedAccountLoginUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryConnectedAccountLoginUrlArgs, 'id'>>;
  connectedAccountOnboardingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryConnectedAccountOnboardingUrlArgs, 'id'>>;
  connectedAccountRevenue?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryConnectedAccountRevenueArgs, 'id'>>;
  connectedAccountStatus?: Resolver<Maybe<ResolversTypes['WorkspaceConnectedAccountStatus']>, ParentType, ContextType, RequireFields<QueryConnectedAccountStatusArgs, 'id'>>;
  connectedDomains?: Resolver<Array<ResolversTypes['ConnectedDomain']>, ParentType, ContextType, RequireFields<QueryConnectedDomainsArgs, 'projectId'>>;
  coupon?: Resolver<Maybe<ResolversTypes['Coupon']>, ParentType, ContextType, RequireFields<QueryCouponArgs, 'id'>>;
  coupons?: Resolver<ResolversTypes['CouponPaginator'], ParentType, ContextType, RequireFields<QueryCouponsArgs, 'first'>>;
  domain?: Resolver<Maybe<ResolversTypes['Domain']>, ParentType, ContextType, RequireFields<QueryDomainArgs, 'id'>>;
  domainPackage?: Resolver<Maybe<ResolversTypes['DomainPackage']>, ParentType, ContextType, RequireFields<QueryDomainPackageArgs, 'id'>>;
  domainPackages?: Resolver<ResolversTypes['DomainPackagePaginator'], ParentType, ContextType, RequireFields<QueryDomainPackagesArgs, 'first'>>;
  domainStatuses?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  domains?: Resolver<ResolversTypes['DomainPaginator'], ParentType, ContextType, RequireFields<QueryDomainsArgs, 'first' | 'projectId'>>;
  entitiesToDisable?: Resolver<Maybe<ResolversTypes['PackageLimitedEntities']>, ParentType, ContextType, RequireFields<QueryEntitiesToDisableArgs, 'planAlias' | 'projectId'>>;
  entityCountLimitation?: Resolver<Maybe<Array<ResolversTypes['Limitation']>>, ParentType, ContextType, RequireFields<QueryEntityCountLimitationArgs, 'entities' | 'projectId'>>;
  feature?: Resolver<Maybe<ResolversTypes['Feature']>, ParentType, ContextType, RequireFields<QueryFeatureArgs, 'id'>>;
  featureGroup?: Resolver<Maybe<ResolversTypes['FeatureGroup']>, ParentType, ContextType, RequireFields<QueryFeatureGroupArgs, 'id'>>;
  featureGroups?: Resolver<Maybe<Array<ResolversTypes['FeatureGroup']>>, ParentType, ContextType, RequireFields<QueryFeatureGroupsArgs, 'planAlias'>>;
  features?: Resolver<ResolversTypes['FeaturePaginator'], ParentType, ContextType, RequireFields<QueryFeaturesArgs, 'first'>>;
  getCurrentPaymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QueryGetCurrentPaymentMethodArgs, 'projectId'>>;
  getCurrentPurchase?: Resolver<Maybe<ResolversTypes['Purchase']>, ParentType, ContextType, RequireFields<QueryGetCurrentPurchaseArgs, 'alias' | 'packageType' | 'projectId'>>;
  getCurrentSubscription?: Resolver<Maybe<ResolversTypes['SubscriptionService']>, ParentType, ContextType, RequireFields<QueryGetCurrentSubscriptionArgs, 'projectId'>>;
  getDomain?: Resolver<Maybe<ResolversTypes['Domain']>, ParentType, ContextType, RequireFields<QueryGetDomainArgs, 'id'>>;
  getPlanPurchaseInfo?: Resolver<Maybe<ResolversTypes['PlanPurchaseInfo']>, ParentType, ContextType, RequireFields<QueryGetPlanPurchaseInfoArgs, 'projectId'>>;
  getTransactions?: Resolver<Maybe<Array<ResolversTypes['Transaction']>>, ParentType, ContextType, RequireFields<QueryGetTransactionsArgs, 'subscriptionId'>>;
  getUserProjectNotificationSettings?: Resolver<Maybe<ResolversTypes['UserProjectNotificationSetting']>, ParentType, ContextType, RequireFields<QueryGetUserProjectNotificationSettingsArgs, 'input'>>;
  industries?: Resolver<Maybe<Array<ResolversTypes['Industry']>>, ParentType, ContextType, Partial<QueryIndustriesArgs>>;
  limitationAwareEntities?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  limitationAwareWorkspaceEntities?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  locales?: Resolver<ResolversTypes['LocalesInfo'], ParentType, ContextType>;
  mailTemplate?: Resolver<ResolversTypes['MailTemplate'], ParentType, ContextType, RequireFields<QueryMailTemplateArgs, 'id'>>;
  mailTemplateAliases?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  mailTemplates?: Resolver<Maybe<Array<ResolversTypes['MailTemplate']>>, ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['TeamMember']>, ParentType, ContextType, RequireFields<QueryMemberArgs, 'projectId' | 'userId'>>;
  members?: Resolver<Array<ResolversTypes['Member']>, ParentType, ContextType, RequireFields<QueryMembersArgs, 'input'>>;
  packageTypes?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  paymentMethods?: Resolver<Maybe<Array<ResolversTypes['PaymentMethod']>>, ParentType, ContextType, RequireFields<QueryPaymentMethodsArgs, 'projectId'>>;
  paymentToken?: Resolver<ResolversTypes['PaymentTokenResponse'], ParentType, ContextType, Partial<QueryPaymentTokenArgs>>;
  permission?: Resolver<ResolversTypes['Permission'], ParentType, ContextType, RequireFields<QueryPermissionArgs, 'id'>>;
  permissionGroup?: Resolver<ResolversTypes['PermissionGroup'], ParentType, ContextType, RequireFields<QueryPermissionGroupArgs, 'id'>>;
  permissionGroups?: Resolver<ResolversTypes['PermissionGroupPaginator'], ParentType, ContextType, RequireFields<QueryPermissionGroupsArgs, 'first'>>;
  permissions?: Resolver<ResolversTypes['PermissionPaginator'], ParentType, ContextType, RequireFields<QueryPermissionsArgs, 'first'>>;
  plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<QueryPlanArgs, 'id'>>;
  planVisibilities?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  plans?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType, Partial<QueryPlansArgs>>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<QueryProjectArgs, 'id'>>;
  projectByAlias?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<QueryProjectByAliasArgs, 'name'>>;
  projectByUuid?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<QueryProjectByUuidArgs, 'uuid'>>;
  projectGroupedPermissions?: Resolver<Maybe<Array<ResolversTypes['ProjectPermissionGroup']>>, ParentType, ContextType, RequireFields<QueryProjectGroupedPermissionsArgs, 'projectId'>>;
  projectPackage?: Resolver<Maybe<ResolversTypes['ProjectPackage']>, ParentType, ContextType, RequireFields<QueryProjectPackageArgs, 'id'>>;
  projectPackages?: Resolver<ResolversTypes['ProjectPackagePaginator'], ParentType, ContextType, RequireFields<QueryProjectPackagesArgs, 'first'>>;
  projectPermissions?: Resolver<Maybe<Array<ResolversTypes['Permission']>>, ParentType, ContextType, RequireFields<QueryProjectPermissionsArgs, 'projectId'>>;
  projectStatuses?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  projects?: Resolver<ResolversTypes['ProjectPaginator'], ParentType, ContextType, RequireFields<QueryProjectsArgs, 'first'>>;
  purchase?: Resolver<ResolversTypes['Purchase'], ParentType, ContextType, RequireFields<QueryPurchaseArgs, 'id'>>;
  purchases?: Resolver<ResolversTypes['PurchasePaginator'], ParentType, ContextType, RequireFields<QueryPurchasesArgs, 'first'>>;
  requestedPermissions?: Resolver<Maybe<Array<ResolversTypes['Permission']>>, ParentType, ContextType, RequireFields<QueryRequestedPermissionsArgs, 'input'>>;
  retrievePlan?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType, Partial<QueryRetrievePlanArgs>>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType, RequireFields<QueryRoleArgs, 'id'>>;
  roles?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<QueryRolesArgs, 'projectId'>>;
  searchDomain?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainSearchResult']>>>, ParentType, ContextType, RequireFields<QuerySearchDomainArgs, 'input'>>;
  searchDomainAvailability?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainSearchResult']>>>, ParentType, ContextType, RequireFields<QuerySearchDomainAvailabilityArgs, 'input'>>;
  sendDownloadLink?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sqlServers?: Resolver<Maybe<Array<ResolversTypes['SqlServer']>>, ParentType, ContextType>;
  subscription?: Resolver<Maybe<ResolversTypes['SubscriptionService']>, ParentType, ContextType, RequireFields<QuerySubscriptionArgs, 'subscriptionId'>>;
  subscriptions?: Resolver<Maybe<Array<ResolversTypes['SubscriptionService']>>, ParentType, ContextType, RequireFields<QuerySubscriptionsArgs, 'projectId'>>;
  subscriptionsPaginated?: Resolver<ResolversTypes['SubscriptionServicePaginated'], ParentType, ContextType, RequireFields<QuerySubscriptionsPaginatedArgs, 'alias' | 'packageType' | 'page' | 'projectId'>>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType, RequireFields<QueryTemplateArgs, 'id'>>;
  templateTags?: Resolver<Array<ResolversTypes['TemplateTag']>, ParentType, ContextType>;
  templateType?: Resolver<Maybe<ResolversTypes['TemplateType']>, ParentType, ContextType, RequireFields<QueryTemplateTypeArgs, 'id'>>;
  templateTypes?: Resolver<ResolversTypes['TemplateTypePaginator'], ParentType, ContextType, RequireFields<QueryTemplateTypesArgs, 'first'>>;
  templates?: Resolver<Maybe<ResolversTypes['TemplatePaginator']>, ParentType, ContextType, Partial<QueryTemplatesArgs>>;
  transactions?: Resolver<ResolversTypes['TransactionPaginator'], ParentType, ContextType, RequireFields<QueryTransactionsArgs, 'first'>>;
  transactionsPaginated?: Resolver<ResolversTypes['TransactionPaginated'], ParentType, ContextType, RequireFields<QueryTransactionsPaginatedArgs, 'page' | 'subscriptionId'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  users?: Resolver<Maybe<ResolversTypes['UsersPaginator']>, ParentType, ContextType, Partial<QueryUsersArgs>>;
  workspace?: Resolver<Maybe<ResolversTypes['Workspace']>, ParentType, ContextType, RequireFields<QueryWorkspaceArgs, 'id'>>;
  workspaceByName?: Resolver<Maybe<ResolversTypes['Workspace']>, ParentType, ContextType, RequireFields<QueryWorkspaceByNameArgs, 'name'>>;
  workspaceClient?: Resolver<Maybe<ResolversTypes['WorkspaceClient']>, ParentType, ContextType, RequireFields<QueryWorkspaceClientArgs, 'id'>>;
  workspaceClientTotalSpent?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryWorkspaceClientTotalSpentArgs, 'id'>>;
  workspaceClients?: Resolver<Maybe<ResolversTypes['WorkspaceClientPaginator']>, ParentType, ContextType, RequireFields<QueryWorkspaceClientsArgs, 'input'>>;
  workspaceClientsInvoices?: Resolver<Maybe<ResolversTypes['WorkspaceClientInvoicePaginator']>, ParentType, ContextType, RequireFields<QueryWorkspaceClientsInvoicesArgs, 'input'>>;
  workspaceClientsSubscriptions?: Resolver<Maybe<ResolversTypes['WorkspaceClientSubscriptionPaginator']>, ParentType, ContextType, RequireFields<QueryWorkspaceClientsSubscriptionsArgs, 'input'>>;
  workspaceCurrentPaymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QueryWorkspaceCurrentPaymentMethodArgs, 'workspaceId'>>;
  workspaceCurrentSubscription?: Resolver<Maybe<ResolversTypes['SubscriptionService']>, ParentType, ContextType, RequireFields<QueryWorkspaceCurrentSubscriptionArgs, 'workspaceId'>>;
  workspaceEntityCountLimitation?: Resolver<Maybe<Array<ResolversTypes['WorkspaceLimitation']>>, ParentType, ContextType, RequireFields<QueryWorkspaceEntityCountLimitationArgs, 'input'>>;
  workspaceFeatureGroups?: Resolver<Maybe<Array<ResolversTypes['FeatureGroup']>>, ParentType, ContextType, RequireFields<QueryWorkspaceFeatureGroupsArgs, 'planAlias'>>;
  workspaceMember?: Resolver<Maybe<ResolversTypes['WorkspaceTeamMember']>, ParentType, ContextType, RequireFields<QueryWorkspaceMemberArgs, 'userId' | 'workspaceId'>>;
  workspaceMembers?: Resolver<Array<ResolversTypes['WorkspaceMember']>, ParentType, ContextType, RequireFields<QueryWorkspaceMembersArgs, 'input'>>;
  workspacePackage?: Resolver<Maybe<ResolversTypes['WorkspacePackage']>, ParentType, ContextType, RequireFields<QueryWorkspacePackageArgs, 'id'>>;
  workspacePackages?: Resolver<ResolversTypes['WorkspacePackagePaginator'], ParentType, ContextType, RequireFields<QueryWorkspacePackagesArgs, 'first'>>;
  workspacePaymentMethods?: Resolver<Maybe<Array<ResolversTypes['PaymentMethod']>>, ParentType, ContextType, RequireFields<QueryWorkspacePaymentMethodsArgs, 'workspaceId'>>;
  workspacePaymentToken?: Resolver<ResolversTypes['PaymentTokenResponse'], ParentType, ContextType, Partial<QueryWorkspacePaymentTokenArgs>>;
  workspacePermissionGroup?: Resolver<ResolversTypes['WorkspacePermissionGroup'], ParentType, ContextType, RequireFields<QueryWorkspacePermissionGroupArgs, 'id'>>;
  workspacePermissionGroups?: Resolver<Maybe<ResolversTypes['WorkspacePermissionGroupPaginator']>, ParentType, ContextType, Partial<QueryWorkspacePermissionGroupsArgs>>;
  workspacePlans?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType, Partial<QueryWorkspacePlansArgs>>;
  workspaceProjectPlans?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType, RequireFields<QueryWorkspaceProjectPlansArgs, 'workspaceId'>>;
  workspaceRole?: Resolver<ResolversTypes['WorkspaceRole'], ParentType, ContextType, RequireFields<QueryWorkspaceRoleArgs, 'id'>>;
  workspaceRoles?: Resolver<Array<ResolversTypes['WorkspaceRole']>, ParentType, ContextType, RequireFields<QueryWorkspaceRolesArgs, 'input'>>;
  workspaceStatuses?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  workspaceSubscription?: Resolver<Maybe<ResolversTypes['SubscriptionService']>, ParentType, ContextType, RequireFields<QueryWorkspaceSubscriptionArgs, 'subscriptionId'>>;
  workspaceSubscriptions?: Resolver<Maybe<Array<ResolversTypes['SubscriptionService']>>, ParentType, ContextType, RequireFields<QueryWorkspaceSubscriptionsArgs, 'workspaceId'>>;
  workspaceTransactions?: Resolver<Maybe<Array<ResolversTypes['Transaction']>>, ParentType, ContextType, RequireFields<QueryWorkspaceTransactionsArgs, 'subscriptionId'>>;
  workspaces?: Resolver<Maybe<ResolversTypes['WorkspacePaginator']>, ParentType, ContextType, Partial<QueryWorkspacesArgs>>;
};

export type RelatedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['RelatedUser'] = ResolversParentTypes['RelatedUser']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  emailVerifiedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelatedUserPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['RelatedUserPaginator'] = ResolversParentTypes['RelatedUserPaginator']> = {
  data?: Resolver<Array<ResolversTypes['RelatedUser']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SocialItem'] = ResolversParentTypes['SocialItem']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SqlServerResolvers<ContextType = any, ParentType extends ResolversParentTypes['SqlServer'] = ResolversParentTypes['SqlServer']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionDetails'] = ResolversParentTypes['SubscriptionDetails']> = {
  isConnectedCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUpgradable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  onTrial?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  remainingDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionService'] = ResolversParentTypes['SubscriptionService']> = {
  autoRenew?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencySymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  daysLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  frequencyLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['Host'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isExtendable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExtended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextBillingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  package?: Resolver<ResolversTypes['Package'], ParentType, ContextType>;
  period?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchase?: Resolver<ResolversTypes['Purchase'], ParentType, ContextType>;
  startBillingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SubscriptionStatus'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  workspace?: Resolver<Maybe<ResolversTypes['Workspace']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionServicePaginatedResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionServicePaginated'] = ResolversParentTypes['SubscriptionServicePaginated']> = {
  data?: Resolver<Array<ResolversTypes['SubscriptionService']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionServicePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionServicePaginator'] = ResolversParentTypes['SubscriptionServicePaginator']> = {
  data?: Resolver<Array<ResolversTypes['SubscriptionService']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamMember'] = ResolversParentTypes['TeamMember']> = {
  applicationRoles?: Resolver<Maybe<Array<ResolversTypes['ApplicationRoleType']>>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  pivot?: Resolver<Maybe<ResolversTypes['ProjectAccessRolesPivot']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType, RequireFields<TeamMemberRoleArgs, 'projectId'>>;
  status?: Resolver<ResolversTypes['MembershipStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  demoPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  designer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industries?: Resolver<Maybe<Array<ResolversTypes['Industry']>>, ParentType, ContextType>;
  isBlank?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metaDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['TemplateTag']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TemplateType'], ParentType, ContextType>;
  usage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplatePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplatePaginator'] = ResolversParentTypes['TemplatePaginator']> = {
  data?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateTag'] = ResolversParentTypes['TemplateTag']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordering?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  templates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType>;
  visible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateTagPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateTagPaginator'] = ResolversParentTypes['TemplateTagPaginator']> = {
  data?: Resolver<Array<ResolversTypes['TemplateTag']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateType'] = ResolversParentTypes['TemplateType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  package?: Resolver<ResolversTypes['ProjectPackage'], ParentType, ContextType>;
  planAlias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateTypePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateTypePaginator'] = ResolversParentTypes['TemplateTypePaginator']> = {
  data?: Resolver<Array<ResolversTypes['TemplateType']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencySymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invoiceUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase?: Resolver<Maybe<ResolversTypes['Purchase']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TransactionStatus'], ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionPaginatedResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionPaginated'] = ResolversParentTypes['TransactionPaginated']> = {
  data?: Resolver<Array<ResolversTypes['Transaction']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionPaginator'] = ResolversParentTypes['TransactionPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Transaction']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  balance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  billingDetails?: Resolver<Maybe<ResolversTypes['BillingDetails']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  emailVerifiedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['UserGroup'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentGateway?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  permissions?: Resolver<Maybe<Array<ResolversTypes['Permission']>>, ParentType, ContextType, RequireFields<UserPermissionsArgs, 'projectId'>>;
  projects?: Resolver<ResolversTypes['ProjectPaginator'], ParentType, ContextType, RequireFields<UserProjectsArgs, 'first'>>;
  projectsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  receiveNewsletter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<UserRoleArgs, 'projectId'>>;
  subscriptions?: Resolver<ResolversTypes['SubscriptionServicePaginator'], ParentType, ContextType, RequireFields<UserSubscriptionsArgs, 'first'>>;
  transactions?: Resolver<ResolversTypes['TransactionPaginator'], ParentType, ContextType, RequireFields<UserTransactionsArgs, 'first'>>;
  workspaceRole?: Resolver<Maybe<ResolversTypes['WorkspaceRole']>, ParentType, ContextType, RequireFields<UserWorkspaceRoleArgs, 'workspaceId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAgentResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAgent'] = ResolversParentTypes['UserAgent']> = {
  browser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operatingSystem?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProjectNotificationSettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProjectNotificationSetting'] = ResolversParentTypes['UserProjectNotificationSetting']> = {
  receiveMailNotification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  receivePushNotification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersPaginator'] = ResolversParentTypes['UsersPaginator']> = {
  data?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workspace'] = ResolversParentTypes['Workspace']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessLegalName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  connectedAccountStatus?: Resolver<ResolversTypes['WorkspaceConnectedAccountStatus'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  currentPackage?: Resolver<Maybe<ResolversTypes['WorkspacePackage']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  favicon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  faviconUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  legal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logoUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  mobileTouchIcon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileTouchIconUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nativeDomainHost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projects?: Resolver<Maybe<ResolversTypes['WorkspaceProjectsPaginator']>, ParentType, ContextType, Partial<WorkspaceProjectsArgs>>;
  projectsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['WorkspaceStatus'], ParentType, ContextType>;
  subscriptions?: Resolver<ResolversTypes['SubscriptionServicePaginator'], ParentType, ContextType, RequireFields<WorkspaceSubscriptionsArgs, 'first'>>;
  teamSize?: Resolver<Maybe<ResolversTypes['WorkspaceTeamSize']>, ParentType, ContextType>;
  teamType?: Resolver<Maybe<ResolversTypes['WorkspaceTeamType']>, ParentType, ContextType>;
  templates?: Resolver<Maybe<ResolversTypes['WorkspaceTemplatesPaginator']>, ParentType, ContextType, Partial<WorkspaceTemplatesArgs>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userRole?: Resolver<Maybe<ResolversTypes['WorkspaceRole']>, ParentType, ContextType>;
  users?: Resolver<Maybe<ResolversTypes['WorkspaceUsersPaginator']>, ParentType, ContextType, Partial<WorkspaceUsersArgs>>;
  usersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  webPage?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceAccessRolesPivotResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceAccessRolesPivot'] = ResolversParentTypes['WorkspaceAccessRolesPivot']> = {
  disabledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClient'] = ResolversParentTypes['WorkspaceClient']> = {
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['WorkspaceClientCustomer']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invoices?: Resolver<Maybe<ResolversTypes['WorkspaceClientInvoicePaginator']>, ParentType, ContextType, RequireFields<WorkspaceClientInvoicesArgs, 'input'>>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projects?: Resolver<Maybe<ResolversTypes['WorkspaceProjectsPaginator']>, ParentType, ContextType, Partial<WorkspaceClientProjectsArgs>>;
  projectsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['WorkspaceClientStatus'], ParentType, ContextType>;
  subscriptions?: Resolver<Maybe<ResolversTypes['WorkspaceClientSubscriptionPaginator']>, ParentType, ContextType, RequireFields<WorkspaceClientSubscriptionsArgs, 'input'>>;
  workspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientCustomer'] = ResolversParentTypes['WorkspaceClientCustomer']> = {
  balance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientInvoice'] = ResolversParentTypes['WorkspaceClientInvoice']> = {
  applicationFeePercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hostedInvoiceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invoicePdf?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  items?: Resolver<Maybe<ResolversTypes['WorkspaceClientInvoiceItemCollection']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientInvoiceItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientInvoiceItem'] = ResolversParentTypes['WorkspaceClientInvoiceItem']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intervalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientInvoiceItemCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientInvoiceItemCollection'] = ResolversParentTypes['WorkspaceClientInvoiceItemCollection']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['WorkspaceClientInvoiceItem']>>, ParentType, ContextType>;
  hasMore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientInvoiceItemPeriodResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientInvoiceItemPeriod'] = ResolversParentTypes['WorkspaceClientInvoiceItemPeriod']> = {
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientInvoicePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientInvoicePaginator'] = ResolversParentTypes['WorkspaceClientInvoicePaginator']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['WorkspaceClientInvoice']>>, ParentType, ContextType>;
  hasMore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientPaginator'] = ResolversParentTypes['WorkspaceClientPaginator']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['WorkspaceClient']>>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientSubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientSubscription'] = ResolversParentTypes['WorkspaceClientSubscription']> = {
  applicationFeePercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cancelAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  canceledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  collectionMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentPeriodEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  currentPeriodStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  daysUntilDue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  items?: Resolver<Maybe<ResolversTypes['WorkspaceClientSubscriptionItemCollection']>, ParentType, ContextType>;
  livemode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  nextPendingInvoiceItemInvoice?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientSubscriptionItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientSubscriptionItem'] = ResolversParentTypes['WorkspaceClientSubscriptionItem']> = {
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intervalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientSubscriptionItemCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientSubscriptionItemCollection'] = ResolversParentTypes['WorkspaceClientSubscriptionItemCollection']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['WorkspaceClientSubscriptionItem']>>, ParentType, ContextType>;
  hasMore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceClientSubscriptionPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceClientSubscriptionPaginator'] = ResolversParentTypes['WorkspaceClientSubscriptionPaginator']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['WorkspaceClientSubscription']>>, ParentType, ContextType>;
  hasMore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceInvitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceInvitation'] = ResolversParentTypes['WorkspaceInvitation']> = {
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['WorkspaceRole'], ParentType, ContextType, RequireFields<WorkspaceInvitationRoleArgs, 'workspaceId'>>;
  status?: Resolver<ResolversTypes['MembershipStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceLimitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceLimitation'] = ResolversParentTypes['WorkspaceLimitation']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entity?: Resolver<ResolversTypes['WorkspaceLimitationType'], ParentType, ContextType>;
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  limitReached?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceMember'] = ResolversParentTypes['WorkspaceMember']> = {
  __resolveType: TypeResolveFn<'WorkspaceInvitation' | 'WorkspaceTeamMember', ParentType, ContextType>;
};

export type WorkspaceOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceOrder'] = ResolversParentTypes['WorkspaceOrder']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['WorkspaceOrderStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspacePackage'] = ResolversParentTypes['WorkspacePackage']> = {
  agreement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  allowedTemplateTypes?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<ResolversTypes['WorkspacePackageFeaturePaginator'], ParentType, ContextType, RequireFields<WorkspacePackageFeaturesArgs, 'first'>>;
  featuresText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['WorkspaceLimitation']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  packageGroups?: Resolver<Maybe<Array<ResolversTypes['PackageGroup']>>, ParentType, ContextType>;
  partnerDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  permissions?: Resolver<ResolversTypes['PermissionPaginator'], ParentType, ContextType, RequireFields<WorkspacePackagePermissionsArgs, 'first'>>;
  projectRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  public?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePackageFeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspacePackageFeature'] = ResolversParentTypes['WorkspacePackageFeature']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['FeatureGroup']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  packageType?: Resolver<ResolversTypes['PackageType'], ParentType, ContextType>;
  selected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePackageFeaturePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspacePackageFeaturePaginator'] = ResolversParentTypes['WorkspacePackageFeaturePaginator']> = {
  data?: Resolver<Array<ResolversTypes['WorkspacePackageFeature']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePackagePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspacePackagePaginator'] = ResolversParentTypes['WorkspacePackagePaginator']> = {
  data?: Resolver<Array<ResolversTypes['WorkspacePackage']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspacePaginator'] = ResolversParentTypes['WorkspacePaginator']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Workspace']>>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspacePermission'] = ResolversParentTypes['WorkspacePermission']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['WorkspacePermissionGroup'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePermissionGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspacePermissionGroup'] = ResolversParentTypes['WorkspacePermissionGroup']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['WorkspacePermission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePermissionGroupPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspacePermissionGroupPaginator'] = ResolversParentTypes['WorkspacePermissionGroupPaginator']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['WorkspacePermissionGroup']>>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceProjectsPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceProjectsPaginator'] = ResolversParentTypes['WorkspaceProjectsPaginator']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Project']>>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceRole'] = ResolversParentTypes['WorkspaceRole']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAdminType?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOwnerType?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['WorkspacePermission']>, ParentType, ContextType>;
  workspace?: Resolver<Maybe<ResolversTypes['Workspace']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceTeamMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceTeamMember'] = ResolversParentTypes['WorkspaceTeamMember']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  pivot?: Resolver<Maybe<ResolversTypes['WorkspaceAccessRolesPivot']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['WorkspaceRole'], ParentType, ContextType, RequireFields<WorkspaceTeamMemberRoleArgs, 'workspaceId'>>;
  status?: Resolver<ResolversTypes['MembershipStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface WorkspaceTeamSizeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['WorkspaceTeamSize'], any> {
  name: 'WorkspaceTeamSize';
}

export interface WorkspaceTeamTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['WorkspaceTeamType'], any> {
  name: 'WorkspaceTeamType';
}

export type WorkspaceTemplatesPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceTemplatesPaginator'] = ResolversParentTypes['WorkspaceTemplatesPaginator']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Template']>>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceUsersPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceUsersPaginator'] = ResolversParentTypes['WorkspaceUsersPaginator']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['RelatedUser']>>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WrongRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['WrongRecord'] = ResolversParentTypes['WrongRecord']> = {
  host?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AIBlock?: AiBlockResolvers<ContextType>;
  AccountDeletionReason?: AccountDeletionReasonResolvers<ContextType>;
  ActivityRecord?: ActivityRecordResolvers<ContextType>;
  ActivityRecordFilter?: ActivityRecordFilterResolvers<ContextType>;
  ActivityRecordPaginator?: ActivityRecordPaginatorResolvers<ContextType>;
  AdminProjectPaginator?: AdminProjectPaginatorResolvers<ContextType>;
  ApplicationPackage?: ApplicationPackageResolvers<ContextType>;
  ApplicationPackagePaginator?: ApplicationPackagePaginatorResolvers<ContextType>;
  ApplicationPackageReference?: ApplicationPackageReferenceResolvers<ContextType>;
  ApplicationRoleType?: ApplicationRoleTypeResolvers<ContextType>;
  Array?: GraphQLScalarType;
  BillingDetails?: BillingDetailsResolvers<ContextType>;
  BillingInfo?: BillingInfoResolvers<ContextType>;
  Block?: BlockResolvers<ContextType>;
  BlockCategory?: BlockCategoryResolvers<ContextType>;
  BlockInterface?: BlockInterfaceResolvers<ContextType>;
  BlockPaginator?: BlockPaginatorResolvers<ContextType>;
  BulkActionResponse?: BulkActionResponseResolvers<ContextType>;
  CheckProjectCreationStatus?: CheckProjectCreationStatusResolvers<ContextType>;
  Configuration?: ConfigurationResolvers<ContextType>;
  ConnectedDomain?: ConnectedDomainResolvers<ContextType>;
  Coupon?: CouponResolvers<ContextType>;
  CouponPaginator?: CouponPaginatorResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DateTimeUnix?: GraphQLScalarType;
  DnsRecord?: DnsRecordResolvers<ContextType>;
  Domain?: DomainResolvers<ContextType>;
  DomainCheckoutResponse?: DomainCheckoutResponseResolvers<ContextType>;
  DomainFrequency?: DomainFrequencyResolvers<ContextType>;
  DomainInvoice?: DomainInvoiceResolvers<ContextType>;
  DomainPackage?: DomainPackageResolvers<ContextType>;
  DomainPackagePaginator?: DomainPackagePaginatorResolvers<ContextType>;
  DomainPaginator?: DomainPaginatorResolvers<ContextType>;
  DomainParamsData?: DomainParamsDataResolvers<ContextType>;
  DomainRegistrantData?: DomainRegistrantDataResolvers<ContextType>;
  DomainSearchResult?: DomainSearchResultResolvers<ContextType>;
  Email?: GraphQLScalarType;
  EntityLimitation?: EntityLimitationResolvers<ContextType>;
  Feature?: FeatureResolvers<ContextType>;
  FeatureGroup?: FeatureGroupResolvers<ContextType>;
  FeatureGroupPaginator?: FeatureGroupPaginatorResolvers<ContextType>;
  FeaturePaginator?: FeaturePaginatorResolvers<ContextType>;
  GeographicInfo?: GeographicInfoResolvers<ContextType>;
  GlobalBlock?: GlobalBlockResolvers<ContextType>;
  Host?: HostResolvers<ContextType>;
  Industry?: IndustryResolvers<ContextType>;
  Invitation?: InvitationResolvers<ContextType>;
  Json?: GraphQLScalarType;
  LightweightUser?: LightweightUserResolvers<ContextType>;
  Limitation?: LimitationResolvers<ContextType>;
  LocalBlock?: LocalBlockResolvers<ContextType>;
  LocalesInfo?: LocalesInfoResolvers<ContextType>;
  MailTemplate?: MailTemplateResolvers<ContextType>;
  Member?: MemberResolvers<ContextType>;
  Membership?: MembershipResolvers<ContextType>;
  Mixed?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  Package?: PackageResolvers<ContextType>;
  PackageGroup?: PackageGroupResolvers<ContextType>;
  PackageLimitedEntities?: PackageLimitedEntitiesResolvers<ContextType>;
  PackageLimitedEntityLimitation?: PackageLimitedEntityLimitationResolvers<ContextType>;
  PaginatorInfo?: PaginatorInfoResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  PaymentToken?: PaymentTokenResolvers<ContextType>;
  PaymentTokenResponse?: PaymentTokenResponseResolvers<ContextType>;
  Permission?: PermissionResolvers<ContextType>;
  PermissionGroup?: PermissionGroupResolvers<ContextType>;
  PermissionGroupPaginator?: PermissionGroupPaginatorResolvers<ContextType>;
  PermissionPaginator?: PermissionPaginatorResolvers<ContextType>;
  PermissionRequest?: PermissionRequestResolvers<ContextType>;
  Plan?: PlanResolvers<ContextType>;
  PlanFeature?: PlanFeatureResolvers<ContextType>;
  PlanPaginator?: PlanPaginatorResolvers<ContextType>;
  PlanPurchase?: PlanPurchaseResolvers<ContextType>;
  PlanPurchaseInfo?: PlanPurchaseInfoResolvers<ContextType>;
  PlanSubscription?: PlanSubscriptionResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  ProjectAccessRolesPivot?: ProjectAccessRolesPivotResolvers<ContextType>;
  ProjectConfiguration?: ProjectConfigurationResolvers<ContextType>;
  ProjectDomain?: ProjectDomainResolvers<ContextType>;
  ProjectDomainPaginator?: ProjectDomainPaginatorResolvers<ContextType>;
  ProjectEmailSenderConfig?: ProjectEmailSenderConfigResolvers<ContextType>;
  ProjectEmailSenderDomainRecord?: ProjectEmailSenderDomainRecordResolvers<ContextType>;
  ProjectEmailSenderDomainSettings?: ProjectEmailSenderDomainSettingsResolvers<ContextType>;
  ProjectPackage?: ProjectPackageResolvers<ContextType>;
  ProjectPackageFeature?: ProjectPackageFeatureResolvers<ContextType>;
  ProjectPackageFeaturePaginator?: ProjectPackageFeaturePaginatorResolvers<ContextType>;
  ProjectPackagePaginator?: ProjectPackagePaginatorResolvers<ContextType>;
  ProjectPaginator?: ProjectPaginatorResolvers<ContextType>;
  ProjectPermissionGroup?: ProjectPermissionGroupResolvers<ContextType>;
  ProjectRole?: ProjectRoleResolvers<ContextType>;
  ProjectTemplate?: ProjectTemplateResolvers<ContextType>;
  ProjectWorkspace?: ProjectWorkspaceResolvers<ContextType>;
  Purchase?: PurchaseResolvers<ContextType>;
  PurchasePaginator?: PurchasePaginatorResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RelatedUser?: RelatedUserResolvers<ContextType>;
  RelatedUserPaginator?: RelatedUserPaginatorResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  SocialItem?: SocialItemResolvers<ContextType>;
  SqlServer?: SqlServerResolvers<ContextType>;
  SubscriptionDetails?: SubscriptionDetailsResolvers<ContextType>;
  SubscriptionService?: SubscriptionServiceResolvers<ContextType>;
  SubscriptionServicePaginated?: SubscriptionServicePaginatedResolvers<ContextType>;
  SubscriptionServicePaginator?: SubscriptionServicePaginatorResolvers<ContextType>;
  TeamMember?: TeamMemberResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  TemplatePaginator?: TemplatePaginatorResolvers<ContextType>;
  TemplateTag?: TemplateTagResolvers<ContextType>;
  TemplateTagPaginator?: TemplateTagPaginatorResolvers<ContextType>;
  TemplateType?: TemplateTypeResolvers<ContextType>;
  TemplateTypePaginator?: TemplateTypePaginatorResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  TransactionPaginated?: TransactionPaginatedResolvers<ContextType>;
  TransactionPaginator?: TransactionPaginatorResolvers<ContextType>;
  URL?: GraphQLScalarType;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserAgent?: UserAgentResolvers<ContextType>;
  UserProjectNotificationSetting?: UserProjectNotificationSettingResolvers<ContextType>;
  UsersPaginator?: UsersPaginatorResolvers<ContextType>;
  Workspace?: WorkspaceResolvers<ContextType>;
  WorkspaceAccessRolesPivot?: WorkspaceAccessRolesPivotResolvers<ContextType>;
  WorkspaceClient?: WorkspaceClientResolvers<ContextType>;
  WorkspaceClientCustomer?: WorkspaceClientCustomerResolvers<ContextType>;
  WorkspaceClientInvoice?: WorkspaceClientInvoiceResolvers<ContextType>;
  WorkspaceClientInvoiceItem?: WorkspaceClientInvoiceItemResolvers<ContextType>;
  WorkspaceClientInvoiceItemCollection?: WorkspaceClientInvoiceItemCollectionResolvers<ContextType>;
  WorkspaceClientInvoiceItemPeriod?: WorkspaceClientInvoiceItemPeriodResolvers<ContextType>;
  WorkspaceClientInvoicePaginator?: WorkspaceClientInvoicePaginatorResolvers<ContextType>;
  WorkspaceClientPaginator?: WorkspaceClientPaginatorResolvers<ContextType>;
  WorkspaceClientSubscription?: WorkspaceClientSubscriptionResolvers<ContextType>;
  WorkspaceClientSubscriptionItem?: WorkspaceClientSubscriptionItemResolvers<ContextType>;
  WorkspaceClientSubscriptionItemCollection?: WorkspaceClientSubscriptionItemCollectionResolvers<ContextType>;
  WorkspaceClientSubscriptionPaginator?: WorkspaceClientSubscriptionPaginatorResolvers<ContextType>;
  WorkspaceInvitation?: WorkspaceInvitationResolvers<ContextType>;
  WorkspaceLimitation?: WorkspaceLimitationResolvers<ContextType>;
  WorkspaceMember?: WorkspaceMemberResolvers<ContextType>;
  WorkspaceOrder?: WorkspaceOrderResolvers<ContextType>;
  WorkspacePackage?: WorkspacePackageResolvers<ContextType>;
  WorkspacePackageFeature?: WorkspacePackageFeatureResolvers<ContextType>;
  WorkspacePackageFeaturePaginator?: WorkspacePackageFeaturePaginatorResolvers<ContextType>;
  WorkspacePackagePaginator?: WorkspacePackagePaginatorResolvers<ContextType>;
  WorkspacePaginator?: WorkspacePaginatorResolvers<ContextType>;
  WorkspacePermission?: WorkspacePermissionResolvers<ContextType>;
  WorkspacePermissionGroup?: WorkspacePermissionGroupResolvers<ContextType>;
  WorkspacePermissionGroupPaginator?: WorkspacePermissionGroupPaginatorResolvers<ContextType>;
  WorkspaceProjectsPaginator?: WorkspaceProjectsPaginatorResolvers<ContextType>;
  WorkspaceRole?: WorkspaceRoleResolvers<ContextType>;
  WorkspaceTeamMember?: WorkspaceTeamMemberResolvers<ContextType>;
  WorkspaceTeamSize?: GraphQLScalarType;
  WorkspaceTeamType?: GraphQLScalarType;
  WorkspaceTemplatesPaginator?: WorkspaceTemplatesPaginatorResolvers<ContextType>;
  WorkspaceUsersPaginator?: WorkspaceUsersPaginatorResolvers<ContextType>;
  WrongRecord?: WrongRecordResolvers<ContextType>;
};


export type MyDomainFragment = { __typename?: 'Domain', status: DomainStatus, type: DomainType, default: boolean, ssl: boolean, dnsVerified: boolean, dnsRecords?: Array<{ __typename?: 'DnsRecord', host: string, type: string, value: string, connected: boolean }> | null, wrongRecords?: Array<{ __typename?: 'WrongRecord', host: string, type: string, value: string } | null> | null };

export type ActivityRecordFragmentFragment = { __typename?: 'ActivityRecord', id?: string | null, action?: string | null, dateTime?: any | null, description?: string | null, geographicInfo?: { __typename?: 'GeographicInfo', city: string, country: string, ip: string } | null, userAgent?: { __typename?: 'UserAgent', browser: string, operatingSystem: string } | null, user?: { __typename?: 'LightweightUser', id: string, firstName: string, lastName?: string | null, email: any, avatar?: string | null, role?: string | null } | null };

export type MyProjectFragment = { __typename?: 'Project', id: string, name: string, status: ProjectStatus, clientName: string, users: { __typename?: 'RelatedUserPaginator', data: Array<{ __typename?: 'RelatedUser', id: string, firstName: string, avatarUrl?: string | null }> } };

type MemberFragment_Invitation_Fragment = { __typename?: 'Invitation', id: string, email: any, status: MembershipStatus };

type MemberFragment_TeamMember_Fragment = { __typename?: 'TeamMember', id: string, email: any, status: MembershipStatus };

type MemberFragment_WorkspaceInvitation_Fragment = { __typename?: 'WorkspaceInvitation', id: string, email: any, status: MembershipStatus };

type MemberFragment_WorkspaceTeamMember_Fragment = { __typename?: 'WorkspaceTeamMember', id: string, email: any, status: MembershipStatus };

export type MemberFragmentFragment = MemberFragment_Invitation_Fragment | MemberFragment_TeamMember_Fragment | MemberFragment_WorkspaceInvitation_Fragment | MemberFragment_WorkspaceTeamMember_Fragment;

export type PaginatorInfoFragmentFragment = { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number };

export type BulkActionResponseFragmentFragment = { __typename?: 'BulkActionResponse', uuid: string, entity: BulkActionEntity };

export type NewRoleFragment = { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string }> };

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id: string, name: string, alias: string, description: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string, alias: string, description: string, group: { __typename?: 'PermissionGroup', id: string, name: string, description: string, permissions: Array<{ __typename?: 'Permission', id: string }> } }> } };

export type ExtendSubscriptionMutationVariables = Exact<{
  input: ExtendSubscriptionInput;
}>;


export type ExtendSubscriptionMutation = { __typename?: 'Mutation', extendSubscription?: { __typename?: 'SubscriptionService', id: string, status: SubscriptionStatus } | null };

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', id: string, name: string, alias: string, description: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string, alias: string, description: string, group: { __typename?: 'PermissionGroup', id: string, name: string, description: string, permissions: Array<{ __typename?: 'Permission', id: string }> } }> } };

export type DeleteRoleMutationVariables = Exact<{
  input: DeleteRoleInput;
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: boolean };

export type DuplicateRoleMutationVariables = Exact<{
  input: DuplicateRoleInput;
}>;


export type DuplicateRoleMutation = { __typename?: 'Mutation', duplicateRole: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string }> } };

export type InviteMemberMutationVariables = Exact<{
  input: InviteMembersInput;
}>;


export type InviteMemberMutation = { __typename?: 'Mutation', inviteMembers: boolean };

export type InviteMemberWithNewRoleMutationVariables = Exact<{
  input: InviteMembersWithNewRoleInput;
}>;


export type InviteMemberWithNewRoleMutation = { __typename?: 'Mutation', inviteMembersWithNewRole?: boolean | null };

export type DeleteMemberMutationVariables = Exact<{
  input: DeleteMemberInput;
}>;


export type DeleteMemberMutation = { __typename?: 'Mutation', deleteMember: boolean };

export type UpdateMembersRoleMutationVariables = Exact<{
  input: UpdateMembersRoleInput;
}>;


export type UpdateMembersRoleMutation = { __typename?: 'Mutation', updateMembersRole: boolean };

export type UpdateMembersWithNewRoleMutationVariables = Exact<{
  input: UpdateMembersWithNewRoleInput;
}>;


export type UpdateMembersWithNewRoleMutation = { __typename?: 'Mutation', updateMembersWithNewRole: boolean };

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject?: { __typename?: 'Project', clientName: string, logo?: string | null, darkModeLogo?: string | null, mobileLogo?: string | null, mobileDarkModeLogo?: string | null, languageDetectionMode: LanguageDetectionMode } | null };

export type SetPasswordMutationVariables = Exact<{
  input: SetPasswordInput;
}>;


export type SetPasswordMutation = { __typename?: 'Mutation', setPassword?: { __typename?: 'Project', id: string, name: string, status: ProjectStatus, passwordProtection: boolean, purePassword?: string | null } | null };

export type UnsetPasswordMutationVariables = Exact<{
  input: UnsetPasswordInput;
}>;


export type UnsetPasswordMutation = { __typename?: 'Mutation', unsetPassword?: { __typename?: 'Project', id: string } | null };

export type ConnectDomainMutationVariables = Exact<{
  input: ConnectDomainInput;
}>;


export type ConnectDomainMutation = { __typename?: 'Mutation', connectDomain: { __typename?: 'Domain', id: string, isChecked: boolean, domain: string, status: DomainStatus, type: DomainType, mailbox?: string | null, expiryDate?: string | null, language: string } };

export type DeleteDomainMutationVariables = Exact<{
  input: DeleteDomainInput;
}>;


export type DeleteDomainMutation = { __typename?: 'Mutation', deleteDomain: boolean };

export type UpdateSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateSubscription: boolean };

export type CancelSubscriptionMutationVariables = Exact<{
  input: CancelSubscriptionInput;
}>;


export type CancelSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription: boolean };

export type ActivateSubscriptionMutationVariables = Exact<{
  input: ActivateSubscriptionInput;
}>;


export type ActivateSubscriptionMutation = { __typename?: 'Mutation', activateSubscription: boolean };

export type CreatePaymentMethodMutationVariables = Exact<{
  input: CreatePaymentMethodInput;
}>;


export type CreatePaymentMethodMutation = { __typename?: 'Mutation', createPaymentMethod: { __typename?: 'PaymentMethod', token: string, last4: string } };

export type UpdateCurrentPlanMutationVariables = Exact<{
  input: UpdateCurrentPlanInput;
}>;


export type UpdateCurrentPlanMutation = { __typename?: 'Mutation', updateCurrentPlan: { __typename?: 'Plan', id: string } };

export type DeleteProjectMutationVariables = Exact<{
  input: DeleteProjectInput;
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject: boolean };

export type CheckoutDomainMutationVariables = Exact<{
  input: CheckoutDomainInput;
}>;


export type CheckoutDomainMutation = { __typename?: 'Mutation', checkoutDomain: { __typename?: 'DomainCheckoutResponse', paymentMethod: { __typename?: 'PaymentMethod', description?: string | null }, invoices: Array<{ __typename?: 'DomainInvoice', id: string, price: number, discount?: number | null, summary: number, transactionId: string, old?: boolean | null, frequency: number, domain: string }> } };

export type LeaveProjectMutationVariables = Exact<{
  input: LeaveProjectInput;
}>;


export type LeaveProjectMutation = { __typename?: 'Mutation', leaveProject?: boolean | null };

export type DeleteInvitationMutationVariables = Exact<{
  input: DeleteInvitationInput;
}>;


export type DeleteInvitationMutation = { __typename?: 'Mutation', deleteInvitation: boolean };

export type MakeDomainDefaultMutationVariables = Exact<{
  input: MakeDomainDefaultInput;
}>;


export type MakeDomainDefaultMutation = { __typename?: 'Mutation', makeDomainDefault: { __typename?: 'Domain', id: string, isChecked: boolean, domain: string, status: DomainStatus, type: DomainType, mailbox?: string | null, expiryDate?: string | null, language: string, default: boolean, ssl: boolean, dnsVerified: boolean, dnsRecords?: Array<{ __typename?: 'DnsRecord', host: string, type: string, value: string, connected: boolean }> | null, wrongRecords?: Array<{ __typename?: 'WrongRecord', host: string, type: string, value: string } | null> | null } };

export type DisconnectDomainMutationVariables = Exact<{
  input: DisconnectDomainInput;
}>;


export type DisconnectDomainMutation = { __typename?: 'Mutation', disconnectDomain: { __typename?: 'Domain', id: string, isChecked: boolean, domain: string, status: DomainStatus, type: DomainType, mailbox?: string | null, expiryDate?: string | null, language: string, default: boolean, ssl: boolean, dnsVerified: boolean, dnsRecords?: Array<{ __typename?: 'DnsRecord', host: string, type: string, value: string, connected: boolean }> | null, wrongRecords?: Array<{ __typename?: 'WrongRecord', host: string, type: string, value: string } | null> | null } };

export type UpdateDomainMutationVariables = Exact<{
  input: UpdateDomainInput;
}>;


export type UpdateDomainMutation = { __typename?: 'Mutation', updateDomain: { __typename?: 'Domain', id: string, redirectToWww: boolean } };

export type BulkDeleteMemberMutationVariables = Exact<{
  input: BulkActionTeamInput;
}>;


export type BulkDeleteMemberMutation = { __typename?: 'Mutation', bulkDeleteMember: { __typename?: 'BulkActionResponse', uuid: string, entity: BulkActionEntity } };

export type UpdateProjectEmailSenderConfigMutationVariables = Exact<{
  input: UpdateProjectEmailSenderConfigInput;
}>;


export type UpdateProjectEmailSenderConfigMutation = { __typename?: 'Mutation', updateProjectEmailSenderConfig: { __typename?: 'ProjectEmailSenderConfig', projectId: string, domainId?: string | null, name: string, emailPrefix: string, contactEmail?: string | null, isActive: boolean, isVerified: boolean } };

export type UpdateUserProjectNotificationSettingsMutationVariables = Exact<{
  input: UpdateUserProjectNotificationSettingsInput;
}>;


export type UpdateUserProjectNotificationSettingsMutation = { __typename?: 'Mutation', updateUserProjectNotificationSettings: { __typename?: 'UserProjectNotificationSetting', receiveMailNotification: boolean, receivePushNotification: boolean } };

export type MembersQueryVariables = Exact<{
  input: MembersInput;
  projectId: Scalars['ID']['input'];
}>;


export type MembersQuery = { __typename?: 'Query', members: Array<{ __typename?: 'Invitation', id: string, email: any, status: MembershipStatus, role: { __typename?: 'Role', name: string, id: string } } | { __typename?: 'TeamMember', avatar?: string | null, firstName: string, lastName?: string | null, params?: any | null, id: string, email: any, status: MembershipStatus, role: { __typename?: 'Role', name: string, id: string } }> };

export type MemberQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
}>;


export type MemberQuery = { __typename?: 'Query', member?: { __typename?: 'TeamMember', id: string, firstName: string, lastName?: string | null, avatar?: string | null, params?: any | null, email: any, applicationRoles?: Array<{ __typename?: 'ApplicationRoleType', applicationId: string, identifier: string, name: string }> | null, role: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string }> } } | null };

export type RolesQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  orderBy?: InputMaybe<Array<RolesOrderByClause> | RolesOrderByClause>;
}>;


export type RolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: string, name: string, alias: string, description: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string }> }> };

export type PermissionGroupsQueryVariables = Exact<{
  types?: InputMaybe<Array<PermissionType> | PermissionType>;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<QueryPermissionGroupsOrderByOrderByClause> | QueryPermissionGroupsOrderByOrderByClause>;
}>;


export type PermissionGroupsQuery = { __typename?: 'Query', permissionGroups: { __typename?: 'PermissionGroupPaginator', data: Array<{ __typename?: 'PermissionGroup', id: string, name: string, alias: string, description: string, permissions: Array<{ __typename?: 'Permission', id: string, name: string, alias: string, description: string }> }> } };

export type ProjectsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<QueryProjectsOrderByOrderByClause> | QueryProjectsOrderByOrderByClause>;
}>;


export type ProjectsQuery = { __typename?: 'Query', projects: { __typename?: 'ProjectPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number }, data: Array<{ __typename?: 'Project', id: string, name: string, status: ProjectStatus, isOffline: boolean, isExpired: boolean, url: string, screenshotUrl: string, previewUrl: string, clientName: string, logo?: string | null, darkModeLogo?: string | null, template?: { __typename?: 'ProjectTemplate', id: string, thumbnail?: any | null } | null, users: { __typename?: 'RelatedUserPaginator', data: Array<{ __typename?: 'RelatedUser', id: string, firstName: string, avatarUrl?: string | null }> } }> } };

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, name: string, clientName: string, businessLegalName?: string | null, phoneNumber?: string | null, state?: string | null, country?: string | null, city?: string | null, industries?: Array<string> | null, logo?: string | null, darkModeLogo?: string | null, mobileLogo?: string | null, mobileDarkModeLogo?: string | null, favicon?: string | null, mobileTouchIcon?: string | null, isOffline: boolean, languageDetectionMode: LanguageDetectionMode, zipCode?: string | null, address?: string | null, passwordProtection: boolean, purePassword?: string | null, status: ProjectStatus, url: string, previewUrl: string, emailSenderConfig?: { __typename?: 'ProjectEmailSenderConfig', projectId: string, domainId?: string | null, name: string, emailPrefix: string, contactEmail?: string | null, isActive: boolean, isVerified: boolean } | null, currentPackage?: { __typename?: 'ProjectPackage', name: string } | null, socials?: Array<{ __typename?: 'SocialItem', url: string, name: string, order: number }> | null } | null };

export type DomainsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type DomainsQuery = { __typename?: 'Query', domains: { __typename?: 'DomainPaginator', data: Array<{ __typename?: 'Domain', id: string, isChecked: boolean, domain: string, status: DomainStatus, type: DomainType, mailbox?: string | null, expiryDate?: string | null, language: string, default: boolean, redirectToWww: boolean, ssl: boolean, dnsVerified: boolean, dnsRecords?: Array<{ __typename?: 'DnsRecord', host: string, type: string, value: string, connected: boolean }> | null, wrongRecords?: Array<{ __typename?: 'WrongRecord', host: string, type: string, value: string } | null> | null }>, paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number } } };

export type CheckDomainQueryVariables = Exact<{
  input: CheckDomainInput;
}>;


export type CheckDomainQuery = { __typename?: 'Query', checkDomain: { __typename?: 'Domain', id: string, isChecked: boolean, domain: string, status: DomainStatus, type: DomainType, mailbox?: string | null, expiryDate?: string | null, language: string, default: boolean, ssl: boolean, dnsVerified: boolean, dnsRecords?: Array<{ __typename?: 'DnsRecord', host: string, type: string, value: string, connected: boolean }> | null, wrongRecords?: Array<{ __typename?: 'WrongRecord', host: string, type: string, value: string } | null> | null } };

export type ProjectByAliasQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type ProjectByAliasQuery = { __typename?: 'Query', projectByAlias?: { __typename?: 'Project', id: string, name: string, logo?: string | null, clientName: string, previewUrl: string, status: ProjectStatus, isOffline: boolean, createdAt: any, screenshotUrl: string, isExpired: boolean, industries?: Array<string> | null, currentPackage?: { __typename?: 'ProjectPackage', id: string, name: string, packageGroups?: Array<{ __typename?: 'PackageGroup', alias: string }> | null } | null, subscriptionDetails?: { __typename?: 'SubscriptionDetails', isConnectedCard?: boolean | null, onTrial?: boolean | null, remainingDays?: number | null, isUpgradable?: boolean | null } | null, owners: { __typename?: 'RelatedUserPaginator', data: Array<{ __typename?: 'RelatedUser', id: string }> } } | null };

export type SubscriptionsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type SubscriptionsQuery = { __typename?: 'Query', subscriptions?: Array<{ __typename?: 'SubscriptionService', id: string, name: string, currency: string, price: string, currencySymbol: string, status: SubscriptionStatus, period: string, nextBillingDate?: any | null, frequencyLabel: string, startBillingDate?: any | null, autoRenew: boolean, summary: string }> | null };

export type PaymentMethodsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type PaymentMethodsQuery = { __typename?: 'Query', paymentMethods?: Array<{ __typename?: 'PaymentMethod', additional?: any | null, country?: string | null, default: boolean, description?: string | null, gateway: string, last4: string, token: string, type: PaymentMethodType, holder?: string | null, imageUrl?: string | null, zip?: string | null, expYear?: number | null, expMonth?: number | null }> | null };

export type GetCurrentSubscriptionQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type GetCurrentSubscriptionQuery = { __typename?: 'Query', getCurrentSubscription?: { __typename?: 'SubscriptionService', id: string, name: string, currency: string, price: string, currencySymbol: string, status: SubscriptionStatus, isExtendable: boolean, period: string, nextBillingDate?: any | null, frequencyLabel: string, startBillingDate?: any | null, autoRenew: boolean, summary: string, package: { __typename?: 'Package', id: string }, plan: { __typename?: 'Plan', alias: string, name: string, icon?: string | null, agreement?: string | null, description?: string | null, price: number, recommended: boolean, moneyback: boolean, popular: boolean, cardRequired: boolean, comingSoon: boolean, billingFrequency: number, trialDays: number, upgradable?: boolean | null } } | null };

export type PaymentTokenQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type PaymentTokenQuery = { __typename?: 'Query', paymentToken: { __typename?: 'PaymentTokenResponse', userId: string, paymentToken?: { __typename?: 'PaymentToken', api_key?: string | null, client_secret: string, payment_method?: string | null, id: string, object: string, application?: string | null, cancellation_reason?: string | null, created?: number | null, customer: string, last_setup_error?: string | null, livemode?: boolean | null, next_action?: string | null, usage?: string | null, payment_method_options?: any | null, payment_method_types: any, single_use_mandate?: string | null } | null } };

export type GetCurrentPaymentMethodQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type GetCurrentPaymentMethodQuery = { __typename?: 'Query', getCurrentPaymentMethod?: { __typename?: 'PaymentMethod', additional?: any | null, country?: string | null, default: boolean, description?: string | null, gateway: string, holder?: string | null, imageUrl?: string | null, last4: string, token: string, type: PaymentMethodType, zip?: string | null, expYear?: number | null, expMonth?: number | null } | null };

export type PlansQueryVariables = Exact<{
  input?: InputMaybe<PlansQueryInput>;
}>;


export type PlansQuery = { __typename?: 'Query', plans: Array<{ __typename?: 'Plan', id: string, alias: string, name: string, description?: string | null, recommended: boolean, price: number, billingFrequency: number, upgradable?: boolean | null, trialDays: number, summary: number, cardRequired: boolean, ordering: number, current?: boolean | null, currencySymbol: string, activeFeatures?: Array<{ __typename?: 'PlanFeature', id: string, title: string, description: string, limit?: string | null }> | null, package?: { __typename?: 'Package', id: string } | null }> };

export type PlanQueryVariables = Exact<{
  input?: InputMaybe<PlanQueryInput>;
}>;


export type PlanQuery = { __typename?: 'Query', retrievePlan?: { __typename?: 'Plan', id: string, alias: string, name: string, description?: string | null, recommended: boolean, price: number, billingFrequency: number, upgradable?: boolean | null, trialDays: number, summary: number, cardRequired: boolean, ordering: number, current?: boolean | null, currencySymbol: string, error?: string | null, package?: { __typename?: 'Package', id: string } | null } | null };

export type FeatureGroupsQueryVariables = Exact<{
  planAlias: Scalars['String']['input'];
}>;


export type FeatureGroupsQuery = { __typename?: 'Query', featureGroups?: Array<{ __typename?: 'FeatureGroup', title: string, alias: string, features: Array<{ __typename?: 'Feature', id: string, title: string, description: string, ordering?: number | null, packageType: PackageType, limit?: string | null, selected?: boolean | null, group?: { __typename?: 'FeatureGroup', id: string, title: string, alias: string } | null }> }> | null };

export type GetTransactionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['ID']['input'];
}>;


export type GetTransactionsQuery = { __typename?: 'Query', getTransactions?: Array<{ __typename?: 'Transaction', id: string, name: string, createdAt: any, currency: string, currencySymbol: string, price?: string | null, status: TransactionStatus, summary?: string | null, invoiceUrl: string }> | null };

export type SearchDomainAvailabilityQueryVariables = Exact<{
  input: CheckDomainInput;
}>;


export type SearchDomainAvailabilityQuery = { __typename?: 'Query', searchDomainAvailability?: Array<{ __typename?: 'DomainSearchResult', domain: string, name: string, available: boolean, price: number, currencySymbol: string, currency: string, domainName: string, billingFrequencies: Array<{ __typename?: 'DomainFrequency', id: string, price: number, frequency: number }> } | null> | null };

export type ActivityRecordsQueryVariables = Exact<{
  input: PaginateActivityRecordInput;
}>;


export type ActivityRecordsQuery = { __typename?: 'Query', activityRecords: { __typename?: 'ActivityRecordPaginator', data: Array<{ __typename?: 'ActivityRecord', id?: string | null, action?: string | null, dateTime?: any | null, description?: string | null, geographicInfo?: { __typename?: 'GeographicInfo', city: string, country: string, ip: string } | null, userAgent?: { __typename?: 'UserAgent', browser: string, operatingSystem: string } | null, user?: { __typename?: 'LightweightUser', id: string, firstName: string, lastName?: string | null, email: any, avatar?: string | null, role?: string | null } | null }>, paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number } } };

export type ActivityRecordQueryVariables = Exact<{
  input: ActivityRecordInput;
}>;


export type ActivityRecordQuery = { __typename?: 'Query', activityRecord?: { __typename?: 'ActivityRecord', id?: string | null, action?: string | null, dateTime?: any | null, description?: string | null, geographicInfo?: { __typename?: 'GeographicInfo', city: string, country: string, ip: string } | null, userAgent?: { __typename?: 'UserAgent', browser: string, operatingSystem: string } | null, user?: { __typename?: 'LightweightUser', id: string, firstName: string, lastName?: string | null, email: any, avatar?: string | null, role?: string | null } | null } | null };

export type UserQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type UserQuery = { __typename?: 'Query', authUser: { __typename?: 'User', language: string, role?: { __typename?: 'Role', name: string, id: string } | null } };

export type IndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type IndustriesQuery = { __typename?: 'Query', industries?: Array<{ __typename?: 'Industry', name: string, id: string, alias: string }> | null };

export type ActivityRecordsAvailableFiltersQueryVariables = Exact<{
  input?: InputMaybe<ActivityRecordsAvailableFilterInput>;
}>;


export type ActivityRecordsAvailableFiltersQuery = { __typename?: 'Query', activityRecordsAvailableFilters?: Array<{ __typename?: 'ActivityRecordFilter', filter: string, options?: any | null } | null> | null };

export type EntitiesToDisableQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  planAlias: Scalars['String']['input'];
}>;


export type EntitiesToDisableQuery = { __typename?: 'Query', entitiesToDisable?: { __typename?: 'PackageLimitedEntities', payment?: Array<PackageLimitedPayment> | null, shipping?: Array<PackageLimitedShipping> | null, domain?: Array<PackageLimitedDomain> | null, product?: Array<PackageLimitedProduct> | null, limitations?: Array<{ __typename?: 'PackageLimitedEntityLimitation', entity: LimitationType, limit: number, count: number }> | null } | null };

export type ProjectGroupedPermissionsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type ProjectGroupedPermissionsQuery = { __typename?: 'Query', projectGroupedPermissions?: Array<{ __typename?: 'ProjectPermissionGroup', id: string, name: string, alias: string, description: string, permissions?: Array<{ __typename?: 'Permission', id: string, name: string, alias: string, description: string }> | null }> | null };

export type ConnectedDomainsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type ConnectedDomainsQuery = { __typename?: 'Query', connectedDomains: Array<{ __typename?: 'ConnectedDomain', id: string, domain: string, isBoughtFromUs: boolean }> };

export type CheckProjectEmailSenderDomainSettingsQueryVariables = Exact<{
  input: CheckProjectEmailSenderDomainSettingsInput;
}>;


export type CheckProjectEmailSenderDomainSettingsQuery = { __typename?: 'Query', checkProjectEmailSenderDomainSettings: { __typename?: 'ProjectEmailSenderDomainSettings', isAllVerified: boolean, records: Array<{ __typename?: 'ProjectEmailSenderDomainRecord', type: ProjectEmailSenderDomainRecordType, name: string, value: string, valueEscaped?: string | null, isVerified: boolean, dnsRecordType: DnsRecordType }> } };

export type GetCurrentPurchaseQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  alias: Scalars['String']['input'];
  packageType: Scalars['String']['input'];
}>;


export type GetCurrentPurchaseQuery = { __typename?: 'Query', getCurrentPurchase?: { __typename?: 'Purchase', id: string, name: string, currentSubscription?: { __typename?: 'SubscriptionService', id: string, name: string, currency: string, price: string, frequencyLabel: string, status: SubscriptionStatus, nextBillingDate?: any | null, startBillingDate?: any | null, autoRenew: boolean, daysLeft: number } | null } | null };

export type SubscriptionsPaginatedQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  alias: Scalars['String']['input'];
  packageType: Scalars['String']['input'];
  fromDate?: InputMaybe<Scalars['DateTimeUnix']['input']>;
  toDate?: InputMaybe<Scalars['DateTimeUnix']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SubscriptionsPaginatedQuery = { __typename?: 'Query', subscriptionsPaginated: { __typename?: 'SubscriptionServicePaginated', data: Array<{ __typename?: 'SubscriptionService', id: string, name: string, currency: string, price: string, summary: string, currencySymbol: string, frequencyLabel: string, status: SubscriptionStatus, period: string, nextBillingDate?: any | null, startBillingDate?: any | null, autoRenew: boolean }>, paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number } } };

export type GetUserProjectNotificationSettingsQueryVariables = Exact<{
  input: UserProjectNotificationSettingsInput;
}>;


export type GetUserProjectNotificationSettingsQuery = { __typename?: 'Query', getUserProjectNotificationSettings?: { __typename?: 'UserProjectNotificationSetting', receiveMailNotification: boolean, receivePushNotification: boolean } | null };

export type TransactionsPaginatedQueryVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TransactionsPaginatedQuery = { __typename?: 'Query', transactionsPaginated: { __typename?: 'TransactionPaginated', data: Array<{ __typename?: 'Transaction', id: string, name: string, createdAt: any, currency: string, currencySymbol: string, price?: string | null, summary?: string | null, discount?: string | null, status: TransactionStatus, invoiceUrl: string }>, paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number } } };

export const MyDomainFragmentDoc = gql`
    fragment MyDomain on Domain {
  status
  type
  default
  dnsRecords {
    host
    type
    value
    connected
  }
  ssl
  dnsVerified
  wrongRecords {
    host
    type
    value
  }
}
    `;
export const ActivityRecordFragmentFragmentDoc = gql`
    fragment ActivityRecordFragment on ActivityRecord {
  id
  action
  dateTime
  description
  geographicInfo {
    city
    country
    ip
  }
  userAgent {
    browser
    operatingSystem
  }
  user {
    id
    firstName
    lastName
    email
    avatar
    role
  }
}
    `;
export const MyProjectFragmentDoc = gql`
    fragment MyProject on Project {
  id
  name
  status
  clientName
  users(first: 10) {
    data {
      id
      firstName
      avatarUrl
    }
  }
}
    `;
export const MemberFragmentFragmentDoc = gql`
    fragment memberFragment on Membership {
  id
  email
  status
}
    `;
export const PaginatorInfoFragmentFragmentDoc = gql`
    fragment PaginatorInfoFragment on PaginatorInfo {
  count
  currentPage
  firstItem
  hasMorePages
  lastItem
  lastPage
  perPage
  total
}
    `;
export const BulkActionResponseFragmentFragmentDoc = gql`
    fragment BulkActionResponseFragment on BulkActionResponse {
  uuid
  entity
}
    `;
export const NewRoleFragmentDoc = gql`
    fragment NewRole on Role {
  id
  name
  permissions {
    id
    name
  }
}
    `;
export const CreateRoleDocument = gql`
    mutation createRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    id
    name
    alias
    description
    permissions {
      id
      name
      alias
      description
      group {
        id
        name
        description
        permissions {
          id
        }
      }
    }
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const ExtendSubscriptionDocument = gql`
    mutation extendSubscription($input: ExtendSubscriptionInput!) {
  extendSubscription(input: $input) {
    id
    status
  }
}
    `;
export type ExtendSubscriptionMutationFn = Apollo.MutationFunction<ExtendSubscriptionMutation, ExtendSubscriptionMutationVariables>;

/**
 * __useExtendSubscriptionMutation__
 *
 * To run a mutation, you first call `useExtendSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendSubscriptionMutation, { data, loading, error }] = useExtendSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtendSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ExtendSubscriptionMutation, ExtendSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtendSubscriptionMutation, ExtendSubscriptionMutationVariables>(ExtendSubscriptionDocument, options);
      }
export type ExtendSubscriptionMutationHookResult = ReturnType<typeof useExtendSubscriptionMutation>;
export type ExtendSubscriptionMutationResult = Apollo.MutationResult<ExtendSubscriptionMutation>;
export type ExtendSubscriptionMutationOptions = Apollo.BaseMutationOptions<ExtendSubscriptionMutation, ExtendSubscriptionMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    id
    name
    alias
    description
    permissions {
      id
      name
      alias
      description
      group {
        id
        name
        description
        permissions {
          id
        }
      }
    }
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation deleteRole($input: DeleteRoleInput!) {
  deleteRole(input: $input)
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const DuplicateRoleDocument = gql`
    mutation duplicateRole($input: DuplicateRoleInput!) {
  duplicateRole(input: $input) {
    id
    name
    permissions {
      id
      name
    }
  }
}
    `;
export type DuplicateRoleMutationFn = Apollo.MutationFunction<DuplicateRoleMutation, DuplicateRoleMutationVariables>;

/**
 * __useDuplicateRoleMutation__
 *
 * To run a mutation, you first call `useDuplicateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRoleMutation, { data, loading, error }] = useDuplicateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateRoleMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateRoleMutation, DuplicateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateRoleMutation, DuplicateRoleMutationVariables>(DuplicateRoleDocument, options);
      }
export type DuplicateRoleMutationHookResult = ReturnType<typeof useDuplicateRoleMutation>;
export type DuplicateRoleMutationResult = Apollo.MutationResult<DuplicateRoleMutation>;
export type DuplicateRoleMutationOptions = Apollo.BaseMutationOptions<DuplicateRoleMutation, DuplicateRoleMutationVariables>;
export const InviteMemberDocument = gql`
    mutation inviteMember($input: InviteMembersInput!) {
  inviteMembers(input: $input)
}
    `;
export type InviteMemberMutationFn = Apollo.MutationFunction<InviteMemberMutation, InviteMemberMutationVariables>;

/**
 * __useInviteMemberMutation__
 *
 * To run a mutation, you first call `useInviteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMemberMutation, { data, loading, error }] = useInviteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMemberMutation(baseOptions?: Apollo.MutationHookOptions<InviteMemberMutation, InviteMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteMemberMutation, InviteMemberMutationVariables>(InviteMemberDocument, options);
      }
export type InviteMemberMutationHookResult = ReturnType<typeof useInviteMemberMutation>;
export type InviteMemberMutationResult = Apollo.MutationResult<InviteMemberMutation>;
export type InviteMemberMutationOptions = Apollo.BaseMutationOptions<InviteMemberMutation, InviteMemberMutationVariables>;
export const InviteMemberWithNewRoleDocument = gql`
    mutation inviteMemberWithNewRole($input: InviteMembersWithNewRoleInput!) {
  inviteMembersWithNewRole(input: $input)
}
    `;
export type InviteMemberWithNewRoleMutationFn = Apollo.MutationFunction<InviteMemberWithNewRoleMutation, InviteMemberWithNewRoleMutationVariables>;

/**
 * __useInviteMemberWithNewRoleMutation__
 *
 * To run a mutation, you first call `useInviteMemberWithNewRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMemberWithNewRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMemberWithNewRoleMutation, { data, loading, error }] = useInviteMemberWithNewRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMemberWithNewRoleMutation(baseOptions?: Apollo.MutationHookOptions<InviteMemberWithNewRoleMutation, InviteMemberWithNewRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteMemberWithNewRoleMutation, InviteMemberWithNewRoleMutationVariables>(InviteMemberWithNewRoleDocument, options);
      }
export type InviteMemberWithNewRoleMutationHookResult = ReturnType<typeof useInviteMemberWithNewRoleMutation>;
export type InviteMemberWithNewRoleMutationResult = Apollo.MutationResult<InviteMemberWithNewRoleMutation>;
export type InviteMemberWithNewRoleMutationOptions = Apollo.BaseMutationOptions<InviteMemberWithNewRoleMutation, InviteMemberWithNewRoleMutationVariables>;
export const DeleteMemberDocument = gql`
    mutation deleteMember($input: DeleteMemberInput!) {
  deleteMember(input: $input)
}
    `;
export type DeleteMemberMutationFn = Apollo.MutationFunction<DeleteMemberMutation, DeleteMemberMutationVariables>;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberMutation, DeleteMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, options);
      }
export type DeleteMemberMutationHookResult = ReturnType<typeof useDeleteMemberMutation>;
export type DeleteMemberMutationResult = Apollo.MutationResult<DeleteMemberMutation>;
export type DeleteMemberMutationOptions = Apollo.BaseMutationOptions<DeleteMemberMutation, DeleteMemberMutationVariables>;
export const UpdateMembersRoleDocument = gql`
    mutation updateMembersRole($input: UpdateMembersRoleInput!) {
  updateMembersRole(input: $input)
}
    `;
export type UpdateMembersRoleMutationFn = Apollo.MutationFunction<UpdateMembersRoleMutation, UpdateMembersRoleMutationVariables>;

/**
 * __useUpdateMembersRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMembersRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersRoleMutation, { data, loading, error }] = useUpdateMembersRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembersRoleMutation, UpdateMembersRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembersRoleMutation, UpdateMembersRoleMutationVariables>(UpdateMembersRoleDocument, options);
      }
export type UpdateMembersRoleMutationHookResult = ReturnType<typeof useUpdateMembersRoleMutation>;
export type UpdateMembersRoleMutationResult = Apollo.MutationResult<UpdateMembersRoleMutation>;
export type UpdateMembersRoleMutationOptions = Apollo.BaseMutationOptions<UpdateMembersRoleMutation, UpdateMembersRoleMutationVariables>;
export const UpdateMembersWithNewRoleDocument = gql`
    mutation updateMembersWithNewRole($input: UpdateMembersWithNewRoleInput!) {
  updateMembersWithNewRole(input: $input)
}
    `;
export type UpdateMembersWithNewRoleMutationFn = Apollo.MutationFunction<UpdateMembersWithNewRoleMutation, UpdateMembersWithNewRoleMutationVariables>;

/**
 * __useUpdateMembersWithNewRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMembersWithNewRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersWithNewRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersWithNewRoleMutation, { data, loading, error }] = useUpdateMembersWithNewRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersWithNewRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembersWithNewRoleMutation, UpdateMembersWithNewRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembersWithNewRoleMutation, UpdateMembersWithNewRoleMutationVariables>(UpdateMembersWithNewRoleDocument, options);
      }
export type UpdateMembersWithNewRoleMutationHookResult = ReturnType<typeof useUpdateMembersWithNewRoleMutation>;
export type UpdateMembersWithNewRoleMutationResult = Apollo.MutationResult<UpdateMembersWithNewRoleMutation>;
export type UpdateMembersWithNewRoleMutationOptions = Apollo.BaseMutationOptions<UpdateMembersWithNewRoleMutation, UpdateMembersWithNewRoleMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation updateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    clientName
    logo
    darkModeLogo
    mobileLogo
    mobileDarkModeLogo
    languageDetectionMode
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const SetPasswordDocument = gql`
    mutation setPassword($input: SetPasswordInput!) {
  setPassword(input: $input) {
    id
    name
    status
    passwordProtection
    purePassword
  }
}
    `;
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, options);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const UnsetPasswordDocument = gql`
    mutation unsetPassword($input: UnsetPasswordInput!) {
  unsetPassword(input: $input) {
    id
  }
}
    `;
export type UnsetPasswordMutationFn = Apollo.MutationFunction<UnsetPasswordMutation, UnsetPasswordMutationVariables>;

/**
 * __useUnsetPasswordMutation__
 *
 * To run a mutation, you first call `useUnsetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetPasswordMutation, { data, loading, error }] = useUnsetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UnsetPasswordMutation, UnsetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetPasswordMutation, UnsetPasswordMutationVariables>(UnsetPasswordDocument, options);
      }
export type UnsetPasswordMutationHookResult = ReturnType<typeof useUnsetPasswordMutation>;
export type UnsetPasswordMutationResult = Apollo.MutationResult<UnsetPasswordMutation>;
export type UnsetPasswordMutationOptions = Apollo.BaseMutationOptions<UnsetPasswordMutation, UnsetPasswordMutationVariables>;
export const ConnectDomainDocument = gql`
    mutation connectDomain($input: ConnectDomainInput!) {
  connectDomain(input: $input) {
    id
    isChecked
    domain
    status
    type
    mailbox
    expiryDate
    language
  }
}
    `;
export type ConnectDomainMutationFn = Apollo.MutationFunction<ConnectDomainMutation, ConnectDomainMutationVariables>;

/**
 * __useConnectDomainMutation__
 *
 * To run a mutation, you first call `useConnectDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectDomainMutation, { data, loading, error }] = useConnectDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectDomainMutation(baseOptions?: Apollo.MutationHookOptions<ConnectDomainMutation, ConnectDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectDomainMutation, ConnectDomainMutationVariables>(ConnectDomainDocument, options);
      }
export type ConnectDomainMutationHookResult = ReturnType<typeof useConnectDomainMutation>;
export type ConnectDomainMutationResult = Apollo.MutationResult<ConnectDomainMutation>;
export type ConnectDomainMutationOptions = Apollo.BaseMutationOptions<ConnectDomainMutation, ConnectDomainMutationVariables>;
export const DeleteDomainDocument = gql`
    mutation deleteDomain($input: DeleteDomainInput!) {
  deleteDomain(input: $input)
}
    `;
export type DeleteDomainMutationFn = Apollo.MutationFunction<DeleteDomainMutation, DeleteDomainMutationVariables>;

/**
 * __useDeleteDomainMutation__
 *
 * To run a mutation, you first call `useDeleteDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainMutation, { data, loading, error }] = useDeleteDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDomainMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDomainMutation, DeleteDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDomainMutation, DeleteDomainMutationVariables>(DeleteDomainDocument, options);
      }
export type DeleteDomainMutationHookResult = ReturnType<typeof useDeleteDomainMutation>;
export type DeleteDomainMutationResult = Apollo.MutationResult<DeleteDomainMutation>;
export type DeleteDomainMutationOptions = Apollo.BaseMutationOptions<DeleteDomainMutation, DeleteDomainMutationVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation updateSubscription($input: UpdateSubscriptionInput!) {
  updateSubscription(input: $input)
}
    `;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, options);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription($input: CancelSubscriptionInput!) {
  cancelSubscription(input: $input)
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const ActivateSubscriptionDocument = gql`
    mutation activateSubscription($input: ActivateSubscriptionInput!) {
  activateSubscription(input: $input)
}
    `;
export type ActivateSubscriptionMutationFn = Apollo.MutationFunction<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>;

/**
 * __useActivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useActivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSubscriptionMutation, { data, loading, error }] = useActivateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>(ActivateSubscriptionDocument, options);
      }
export type ActivateSubscriptionMutationHookResult = ReturnType<typeof useActivateSubscriptionMutation>;
export type ActivateSubscriptionMutationResult = Apollo.MutationResult<ActivateSubscriptionMutation>;
export type ActivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>;
export const CreatePaymentMethodDocument = gql`
    mutation createPaymentMethod($input: CreatePaymentMethodInput!) {
  createPaymentMethod(input: $input) {
    token
    last4
  }
}
    `;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, options);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export const UpdateCurrentPlanDocument = gql`
    mutation updateCurrentPlan($input: UpdateCurrentPlanInput!) {
  updateCurrentPlan(input: $input) {
    id
  }
}
    `;
export type UpdateCurrentPlanMutationFn = Apollo.MutationFunction<UpdateCurrentPlanMutation, UpdateCurrentPlanMutationVariables>;

/**
 * __useUpdateCurrentPlanMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentPlanMutation, { data, loading, error }] = useUpdateCurrentPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentPlanMutation, UpdateCurrentPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentPlanMutation, UpdateCurrentPlanMutationVariables>(UpdateCurrentPlanDocument, options);
      }
export type UpdateCurrentPlanMutationHookResult = ReturnType<typeof useUpdateCurrentPlanMutation>;
export type UpdateCurrentPlanMutationResult = Apollo.MutationResult<UpdateCurrentPlanMutation>;
export type UpdateCurrentPlanMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentPlanMutation, UpdateCurrentPlanMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($input: DeleteProjectInput!) {
  deleteProject(input: $input)
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const CheckoutDomainDocument = gql`
    mutation checkoutDomain($input: CheckoutDomainInput!) {
  checkoutDomain(input: $input) {
    paymentMethod {
      description
    }
    invoices {
      id
      price
      discount
      summary
      transactionId
      old
      frequency
      domain
    }
  }
}
    `;
export type CheckoutDomainMutationFn = Apollo.MutationFunction<CheckoutDomainMutation, CheckoutDomainMutationVariables>;

/**
 * __useCheckoutDomainMutation__
 *
 * To run a mutation, you first call `useCheckoutDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutDomainMutation, { data, loading, error }] = useCheckoutDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutDomainMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutDomainMutation, CheckoutDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutDomainMutation, CheckoutDomainMutationVariables>(CheckoutDomainDocument, options);
      }
export type CheckoutDomainMutationHookResult = ReturnType<typeof useCheckoutDomainMutation>;
export type CheckoutDomainMutationResult = Apollo.MutationResult<CheckoutDomainMutation>;
export type CheckoutDomainMutationOptions = Apollo.BaseMutationOptions<CheckoutDomainMutation, CheckoutDomainMutationVariables>;
export const LeaveProjectDocument = gql`
    mutation leaveProject($input: LeaveProjectInput!) {
  leaveProject(input: $input)
}
    `;
export type LeaveProjectMutationFn = Apollo.MutationFunction<LeaveProjectMutation, LeaveProjectMutationVariables>;

/**
 * __useLeaveProjectMutation__
 *
 * To run a mutation, you first call `useLeaveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveProjectMutation, { data, loading, error }] = useLeaveProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveProjectMutation(baseOptions?: Apollo.MutationHookOptions<LeaveProjectMutation, LeaveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveProjectMutation, LeaveProjectMutationVariables>(LeaveProjectDocument, options);
      }
export type LeaveProjectMutationHookResult = ReturnType<typeof useLeaveProjectMutation>;
export type LeaveProjectMutationResult = Apollo.MutationResult<LeaveProjectMutation>;
export type LeaveProjectMutationOptions = Apollo.BaseMutationOptions<LeaveProjectMutation, LeaveProjectMutationVariables>;
export const DeleteInvitationDocument = gql`
    mutation deleteInvitation($input: DeleteInvitationInput!) {
  deleteInvitation(input: $input)
}
    `;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<DeleteInvitationMutation, DeleteInvitationMutationVariables>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvitationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvitationMutation, DeleteInvitationMutationVariables>(DeleteInvitationDocument, options);
      }
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>;
export const MakeDomainDefaultDocument = gql`
    mutation makeDomainDefault($input: MakeDomainDefaultInput!) {
  makeDomainDefault(input: $input) {
    id
    isChecked
    domain
    status
    type
    mailbox
    expiryDate
    language
    default
    dnsRecords {
      host
      type
      value
      connected
    }
    ssl
    dnsVerified
    wrongRecords {
      host
      type
      value
    }
  }
}
    `;
export type MakeDomainDefaultMutationFn = Apollo.MutationFunction<MakeDomainDefaultMutation, MakeDomainDefaultMutationVariables>;

/**
 * __useMakeDomainDefaultMutation__
 *
 * To run a mutation, you first call `useMakeDomainDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeDomainDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeDomainDefaultMutation, { data, loading, error }] = useMakeDomainDefaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeDomainDefaultMutation(baseOptions?: Apollo.MutationHookOptions<MakeDomainDefaultMutation, MakeDomainDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeDomainDefaultMutation, MakeDomainDefaultMutationVariables>(MakeDomainDefaultDocument, options);
      }
export type MakeDomainDefaultMutationHookResult = ReturnType<typeof useMakeDomainDefaultMutation>;
export type MakeDomainDefaultMutationResult = Apollo.MutationResult<MakeDomainDefaultMutation>;
export type MakeDomainDefaultMutationOptions = Apollo.BaseMutationOptions<MakeDomainDefaultMutation, MakeDomainDefaultMutationVariables>;
export const DisconnectDomainDocument = gql`
    mutation disconnectDomain($input: DisconnectDomainInput!) {
  disconnectDomain(input: $input) {
    id
    isChecked
    domain
    status
    type
    mailbox
    expiryDate
    language
    default
    dnsRecords {
      host
      type
      value
      connected
    }
    ssl
    dnsVerified
    wrongRecords {
      host
      type
      value
    }
  }
}
    `;
export type DisconnectDomainMutationFn = Apollo.MutationFunction<DisconnectDomainMutation, DisconnectDomainMutationVariables>;

/**
 * __useDisconnectDomainMutation__
 *
 * To run a mutation, you first call `useDisconnectDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectDomainMutation, { data, loading, error }] = useDisconnectDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectDomainMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectDomainMutation, DisconnectDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectDomainMutation, DisconnectDomainMutationVariables>(DisconnectDomainDocument, options);
      }
export type DisconnectDomainMutationHookResult = ReturnType<typeof useDisconnectDomainMutation>;
export type DisconnectDomainMutationResult = Apollo.MutationResult<DisconnectDomainMutation>;
export type DisconnectDomainMutationOptions = Apollo.BaseMutationOptions<DisconnectDomainMutation, DisconnectDomainMutationVariables>;
export const UpdateDomainDocument = gql`
    mutation updateDomain($input: UpdateDomainInput!) {
  updateDomain(input: $input) {
    id
    redirectToWww
  }
}
    `;
export type UpdateDomainMutationFn = Apollo.MutationFunction<UpdateDomainMutation, UpdateDomainMutationVariables>;

/**
 * __useUpdateDomainMutation__
 *
 * To run a mutation, you first call `useUpdateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainMutation, { data, loading, error }] = useUpdateDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDomainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDomainMutation, UpdateDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDomainMutation, UpdateDomainMutationVariables>(UpdateDomainDocument, options);
      }
export type UpdateDomainMutationHookResult = ReturnType<typeof useUpdateDomainMutation>;
export type UpdateDomainMutationResult = Apollo.MutationResult<UpdateDomainMutation>;
export type UpdateDomainMutationOptions = Apollo.BaseMutationOptions<UpdateDomainMutation, UpdateDomainMutationVariables>;
export const BulkDeleteMemberDocument = gql`
    mutation bulkDeleteMember($input: BulkActionTeamInput!) {
  bulkDeleteMember(input: $input) {
    ...BulkActionResponseFragment
  }
}
    ${BulkActionResponseFragmentFragmentDoc}`;
export type BulkDeleteMemberMutationFn = Apollo.MutationFunction<BulkDeleteMemberMutation, BulkDeleteMemberMutationVariables>;

/**
 * __useBulkDeleteMemberMutation__
 *
 * To run a mutation, you first call `useBulkDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteMemberMutation, { data, loading, error }] = useBulkDeleteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkDeleteMemberMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteMemberMutation, BulkDeleteMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteMemberMutation, BulkDeleteMemberMutationVariables>(BulkDeleteMemberDocument, options);
      }
export type BulkDeleteMemberMutationHookResult = ReturnType<typeof useBulkDeleteMemberMutation>;
export type BulkDeleteMemberMutationResult = Apollo.MutationResult<BulkDeleteMemberMutation>;
export type BulkDeleteMemberMutationOptions = Apollo.BaseMutationOptions<BulkDeleteMemberMutation, BulkDeleteMemberMutationVariables>;
export const UpdateProjectEmailSenderConfigDocument = gql`
    mutation updateProjectEmailSenderConfig($input: UpdateProjectEmailSenderConfigInput!) {
  updateProjectEmailSenderConfig(input: $input) {
    projectId
    domainId
    name
    emailPrefix
    contactEmail
    isActive
    isVerified
  }
}
    `;
export type UpdateProjectEmailSenderConfigMutationFn = Apollo.MutationFunction<UpdateProjectEmailSenderConfigMutation, UpdateProjectEmailSenderConfigMutationVariables>;

/**
 * __useUpdateProjectEmailSenderConfigMutation__
 *
 * To run a mutation, you first call `useUpdateProjectEmailSenderConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectEmailSenderConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectEmailSenderConfigMutation, { data, loading, error }] = useUpdateProjectEmailSenderConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectEmailSenderConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectEmailSenderConfigMutation, UpdateProjectEmailSenderConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectEmailSenderConfigMutation, UpdateProjectEmailSenderConfigMutationVariables>(UpdateProjectEmailSenderConfigDocument, options);
      }
export type UpdateProjectEmailSenderConfigMutationHookResult = ReturnType<typeof useUpdateProjectEmailSenderConfigMutation>;
export type UpdateProjectEmailSenderConfigMutationResult = Apollo.MutationResult<UpdateProjectEmailSenderConfigMutation>;
export type UpdateProjectEmailSenderConfigMutationOptions = Apollo.BaseMutationOptions<UpdateProjectEmailSenderConfigMutation, UpdateProjectEmailSenderConfigMutationVariables>;
export const UpdateUserProjectNotificationSettingsDocument = gql`
    mutation updateUserProjectNotificationSettings($input: UpdateUserProjectNotificationSettingsInput!) {
  updateUserProjectNotificationSettings(input: $input) {
    receiveMailNotification
    receivePushNotification
  }
}
    `;
export type UpdateUserProjectNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateUserProjectNotificationSettingsMutation, UpdateUserProjectNotificationSettingsMutationVariables>;

/**
 * __useUpdateUserProjectNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserProjectNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProjectNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProjectNotificationSettingsMutation, { data, loading, error }] = useUpdateUserProjectNotificationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProjectNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProjectNotificationSettingsMutation, UpdateUserProjectNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProjectNotificationSettingsMutation, UpdateUserProjectNotificationSettingsMutationVariables>(UpdateUserProjectNotificationSettingsDocument, options);
      }
export type UpdateUserProjectNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateUserProjectNotificationSettingsMutation>;
export type UpdateUserProjectNotificationSettingsMutationResult = Apollo.MutationResult<UpdateUserProjectNotificationSettingsMutation>;
export type UpdateUserProjectNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserProjectNotificationSettingsMutation, UpdateUserProjectNotificationSettingsMutationVariables>;
export const MembersDocument = gql`
    query members($input: MembersInput!, $projectId: ID!) {
  members(input: $input) {
    ... on Invitation {
      role(projectId: $projectId) {
        name
        id
      }
      id
      email
      status
    }
    ... on TeamMember {
      avatar
      firstName
      lastName
      params
      role(projectId: $projectId) {
        name
        id
      }
      id
      email
      status
    }
  }
}
    `;

/**
 * __useMembersQuery__
 *
 * To run a query within a React component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useMembersQuery(baseOptions: Apollo.QueryHookOptions<MembersQuery, MembersQueryVariables> & ({ variables: MembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
      }
export function useMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
        }
export function useMembersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MembersQuery, MembersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
        }
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersSuspenseQueryHookResult = ReturnType<typeof useMembersSuspenseQuery>;
export type MembersQueryResult = Apollo.QueryResult<MembersQuery, MembersQueryVariables>;
export function refetchMembersQuery(variables: MembersQueryVariables) {
      return { query: MembersDocument, variables: variables }
    }
export const MemberDocument = gql`
    query member($userId: ID!, $projectId: ID!) {
  member(projectId: $projectId, userId: $userId) {
    id
    firstName
    lastName
    avatar
    params
    email
    applicationRoles {
      applicationId
      identifier
      name
    }
    role(projectId: $projectId) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useMemberQuery(baseOptions: Apollo.QueryHookOptions<MemberQuery, MemberQueryVariables> & ({ variables: MemberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
      }
export function useMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberQuery, MemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
        }
export function useMemberSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MemberQuery, MemberQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
        }
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberSuspenseQueryHookResult = ReturnType<typeof useMemberSuspenseQuery>;
export type MemberQueryResult = Apollo.QueryResult<MemberQuery, MemberQueryVariables>;
export function refetchMemberQuery(variables: MemberQueryVariables) {
      return { query: MemberDocument, variables: variables }
    }
export const RolesDocument = gql`
    query roles($projectId: ID!, $orderBy: [RolesOrderByClause!]) {
  roles(projectId: $projectId, orderBy: $orderBy) {
    id
    name
    alias
    description
    permissions {
      id
      name
    }
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRolesQuery(baseOptions: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables> & ({ variables: RolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export function useRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesSuspenseQueryHookResult = ReturnType<typeof useRolesSuspenseQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export function refetchRolesQuery(variables: RolesQueryVariables) {
      return { query: RolesDocument, variables: variables }
    }
export const PermissionGroupsDocument = gql`
    query permissionGroups($types: [PermissionType!], $first: Int!, $page: Int, $orderBy: [QueryPermissionGroupsOrderByOrderByClause!]) {
  permissionGroups(first: $first, page: $page, orderBy: $orderBy) {
    data {
      id
      name
      alias
      description
      permissions(types: $types) {
        id
        name
        alias
        description
      }
    }
  }
}
    `;

/**
 * __usePermissionGroupsQuery__
 *
 * To run a query within a React component, call `usePermissionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupsQuery({
 *   variables: {
 *      types: // value for 'types'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePermissionGroupsQuery(baseOptions: Apollo.QueryHookOptions<PermissionGroupsQuery, PermissionGroupsQueryVariables> & ({ variables: PermissionGroupsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionGroupsQuery, PermissionGroupsQueryVariables>(PermissionGroupsDocument, options);
      }
export function usePermissionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionGroupsQuery, PermissionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionGroupsQuery, PermissionGroupsQueryVariables>(PermissionGroupsDocument, options);
        }
export function usePermissionGroupsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PermissionGroupsQuery, PermissionGroupsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PermissionGroupsQuery, PermissionGroupsQueryVariables>(PermissionGroupsDocument, options);
        }
export type PermissionGroupsQueryHookResult = ReturnType<typeof usePermissionGroupsQuery>;
export type PermissionGroupsLazyQueryHookResult = ReturnType<typeof usePermissionGroupsLazyQuery>;
export type PermissionGroupsSuspenseQueryHookResult = ReturnType<typeof usePermissionGroupsSuspenseQuery>;
export type PermissionGroupsQueryResult = Apollo.QueryResult<PermissionGroupsQuery, PermissionGroupsQueryVariables>;
export function refetchPermissionGroupsQuery(variables: PermissionGroupsQueryVariables) {
      return { query: PermissionGroupsDocument, variables: variables }
    }
export const ProjectsDocument = gql`
    query projects($first: Int!, $page: Int, $search: String, $orderBy: [QueryProjectsOrderByOrderByClause!]) {
  projects(first: $first, page: $page, orderBy: $orderBy, search: $search) {
    paginatorInfo {
      ...PaginatorInfoFragment
    }
    data {
      id
      name
      status
      isOffline
      isExpired
      url
      screenshotUrl
      previewUrl
      clientName
      logo
      darkModeLogo
      template {
        id
        thumbnail
      }
      users(first: 10) {
        data {
          id
          firstName
          avatarUrl
        }
      }
    }
  }
}
    ${PaginatorInfoFragmentFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables> & ({ variables: ProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export function useProjectsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsSuspenseQueryHookResult = ReturnType<typeof useProjectsSuspenseQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export function refetchProjectsQuery(variables: ProjectsQueryVariables) {
      return { query: ProjectsDocument, variables: variables }
    }
export const ProjectDocument = gql`
    query project($id: ID!) {
  project(id: $id) {
    id
    name
    clientName
    businessLegalName
    phoneNumber
    state
    country
    city
    industries
    logo
    darkModeLogo
    mobileLogo
    mobileDarkModeLogo
    favicon
    mobileTouchIcon
    isOffline
    languageDetectionMode
    emailSenderConfig {
      projectId
      domainId
      name
      emailPrefix
      contactEmail
      isActive
      isVerified
    }
    currentPackage {
      name
    }
    socials {
      url
      name
      order
    }
    zipCode
    address
    passwordProtection
    purePassword
    status
    url
    previewUrl
  }
}
    `;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables> & ({ variables: ProjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export function useProjectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectSuspenseQueryHookResult = ReturnType<typeof useProjectSuspenseQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export function refetchProjectQuery(variables: ProjectQueryVariables) {
      return { query: ProjectDocument, variables: variables }
    }
export const DomainsDocument = gql`
    query domains($first: Int!, $page: Int, $projectId: ID!, $name: String) {
  domains(first: $first, page: $page, projectId: $projectId, name: $name) {
    data {
      id
      isChecked
      domain
      status
      type
      mailbox
      expiryDate
      language
      default
      redirectToWww
      dnsRecords {
        host
        type
        value
        connected
      }
      ssl
      id
      dnsVerified
      wrongRecords {
        host
        type
        value
      }
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${PaginatorInfoFragmentFragmentDoc}`;

/**
 * __useDomainsQuery__
 *
 * To run a query within a React component, call `useDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDomainsQuery(baseOptions: Apollo.QueryHookOptions<DomainsQuery, DomainsQueryVariables> & ({ variables: DomainsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
      }
export function useDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DomainsQuery, DomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
        }
export function useDomainsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DomainsQuery, DomainsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
        }
export type DomainsQueryHookResult = ReturnType<typeof useDomainsQuery>;
export type DomainsLazyQueryHookResult = ReturnType<typeof useDomainsLazyQuery>;
export type DomainsSuspenseQueryHookResult = ReturnType<typeof useDomainsSuspenseQuery>;
export type DomainsQueryResult = Apollo.QueryResult<DomainsQuery, DomainsQueryVariables>;
export function refetchDomainsQuery(variables: DomainsQueryVariables) {
      return { query: DomainsDocument, variables: variables }
    }
export const CheckDomainDocument = gql`
    query checkDomain($input: CheckDomainInput!) {
  checkDomain(input: $input) {
    id
    isChecked
    domain
    status
    type
    mailbox
    expiryDate
    language
    default
    dnsRecords {
      host
      type
      value
      connected
    }
    ssl
    dnsVerified
    wrongRecords {
      host
      type
      value
    }
  }
}
    `;

/**
 * __useCheckDomainQuery__
 *
 * To run a query within a React component, call `useCheckDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDomainQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckDomainQuery(baseOptions: Apollo.QueryHookOptions<CheckDomainQuery, CheckDomainQueryVariables> & ({ variables: CheckDomainQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDomainQuery, CheckDomainQueryVariables>(CheckDomainDocument, options);
      }
export function useCheckDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDomainQuery, CheckDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDomainQuery, CheckDomainQueryVariables>(CheckDomainDocument, options);
        }
export function useCheckDomainSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckDomainQuery, CheckDomainQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckDomainQuery, CheckDomainQueryVariables>(CheckDomainDocument, options);
        }
export type CheckDomainQueryHookResult = ReturnType<typeof useCheckDomainQuery>;
export type CheckDomainLazyQueryHookResult = ReturnType<typeof useCheckDomainLazyQuery>;
export type CheckDomainSuspenseQueryHookResult = ReturnType<typeof useCheckDomainSuspenseQuery>;
export type CheckDomainQueryResult = Apollo.QueryResult<CheckDomainQuery, CheckDomainQueryVariables>;
export function refetchCheckDomainQuery(variables: CheckDomainQueryVariables) {
      return { query: CheckDomainDocument, variables: variables }
    }
export const ProjectByAliasDocument = gql`
    query projectByAlias($name: String!) {
  projectByAlias(name: $name) {
    id
    name
    logo
    clientName
    previewUrl
    status
    isOffline
    createdAt
    screenshotUrl
    isExpired
    industries
    currentPackage {
      id
      name
      packageGroups {
        alias
      }
    }
    subscriptionDetails {
      isConnectedCard
      onTrial
      remainingDays
      isUpgradable
    }
    owners(first: 100, page: 1) {
      data {
        id
      }
    }
  }
}
    `;

/**
 * __useProjectByAliasQuery__
 *
 * To run a query within a React component, call `useProjectByAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectByAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectByAliasQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useProjectByAliasQuery(baseOptions: Apollo.QueryHookOptions<ProjectByAliasQuery, ProjectByAliasQueryVariables> & ({ variables: ProjectByAliasQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectByAliasQuery, ProjectByAliasQueryVariables>(ProjectByAliasDocument, options);
      }
export function useProjectByAliasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectByAliasQuery, ProjectByAliasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectByAliasQuery, ProjectByAliasQueryVariables>(ProjectByAliasDocument, options);
        }
export function useProjectByAliasSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectByAliasQuery, ProjectByAliasQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectByAliasQuery, ProjectByAliasQueryVariables>(ProjectByAliasDocument, options);
        }
export type ProjectByAliasQueryHookResult = ReturnType<typeof useProjectByAliasQuery>;
export type ProjectByAliasLazyQueryHookResult = ReturnType<typeof useProjectByAliasLazyQuery>;
export type ProjectByAliasSuspenseQueryHookResult = ReturnType<typeof useProjectByAliasSuspenseQuery>;
export type ProjectByAliasQueryResult = Apollo.QueryResult<ProjectByAliasQuery, ProjectByAliasQueryVariables>;
export function refetchProjectByAliasQuery(variables: ProjectByAliasQueryVariables) {
      return { query: ProjectByAliasDocument, variables: variables }
    }
export const SubscriptionsDocument = gql`
    query subscriptions($projectId: ID!) {
  subscriptions(projectId: $projectId) {
    id
    name
    currency
    price
    currencySymbol
    status
    period
    nextBillingDate
    frequencyLabel
    startBillingDate
    autoRenew
    summary
  }
}
    `;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables> & ({ variables: SubscriptionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
      }
export function useSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export function useSubscriptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export type SubscriptionsQueryHookResult = ReturnType<typeof useSubscriptionsQuery>;
export type SubscriptionsLazyQueryHookResult = ReturnType<typeof useSubscriptionsLazyQuery>;
export type SubscriptionsSuspenseQueryHookResult = ReturnType<typeof useSubscriptionsSuspenseQuery>;
export type SubscriptionsQueryResult = Apollo.QueryResult<SubscriptionsQuery, SubscriptionsQueryVariables>;
export function refetchSubscriptionsQuery(variables: SubscriptionsQueryVariables) {
      return { query: SubscriptionsDocument, variables: variables }
    }
export const PaymentMethodsDocument = gql`
    query paymentMethods($projectId: ID!) {
  paymentMethods(projectId: $projectId) {
    additional
    country
    default
    description
    gateway
    last4
    token
    type
    holder
    imageUrl
    zip
    expYear
    expMonth
  }
}
    `;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables> & ({ variables: PaymentMethodsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export function usePaymentMethodsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsSuspenseQueryHookResult = ReturnType<typeof usePaymentMethodsSuspenseQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export function refetchPaymentMethodsQuery(variables: PaymentMethodsQueryVariables) {
      return { query: PaymentMethodsDocument, variables: variables }
    }
export const GetCurrentSubscriptionDocument = gql`
    query getCurrentSubscription($projectId: ID!) {
  getCurrentSubscription(projectId: $projectId) {
    id
    name
    currency
    price
    currencySymbol
    status
    isExtendable
    period
    nextBillingDate
    frequencyLabel
    startBillingDate
    autoRenew
    summary
    package {
      id
    }
    plan {
      alias
      name
      icon
      agreement
      description
      price
      recommended
      moneyback
      popular
      cardRequired
      comingSoon
      billingFrequency
      trialDays
      icon
      upgradable
    }
  }
}
    `;

/**
 * __useGetCurrentSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetCurrentSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSubscriptionQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCurrentSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentSubscriptionQuery, GetCurrentSubscriptionQueryVariables> & ({ variables: GetCurrentSubscriptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentSubscriptionQuery, GetCurrentSubscriptionQueryVariables>(GetCurrentSubscriptionDocument, options);
      }
export function useGetCurrentSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentSubscriptionQuery, GetCurrentSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentSubscriptionQuery, GetCurrentSubscriptionQueryVariables>(GetCurrentSubscriptionDocument, options);
        }
export function useGetCurrentSubscriptionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrentSubscriptionQuery, GetCurrentSubscriptionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentSubscriptionQuery, GetCurrentSubscriptionQueryVariables>(GetCurrentSubscriptionDocument, options);
        }
export type GetCurrentSubscriptionQueryHookResult = ReturnType<typeof useGetCurrentSubscriptionQuery>;
export type GetCurrentSubscriptionLazyQueryHookResult = ReturnType<typeof useGetCurrentSubscriptionLazyQuery>;
export type GetCurrentSubscriptionSuspenseQueryHookResult = ReturnType<typeof useGetCurrentSubscriptionSuspenseQuery>;
export type GetCurrentSubscriptionQueryResult = Apollo.QueryResult<GetCurrentSubscriptionQuery, GetCurrentSubscriptionQueryVariables>;
export function refetchGetCurrentSubscriptionQuery(variables: GetCurrentSubscriptionQueryVariables) {
      return { query: GetCurrentSubscriptionDocument, variables: variables }
    }
export const PaymentTokenDocument = gql`
    query paymentToken($projectId: ID!) {
  paymentToken(projectId: $projectId) {
    userId
    paymentToken {
      api_key
      client_secret
      payment_method
      id
      object
      application
      cancellation_reason
      created
      customer
      last_setup_error
      livemode
      next_action
      object
      usage
      payment_method
      payment_method_options
      payment_method_types
      single_use_mandate
    }
  }
}
    `;

/**
 * __usePaymentTokenQuery__
 *
 * To run a query within a React component, call `usePaymentTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentTokenQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePaymentTokenQuery(baseOptions: Apollo.QueryHookOptions<PaymentTokenQuery, PaymentTokenQueryVariables> & ({ variables: PaymentTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentTokenQuery, PaymentTokenQueryVariables>(PaymentTokenDocument, options);
      }
export function usePaymentTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentTokenQuery, PaymentTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentTokenQuery, PaymentTokenQueryVariables>(PaymentTokenDocument, options);
        }
export function usePaymentTokenSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentTokenQuery, PaymentTokenQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentTokenQuery, PaymentTokenQueryVariables>(PaymentTokenDocument, options);
        }
export type PaymentTokenQueryHookResult = ReturnType<typeof usePaymentTokenQuery>;
export type PaymentTokenLazyQueryHookResult = ReturnType<typeof usePaymentTokenLazyQuery>;
export type PaymentTokenSuspenseQueryHookResult = ReturnType<typeof usePaymentTokenSuspenseQuery>;
export type PaymentTokenQueryResult = Apollo.QueryResult<PaymentTokenQuery, PaymentTokenQueryVariables>;
export function refetchPaymentTokenQuery(variables: PaymentTokenQueryVariables) {
      return { query: PaymentTokenDocument, variables: variables }
    }
export const GetCurrentPaymentMethodDocument = gql`
    query getCurrentPaymentMethod($projectId: ID!) {
  getCurrentPaymentMethod(projectId: $projectId) {
    additional
    country
    default
    description
    gateway
    holder
    imageUrl
    last4
    token
    type
    zip
    expYear
    expMonth
  }
}
    `;

/**
 * __useGetCurrentPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetCurrentPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentPaymentMethodQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCurrentPaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentPaymentMethodQuery, GetCurrentPaymentMethodQueryVariables> & ({ variables: GetCurrentPaymentMethodQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentPaymentMethodQuery, GetCurrentPaymentMethodQueryVariables>(GetCurrentPaymentMethodDocument, options);
      }
export function useGetCurrentPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentPaymentMethodQuery, GetCurrentPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentPaymentMethodQuery, GetCurrentPaymentMethodQueryVariables>(GetCurrentPaymentMethodDocument, options);
        }
export function useGetCurrentPaymentMethodSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrentPaymentMethodQuery, GetCurrentPaymentMethodQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentPaymentMethodQuery, GetCurrentPaymentMethodQueryVariables>(GetCurrentPaymentMethodDocument, options);
        }
export type GetCurrentPaymentMethodQueryHookResult = ReturnType<typeof useGetCurrentPaymentMethodQuery>;
export type GetCurrentPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetCurrentPaymentMethodLazyQuery>;
export type GetCurrentPaymentMethodSuspenseQueryHookResult = ReturnType<typeof useGetCurrentPaymentMethodSuspenseQuery>;
export type GetCurrentPaymentMethodQueryResult = Apollo.QueryResult<GetCurrentPaymentMethodQuery, GetCurrentPaymentMethodQueryVariables>;
export function refetchGetCurrentPaymentMethodQuery(variables: GetCurrentPaymentMethodQueryVariables) {
      return { query: GetCurrentPaymentMethodDocument, variables: variables }
    }
export const PlansDocument = gql`
    query plans($input: PlansQueryInput) {
  plans(input: $input) {
    id
    alias
    name
    activeFeatures {
      id
      title
      description
      limit
    }
    description
    recommended
    price
    billingFrequency
    upgradable
    trialDays
    summary
    cardRequired
    ordering
    current
    currencySymbol
    package {
      id
    }
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export function usePlansSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansSuspenseQueryHookResult = ReturnType<typeof usePlansSuspenseQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export function refetchPlansQuery(variables?: PlansQueryVariables) {
      return { query: PlansDocument, variables: variables }
    }
export const PlanDocument = gql`
    query plan($input: PlanQueryInput) {
  retrievePlan(input: $input) {
    id
    alias
    name
    description
    recommended
    price
    billingFrequency
    upgradable
    trialDays
    summary
    cardRequired
    ordering
    current
    currencySymbol
    package {
      id
    }
    error
  }
}
    `;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlanQuery(baseOptions?: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
      }
export function usePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
        }
export function usePlanSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PlanQuery, PlanQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
        }
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanSuspenseQueryHookResult = ReturnType<typeof usePlanSuspenseQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;
export function refetchPlanQuery(variables?: PlanQueryVariables) {
      return { query: PlanDocument, variables: variables }
    }
export const FeatureGroupsDocument = gql`
    query featureGroups($planAlias: String!) {
  featureGroups(planAlias: $planAlias) {
    title
    alias
    features {
      id
      title
      description
      ordering
      group {
        id
        title
        alias
      }
      packageType
      limit
      selected
    }
  }
}
    `;

/**
 * __useFeatureGroupsQuery__
 *
 * To run a query within a React component, call `useFeatureGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureGroupsQuery({
 *   variables: {
 *      planAlias: // value for 'planAlias'
 *   },
 * });
 */
export function useFeatureGroupsQuery(baseOptions: Apollo.QueryHookOptions<FeatureGroupsQuery, FeatureGroupsQueryVariables> & ({ variables: FeatureGroupsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureGroupsQuery, FeatureGroupsQueryVariables>(FeatureGroupsDocument, options);
      }
export function useFeatureGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureGroupsQuery, FeatureGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureGroupsQuery, FeatureGroupsQueryVariables>(FeatureGroupsDocument, options);
        }
export function useFeatureGroupsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FeatureGroupsQuery, FeatureGroupsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeatureGroupsQuery, FeatureGroupsQueryVariables>(FeatureGroupsDocument, options);
        }
export type FeatureGroupsQueryHookResult = ReturnType<typeof useFeatureGroupsQuery>;
export type FeatureGroupsLazyQueryHookResult = ReturnType<typeof useFeatureGroupsLazyQuery>;
export type FeatureGroupsSuspenseQueryHookResult = ReturnType<typeof useFeatureGroupsSuspenseQuery>;
export type FeatureGroupsQueryResult = Apollo.QueryResult<FeatureGroupsQuery, FeatureGroupsQueryVariables>;
export function refetchFeatureGroupsQuery(variables: FeatureGroupsQueryVariables) {
      return { query: FeatureGroupsDocument, variables: variables }
    }
export const GetTransactionsDocument = gql`
    query getTransactions($first: Int, $subscriptionId: ID!) {
  getTransactions(first: $first, subscriptionId: $subscriptionId) {
    id
    name
    createdAt
    currency
    currencySymbol
    price
    status
    summary
    invoiceUrl
  }
}
    `;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables> & ({ variables: GetTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
      }
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
        }
export function useGetTransactionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsSuspenseQueryHookResult = ReturnType<typeof useGetTransactionsSuspenseQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export function refetchGetTransactionsQuery(variables: GetTransactionsQueryVariables) {
      return { query: GetTransactionsDocument, variables: variables }
    }
export const SearchDomainAvailabilityDocument = gql`
    query searchDomainAvailability($input: CheckDomainInput!) {
  searchDomainAvailability(input: $input) {
    domain
    name
    available
    price
    currencySymbol
    currency
    domainName
    billingFrequencies {
      id
      price
      frequency
    }
  }
}
    `;

/**
 * __useSearchDomainAvailabilityQuery__
 *
 * To run a query within a React component, call `useSearchDomainAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDomainAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDomainAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchDomainAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<SearchDomainAvailabilityQuery, SearchDomainAvailabilityQueryVariables> & ({ variables: SearchDomainAvailabilityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDomainAvailabilityQuery, SearchDomainAvailabilityQueryVariables>(SearchDomainAvailabilityDocument, options);
      }
export function useSearchDomainAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDomainAvailabilityQuery, SearchDomainAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDomainAvailabilityQuery, SearchDomainAvailabilityQueryVariables>(SearchDomainAvailabilityDocument, options);
        }
export function useSearchDomainAvailabilitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchDomainAvailabilityQuery, SearchDomainAvailabilityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchDomainAvailabilityQuery, SearchDomainAvailabilityQueryVariables>(SearchDomainAvailabilityDocument, options);
        }
export type SearchDomainAvailabilityQueryHookResult = ReturnType<typeof useSearchDomainAvailabilityQuery>;
export type SearchDomainAvailabilityLazyQueryHookResult = ReturnType<typeof useSearchDomainAvailabilityLazyQuery>;
export type SearchDomainAvailabilitySuspenseQueryHookResult = ReturnType<typeof useSearchDomainAvailabilitySuspenseQuery>;
export type SearchDomainAvailabilityQueryResult = Apollo.QueryResult<SearchDomainAvailabilityQuery, SearchDomainAvailabilityQueryVariables>;
export function refetchSearchDomainAvailabilityQuery(variables: SearchDomainAvailabilityQueryVariables) {
      return { query: SearchDomainAvailabilityDocument, variables: variables }
    }
export const ActivityRecordsDocument = gql`
    query activityRecords($input: PaginateActivityRecordInput!) {
  activityRecords(input: $input) {
    data {
      id
      action
      dateTime
      description
      geographicInfo {
        city
        country
        ip
      }
      userAgent {
        browser
        operatingSystem
      }
      user {
        id
        firstName
        lastName
        email
        avatar
        role
      }
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${PaginatorInfoFragmentFragmentDoc}`;

/**
 * __useActivityRecordsQuery__
 *
 * To run a query within a React component, call `useActivityRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityRecordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivityRecordsQuery(baseOptions: Apollo.QueryHookOptions<ActivityRecordsQuery, ActivityRecordsQueryVariables> & ({ variables: ActivityRecordsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityRecordsQuery, ActivityRecordsQueryVariables>(ActivityRecordsDocument, options);
      }
export function useActivityRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityRecordsQuery, ActivityRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityRecordsQuery, ActivityRecordsQueryVariables>(ActivityRecordsDocument, options);
        }
export function useActivityRecordsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActivityRecordsQuery, ActivityRecordsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActivityRecordsQuery, ActivityRecordsQueryVariables>(ActivityRecordsDocument, options);
        }
export type ActivityRecordsQueryHookResult = ReturnType<typeof useActivityRecordsQuery>;
export type ActivityRecordsLazyQueryHookResult = ReturnType<typeof useActivityRecordsLazyQuery>;
export type ActivityRecordsSuspenseQueryHookResult = ReturnType<typeof useActivityRecordsSuspenseQuery>;
export type ActivityRecordsQueryResult = Apollo.QueryResult<ActivityRecordsQuery, ActivityRecordsQueryVariables>;
export function refetchActivityRecordsQuery(variables: ActivityRecordsQueryVariables) {
      return { query: ActivityRecordsDocument, variables: variables }
    }
export const ActivityRecordDocument = gql`
    query activityRecord($input: ActivityRecordInput!) {
  activityRecord(input: $input) {
    id
    action
    dateTime
    description
    geographicInfo {
      city
      country
      ip
    }
    userAgent {
      browser
      operatingSystem
    }
    user {
      id
      firstName
      lastName
      email
      avatar
      role
    }
  }
}
    `;

/**
 * __useActivityRecordQuery__
 *
 * To run a query within a React component, call `useActivityRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityRecordQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivityRecordQuery(baseOptions: Apollo.QueryHookOptions<ActivityRecordQuery, ActivityRecordQueryVariables> & ({ variables: ActivityRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityRecordQuery, ActivityRecordQueryVariables>(ActivityRecordDocument, options);
      }
export function useActivityRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityRecordQuery, ActivityRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityRecordQuery, ActivityRecordQueryVariables>(ActivityRecordDocument, options);
        }
export function useActivityRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActivityRecordQuery, ActivityRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActivityRecordQuery, ActivityRecordQueryVariables>(ActivityRecordDocument, options);
        }
export type ActivityRecordQueryHookResult = ReturnType<typeof useActivityRecordQuery>;
export type ActivityRecordLazyQueryHookResult = ReturnType<typeof useActivityRecordLazyQuery>;
export type ActivityRecordSuspenseQueryHookResult = ReturnType<typeof useActivityRecordSuspenseQuery>;
export type ActivityRecordQueryResult = Apollo.QueryResult<ActivityRecordQuery, ActivityRecordQueryVariables>;
export function refetchActivityRecordQuery(variables: ActivityRecordQueryVariables) {
      return { query: ActivityRecordDocument, variables: variables }
    }
export const UserDocument = gql`
    query user($projectId: ID!) {
  authUser {
    role(projectId: $projectId) {
      name
      id
    }
    language
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export function refetchUserQuery(variables: UserQueryVariables) {
      return { query: UserDocument, variables: variables }
    }
export const IndustriesDocument = gql`
    query industries {
  industries {
    name
    id
    alias
  }
}
    `;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, options);
      }
export function useIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, options);
        }
export function useIndustriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, options);
        }
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesSuspenseQueryHookResult = ReturnType<typeof useIndustriesSuspenseQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<IndustriesQuery, IndustriesQueryVariables>;
export function refetchIndustriesQuery(variables?: IndustriesQueryVariables) {
      return { query: IndustriesDocument, variables: variables }
    }
export const ActivityRecordsAvailableFiltersDocument = gql`
    query activityRecordsAvailableFilters($input: ActivityRecordsAvailableFilterInput) {
  activityRecordsAvailableFilters(input: $input) {
    filter
    options
  }
}
    `;

/**
 * __useActivityRecordsAvailableFiltersQuery__
 *
 * To run a query within a React component, call `useActivityRecordsAvailableFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityRecordsAvailableFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityRecordsAvailableFiltersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivityRecordsAvailableFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ActivityRecordsAvailableFiltersQuery, ActivityRecordsAvailableFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityRecordsAvailableFiltersQuery, ActivityRecordsAvailableFiltersQueryVariables>(ActivityRecordsAvailableFiltersDocument, options);
      }
export function useActivityRecordsAvailableFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityRecordsAvailableFiltersQuery, ActivityRecordsAvailableFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityRecordsAvailableFiltersQuery, ActivityRecordsAvailableFiltersQueryVariables>(ActivityRecordsAvailableFiltersDocument, options);
        }
export function useActivityRecordsAvailableFiltersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActivityRecordsAvailableFiltersQuery, ActivityRecordsAvailableFiltersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActivityRecordsAvailableFiltersQuery, ActivityRecordsAvailableFiltersQueryVariables>(ActivityRecordsAvailableFiltersDocument, options);
        }
export type ActivityRecordsAvailableFiltersQueryHookResult = ReturnType<typeof useActivityRecordsAvailableFiltersQuery>;
export type ActivityRecordsAvailableFiltersLazyQueryHookResult = ReturnType<typeof useActivityRecordsAvailableFiltersLazyQuery>;
export type ActivityRecordsAvailableFiltersSuspenseQueryHookResult = ReturnType<typeof useActivityRecordsAvailableFiltersSuspenseQuery>;
export type ActivityRecordsAvailableFiltersQueryResult = Apollo.QueryResult<ActivityRecordsAvailableFiltersQuery, ActivityRecordsAvailableFiltersQueryVariables>;
export function refetchActivityRecordsAvailableFiltersQuery(variables?: ActivityRecordsAvailableFiltersQueryVariables) {
      return { query: ActivityRecordsAvailableFiltersDocument, variables: variables }
    }
export const EntitiesToDisableDocument = gql`
    query entitiesToDisable($projectId: ID!, $planAlias: String!) {
  entitiesToDisable(projectId: $projectId, planAlias: $planAlias) {
    limitations {
      entity
      limit
      count
    }
    payment
    shipping
    domain
    product
  }
}
    `;

/**
 * __useEntitiesToDisableQuery__
 *
 * To run a query within a React component, call `useEntitiesToDisableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitiesToDisableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitiesToDisableQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      planAlias: // value for 'planAlias'
 *   },
 * });
 */
export function useEntitiesToDisableQuery(baseOptions: Apollo.QueryHookOptions<EntitiesToDisableQuery, EntitiesToDisableQueryVariables> & ({ variables: EntitiesToDisableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EntitiesToDisableQuery, EntitiesToDisableQueryVariables>(EntitiesToDisableDocument, options);
      }
export function useEntitiesToDisableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EntitiesToDisableQuery, EntitiesToDisableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EntitiesToDisableQuery, EntitiesToDisableQueryVariables>(EntitiesToDisableDocument, options);
        }
export function useEntitiesToDisableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EntitiesToDisableQuery, EntitiesToDisableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EntitiesToDisableQuery, EntitiesToDisableQueryVariables>(EntitiesToDisableDocument, options);
        }
export type EntitiesToDisableQueryHookResult = ReturnType<typeof useEntitiesToDisableQuery>;
export type EntitiesToDisableLazyQueryHookResult = ReturnType<typeof useEntitiesToDisableLazyQuery>;
export type EntitiesToDisableSuspenseQueryHookResult = ReturnType<typeof useEntitiesToDisableSuspenseQuery>;
export type EntitiesToDisableQueryResult = Apollo.QueryResult<EntitiesToDisableQuery, EntitiesToDisableQueryVariables>;
export function refetchEntitiesToDisableQuery(variables: EntitiesToDisableQueryVariables) {
      return { query: EntitiesToDisableDocument, variables: variables }
    }
export const ProjectGroupedPermissionsDocument = gql`
    query projectGroupedPermissions($projectId: ID!) {
  projectGroupedPermissions(projectId: $projectId) {
    id
    name
    alias
    description
    permissions {
      id
      name
      alias
      description
    }
  }
}
    `;

/**
 * __useProjectGroupedPermissionsQuery__
 *
 * To run a query within a React component, call `useProjectGroupedPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGroupedPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGroupedPermissionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectGroupedPermissionsQuery(baseOptions: Apollo.QueryHookOptions<ProjectGroupedPermissionsQuery, ProjectGroupedPermissionsQueryVariables> & ({ variables: ProjectGroupedPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectGroupedPermissionsQuery, ProjectGroupedPermissionsQueryVariables>(ProjectGroupedPermissionsDocument, options);
      }
export function useProjectGroupedPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectGroupedPermissionsQuery, ProjectGroupedPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectGroupedPermissionsQuery, ProjectGroupedPermissionsQueryVariables>(ProjectGroupedPermissionsDocument, options);
        }
export function useProjectGroupedPermissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectGroupedPermissionsQuery, ProjectGroupedPermissionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectGroupedPermissionsQuery, ProjectGroupedPermissionsQueryVariables>(ProjectGroupedPermissionsDocument, options);
        }
export type ProjectGroupedPermissionsQueryHookResult = ReturnType<typeof useProjectGroupedPermissionsQuery>;
export type ProjectGroupedPermissionsLazyQueryHookResult = ReturnType<typeof useProjectGroupedPermissionsLazyQuery>;
export type ProjectGroupedPermissionsSuspenseQueryHookResult = ReturnType<typeof useProjectGroupedPermissionsSuspenseQuery>;
export type ProjectGroupedPermissionsQueryResult = Apollo.QueryResult<ProjectGroupedPermissionsQuery, ProjectGroupedPermissionsQueryVariables>;
export function refetchProjectGroupedPermissionsQuery(variables: ProjectGroupedPermissionsQueryVariables) {
      return { query: ProjectGroupedPermissionsDocument, variables: variables }
    }
export const ConnectedDomainsDocument = gql`
    query connectedDomains($projectId: ID!) {
  connectedDomains(projectId: $projectId) {
    id
    domain
    isBoughtFromUs
  }
}
    `;

/**
 * __useConnectedDomainsQuery__
 *
 * To run a query within a React component, call `useConnectedDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedDomainsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useConnectedDomainsQuery(baseOptions: Apollo.QueryHookOptions<ConnectedDomainsQuery, ConnectedDomainsQueryVariables> & ({ variables: ConnectedDomainsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectedDomainsQuery, ConnectedDomainsQueryVariables>(ConnectedDomainsDocument, options);
      }
export function useConnectedDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectedDomainsQuery, ConnectedDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectedDomainsQuery, ConnectedDomainsQueryVariables>(ConnectedDomainsDocument, options);
        }
export function useConnectedDomainsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ConnectedDomainsQuery, ConnectedDomainsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConnectedDomainsQuery, ConnectedDomainsQueryVariables>(ConnectedDomainsDocument, options);
        }
export type ConnectedDomainsQueryHookResult = ReturnType<typeof useConnectedDomainsQuery>;
export type ConnectedDomainsLazyQueryHookResult = ReturnType<typeof useConnectedDomainsLazyQuery>;
export type ConnectedDomainsSuspenseQueryHookResult = ReturnType<typeof useConnectedDomainsSuspenseQuery>;
export type ConnectedDomainsQueryResult = Apollo.QueryResult<ConnectedDomainsQuery, ConnectedDomainsQueryVariables>;
export function refetchConnectedDomainsQuery(variables: ConnectedDomainsQueryVariables) {
      return { query: ConnectedDomainsDocument, variables: variables }
    }
export const CheckProjectEmailSenderDomainSettingsDocument = gql`
    query checkProjectEmailSenderDomainSettings($input: CheckProjectEmailSenderDomainSettingsInput!) {
  checkProjectEmailSenderDomainSettings(input: $input) {
    isAllVerified
    records {
      type
      name
      value
      valueEscaped
      isVerified
      dnsRecordType
    }
  }
}
    `;

/**
 * __useCheckProjectEmailSenderDomainSettingsQuery__
 *
 * To run a query within a React component, call `useCheckProjectEmailSenderDomainSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckProjectEmailSenderDomainSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckProjectEmailSenderDomainSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckProjectEmailSenderDomainSettingsQuery(baseOptions: Apollo.QueryHookOptions<CheckProjectEmailSenderDomainSettingsQuery, CheckProjectEmailSenderDomainSettingsQueryVariables> & ({ variables: CheckProjectEmailSenderDomainSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckProjectEmailSenderDomainSettingsQuery, CheckProjectEmailSenderDomainSettingsQueryVariables>(CheckProjectEmailSenderDomainSettingsDocument, options);
      }
export function useCheckProjectEmailSenderDomainSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckProjectEmailSenderDomainSettingsQuery, CheckProjectEmailSenderDomainSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckProjectEmailSenderDomainSettingsQuery, CheckProjectEmailSenderDomainSettingsQueryVariables>(CheckProjectEmailSenderDomainSettingsDocument, options);
        }
export function useCheckProjectEmailSenderDomainSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckProjectEmailSenderDomainSettingsQuery, CheckProjectEmailSenderDomainSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckProjectEmailSenderDomainSettingsQuery, CheckProjectEmailSenderDomainSettingsQueryVariables>(CheckProjectEmailSenderDomainSettingsDocument, options);
        }
export type CheckProjectEmailSenderDomainSettingsQueryHookResult = ReturnType<typeof useCheckProjectEmailSenderDomainSettingsQuery>;
export type CheckProjectEmailSenderDomainSettingsLazyQueryHookResult = ReturnType<typeof useCheckProjectEmailSenderDomainSettingsLazyQuery>;
export type CheckProjectEmailSenderDomainSettingsSuspenseQueryHookResult = ReturnType<typeof useCheckProjectEmailSenderDomainSettingsSuspenseQuery>;
export type CheckProjectEmailSenderDomainSettingsQueryResult = Apollo.QueryResult<CheckProjectEmailSenderDomainSettingsQuery, CheckProjectEmailSenderDomainSettingsQueryVariables>;
export function refetchCheckProjectEmailSenderDomainSettingsQuery(variables: CheckProjectEmailSenderDomainSettingsQueryVariables) {
      return { query: CheckProjectEmailSenderDomainSettingsDocument, variables: variables }
    }
export const GetCurrentPurchaseDocument = gql`
    query getCurrentPurchase($projectId: ID!, $alias: String!, $packageType: String!) {
  getCurrentPurchase(
    projectId: $projectId
    alias: $alias
    packageType: $packageType
  ) {
    id
    name
    currentSubscription {
      id
      name
      currency
      price
      frequencyLabel
      status
      nextBillingDate
      startBillingDate
      autoRenew
      daysLeft
    }
  }
}
    `;

/**
 * __useGetCurrentPurchaseQuery__
 *
 * To run a query within a React component, call `useGetCurrentPurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentPurchaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentPurchaseQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      alias: // value for 'alias'
 *      packageType: // value for 'packageType'
 *   },
 * });
 */
export function useGetCurrentPurchaseQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentPurchaseQuery, GetCurrentPurchaseQueryVariables> & ({ variables: GetCurrentPurchaseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentPurchaseQuery, GetCurrentPurchaseQueryVariables>(GetCurrentPurchaseDocument, options);
      }
export function useGetCurrentPurchaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentPurchaseQuery, GetCurrentPurchaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentPurchaseQuery, GetCurrentPurchaseQueryVariables>(GetCurrentPurchaseDocument, options);
        }
export function useGetCurrentPurchaseSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrentPurchaseQuery, GetCurrentPurchaseQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentPurchaseQuery, GetCurrentPurchaseQueryVariables>(GetCurrentPurchaseDocument, options);
        }
export type GetCurrentPurchaseQueryHookResult = ReturnType<typeof useGetCurrentPurchaseQuery>;
export type GetCurrentPurchaseLazyQueryHookResult = ReturnType<typeof useGetCurrentPurchaseLazyQuery>;
export type GetCurrentPurchaseSuspenseQueryHookResult = ReturnType<typeof useGetCurrentPurchaseSuspenseQuery>;
export type GetCurrentPurchaseQueryResult = Apollo.QueryResult<GetCurrentPurchaseQuery, GetCurrentPurchaseQueryVariables>;
export function refetchGetCurrentPurchaseQuery(variables: GetCurrentPurchaseQueryVariables) {
      return { query: GetCurrentPurchaseDocument, variables: variables }
    }
export const SubscriptionsPaginatedDocument = gql`
    query subscriptionsPaginated($projectId: ID!, $alias: String!, $packageType: String!, $fromDate: DateTimeUnix, $toDate: DateTimeUnix, $page: Int, $first: Int) {
  subscriptionsPaginated(
    projectId: $projectId
    alias: $alias
    packageType: $packageType
    fromDate: $fromDate
    toDate: $toDate
    page: $page
    first: $first
  ) {
    data {
      id
      name
      currency
      price
      summary
      currencySymbol
      frequencyLabel
      status
      period
      nextBillingDate
      startBillingDate
      autoRenew
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${PaginatorInfoFragmentFragmentDoc}`;

/**
 * __useSubscriptionsPaginatedQuery__
 *
 * To run a query within a React component, call `useSubscriptionsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsPaginatedQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      alias: // value for 'alias'
 *      packageType: // value for 'packageType'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSubscriptionsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionsPaginatedQuery, SubscriptionsPaginatedQueryVariables> & ({ variables: SubscriptionsPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsPaginatedQuery, SubscriptionsPaginatedQueryVariables>(SubscriptionsPaginatedDocument, options);
      }
export function useSubscriptionsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsPaginatedQuery, SubscriptionsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsPaginatedQuery, SubscriptionsPaginatedQueryVariables>(SubscriptionsPaginatedDocument, options);
        }
export function useSubscriptionsPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubscriptionsPaginatedQuery, SubscriptionsPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionsPaginatedQuery, SubscriptionsPaginatedQueryVariables>(SubscriptionsPaginatedDocument, options);
        }
export type SubscriptionsPaginatedQueryHookResult = ReturnType<typeof useSubscriptionsPaginatedQuery>;
export type SubscriptionsPaginatedLazyQueryHookResult = ReturnType<typeof useSubscriptionsPaginatedLazyQuery>;
export type SubscriptionsPaginatedSuspenseQueryHookResult = ReturnType<typeof useSubscriptionsPaginatedSuspenseQuery>;
export type SubscriptionsPaginatedQueryResult = Apollo.QueryResult<SubscriptionsPaginatedQuery, SubscriptionsPaginatedQueryVariables>;
export function refetchSubscriptionsPaginatedQuery(variables: SubscriptionsPaginatedQueryVariables) {
      return { query: SubscriptionsPaginatedDocument, variables: variables }
    }
export const GetUserProjectNotificationSettingsDocument = gql`
    query getUserProjectNotificationSettings($input: UserProjectNotificationSettingsInput!) {
  getUserProjectNotificationSettings(input: $input) {
    receiveMailNotification
    receivePushNotification
  }
}
    `;

/**
 * __useGetUserProjectNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserProjectNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProjectNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProjectNotificationSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserProjectNotificationSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetUserProjectNotificationSettingsQuery, GetUserProjectNotificationSettingsQueryVariables> & ({ variables: GetUserProjectNotificationSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProjectNotificationSettingsQuery, GetUserProjectNotificationSettingsQueryVariables>(GetUserProjectNotificationSettingsDocument, options);
      }
export function useGetUserProjectNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProjectNotificationSettingsQuery, GetUserProjectNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProjectNotificationSettingsQuery, GetUserProjectNotificationSettingsQueryVariables>(GetUserProjectNotificationSettingsDocument, options);
        }
export function useGetUserProjectNotificationSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserProjectNotificationSettingsQuery, GetUserProjectNotificationSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserProjectNotificationSettingsQuery, GetUserProjectNotificationSettingsQueryVariables>(GetUserProjectNotificationSettingsDocument, options);
        }
export type GetUserProjectNotificationSettingsQueryHookResult = ReturnType<typeof useGetUserProjectNotificationSettingsQuery>;
export type GetUserProjectNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetUserProjectNotificationSettingsLazyQuery>;
export type GetUserProjectNotificationSettingsSuspenseQueryHookResult = ReturnType<typeof useGetUserProjectNotificationSettingsSuspenseQuery>;
export type GetUserProjectNotificationSettingsQueryResult = Apollo.QueryResult<GetUserProjectNotificationSettingsQuery, GetUserProjectNotificationSettingsQueryVariables>;
export function refetchGetUserProjectNotificationSettingsQuery(variables: GetUserProjectNotificationSettingsQueryVariables) {
      return { query: GetUserProjectNotificationSettingsDocument, variables: variables }
    }
export const TransactionsPaginatedDocument = gql`
    query transactionsPaginated($subscriptionId: ID!, $page: Int, $first: Int) {
  transactionsPaginated(
    subscriptionId: $subscriptionId
    first: $first
    page: $page
  ) {
    data {
      id
      name
      createdAt
      currency
      currencySymbol
      price
      summary
      discount
      status
      invoiceUrl
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${PaginatorInfoFragmentFragmentDoc}`;

/**
 * __useTransactionsPaginatedQuery__
 *
 * To run a query within a React component, call `useTransactionsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsPaginatedQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTransactionsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<TransactionsPaginatedQuery, TransactionsPaginatedQueryVariables> & ({ variables: TransactionsPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsPaginatedQuery, TransactionsPaginatedQueryVariables>(TransactionsPaginatedDocument, options);
      }
export function useTransactionsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsPaginatedQuery, TransactionsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsPaginatedQuery, TransactionsPaginatedQueryVariables>(TransactionsPaginatedDocument, options);
        }
export function useTransactionsPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TransactionsPaginatedQuery, TransactionsPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TransactionsPaginatedQuery, TransactionsPaginatedQueryVariables>(TransactionsPaginatedDocument, options);
        }
export type TransactionsPaginatedQueryHookResult = ReturnType<typeof useTransactionsPaginatedQuery>;
export type TransactionsPaginatedLazyQueryHookResult = ReturnType<typeof useTransactionsPaginatedLazyQuery>;
export type TransactionsPaginatedSuspenseQueryHookResult = ReturnType<typeof useTransactionsPaginatedSuspenseQuery>;
export type TransactionsPaginatedQueryResult = Apollo.QueryResult<TransactionsPaginatedQuery, TransactionsPaginatedQueryVariables>;
export function refetchTransactionsPaginatedQuery(variables: TransactionsPaginatedQueryVariables) {
      return { query: TransactionsPaginatedDocument, variables: variables }
    }