import { ProductMarketplaceStatus } from 'generatedHooks/commerce/generated';

export const SELLER_PRODUCTS_APPROVAL_STATUS_PROPS = {
  [ProductMarketplaceStatus.Pending]: {
    textKey: 'common:pending',
    status: 'warning'
  },
  [ProductMarketplaceStatus.Approved]: {
    textKey: 'common:approved',
    status: 'success'
  },
  [ProductMarketplaceStatus.Rejected]: {
    textKey: 'common:rejected',
    status: 'error'
  }
};
