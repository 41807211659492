import Api from 'axiosApi';
import SessionStorageManager from 'services/SessionStorageManager';
import { deleteOneSignalTags } from 'services/OneSignalService';
import isMarketplaceSellerMode from 'helpers/commonHelpers/isMarketplaceSellerMode';

const {
  VITE_APP_ACCOUNTS_URL,
  VITE_APP_COMMERCE_URL,
  VITE_APP_SELLER_LOGIN_URL
} = import.meta.env;

export const redirectToLogin = () => {
  const redirectPath = window.median ? '/sign-in' : '';

  window.open(`${VITE_APP_ACCOUNTS_URL}${redirectPath}`, '_top');
};

export const redirectToSellerLogin = () => {
  window.location.replace(VITE_APP_SELLER_LOGIN_URL);
};

export const redirectToAccounts = () => {
  const target = window.median ? '_top' : '';

  window.open(VITE_APP_ACCOUNTS_URL as string, target);
};

const redirect = () => {
  if (isMarketplaceSellerMode()) {
    redirectToSellerLogin();
  } else {
    redirectToAccounts();
  }
};

export const logoutAction = () => {
  if (isMarketplaceSellerMode()) {
    return Api.post(
      `${VITE_APP_COMMERCE_URL}/seller-api/logout`,
      {},
      {
        baseURL: ''
      }
    );
  }

  return Api.post('/api/logout');
};

export const logout = async () => {
  try {
    await logoutAction();
    SessionStorageManager.clearDateRange();

    await deleteOneSignalTags();
  } catch (e) {
    console.error(e);
  } finally {
    redirect();
  }
};
