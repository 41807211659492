import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterDrawer } from 'components/shared';
import { FilterProps } from './types';
import FilterElement from './components/FilterElement';
import FilterButton from 'components/shared/Filter/components/FilterButton';
import ResetButton from 'components/shared/Filter/components/ResetButton';

const { Footer } = FilterDrawer;

const UcFilter: FC<FilterProps> = ({
  visible,
  count,
  onClose,
  loading,
  title,
  resetFilters,
  filters,
  width
}) => {
  const { t } = useTranslation('common');

  const handleClose = () => {
    resetFilters();
    onClose();
  };

  return (
    <FilterDrawer
      title={title}
      width={width}
      visible={visible}
      onClose={onClose}
      rightBarValue={t('common:clear')}
      onResetFilters={handleClose}
      footer={
        <Footer
          loading={loading}
          hasChanges={false}
          onClose={handleClose}
          closeText={t('cancel', 'Cancel')}
          onOk={onClose}
          saveText={t(`filter.item${count === 1 ? '' : 's'}`, {
            count: count
          })}
        />
      }
    >
      {filters?.map((props, index) => <FilterElement key={index} {...props} />)}
    </FilterDrawer>
  );
};

const Filter = Object.assign(UcFilter, {
  Button: FilterButton,
  ResetButton
});

export default Filter;
