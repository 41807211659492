import { useEffect, useState } from 'react';

const useData = (loader?: () => Promise<{ data: any; error: any }>) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loader) {
      setLoading(true);
      loader().then(({ data, error }: { data: any; error: any }) => {
        setData(data);
        setError(error);
        setLoading(false);
      });
    }
  }, []);

  return { data, loading, error };
};

export default useData;
