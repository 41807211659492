import { ComponentType } from 'react';
import { currentUser } from 'apollo/cache';
import { useReactiveVar } from '@apollo/client';
import AccountVerification from 'routes/components/AccountVerification';
import PermissionProtectedRouteWrapper from 'routes/components/PermissionProtectedRouteWrapper';
import { RouteAliases } from 'routes/types';
import useProtectedRoute from 'routes/hooks/useProtectedRoute';
import isMarketplaceSellerMode from 'helpers/commonHelpers/isMarketplaceSellerMode';

import PageLayout from 'pages/projects/components/Layout';

export const protectedRoute = (
  Component: ComponentType<any>,
  routeAlias: RouteAliases = '' as RouteAliases
) => {
  return () => {
    const currUser = useReactiveVar(currentUser);

    useProtectedRoute();

    if (!isMarketplaceSellerMode() && currUser && !currUser.emailVerifiedAt) {
      return <AccountVerification />;
    }

    return (
      <PermissionProtectedRouteWrapper routeAlias={routeAlias}>
        {currUser &&
          (routeAlias === RouteAliases.PROJECTS_PAGE ? (
            <PageLayout user={currUser}>{<Component />}</PageLayout>
          ) : (
            <Component user={currUser} />
          ))}
      </PermissionProtectedRouteWrapper>
    );
  };
};
