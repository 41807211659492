import { ProductMarketplaceStatus } from 'generatedHooks/commerce/generated';

export enum ProductMarketplaceStatusColors {
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red'
}

export const PRODUCT_MARKETPLACE_STATUSES = [
  {
    value: ProductMarketplaceStatus.Pending,
    text: 'products:approvalStatuses.pending',
    color: ProductMarketplaceStatusColors.Yellow
  },
  {
    value: ProductMarketplaceStatus.Approved,
    text: 'products:approvalStatuses.approved',
    color: ProductMarketplaceStatusColors.Green
  },
  {
    value: ProductMarketplaceStatus.Rejected,
    text: 'products:approvalStatuses.rejected',
    color: ProductMarketplaceStatusColors.Red
  }
];
