import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import React, { ReactNode, ChangeEvent } from 'react';

type Props = {
  children: ReactNode;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  multiple?: boolean;
};

function FileInput({ children, onChange, accept, multiple = false }: Props) {
  return (
    <label
      style={{
        display: 'block',
        cursor: 'pointer',
        zIndex: 1,
        position: 'sticky'
      }}
    >
      <input
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Input,
          prefix: 'upload'
        })}
        style={{ display: 'none' }}
        type="file"
        onChange={onChange}
        accept={accept}
        multiple={multiple}
      />
      {children}
    </label>
  );
}

export default FileInput;
