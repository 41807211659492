import { TFunction } from 'react-i18next';
import { Actions } from 'providers/PermissionAndLimitationProvider';
import isMarketplaceSellerMode from 'helpers/commonHelpers/isMarketplaceSellerMode';

import { MenuProps } from './menuTypes';
import { SettingsTablesLazySkeleton } from './components/SettingsTablesLazySkeleton';
import { BillingsLazySkeleton } from './components/BillingsLazySkeleton';
import { SettingsListSkeleton } from './components/SettingsListSkeleton';
import CodeInjectionLazySkeleton from './components/CodeInjectionLazySkeleton';

const { See } = Actions;

const commonSettingsSections = (t: TFunction<'settings'>) => ({
  locations: {
    key: 'locations',
    tab: t('locations'),
    url: 'locations',
    fallback: SettingsListSkeleton
  },
  payments: {
    key: 'payments',
    tab: t('payments'),
    url: 'payments',
    fallback: SettingsListSkeleton
  },
  shipping: {
    key: 'shipping-delivery',
    tab: t('shippingDelivery'),
    url: 'shipping-delivery',
    fallback: SettingsListSkeleton
  },
  businessHours: {
    key: 'business-hours',
    tab: t('timeSlots'),
    url: 'business-hours',
    fallback: BillingsLazySkeleton
  }
});

export const data = (t: TFunction<'settings'>): MenuProps[] => {
  const { businessHours, shipping, payments, locations } =
    commonSettingsSections(t);

  if (isMarketplaceSellerMode()) {
    return [
      {
        key: 'seller-details',
        tab: t('seller-details'),
        url: 'seller-details',
        fallback: SettingsListSkeleton
      },
      locations,
      {
        key: 'payments',
        tab: t('payments'),
        url: 'payments',
        fallback: BillingsLazySkeleton //TODO-MARAT: Change this
      },
      shipping,
      businessHours
    ];
  }

  return [
    {
      key: 'project-setup',
      tab: t('project-setup'),
      url: 'project-setup',
      fallback: SettingsListSkeleton
    },
    locations,
    payments,
    {
      key: 'notifications',
      tab: t('notifications'),
      url: 'notifications',
      fallback: SettingsListSkeleton
    },
    {
      key: 'checkouts',
      tab: t('checkouts'),
      url: 'checkouts',
      fallback: SettingsListSkeleton,
      permission: {
        I: See.CustomCheckout
      }
    },
    shipping,
    {
      key: 'taxes',
      tab: t('taxes'),
      url: 'taxes',
      fallback: SettingsTablesLazySkeleton
    },
    {
      key: 'team',
      tab: t('team'),
      url: 'team',
      fallback: SettingsTablesLazySkeleton
    },
    {
      key: 'domains',
      tab: t('domains'),
      url: 'domains',
      fallback: SettingsTablesLazySkeleton
    },
    {
      key: 'languages',
      tab: t('languages'),
      url: 'languages',
      fallback: SettingsListSkeleton
    },
    {
      key: 'billing',
      tab: t('billing'),
      url: 'billing',
      fallback: BillingsLazySkeleton
    },
    {
      key: 'code-injection',
      tab: t('codeInjection'),
      url: 'code-injection',
      fallback: CodeInjectionLazySkeleton
    },
    businessHours,
    {
      key: 'legal-docs',
      tab: t('legalDocs'),
      url: 'legal-docs'
    }
  ];
};

export const generateSettingsTabsByPermissions = (
  t: TFunction<'settings'>,
  Can: any
) => {
  return data(t).reduce((acc: MenuProps[], curr) => {
    if (curr.permission) {
      const permissionProtectedTab = Can({
        I: curr.permission.I,
        RequestAccess: curr.permission.RequestAccess,
        children: curr
      });

      if (permissionProtectedTab) {
        acc.push(permissionProtectedTab);
      }
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);
};
