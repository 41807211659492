import { FC, useMemo, useState } from 'react';
import { Undefinable } from 'core/globalTypes';
import {
  getFullDateFormat,
  momentToString,
  stringToMoment
} from 'helpers/dateHelpers';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { DATE_FORMAT, FULL_DATE_FORMAT } from 'core/constants/date-constants';
import { useReactiveVar } from '@apollo/client';
import { coreConfigs } from 'apollo/cache';
import { useFilterQuery, useHistory } from 'hooks';
import { useTranslation } from 'react-i18next';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { DatePicker } from 'components/basic';
import { Value } from './types';

const { RangePicker } = DatePicker;

export type RangePickerFormatterProps = {
  value?: Value;
  onChange?: (dates: Value) => void;
  fullDate?: boolean;
  pushToUrl?: boolean;
  fromKey?: string;
  toKey?: string;
  defaultValue?: Value;
} & Omit<RangePickerProps, 'defaultValue' | 'value' | 'onChange'>;

const RangePickerFormatter: FC<RangePickerFormatterProps> = ({
  value,
  onChange,
  fullDate = false,
  pushToUrl = false,
  fromKey,
  toKey,
  defaultValue,
  ...rest
}) => {
  const { t } = useTranslation();
  const { queryObject } = useFilterQuery();

  const qsValue = useMemo(() => {
    if (
      !pushToUrl ||
      !(fromKey && queryObject[fromKey]) ||
      !(toKey && queryObject[toKey])
    ) {
      return null;
    }

    return [dayjs(queryObject[fromKey]), dayjs(queryObject[toKey])];
  }, [pushToUrl, queryObject]);

  const [rangePickerValue, setRangePickerValue] =
    useState<Undefinable<Value>>(defaultValue);

  const { date_format } = useReactiveVar(coreConfigs);
  const { push, resetQuery } = useHistory();

  const format = useMemo(
    () => (fullDate ? getFullDateFormat() : date_format),
    [fullDate, date_format]
  );

  const values = useMemo(
    () =>
      (value || rangePickerValue || qsValue)?.map(v => (v ? dayjs(v) : null)),
    [value, rangePickerValue]
  );

  const changeHandler = (parsedDates: Value) => {
    if (onChange) {
      onChange?.(parsedDates);
    } else {
      setRangePickerValue(parsedDates);
    }
  };

  const handleChange = (_: unknown, datesString: [string, string]) => {
    const beFormat = fullDate ? FULL_DATE_FORMAT : DATE_FORMAT;

    const parsedDates = datesString?.map(date =>
      momentToString(stringToMoment(date, format), beFormat)
    ) as Value;

    changeHandler(parsedDates);

    if (pushToUrl && fromKey && toKey) {
      const [from, to] = parsedDates;

      if (from && to) {
        push({
          [fromKey]: from,
          [toKey]: to
        });
      } else {
        resetQuery([fromKey, toKey]);
      }
    }
  };

  return (
    <RangePicker
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Component,
        prefix: 'range-date'
      })}
      value={values as RangePickerProps['value']}
      format={format}
      onChange={handleChange}
      showTime={fullDate}
      {...rest}
      placeholder={[
        t('common:startDatePlaceholder'),
        t('common:endDatePlaceholder')
      ]}
    />
  );
};

export default RangePickerFormatter;
