import { PERMISSION_CONSTANTS } from '../core/constants';

export const NON_MARKETPLACE_PERMISSIONS = [
  PERMISSION_CONSTANTS['See'].Shipments,
  PERMISSION_CONSTANTS['RequestAccess'].Shipments,
  PERMISSION_CONSTANTS['RequestAccess'].Discounts,
  PERMISSION_CONSTANTS['See'].Discounts,
  PERMISSION_CONSTANTS['See'].Orders,
  PERMISSION_CONSTANTS['RequestAccess'].Orders,
  PERMISSION_CONSTANTS['See'].OwnOrders,
  PERMISSION_CONSTANTS['See'].Products,
  PERMISSION_CONSTANTS['RequestAccess'].Products,
  PERMISSION_CONSTANTS['Manage'].OwnProducts
];
