import { UIEvent, Dispatch, SetStateAction } from 'react';
import { Button, Col, Row, Space, Tabs, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  PlayCircleFilled,
  CustomerServiceFilled,
  FolderFilled
} from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import { OperationVariables } from '@apollo/client/core';
import { LazyQueryExecFunction } from '@apollo/client';
import Modal from 'packages/Media/UIComponents/Modal';
import Overlay from 'packages/Media/UIComponents/Overlay';
import LazyImage from 'packages/Media/UIComponents/LazyImage/LazyImage';
import Uploader, { ListItemType } from 'packages/Media/UIComponents/Uploader';
import {
  mediaWithFolders,
  mediaWithFolders_mediaWithFolders_data
} from 'packages/Media/__generated__/types';
import Skeleton from 'packages/Media/Skeleton';
import { MediaModal, MediaWithoutFolder, PageType } from 'packages/Media/types';
import ActionBar from 'packages/Media/ActionBar';
import ImportFromUrlModal from 'packages/Media/ImportFromUrlModal';
import StockImages from 'packages/Media/StockImages';
import { mediaTypeMapper } from 'packages/Media/constants';
import Empty from 'packages/Media/Empty';
import Breadcrumb from 'packages/Media/Breadcrumb';
import styles from 'packages/Media/UploadFromMedia/styles.module.less';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Typography } from 'components/basic';
import { OrderingMenu } from 'hooks/useSortParams';
import { MediaWithFoldersData } from 'pages/products/products/Mobile/types';
import useMedia from 'hooks/useMedia';

const { UcHeading } = Typography;
const { TabPane } = Tabs;
const gridHeight = 82;
const gridHeightWithSelection = 65;
type MediaType = MediaWithoutFolder | null;

type Props = {
  items: any[];
  order: string;
  column: string;
  type: PageType;
  visible: boolean;
  loading: boolean;
  hiddenIds?: string[];
  onCancel: () => void;
  multiSelect?: boolean;
  orderBy: OrderingMenu;
  types: Array<PageType>;
  parentId: string | null;
  selectedMedias: string[];
  total: number | undefined;
  visibleModal: MediaModal | null;
  loadRemoteMediaLoading: boolean;
  renderPreview: () => JSX.Element;
  onInsert: (ids: string[]) => void;
  uploadList: ListItemType[] | null;
  getMediaById: (id: string) => any;
  onOverlayClick: (ard: string) => void;
  setPreviewMedia: (arg: MediaType) => void;
  setParentId: (arg: string | null) => void;
  orderMenuItems: {
    title: string;
    items: { text: string; onClick: () => void }[];
  }[];
  onLoadRemoteMedia: (url: string) => Promise<any>;
  onScroll: (e: UIEvent<HTMLElement> | Event) => void;
  onBreadcrumbClick: (id: string | null) => void;
  setSelectedMedias: (ids: string[] | []) => void;
  media: MediaWithFoldersData[] | [];
  setSearch: Dispatch<SetStateAction<string | null>>;
  handleImportFromUrlConfirm: (url: string) => Promise<void>;
  setVisibleModal: Dispatch<SetStateAction<MediaModal | null>>;
  getAddNewMenuItems: () => {
    title: string;
    items: (
      | { text: string; icon: JSX.Element; onClick: () => void }
      | { render: JSX.Element }
    )[];
  }[];
  setUploadList: Dispatch<SetStateAction<ListItemType[] | null>>;
  getImages: LazyQueryExecFunction<mediaWithFolders, OperationVariables>;
  onTabChange: (tab: string) => void;
};

function UploadFromMedia({
  type,
  types,
  items,
  media,
  order,
  total,
  column,
  orderBy,
  loading,
  visible,
  onCancel,
  onInsert,
  parentId,
  onScroll,
  getImages,
  setSearch,
  uploadList,
  setParentId,
  visibleModal,
  getMediaById,
  setUploadList,
  renderPreview,
  hiddenIds = [],
  selectedMedias,
  orderMenuItems,
  onOverlayClick,
  setVisibleModal,
  setPreviewMedia,
  onLoadRemoteMedia,
  setSelectedMedias,
  onBreadcrumbClick,
  getAddNewMenuItems,
  multiSelect = false,
  loadRemoteMediaLoading,
  handleImportFromUrlConfirm,
  onTabChange
}: Props) {
  const { t } = useTranslation(['media', 'common']);
  const { thumbnailPath } = useMedia();

  return (
    <Modal
      afterClose={() => setSelectedMedias([])}
      className={styles.Modal}
      title={
        <div className={styles.ModalHeader}>
          <Space size={32}>
            <UcHeading
              ellipsis
              fontWeight="regular"
              level={5}
              style={{ margin: '16px 0' }}
            >
              {types.length > 1 ? t('pageTitle') : t('chooseImage')}
            </UcHeading>
          </Space>
          {types.length > 1 && (
            <div className={styles.TabWrapper}>
              <Tabs
                data-test={createDataTestAttribute({
                  dataTestAttribute: DataTestAttributes.Component,
                  prefix: 'tabs'
                })}
                size="large"
                defaultActiveKey={types[0]}
                onChange={onTabChange}
                tabBarStyle={{ marginBottom: 0 }}
                centered
                moreIcon={null}
              >
                {types.map(type => (
                  <TabPane tab={t(`${type}Tab`)} key={type} />
                ))}
              </Tabs>
            </div>
          )}
        </div>
      }
      width={'97vw'}
      style={{ position: 'relative', top: 24, padding: 0 }}
      footer={
        <>
          <Button
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Button,
              prefix: 'cancel'
            })}
            onClick={onCancel}
          >
            {t('common:cancel')}
          </Button>
          <Button
            data-test={createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Button,
              prefix: 'select'
            })}
            disabled={!selectedMedias.length}
            type="primary"
            onClick={() => {
              onInsert(selectedMedias);
            }}
          >
            {t('addSelection')}
          </Button>
        </>
      }
      onCancel={onCancel}
      styles={{
        body: {
          padding: 0,
          background: 'rgba(0, 0, 0, 0.06)',
          display: 'flex',
          flexDirection: 'column'
        },
        footer: {
          marginTop: 0
        }
      }}
      open={visible}
    >
      <div
        className={styles.ImagesGrid}
        style={{
          height:
            (selectedMedias.length && multiSelect
              ? gridHeightWithSelection
              : gridHeight) + 'vh'
        }}
      >
        <ActionBar
          left={
            <ActionBar.Search
              data-test={createDataTestAttribute({
                dataTestAttribute: DataTestAttributes.Input,
                prefix: 'search'
              })}
              style={{ width: '160px' }}
              placeholder={t('common:search')}
              onChange={event => {
                setSearch(event.currentTarget.value);
              }}
            />
          }
          right={
            <>
              <ActionBar.Dropdown
                triggerType="link"
                menuItems={orderMenuItems}
                label={t(orderBy.title)}
              />

              <ActionBar.Dropdown
                menuItems={getAddNewMenuItems()}
                label={t('common:addNew')}
              />
            </>
          }
        />
        {parentId && <Breadcrumb onClick={onBreadcrumbClick} items={items} />}

        {total && total > 0 ? (
          <div className={styles.ImagesWrapper} onScroll={onScroll}>
            <Row gutter={[24, 18]}>
              {media.map((v: mediaWithFolders_mediaWithFolders_data) => {
                const isItemHidden = hiddenIds?.includes(v.id);
                const isFolder = v.__typename === 'Folder';
                const checked = selectedMedias.includes(v.id);

                if (isItemHidden) {
                  return null;
                }

                const button = (
                  <Button
                    data-test={createDataTestAttribute({
                      dataTestAttribute: DataTestAttributes.Button,
                      prefix: isFolder ? 'open' : 'preview'
                    })}
                    size="small"
                    type="primary"
                    onClick={e => {
                      e.stopPropagation();

                      if (isFolder) {
                        setParentId(v.id);
                        getImages({
                          variables: {
                            orderBy: [{ order, column }],
                            first: 25,
                            parentId: v.id,
                            page: 1,
                            type: mediaTypeMapper[type]
                          }
                        });
                      } else {
                        setPreviewMedia(v as MediaWithoutFolder);
                        setVisibleModal(MediaModal.preview);
                      }
                    }}
                  >
                    {isFolder ? t('common:open') : t('common:preview')}
                  </Button>
                );

                const cover = isFolder ? (
                  <>
                    <FolderFilled className={styles.FolderIcon} />
                    <small className={styles.Count}>
                      {'children' in v && v?.children?.length}
                    </small>
                  </>
                ) : type === 'audios' ? (
                  v.thumbnail ? (
                    <LazyImage
                      src={thumbnailPath[type] + v.thumbnail}
                      alt="media"
                      height="100%"
                    />
                  ) : (
                    <CustomerServiceFilled className={styles.AudioIcon} />
                  )
                ) : (
                  <div className={styles.Image}>
                    <LazyImage
                      src={thumbnailPath[type] + v.thumbnail}
                      alt="media"
                      height="100%"
                    />
                    {type === 'videos' && (
                      <PlayCircleFilled className={styles.PlayIcon} />
                    )}
                  </div>
                );

                return (
                  <Col xs={12} sm={8} md={6} lg={3} key={v.id}>
                    <Card
                      className={styles.StyledCard}
                      bodyStyle={{
                        padding: '8px 16px'
                      }}
                      cover={
                        <div className={styles.CoverWrapper}>
                          {cover}
                          <Overlay
                            onClick={
                              isFolder ? undefined : () => onOverlayClick(v.id)
                            }
                            centerContent={button}
                            topLeftContent={
                              isFolder ? null : (
                                <Overlay.BulkSelect
                                  type={multiSelect ? 'checkbox' : 'radio'}
                                  isChecked={checked}
                                  onChange={() => onOverlayClick(v.id)}
                                />
                              )
                            }
                          />
                        </div>
                      }
                    >
                      <Meta
                        description={
                          <Typography.Paragraph
                            className={styles.FileName}
                            ellipsis
                            title={v.name}
                          >
                            {`${v?.name}${
                              v.__typename !== 'Folder'
                                ? '.' + v?.extension
                                : ''
                            }`}
                          </Typography.Paragraph>
                        }
                      />
                    </Card>
                  </Col>
                );
              })}
              {loading && <Skeleton.Grid count={6} />}
            </Row>
          </div>
        ) : (
          <>
            {total === 0 ? (
              <Empty type={type} />
            ) : (
              <Row gutter={[24, 18]}>
                <Skeleton.Grid count={12} />
              </Row>
            )}
          </>
        )}
      </div>
      {renderPreview()}
      {type === 'images' && (
        <StockImages
          loadRemoteMediaLoading={loadRemoteMediaLoading}
          onLoadRemoteMedia={onLoadRemoteMedia}
          visible={visibleModal === MediaModal.stock}
          onCancel={() => {
            setVisibleModal(null);
          }}
        />
      )}
      <ImportFromUrlModal
        loading={loadRemoteMediaLoading}
        onConfirm={handleImportFromUrlConfirm}
        visible={visibleModal === MediaModal.link}
        onCancel={() => setVisibleModal(null)}
      />
      {uploadList && (
        <Uploader
          list={uploadList}
          onClose={() => setUploadList(null)}
          position="absolute"
        />
      )}
      {!loading && multiSelect && !!selectedMedias.length && (
        <div className={styles.SelectedMediaFooter}>
          <Typography.Text type="secondary" style={{ marginBottom: 8 }}>
            {t('selectedMedia')}
          </Typography.Text>
          <div className={styles.SelectedMediaWrapper}>
            {selectedMedias.map(id => {
              const media = getMediaById(id);
              const folder = Object.keys(mediaTypeMapper).find(
                key =>
                  mediaTypeMapper[key as keyof typeof mediaTypeMapper] ===
                  media?.type
              );

              return (
                media && (
                  <Space key={id} direction="vertical" size={1}>
                    <div
                      className={styles.Thumbnail}
                      style={{ width: '64px', height: '64px' }}
                    >
                      <LazyImage
                        src={
                          thumbnailPath[folder as keyof typeof thumbnailPath] +
                          media.thumbnail
                        }
                        alt="media"
                        width={64}
                      />
                      {media.type === 'VIDEO' && (
                        <PlayCircleFilled className={styles.PlayIcon} />
                      )}
                      <Overlay
                        onClick={() => onOverlayClick(id)}
                        centerContent={
                          <div className={styles.DeleteMediaWrapper}>
                            <DeleteOutlined />
                          </div>
                        }
                      />
                    </div>
                    <div className={styles.SelectedMediaTitleWrapper}>
                      <Typography.Text ellipsis style={{ width: 64 }}>
                        {media.filename}
                      </Typography.Text>
                    </div>
                  </Space>
                )
              );
            })}
          </div>
        </div>
      )}
    </Modal>
  );
}

export default UploadFromMedia;
