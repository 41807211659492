import { useState, useMemo, Key, FC } from 'react';
import {
  useNavigate,
  useLocation,
  NavigateOptions,
  To
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash';
import useCanFunction from 'providers/PermissionAndLimitationProvider/hooks/useCanFunction';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';
import createRootPath from 'helpers/commonHelpers/createRootPath';

import {
  SiderMenuItemType,
  SiderMenuProps
} from 'components/global/Sider/types';
import { Menu } from 'components/basic';
import useMenuActiveUrl from 'hooks/useMenuActiveUrl';
import styles from './styles.module.less';
import { createMenuItems } from 'components/global/Sider/utils';
import { SIDER_MENU_PATHS } from 'components/global/Sider/constants';

const SiderMenu: FC<SiderMenuProps> = ({
  projectName,
  collapsed,
  onClosePopup
}) => {
  const [openKeys, setOpenKeys] = useState<Key[]>([]);
  const { t } = useTranslation('menu');
  const navigate = useNavigate();
  const CanFunction = useCanFunction('menu');
  const location = useLocation();
  const isMobile = useReactiveVar(isMobileDevice);

  const navigateWithCheck = (toPath: To, options?: NavigateOptions) => {
    const _toPath =
      toPath.toString().charAt(0) === '/'
        ? toPath
        : createRootPath({
            projectName,
            path: toPath as string
          });

    if (_toPath !== location.pathname) {
      navigate(toPath, options);
    }
  };

  const menuItems = useMemo(
    () =>
      createMenuItems({
        t,
        Can: CanFunction,
        navigate: navigateWithCheck as NavigateFunction,
        label: (title: string) => title,
        onClick: (url, type) => {
          const toPath = createRootPath({
            projectName,
            path: url
          });

          navigateWithCheck(toPath);
          onClosePopup?.(); //for only mobile

          if (type === SiderMenuItemType.SingleItem) {
            setOpenKeys([]);
          }
        },
        isMobile
      }) as ItemType[],
    [navigateWithCheck]
  );

  const menuActiveUrl = includes(location.pathname, 'settings')
    ? 'settings'
    : useMenuActiveUrl(SIDER_MENU_PATHS);

  const onOpenChange = (keys: Key[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      openKeys={[...openKeys].map(String)}
      style={{
        border: 'none',
        paddingRight: collapsed ? 12 : 24,
        backgroundColor: 'unset',
        paddingBottom: isMobile ? 94 : 0
      }}
      selectedKeys={[menuActiveUrl || '']}
      mode="inline"
      items={menuItems}
      className={styles.sider_menu}
    />
  );
};

export default SiderMenu;
