import { FC } from 'react';
import { useFilterQuery, useHistory, useScreenBreakpoints } from 'hooks';
import { noop } from 'lodash';

import {
  FilterElement as FilterElementType,
  FilterElementType as FilterElementTypes
} from 'components/shared/Filter/types';
import { FilterDrawer } from 'components/shared';
import { useData } from 'components/shared/Filter/hooks';
import {
  buttonGroupChangeHandler,
  dateChangeHandler,
  multiselectChangeHandler,
  numberInputChangeHandler,
  selectChangeHandler
} from './utils';
import { mapping } from './constants';

const { FilterItem } = FilterDrawer;

const changeHandlersMapping: Record<FilterElementTypes, any> = {
  buttonGroups: buttonGroupChangeHandler,
  select: () => selectChangeHandler,
  multiselect: () => multiselectChangeHandler,
  priceRange: () => noop,
  number: () => numberInputChangeHandler,
  date: () => dateChangeHandler,
  dateRange: () => noop
};

const FilterElement: FC<FilterElementType> = props => {
  const screens = useScreenBreakpoints();
  const { queryObject } = useFilterQuery();
  const { push, resetQuery } = useHistory();

  if (props.type === 'custom') {
    return <>{props.element}</>;
  }

  const {
    type,
    filterProps,
    valueModifier,
    loader,
    name,
    emptyValue,
    ...rest
  } = props;

  const { data, loading } = useData(loader);

  const value = queryObject[name] || emptyValue;

  const onChange = (...args: any[]) => {
    let params;

    if (type === 'buttonGroups') {
      params = props.multiple;
    }

    const value = changeHandlersMapping[type](params)(...args);

    if (value === null || value === '') {
      resetQuery(name);
    } else {
      push({ [name]: value });
    }
  };

  if (props.filterProps.showOnlyMobile && screens.sm) {
    return null;
  }

  return (
    <FilterItem {...filterProps}>
      {mapping[type]({
        data,
        loading,
        onChange,
        name,
        ...rest,
        value: valueModifier ? valueModifier?.(value) : value
      })}
    </FilterItem>
  );
};

export default FilterElement;
