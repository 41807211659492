import { BaseType } from 'antd/es/typography/Base';
import { ReactNode } from 'react';
import { SortTypes } from 'core/globalTypes';

export enum popupTypes {
  delete = 'delete',
  filters = 'filters'
}

export interface MenuInterface {
  type?: BaseType;
  label: string;
  value: string;
  color?: string;
  icon?: ReactNode;
  dataTest?: string;
  isMobile?: boolean;
  order: number;
}

export interface DefaultSortDataInterface {
  value: SortTypes;
  name: string;
}
