import { FC } from 'react';

import { FilterElementType } from 'components/shared/Filter/types';
import { RangePickerFormatter, FilterDrawer } from 'components/shared';

const {
  PriceRange,
  ButtonGroups,
  MultiSelectFilter,
  SelectFilter,
  Date,
  NumberInput
} = FilterDrawer;

export const mapping: Record<FilterElementType, FC<any>> = {
  priceRange: PriceRange,
  buttonGroups: ButtonGroups,
  multiselect: MultiSelectFilter,
  select: SelectFilter,
  date: Date,
  dateRange: RangePickerFormatter,
  number: NumberInput
};
