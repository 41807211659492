import { Col, Skeleton } from 'antd';

import styles from './styles.module.less';

type Props = {
  count?: number;
};

function GridSkeleton({ count = 6 }: Props) {
  return (
    <>
      {[...Array(count)].map((_, i) => (
        <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4} key={i}>
          <Skeleton.Input className={styles.styledSkeleton} active />
        </Col>
      ))}
    </>
  );
}

export default GridSkeleton;
