import { useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';

import {
  Actions,
  useCheckPermissions
} from './PermissionAndLimitationProvider';

export const isMarketplace = makeVar(false);
export const useIsMarketplace = () => useReactiveVar(isMarketplace);

const MarketplaceProvider = () => {
  const { [Actions.Has.Marketplace]: hasMarketplace } = useCheckPermissions([
    Actions.Has.Marketplace
  ]);

  useEffect(() => {
    isMarketplace(hasMarketplace);
  }, [isMarketplace]);

  return <></>;
};

export default MarketplaceProvider;
