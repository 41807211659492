import { FC, ReactNode } from 'react';
import isMarketplaceSellerMode from 'helpers/commonHelpers/isMarketplaceSellerMode';

import ShowElement from 'components/shared/ShowElement';

interface Props {
  children: ReactNode;
}

const MarketplaceSellerModeGuard: FC<Props> = ({ children }) => {
  return (
    <ShowElement isShow={!isMarketplaceSellerMode()}>{children}</ShowElement>
  );
};

export default MarketplaceSellerModeGuard;
