import { FC, useEffect, useState } from 'react';
import { Drawer, Typography } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useProjectContext } from 'providers/ProjectProvider';
import { ProjectsListDrawer } from 'containers/layouts/Web/components/ProjectsListDrawer';
import marketplaceSellerModeGuard from 'helpers/commonHelpers/marketplaceSellerModeGuard';

import { ProjectsNavBarProps } from 'components/global/Header/types';
import styles from './styles.module.less';
import MarketplaceSellerModeGuard from 'components/shared/MarketplaceSellerModeGuard';

const { Text } = Typography;

const iconStyles = {
  fontSize: '10px',
  marginLeft: 5
};

const ProjectsNavBar: FC<ProjectsNavBarProps> = ({
  visible,
  visibleHandlerProjectsNavbar
}) => {
  const project = useProjectContext();
  const [isSmallNavbar, setIsSmallNavbar] = useState(window.innerWidth < 379);

  useEffect(() => {
    const listener = () => {
      setIsSmallNavbar(window.innerWidth < 379);
    };

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  return (
    <nav className={styles.navbar}>
      <div
        className={styles.title}
        onClick={marketplaceSellerModeGuard(() =>
          visibleHandlerProjectsNavbar(!visible)
        )}
      >
        <Text
          title={project?.clientName || ''}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 100
          }}
        >
          {project?.clientName || null}
        </Text>
        <MarketplaceSellerModeGuard>
          {!visible ? (
            <DownOutlined style={iconStyles} />
          ) : (
            <UpOutlined style={iconStyles} />
          )}
        </MarketplaceSellerModeGuard>
      </div>
      <MarketplaceSellerModeGuard>
        <Drawer
          rootClassName={styles.projectsNavbar}
          contentWrapperStyle={{ width: isSmallNavbar ? 320 : 378 }}
          height={'100%'}
          placement="top"
          styles={{
            header: {
              paddingLeft: 20,
              height: 48
            },
            body: {
              padding: `24px 0`
            }
          }}
          closeIcon={<></>}
          onClose={() => visibleHandlerProjectsNavbar(false)}
          open={visible}
        >
          <ProjectsListDrawer
            fullHeight={true}
            currentProjectId={project?.id}
          />
        </Drawer>
      </MarketplaceSellerModeGuard>
    </nav>
  );
};

export default ProjectsNavBar;
