import client from 'apollo/apolloClient';
import { MarketsForFilterDocument } from 'generatedHooks/commerce/generated';

export const getMarkets = async () => {
  try {
    const result = await client.query({
      query: MarketsForFilterDocument
    });

    return {
      data: result.data.marketsForFilter,
      error: undefined
    };
  } catch (e) {
    return { data: undefined, error: e };
  }
};
