import marketplaceSellerModeGuard from 'helpers/commonHelpers/marketplaceSellerModeGuard';

const createRootPath = ({
  projectName,
  path
}: {
  projectName: string;
  path: string;
}) => {
  const prefix = marketplaceSellerModeGuard(`/${projectName}`, '');

  return `${prefix}/${path}`;
};

export default createRootPath;
