import Icon, {
  AppstoreOutlined,
  AuditOutlined,
  BugOutlined,
  FileTextOutlined,
  FlagOutlined,
  MacCommandOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SoundOutlined,
  TeamOutlined,
  BulbOutlined
} from '@ant-design/icons';
import { ReactComponent as FiverrIcon } from 'assets/icons/fiverr.svg';

import {
  HelpMenuItemsKeys,
  HelpMenuItemType,
  IHelpMenuItemOptions
} from 'components/global/Help/types';

const {
  VITE_APP_UCRAFT_COMMUNITY,
  VITE_APP_UCRAFT_STATUS,
  VITE_APP_HELP_CENTER,
  VITE_APP_TECH_DOCS,
  VITE_APP_API_DOCS,
  VITE_APP_RELEASE_NOTES,
  VITE_APP_BUG_REPORT,
  VITE_APP_USERSNAP_PROJECT_API_KEY = '',
  VITE_APP_FIVERR_URL
} = import.meta.env;

const helpMenuItems: IHelpMenuItemOptions[] = [
  {
    key: HelpMenuItemsKeys.SUPPORT,
    type: HelpMenuItemType.GROUP
  },
  {
    key: HelpMenuItemsKeys.UCRAFT_COMMUNITY,
    type: HelpMenuItemType.LINK,
    url: VITE_APP_UCRAFT_COMMUNITY, //ai
    icon: <TeamOutlined />
  },
  {
    key: HelpMenuItemsKeys.GIVE_FEEDBACK,
    type: HelpMenuItemType.ACTION,
    onClick: () => () => {
      window.usersnapApi
        ?.show(VITE_APP_USERSNAP_PROJECT_API_KEY)
        .then(widgetApi => widgetApi.open());
    },
    icon: <SoundOutlined />
  },
  {
    key: HelpMenuItemsKeys.FEATURE_REQUEST,
    type: HelpMenuItemType.LINK,
    url: `${VITE_APP_UCRAFT_COMMUNITY}/feature-requests`,
    icon: <BulbOutlined />
  },
  {
    key: HelpMenuItemsKeys.HIRE_AN_EXPERT,
    type: HelpMenuItemType.LINK,
    url: VITE_APP_FIVERR_URL,
    icon: <Icon component={FiverrIcon} viewBox="0 0 120 120" />
  },
  {
    key: HelpMenuItemsKeys.UCRAFT_STATUS,
    type: HelpMenuItemType.LINK,
    url: VITE_APP_UCRAFT_STATUS,
    icon: <FlagOutlined />
  },
  {
    key: HelpMenuItemsKeys.KEYBOARD_SHORTCUTS,
    type: HelpMenuItemType.ACTION,
    onClick: ({ openKeyboardShortcutsGuide }) => openKeyboardShortcutsGuide,
    icon: <MacCommandOutlined />
  },
  {
    key: HelpMenuItemsKeys.APPLICATION_QRS,
    type: HelpMenuItemType.ACTION,
    onClick: ({ openQrsModal }) => openQrsModal,
    icon: <AppstoreOutlined />
  },
  {
    key: HelpMenuItemsKeys.HELP_CENTER,
    type: HelpMenuItemType.LINK,
    url: VITE_APP_HELP_CENTER,
    icon: <QuestionCircleOutlined />
  },
  {
    key: HelpMenuItemsKeys.RESOURCES,
    type: HelpMenuItemType.GROUP
  },
  {
    key: HelpMenuItemsKeys.TECH_DOCS,
    type: HelpMenuItemType.LINK,
    url: VITE_APP_TECH_DOCS, //com
    icon: <FileTextOutlined />
  },
  {
    key: HelpMenuItemsKeys.API_DOCS,
    type: HelpMenuItemType.LINK,
    url: VITE_APP_API_DOCS, //ai
    icon: <AuditOutlined />
  },
  {
    key: HelpMenuItemsKeys.RELEASE_NOTES,
    type: HelpMenuItemType.LINK,
    url: VITE_APP_RELEASE_NOTES, //ai
    icon: <ProfileOutlined />
  },
  {
    key: HelpMenuItemsKeys.BUG_REPORT,
    type: HelpMenuItemType.LINK,
    url: VITE_APP_BUG_REPORT, //ai
    icon: <BugOutlined />
  }
];

export default helpMenuItems;
